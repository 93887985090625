import Person from './person';
import Partnership from './partnership';
import relationships from './relationships';

import { createTransformer } from 'mobx-utils';

const getPartners = (person: Person): Person[] => {
  // @ts-ignore
  return person.partnerships
    .filter(partnership => partnership.hasBothParents)
    .map(partnership => partnership.getPartner(person))
}

const getChildren = (person: Person): Person[] => {
  return person.partnerships
    .map(partnership => partnership.children)
    .reduce((acc, curr) => [...acc, ...curr.slice()], []);
}

const getSiblings = (person: Person): Person[] => {
  if (!person.parentPartnership) return [];
  return person.parentPartnership.children
    .filter(child => child !== person);
}

const getParents = (person: Person): Person[] => {
  const parents: Person[] = [];
  if (person.parentPartnership && person.parentPartnership.parent1)
    parents.push(person.parentPartnership.parent1);

  if (person.parentPartnership && person.parentPartnership.parent2)
    parents.push(person.parentPartnership.parent2);

  return parents;
}

interface calculateShortestDistanceInput {
  people: Person[];
  root: Person;
}

interface calculateShortestDistanceOutput {
  dist: Map<Person, number>;
  pred: Map<Person, Person | null>;
}

const calculateShortestDistance = createTransformer(({ people, root }: calculateShortestDistanceInput) => {
  const dist = new Map<Person, number>();
  const pred = new Map<Person, Person | null>();
  const visited = new Map<Person, boolean>();

  people.forEach(person => {
    dist.set(person, Infinity);
    pred.set(person, null);
    visited.set(person, false);
  });

  dist.set(root, 0);

  const queue: Person[] = [];

  queue.push(root);
  visited.set(root, true);

  function visit(person: Person | undefined | null, current: Person) {
    if (person && !visited.get(person)) {
      queue.push(person);
      visited.set(person, true);

      const distance: number = dist.get(current)! + 1;

      if (distance < dist.get(person)!) {
        pred.set(person, current);
        dist.set(person, distance);
      }
    }
  }

  while (queue.length > 0) {
    const current = queue.shift() as Person;

    if (current.parentPartnership) {
      const { parent1, parent2 } = current.parentPartnership;
      visit(parent1, current);
      visit(parent2, current);
    }

    for (let i = 0; i < current.partnerships.length; i++) {
      const partnership = current.partnerships[i];
      const { parent1, parent2 } = partnership;
      visit(parent1, current);
      visit(parent2, current);

      for (let j = 0; j < partnership.children.length; j++) {
        const child = partnership.children[j];
        visit(child, current);
      }
    }
  }

  const display = {};

  people.forEach(person => {

    let path = '';
    let pre = pred.get(person);

    while (pre) {
      path += `-> ${pre.displayName} `;
      pre = pred.get(pre);
    }

    display[person.displayName] = [path, dist.get(person)];
  });

  //console.table(display);

  return {
    dist,
    pred
  };
})

interface buildRelationshipStringInput {
  people: Person[],
  source: Person;
  target: Person;
}

const buildRelationshipString = createTransformer(({ people, source, target }: buildRelationshipStringInput) => {

  if (target === source) {
    return '';
  };

  const { dist, pred } = calculateShortestDistance({
    people: people,
    root: source
  });

  let current: Person = target;
  let predecessor = pred.get(target);
  let relationString = '';
  let genderString = '';

  while (predecessor) {

    if (predecessor.parentPartnership) {
      const { parent1, parent2 } = predecessor.parentPartnership;
      if (parent1 === current || parent2 === current) {
        const parent = parent1 === current ? parent1 : parent2 as Person;

        relationString = '1' + relationString;
        genderString = (parent.gender === 'female' ? 'f' : 'm') + genderString;
      }
    }

    for (let i = 0; i < predecessor.partnerships.length; i++) {
      const partnership = predecessor.partnerships[i];
      const { parent1, parent2 } = partnership;
      if (parent1 === current || parent2 === current) {
        const parent = parent1 === current ? parent1 : parent2 as Person;
        relationString = '2' + relationString;
        genderString = (parent.gender === 'female' ? 'f' : 'm') + genderString;
      }

      for (let j = 0; j < partnership.children.length; j++) {
        const child = partnership.children[j];
        if (child === current) {
          relationString = '0' + relationString;
          genderString = (child.gender === 'female' ? 'f' : 'm') + genderString;
        }
      }
    }

    current = predecessor;
    predecessor = pred.get(current);
  }

  const relationshipObject = relationships.get(relationString);

  if (relationshipObject) {
    return relationshipObject[target.gender];
  }

  for (let i = relationString.length - 1; i > 0; i--) {
    const firstPart = relationString.substring(0, i);
    const secondPart = relationString.substr(i);

    const firstRelationship = relationships.get(firstPart);
    const secondRelationship = relationships.get(secondPart);

    if (firstRelationship && secondRelationship) {
      if (genderString[i - 1] === 'f') {
        return `${firstRelationship.female}'s ${secondRelationship[target.gender]}`;
      }
      return `${firstRelationship.male}'s ${secondRelationship[target.gender]}`;
    }
  }
  return 'Other';

})

export {
  getPartners,
  getChildren,
  getParents,
  getSiblings,
  calculateShortestDistance,
  buildRelationshipString
}