import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import remove from 'lodash/remove';
import find from 'lodash/find';
import moment from 'moment';
import { service } from 'state/apiClient';
import { Notification } from 'state/objects';
import { Service } from '@feathersjs/feathers';
import { INotification } from 'state/apiClient/ServiceTypes';
import AccountStore from 'state/account';
import RootStore from 'state/rootStore';

class NotificationStore {

  service: Service<INotification>;
  rootStore: RootStore;

  @observable notifications: Notification[] = [];
  @observable loading: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.service = service('notifications');

    this.initEvents();
  }

  initEvents() {
    this.service.on('created', n => this.onCreated(n));
    this.service.on('patched', n => this.onPatched(n));
    this.service.on('updated', n => this.onPatched(n));
    this.service.on('removed', n => this.onRemoved(n));
  }

  @computed
  get numberOfUnread(): number {
    return this.notifications.reduce((sum, not) => sum + (not.read ? 0 : 1), 0);
  }

  @action
  addToArray(data: INotification): Notification {
    let notification = find(this.notifications, n => n._id === data._id);

    if (notification) {
      Object.assign(notification, data);
    } else {
      notification = new Notification(this.rootStore, this, data);
      this.notifications.push(notification);
    }
    return notification;
  }

  @action
  async fetchAll() {
    this.loading = true;
    try {
      const response = await this.service.find({}) as INotification[];

      await Promise.all(response.map(n => this.fetchRelevantResource(n)))

      this.notifications = response.map(n => new Notification(this.rootStore, this, n));
    } catch (e) {
      console.error('NotificationStore.fetchAll()', e);
    } finally {
      this.loading = false;
    }
  }

  @action
  async fetchRelevantResource(notification: INotification) {
    switch (notification.relatedKind) {
      case 'connections':
        await this.rootStore.connectionStore.fetchById(notification.relatedId);
        break;
      case 'photos':
        await this.rootStore.photoStore.fetchById(notification.relatedId);
        break;
      case 'videos':
        await this.rootStore.videoStore.fetchById(notification.relatedId);
        break;
      case 'guestbookEntries':
        await this.rootStore.guestbookEntryStore.fetchById(notification.relatedId);
        break;
      case 'comments':
        await this.rootStore.commentStore.fetchById(notification.relatedId);
        break;
      case 'familyTrees':
        await this.rootStore.familyTreeStore.fetchById(notification.relatedId);
        break;
    }

  }

  @action
  async markAsRead(id: number) {
    const notification = this.notifications.find(n => n._id === id);
    if (notification) {
      try {
        await this.service.patch(id, { read: true });
        notification.read = true;
      } catch (err) {
        console.error(err);
      }
    }
  }

  @action
  async markAllAsRead() {
    return Promise.all(
      this.notifications
        .filter(n => !n.read)
        .map(n => this.markAsRead(n._id))
    );
  }

  @action
  reviewTreeJoinRequest(notificationId: number) {

    const notification = this.notifications.find(n => n._id === notificationId);
    if (notification) {

      this.rootStore.connectionStore.declineConnection(notification.relatedId)
        .then(() => {
          return this.service.remove(notificationId);
        })
        .then(notif => {
          remove(this.notifications, n => n._id === notif._id);
        });
    }
  }

  @action
  acceptConnection(notificationId: number) {
    const notification = this.notifications.find(n => n._id === notificationId);
    if (notification) {
      //accept connection
      this.rootStore.connectionStore.acceptConnection(notification.relatedId)
        .then(() => {
          return this.service.remove(notificationId);
        })
        .then(notif => {
          remove(this.notifications, n => n._id === notif._id);
        });
    }
  }

  @action
  declineConnection(notificationId: number) {
    const notification = this.notifications.find(n => n._id === notificationId);
    if (notification) {
      //decline connection
      this.rootStore.connectionStore.declineConnection(notification.relatedId)
        .then(() => {
          return this.service.remove(notificationId);
        })
        .then(notif => {
          remove(this.notifications, n => n._id === notif._id);
        });
    }
  }

  @action
  onCreated(notification: INotification) {
    this.fetchRelevantResource(notification)
      .then(() => {
        this.addToArray(parseNotificationData(notification));
      })
  }

  @action
  onPatched(notification: INotification) {
    this.fetchRelevantResource(notification)
      .then(() => {
        this.addToArray(parseNotificationData(notification));
      })
  }

  @action
  onRemoved(notification: INotification) {
    remove(this.notifications, n => n._id === notification._id);
  }
}

function parseNotificationData(notif) {
  if (notif.createdAt) {
    notif.createdAt = moment(notif.createdAt);
  }
  return notif;
}

export default NotificationStore;
