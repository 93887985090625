import accountStoreClass from './account';
import searchStoreClass from './search';
import uiStoreClass from './ui';
import rootStoreClass from './rootStore';
import memorialStoreClass from './stores/memorials';
import profileStoreClass from './stores/profiles';
import guestbookEntryStoreClass from './stores/guestbookEntries';
import commentStoreClass from './stores/comments';
import videoStoreClass from './stores/videos';
import photoStoreClass from './stores/photos';
import connectionStoreClass from './stores/connections';
import chatStoreClass from './stores/chats';
import notificationStoreClass from './stores/notifications';
import messageStoreClass from './stores/chatMessages';
import familyTreeStoreClass from './stores/familyTrees';
import cookieStoreClass from './cookieStore';
import reportStoreClass from './reportStore';

let accountStore: accountStoreClass;
let searchStore: searchStoreClass;
let uiStore: uiStoreClass;
let rootStore: rootStoreClass;
let memorialStore: memorialStoreClass;
let profileStore: profileStoreClass;
let guestbookEntryStore: guestbookEntryStoreClass;
let commentStore: commentStoreClass;
let videoStore: videoStoreClass;
let photoStore: photoStoreClass;
let connectionStore: connectionStoreClass;
let chatStore: chatStoreClass;
let notificationStore: notificationStoreClass;
let messageStore: messageStoreClass;
let familyTreeStore: familyTreeStoreClass;
let cookieStore: cookieStoreClass;
let reportStore: reportStoreClass;

function initializeStores () {
  rootStore = new rootStoreClass();
  memorialStore = new memorialStoreClass(rootStore);
  accountStore = new accountStoreClass(rootStore); 
  profileStore = new profileStoreClass(rootStore);
  uiStore = new uiStoreClass();
  searchStore = new searchStoreClass(rootStore);
  guestbookEntryStore = new guestbookEntryStoreClass(rootStore);
  commentStore = new commentStoreClass(rootStore);
  videoStore = new videoStoreClass(rootStore);
  photoStore = new photoStoreClass(rootStore);
  connectionStore = new connectionStoreClass(rootStore);
  chatStore = new chatStoreClass(rootStore);
  notificationStore = new notificationStoreClass(rootStore);
  messageStore = new messageStoreClass(rootStore);
  familyTreeStore = new familyTreeStoreClass(rootStore);
  cookieStore = new cookieStoreClass(rootStore);
  reportStore = new reportStoreClass(rootStore);

  rootStore.accountStore = accountStore;
  rootStore.memorialStore = memorialStore;
  rootStore.profileStore = profileStore;
  rootStore.guestbookEntryStore = guestbookEntryStore;
  rootStore.commentStore = commentStore;
  rootStore.videoStore = videoStore;
  rootStore.photoStore = photoStore;
  rootStore.connectionStore = connectionStore;
  rootStore.chatStore = chatStore;
  rootStore.notificationStore = notificationStore;
  rootStore.messageStore = messageStore;
  rootStore.familyTreeStore = familyTreeStore;
  rootStore.cookieStore = cookieStore;
  rootStore.reportStore = reportStore;
}


export { 
  initializeStores, 
  accountStore, 
  uiStore, 
  searchStore, 
  rootStore, 
  connectionStore, 
  memorialStore, 
  profileStore, 
  guestbookEntryStore, 
  commentStore,
  videoStore,
  photoStore,
  chatStore,
  notificationStore,
  messageStore,
  familyTreeStore,
  cookieStore,
  reportStore
};
