import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';

import {
  Modal,
  Form,
  Button,
  Image,
  Icon,
  Divider,
  Message,
  Loader,
  Radio
} from 'semantic-ui-react';

import {
  Profile,
  Memorial
} from 'state/objects';

import Person from 'state/familyTree/person';
import AccountStore from 'state/account';

import Select from 'react-select';
import ProfileOption from 'components/ProfileOption';

import ImageUploader from 'components/ImageUploader';

import { PersonForm } from 'state/stores/forms';
import { MemorialStore } from 'state/stores';

import DateSelect from "components/Generic/DateSelecter";
import UserAvatar from 'components/Generic/Avatar';

export interface Props {
  treeRootPerson: Profile | Memorial;
  person: Person;
  open: boolean
  onClose(): any;
  accountStore?: AccountStore;
  history?: any;
}

const shouldForceMerge = (rootProfile: Profile | Memorial, connectedProfile: Profile | Memorial) => {
  return ((rootProfile instanceof Profile && rootProfile.userIsOwner) || (rootProfile instanceof Memorial && rootProfile.userIsCreator))
    && ((connectedProfile instanceof Profile && connectedProfile.userIsOwner) || (connectedProfile instanceof Memorial && connectedProfile.userIsCreator));
}

@inject('accountStore', 'memorialStore') @observer
class PersonEditModal extends React.Component<Props, {}> {

  @observable form: PersonForm | null;

  accountStore: AccountStore;

  @observable profileSelectValue: string = '';
  @observable avatarModalOpen: boolean = false;
  @observable loading: boolean = false;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = props.accountStore!;
  }

  componentDidMount() {
    this.props.person.getForm()
      .then(returnedForm => {
        this.form = returnedForm
      });
    if (this.accountStore.myProfile) {
      this.accountStore.myProfile.fetchMemorials();
    }
  }

  selectProfile(listValue: any) {

    if (!this.form) return;

    const split = listValue.value.split('_');

    if (split[0] === 'memorial') {

      this.accountStore.rootStore.memorialStore.fetchById(split[1])
        .then(memorial => {
          this.props.person.editModel.connectedPageId = memorial._id;
          this.props.person.editModel.connectedPageKind = 'memorials';
          this.forceUpdate();
        });

    } else {

      this.accountStore.rootStore.profileStore.fetchById(split[1])
        .then(profile => {
          this.props.person.editModel.connectedPageId = profile._id;
          this.props.person.editModel.connectedPageKind = 'profiles';
          this.forceUpdate();
        });
    }
  }

  deselectProfile() {
    // @ts-ignore
    this.props.person.editModel.connectedPageId = null;
  }

  savePerson(event: React.MouseEvent<HTMLButtonElement>) {

    event.preventDefault();

    // När automerge/redirect till treemerge? 
    // Profil addar egen memorial - JA
    // Profil addar annans memorial/profil - NEJ
    // Egen Memorial addar egen memorial - JA
    // Egen Memorial addar annans memorial/profil - NEJ
    // Egen memorial addar egen profil - JA
    // Annans memorial addar XXX - NEJ

    if (this.form) {
      this.loading = true;
      this.form.save()
        .then(() => {
          const { person } = this.props;
          if (person.editModelConnectedPage) {
            const connectedPage = person.editModelConnectedPage;
            const { treeRootPerson } = this.props;

            if (shouldForceMerge(treeRootPerson, connectedPage)) {
              let { familyTree } = connectedPage;
              return (familyTree ? Promise.resolve(familyTree) : connectedPage.fetchFamilyTree())
                .then(familyTree => {
                  if (familyTree.persons.length > 1) {
                    this.props.history.push(`/jointrees/${connectedPage.kind}/${connectedPage._id}/${treeRootPerson.kind}/${treeRootPerson._id}`);
                  } else {
                    return this.accountStore.rootStore.familyTreeStore.autoMerge(this.props.treeRootPerson as Profile, person.editModelConnectedPage as Memorial);
                  }
                });
            }
          }
        })
        .then(() => {
          this.loading = false;
          this.props.onClose();
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
    }



  }

  removePerson() {
    this.props.person.remove();
    this.props.onClose();
  }

  showAvatarModal() {
    if (!this.props.person.editModel.connectedPageId) this.avatarModalOpen = true;
  }

  closeAvatarModal() {
    this.avatarModalOpen = false;
    this.setBodyScrolling();
  }

  uploadAvatar(img) {
    this.closeAvatarModal();
    this.props.person.uploadAvatar(img);
  }

  //done to ensure the first modal is still scrollable
  setBodyScrolling() {
    setTimeout(() => {
      document.body.classList.toggle('scrolling', true);
    }, 0);
  }

  render() {

    let {
      person,
      open,
      onClose
    } = this.props;

    const { form } = this;

    if (!form) {
      return null;
    }

    const fields = form.form.fields;
    const memorials = this.accountStore.myProfile ? this.accountStore.myProfile.memorials : [];
    const connections = this.accountStore.myProfile ? this.accountStore.myProfile.connections : [];
    const { myProfile } = this.accountStore;

    let profileOptions = memorials
      .map(m => {
        return {
          id: m._id,
          value: `memorial_${m._id}`,
          label: m.displayName + " (Memorial)",
          image: m.avatar
        }
      });

    profileOptions = [
      ...profileOptions,
      ...connections.map(c => {
        return {
          id: c.contact!._id,
          value: `profile_${c.contact!._id}`,
          label: c.contact!.displayName,
          image: c.contact!.avatar
        }
      }),
      ...((!person.tree.rootIsUser && myProfile) ? [{
        id: myProfile._id,
        value: `profile_${myProfile._id}`,
        label: myProfile.displayName,
        image: myProfile.avatar,
      }] : [])
    ]
      .filter(profile => {
        return person.tree.connectedIds.indexOf(profile.id) === -1
      });

    const connectedPage = person.editModelConnectedPage;
    const isConnected = connectedPage !== null;

    const gender = connectedPage ? connectedPage.gender : fields._gender.value;

    return (
      <div>

        <Modal
          open
          closeOnRootNodeClick={false}
          onClose={e => {
            onClose();
          }}
          size="tiny"
        >
          <Modal.Content>
            <div style={{ paddingBottom: "1.5rem", textAlign: "center" }}>
              <span style={{ margin: "0 auto" }}>
                <UserAvatar
                  profile={{ avatar: person.avatar, displayName: "", gender: gender }}
                  style={"default"}
                  size={150}
                  noLink={true}
                  noText={true}
                  onClick={() => { this.showAvatarModal(); }}
                />
              </span>
            </div>

            <Form error>

              <Form.Input
                label="First Name"
                placeholder="Enter Name"
                disabled={isConnected}
                value={connectedPage ? connectedPage.firstName : fields._firstName.value}
                onChange={e => form.onFieldChange('_firstName', e.currentTarget.value)}
              />
              <Message error>{fields._firstName.error}</Message>

              <Form.Input
                label="Last Name"
                placeholder="Enter Name"
                disabled={isConnected}
                value={connectedPage ? connectedPage.lastName : fields._lastName.value}
                onChange={e => form.onFieldChange('_lastName', e.currentTarget.value)}
              />
              <Message error>{fields._lastName.error}</Message>

              <Form.Field disabled={isConnected || !person.canChangeGender}>
                <label>
                  Gender:
              </label>
                <Radio
                  label='Female'
                  name='genderRadioGroup'
                  value='true'
                  checked={connectedPage ? connectedPage.gender === 'female' : fields._gender.value === 'female'}
                  onChange={(e) => {
                    form.onFieldChange('_gender', 'female');
                  }}
                />
              </Form.Field>
              <Form.Field disabled={isConnected || !person.canChangeGender}>
                <Radio
                  label="Male"
                  name='genderRadioGroup'
                  value='false'
                  checked={connectedPage ? connectedPage.gender === 'male' : fields._gender.value === 'male'}
                  onChange={(e) => {
                    form.onFieldChange('_gender', 'male');
                  }}
                />
              </Form.Field>
              <Message error>{fields._gender.error}</Message>

              <div className={`field ${isConnected && 'disabled'}`}>
                <label>Date of Birth</label>
                <DateSelect
                  value={fields._dateOfBirth.value ? fields._dateOfBirth.value : null}
                  onChange={d => form.onFieldChange('_dateOfBirth', d)} />
              </div>
              <Message error>{fields._dateOfBirth.error}</Message>

              <div className={`field ${isConnected && 'disabled'}`}>
                <label>Date of Passing</label>
                <DateSelect
                  value={fields._dateOfPassing.value ? fields._dateOfPassing.value : null}
                  onChange={d => form.onFieldChange('_dateOfPassing', d)} />
              </div>
              <Message error>{fields._dateOfPassing.error}</Message>

              <Message error>{form.form.meta.error}</Message>
            </Form>

            <Divider
              horizontal
              style={{
                textTransform: 'none'
              }}
            >
              {isConnected ? "Connected Page" : "OR"}
            </Divider>

            {connectedPage ? (
              <div>
                <Image avatar src={connectedPage.avatar} />
                <span>
                  {connectedPage.displayName}
                  <Icon
                    name="close"
                    color="red"
                    onClick={() => this.deselectProfile()}
                  />
                </span>
              </div>
            ) : (
                <div>
                  <Form.Field>
                    <label
                      style={{
                        display: 'block',
                        marginBottom: '.28571429rem',
                        color: 'rgba(0,0,0,.87)',
                        fontSize: '.92857143em',
                        fontWeight: 700,
                      }}
                    >
                      Link to an existing memorial or profile:
                </label>
                    <Select
                      name="memorial-admins"
                      placeholder="Select memorial or profile..."
                      value={this.profileSelectValue}
                      onChange={val => this.selectProfile(val)}
                      options={profileOptions}
                      optionComponent={ProfileOption}
                    />
                  </Form.Field>
                </div>
              )}


          </Modal.Content>
          <Modal.Actions>
            {(!person.isNew) && (
              <div style={{ float: "left" }}>
                <Button disabled={!person.isRemovable} onClick={() => { this.removePerson(); }}><Icon name='warning sign' />Delete</Button>
              </div>
            )}

            {this.loading && (
              <Loader
                active
                inline
                inverted
              />
            )}
            <Button
              secondary
              disabled={this.loading}
              onClick={() => {
                this.props.person.editModel.reset();
                onClose();
              }}>
              Cancel
            </Button>
            <Button
              type="submit"
              primary
              disabled={!form.form.meta.isValid || this.loading}
              onClick={e => this.savePerson(e)}
            >
              Save
            </Button>
          </Modal.Actions>

        </Modal>


        {this.avatarModalOpen && (
          <Modal
            open
            basic
            closeIcon
            size="large"
            onClose={() => this.closeAvatarModal()}
          >
            <Modal.Content>
              <ImageUploader
                aspectRatio={1}
                onUpload={img => this.uploadAvatar(img)}
              />
            </Modal.Content>
          </Modal>
        )}

      </div>
    );
  }
}


//@ts-ignore
export default withRouter(PersonEditModal);
