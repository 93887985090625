import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link, withRouter } from 'react-router-dom';

import { setTimeout } from 'timers';
import AccountStore from 'state/account';
import { ChatStore, NotificationStore, } from 'state/stores';
import uiStore from 'state/ui';

import {
  Grid,
  Container,
  Menu,
  Icon,
  Input,
  Label,
  Popup,
  List
} from 'semantic-ui-react';

import SearchBar from 'components/Search/SearchBar';
import Notifications from 'components/TopBar/notifications';


export interface Props {
  open: boolean
  onClose(): any;
  match: any;
  history?: any;
  accountStore?: AccountStore;
  uiStore: uiStore;
}

@inject('accountStore', 'chatStore', 'uiStore', 'notificationStore') @observer
class StoreBar extends React.Component<Props, {}> {

  @observable notificationsOpen = false;
  @observable showAccountSettings = false;
 
  accountStore: AccountStore;
  uiStore: uiStore;

  constructor (props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.uiStore = this.props.uiStore;
  }

  render () {

    return (
      <div style={{marginTop:"71px"}} className="light-black">
        <Menu secondary>
            <Menu.Menu position="right" className="brown">
              {/********************************
                FEEDBACK (temp for beta)
              ********************************/}
              <Menu.Item>
                  <a target="_blank" style={{color:"white"}}><Icon name="shopping cart" />Cart: 0</a>
              </Menu.Item>
            </Menu.Menu>

            {/*If logged in show this */}
            {!this.accountStore.myProfile && (
              <Menu.Menu position="right">
                {/********************************
                  FEEDBACK (temp for beta)
                ********************************/}
                <Menu.Item>
                    <a target="_blank" href='https://goo.gl/forms/yWJ2foJq6zxMRcjs1'><Icon name="talk" />Send feedback</a>
                </Menu.Item>
              </Menu.Menu>
            
            )}
          
        </Menu>
      </div>
    );
  }
};

// @ts-ignore
export default withRouter(StoreBar);
