import * as React from 'react';
import { Link } from 'react-router-dom';

import {
  Grid, 
  Container,
  Message
} from 'semantic-ui-react';

function PrivacyPolicy (): JSX.Element {
  return (
    <Grid className="dark-blue no-mobile-padding fill-window-height">
    <Container>
      <Message floating style={{marginTop: '40px'}}>
        <Message.Content>
          <h1>MemoriaTree Privacy Notice</h1>
          <p>Note: This notice was written in English (US). To the extent any translated version of this notice conflicts with the English version, the English (US) version controls.</p>
          <p>This Privacy Notice governs the manner in which MemoriaTree AB (MemoriaTree) collects, uses, maintains and discloses information collected from users (each, a "User") of the&nbsp;<a href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttp://www.memoriatree.com%26sa%3DD%26ust%3D1520208389567000%26usg%3DAFQjCNGfhlg4tz8GBj70GlhTxGB0zVsNHA&amp;sa=D&amp;ust=1593529211082000&amp;usg=AOvVaw2TOp5jzuwHyT0cxHnN6EH7">www.memoriatree.com</a>&nbsp;website ("Site"). This privacy Notice applies to the Site and all products and services offered by MemoriaTree.</p>
          <p>MemoriaTree AB (registration nr: 559231-4057) is responsible for the processing of personal data mentioned in this Privacy notice.</p>
          <p>We process personal data in accordance with the General Data Protection Regulation (2016/679) (GDPR) and continuously strive to provide you with optimal integrity protection.</p>
          <h5>PERSONAL DATA</h5>
          <p>We collect personal data from Users when Users visit our site, register on the site, fill out a form, and in connection with activities, services, features or resources we make available on our Site. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply information, except that it may prevent them from engaging in certain Site related activities.</p>
          <p>When signing up to our Site we ask for first and last name, gender and email address. You may also once you have registered an account choose to provide your place of birth and date of birth on your profile.</p>
          <p>Users may upload personal data of other living individuals, such as names or photographs, but can only do so by first obtaining the concerned living individuals&rsquo; consent.</p>
          <p>When creating a memorial, a User can add a person&rsquo;s first and last name, gender, date of birth, date of passing, place of birth, place of passing, location of resting place and enter a short obituary and biography of the person.</p>
          <p>When creating a family tree, a user can add a person&rsquo;s first and last name, gender, date of birth and date of passing.</p>
          <p>If someone has published your personal data without your permission, you can contact us using the contact details stated below under &ldquo;CONTACTING US&rdquo;.</p>
          <h5>NON-PERSONAL DATA</h5>
          <p>We may collect non-personal data about Users whenever they interact with our Site. Non-personal data may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.</p>
          <h5>OUR USE OF COLLECTED INFORMATION</h5>
          <p>MemoriaTree may collect and use Users personal data for the following purposes:</p>
          <ul>
          <li>To be able to provide our service and communicate with you in relation to such service</li>
          <li>To improve customer service</li>
          <li>Information you provide helps us respond to your customer service requests and support needs more efficiently.</li>
          <li>To personalize user experience</li>
          <li>We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
          <li>To improve our Site</li>
          <li>We may use feedback you provide to improve our products and services.</li>
          </ul>
          <h5>LEGAL BASIS FOR THE PROCESSING</h5>
          <p>The legal basis for the processing of User&rsquo;s name and email address is that the data is necessary for the performance of the contract in which the User is party in or is about to enter into. The legal basis for the processing of User&rsquo;s gender, place of birth and date of birth is our legitimate interest to provide a service where Users can create family trees and similar functions. Processing of personal data that is necessary to improve our service and site as well as to understand our Users is based on our legitimate interest. MemoriaTree&rsquo;s legitimate interest is to improve its business and service in order to continuously provide a valuable service to our Users. The legal basis for the processing of personal data of non-users published by users is our legitimate interest to provide a service where users can create family trees and similar functions.</p>
          <h5>HOW WE PROTECT YOUR INFORMATION</h5>
          <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
          <h5>SHARING YOUR PERSONAL INFORMATION</h5>
          <p>We do not sell, trade, or rent Users personal data to others. We may share generic aggregated demographic information not linked to any personal data regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
          <h5>WHERE WE PROCESS PERSONAL DATA</h5>
          <p>Your personal data is stored on servers hosted in the EU (hosted by suppliers Scaleway, mLab, MongoDB Atlas and AWS).</p>
          <h5>PERIOD FOR WHICH INFORMATION WILL BE STORED</h5>
          <p>We store personal data relating to your account until you choose to delete the account. We may need to save personal data for a longer time if it is necessary to fulfill a legal obligation that requires processing in accordance with applicable laws, or so that we can establish, enforce, or defend legal claims.</p>
          <h5>YOUR RIGHTS</h5>
          <p>Right to access: You have the right to know what personal data about you we are processing. Upon request from you, we will provide you with information about our processing of your personal data, free of charge.</p>
          <p>Correction of erroneous information: You have the right to request that we correct erroneous or incomplete information about yourself.</p>
          <p>Erasure of certain data: You have the right to request erasure of your personal data under certain conditions. In some cases, we must save your personal data owing to legal requirements such as bookkeeping legislation or if we need to retain certain data in order to establish, enforce or defend a legal claim.</p>
          <p>Right to restrict processing of your personal data: You have the right to demand restriction on our processing of your personal data in certain cases, for example if we are investigating if your personal data is correct after a request of correction from you.</p>
          <p>Right to object to our processing of your personal data: You have the right, under certain conditions, to object to our processing of your personal data. However, we may continue the processing of your data if we have compelling legitimate reasons outweighing your privacy interests.</p>
          <p>Right to data portability: You have the right to request a copy of the personal data you have provided us. You also have the right to request that we transfer your personal data to another controller.</p>
          <h5>WEB BROWSER COOKIES</h5>
          <p>Our Site may use "cookies" to enhance User experience. A User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly. For more information about cookies and how a User may limit or turn off cookies in their web browser see <Link to="/cookies">Information about cookies.</Link></p>
          <h5>CHANGES TO THIS PRIVACY NOTICE</h5>
          <p>MemoriaTree has the discretion to update this privacy notice at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy notice periodically and become aware of modifications.</p>
          <h5>CONTACTING US</h5>
          <p>If you have any questions about this Privacy Notice, or if you have any concerns regarding our processing of personal data, feel free to contact us:</p>
          <p><a href="mailto:support@memoriatree.com">support@memoriatree.com</a></p>
          <p>MemoriaTree AB<br />Postbox 20<br />191 21, Sollentuna<br />SWEDEN</p>
          <p>If you have a complaint regarding our processing of your personal data, you have the right to submit such complaints to the Data Protection Authority (<a href="https://www.datainspektionen.se">www.datainspektionen.se</a>). </p>
          <p>This document was last updated on 8 July 2020.</p>
        </Message.Content>
      </Message>
    </Container>
  </Grid>
  );
}

export default PrivacyPolicy;