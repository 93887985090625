import * as React from 'react';
import { observable, toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { Memorial } from 'state/objects';
//import * as Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Route, withRouter, Link, RouteComponentProps } from 'react-router-dom';

import {
  Grid,
  Header,
  Button,
  Icon,
  Divider,
  Modal,
  Image
} from 'semantic-ui-react';

import {
  PhotoStore,
  VideoStore
} from 'state/stores';
import ReportStore from 'state/reportStore';

import AsyncComponent from 'components/AsyncComponent';
import ImageUploader from 'components/ImageUploader/multiple';
import Photo from './Photo';

/*
import VideoModalJS from '../VideosPane/VideoModal';
const VideoModal: any = VideoModalJS;
*/

export interface GalleryPaneProps extends RouteComponentProps<any> {
  profile: Memorial;

  photoStore?: PhotoStore;
  videoStore?: VideoStore;
  reportStore?: ReportStore;
}

@inject('photoStore', 'videoStore', 'reportStore') @observer
class GalleryPane extends React.Component<GalleryPaneProps, {}> {

  photoStore: PhotoStore;
  videoStore: VideoStore;

  @observable uploadPhotoModalOpen = false;

  @observable videoModalOpen = false;
  @observable videoShowing = '';
  @observable uploadVideoModalOpen = false;

  @observable photoToDelete: any = null;
  @observable videoToDelete: any = null;

  constructor (props: GalleryPaneProps, context) {
    super(props, context);

    this.photoStore = props.photoStore!;
    this.videoStore = props.videoStore!;

    props.profile.fetchPhotos();
    props.profile.fetchVideos();
  }

  componentWillReceiveProps (nextProps: GalleryPaneProps) {
    if (this.props.profile !== nextProps.profile) {
      nextProps.profile.fetchPhotos();
      nextProps.profile.fetchVideos();
    }
  }

  deletePhoto () {
    if(this.photoToDelete){
      this.photoStore.remove(this.photoToDelete)
      .then(() => {
        this.props.history.push(this.props.match.url);
        this.photoToDelete = null;
      })
    }
  }
 
  deleteVideo () {
    if(this.videoToDelete){
      this.videoStore.remove(this.videoToDelete)
      .then(() => {
        this.props.history.push(this.props.match.url);
        this.videoToDelete = null;
      })
    }
  }

  renderLightBox = ({match}) => {

    const { photos } = this.props.profile;
    const photo = photos.find(p => p._id == match.params.mediaID);

    if (photo) {
      const author = photo.owner;
      const photoURLs = photos.map(photo => photo.url);

      const index = photoURLs.indexOf(photo.url);
      const previousPhotoID = photos[(index + photos.length - 1) % photos.length]._id;
      const nextPhotoID = photos[(index + 1) % photos.length]._id;

      const buttonStyle = {
        border: 'solid #3c3c3c 1px',
        color: '#cccccc',
        opacity: 1,
        padding: '3px 10px 3px 10px',
        background: 'rgba(255, 255, 255, 0)'
      };

      let buttons: JSX.Element[] = [];

      if (!photo.isOwner) {
        buttons.push(
          <Button
            style={buttonStyle}
            onClick={() => {
              this.props.reportStore?.openModal('photos', photo._id);
            }}
          >
            Report Photo
          </Button>
        )
      }

      if(photo.canDelete) {
        buttons.push(
          <Button
            style={buttonStyle}
            onClick={() => this.photoToDelete = photo._id}
          >
            Delete Photo
          </Button>
        );
      }

      let authorNode: JSX.Element | null = null; 
    
      authorNode = (
        <Link to={`/profile/${author!._id}`}>
          <Header as="h4" inverted style={{paddingTop: '6px'}}>
            <Image src={author!.avatar} avatar />
            {author!.displayName}
          </Header>
        </Link>
      );

      return (
        <AsyncComponent
          moduleProvider={() => import(/* webpackChunkName: "ImageLightbox" */ 'react-image-lightbox')}
          showLoadingBar

          mainSrc={photoURLs[index]}
          nextSrc={photoURLs[(index + 1) % photos.length]}
          prevSrc={photoURLs[(index + photos.length - 1) % photos.length]}

          onCloseRequest={() => this.props.history.push(this.props.match.url)}
          onMovePrevRequest={() => this.props.history.push(`${this.props.match.url}/photo/${previousPhotoID}`)}
          onMoveNextRequest={() => this.props.history.push(`${this.props.match.url}/photo/${nextPhotoID}`)}

          toolbarButtons={buttons}

          imageTitle={authorNode}
        />
      );
    }
    return null;
  }

  renderVideoModal = ({match}) => {

    const { videos } = this.props.profile;
    const video = videos.find(v => v._id === match.params.mediaID);

    if (video) {
      return (
        <AsyncComponent
          showLoadingBar
          moduleProvider={() => import(/* webpackChunkName: "VideoModal" */ './VideoModal')}
          title="Video"
          open
          onClose={() => this.props.history.push(this.props.match.url)}
          onDelete={() => this.videoToDelete = video._id}
          video={video}
        />
      );
    }
    return null;
  }

  render() {

    const { photos } = this.props.profile;
    const { videos } = this.props.profile;

    return (
      <Grid style={{paddingBottom: 50}}>

        <Grid.Row id="photos">

          <Grid.Column computer={9} tablet={9} mobile={6}>
            <Header as="h3" color="red">Photos</Header>
            <Divider />
          </Grid.Column>

          <Grid.Column floated="right" computer={3} tablet={3} mobile={6}>
            <Button fluid compact primary onClick={() => this.uploadPhotoModalOpen = true}><Icon name="photo" />
              Upload Photo
            </Button>
          </Grid.Column>

        </Grid.Row>

        {photos.length > 0 ? (

          <Grid.Column width={12}>
            <div className="image-grid">

              {photos.map(
                (photo, idx) => (
                  <Photo 
                    id={`photo-${photo._id}`}
                    key={photo._id}
                    uri={photo.url}
                    to={`${this.props.match.path}/photo/${photo._id}`}
                  />
                )
              )}

            </div>
          </Grid.Column>

        ) : (

          <Grid.Column width={12} textAlign="center">
            <Icon name="photo" size="huge" style={{color: 'rgba(34,36,38,.15)'}} />
          </Grid.Column>
          
        )}

        <Grid.Row id="videos">

          <Grid.Column computer={9} tablet={9} mobile={6}>
            <Header as="h3" color="red">Videos</Header>
            <Divider />
          </Grid.Column>

          <Grid.Column floated="right" computer={3} tablet={3} mobile={6}>
            <Button fluid compact primary onClick={() => this.uploadVideoModalOpen = true}><Icon name="video" />Upload Video</Button>
          </Grid.Column>

        </Grid.Row>

        {videos.length > 0 ? (

          <Grid.Column width={12}>
            <div className="image-grid">

              {videos.map(
                (video, idx) => (
                  <Photo
                    id={`video-${video._id}`}
                    key={video._id}
                    uri={video.thumbnail}
                    to={`${this.props.match.path}/video/${video._id}`}
                  />
                )
              )}
              
            </div>
          </Grid.Column>

        ) : (

          <Grid.Column width={12} textAlign="center">
            <Icon name="video" size="huge" style={{color: 'rgba(34,36,38,.15)'}} />
          </Grid.Column>

        )}



        {/*}
        <Grid.Row>
          <Grid.Column computer={9} tablet={9} mobile={6}>
            <Header as="h3" color="red">Files</Header>
            <Divider />
          </Grid.Column>
          <Grid.Column floated="right" computer={3} tablet={3} mobile={6}>
            <Button fluid compact primary onClick={() => this.uploadVideoModalOpen = true}><Icon name="file text outline" />Upload File</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Column width={12} textAlign="center">
          <Icon name="file text outline" size="huge" style={{color: 'rgba(34,36,38,.15)'}} />
        </Grid.Column>
      */}

        {this.uploadPhotoModalOpen && (
          <Modal open basic closeIcon  size="large" onClose={(e) => this.uploadPhotoModalOpen = false}>         
            <Modal.Content>
              <ImageUploader
                profile={this.props.profile}
                acceptedFileType="image"
                onClose={() => this.uploadPhotoModalOpen = false}
              />
            </Modal.Content>
          </Modal>
        )}

        {this.uploadVideoModalOpen && (
          <Modal open closeIcon basic onClose={() => this.uploadVideoModalOpen = false} >
            <Modal.Content>
              <ImageUploader
                profile={this.props.profile}
                acceptedFileType="video"
                onClose={() => this.uploadVideoModalOpen = false}
              />
            </Modal.Content>
          </Modal>
        )}

        {this.photoToDelete && (
          <Modal
            open
            size="mini"

          >
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
               <strong>
               This will permanently delete this photo from the MemoriaTree website.
               Are you sure you want to continue?
               </strong>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.photoToDelete = null}>Cancel</Button>
              <Button secondary type="submit" onClick={() => this.deletePhoto()}>Delete Photo</Button>
            </Modal.Actions>
          </Modal>
        )}
        
        {this.videoToDelete && (
          <Modal
            open
            size="mini"

          >
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
               <strong>
               This will permanently delete this video from the MemoriaTree website.
               Are you sure you want to continue?
               </strong>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.videoToDelete = null}>Cancel</Button>
              <Button secondary type="submit" onClick={() => this.deleteVideo()}>Delete Video</Button>
            </Modal.Actions>
          </Modal>
        )}

        <Route path={`${this.props.match.path}/photo/:mediaID`} render={this.renderLightBox} />
        <Route path={`${this.props.match.url}/video/:mediaID`} render={this.renderVideoModal} />

      </Grid>
    );
  }
};

export default withRouter(GalleryPane);
