import * as React from 'react';
import { observer, inject } from "mobx-react";
import { Link } from 'react-router-dom';

import {
  Icon,
  Button,
  Grid,
} from 'semantic-ui-react';

import AccountStore from 'state/account';

export interface Props {
  accountStore?: AccountStore;
}

@inject('accountStore') @observer
class QuickLinks extends React.Component<Props, {}> {

  accountStore: AccountStore;

  constructor (props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
  }

  render() {    
    return (
      <Grid style={{margin: "0rem 0rem 0rem 0rem"}}>
        <Grid.Row>
          <Grid.Column>
            <h1 className="memtree-cursive" style={{color:"white"}}>
              Shortcuts
            </h1 >
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={4} textAlign='center' style={{paddingBottom:"28px"}}>
          <Grid.Column>
            <Link to={`/profile/${this.accountStore.profileId}/memorials?createMemorial=true`}>
              <Button fluid ><Icon name="plus" />Create Memorial</Button>
            </Link>  
          </Grid.Column>
          <Grid.Column>
            <Link to={`/profile/${this.accountStore.profileId}/memorials`}>
              <Button fluid ><Icon name="heart" />My Memorials</Button>
            </Link>  
          </Grid.Column>
          <Grid.Column>
            <Link to={`/profile/${this.accountStore.profileId}/familytree`}>
              <Button fluid ><Icon name="tree" />My Family Tree</Button>
            </Link>  
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  
};

export default QuickLinks;
