import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import {
  Form,
  Divider,
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import CookieStore from 'state/cookieStore';

export interface Props {
  accountStore?: AccountStore;
  cookieStore?: CookieStore;
}

@inject("cookieStore", "accountStore") @observer
class GeneralTab extends React.Component<Props, {}> {

  accountStore: AccountStore;
  @observable personalizedAds: boolean = false;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    //this.personalizedAds = this.accountStore.getCookieSettings;
    this.personalizedAds = true;
  }

  togglePersonalizedAds() {
    this.personalizedAds = !this.personalizedAds;
    this.accountStore.setCookieSettings(this.personalizedAds);
  }

  render() {

    return (
      <div>
        <h3>General Settings</h3>
        <Divider />
        <Form>
          <Form.Checkbox
            label='Personalized advertisements'
            checked={this.personalizedAds}
            onChange={() => this.togglePersonalizedAds()}
          />
        </Form>
      </div>

    );
  }

}

export default GeneralTab;