import * as React from 'react';
import mobx, { observable } from "mobx";
import { observer, inject } from "mobx-react";
import AccountStore from 'state/account';
import { ProfileStore } from 'state/stores';
import UISTore from 'state/ui';

import ProfileHeaderJS from './ProfileHeader';
const ProfileHeader: any = ProfileHeaderJS; //until ts migrate

import {
  Grid,
  Container,
  Menu,
  Icon,
  Label
} from 'semantic-ui-react';

import { LinkContainer } from 'react-router-bootstrap';

import { Switch, Route, Link } from 'react-router-dom';

import ConnectionsPane from './ConnectionsPane';
import FamilyTreePane from 'components/FamilyTree/FamilyTreePane';
import MemorialsPane from './MemorialsPane';
import AdBanner from "components/Ads/AdBanner";

import AsyncComponent from 'components/AsyncComponent';
import NotFound from 'components/NotFound';

import {
  Profile
} from 'state/objects';
import { match } from 'react-router';

export interface ProfileContainerProps {
  accountStore: AccountStore;
  profileStore: ProfileStore;
  uiStore: UISTore;
  match: match<{profileID: string}>;
}

@inject('uiStore', 'accountStore', 'profileStore') @observer
class ProfileContainer extends React.Component<ProfileContainerProps, {}> {

  accountStore: AccountStore;
  profileStore: ProfileStore;

  @observable editModalOpen: boolean = false;
  @observable profile: Profile;
  @observable error: boolean = false;

  constructor (props: ProfileContainerProps, context) {
    super(props, context);

    this.accountStore = props.accountStore;
    this.profileStore = props.profileStore;

    this.fetchProfile(props.match.params.profileID);
  }

  componentWillReceiveProps (nextProps: ProfileContainerProps) {
    if (this.props.match.params.profileID !== nextProps.match.params.profileID) {
      this.fetchProfile(nextProps.match.params.profileID);
    }
  }

  fetchProfile (id: string) {
    if (this.accountStore.myProfile) {
      this.accountStore.myProfile.fetchConnections();
    }
    
    this.error = false;
    this.profileStore.fetchById(id)
    .then(profile => {
      this.profile = profile;
    })
    .catch(err => {
      console.log(err);
      this.error = true;
    });

    window.scrollTo(0, 0);
  }

  renderConnectionsPane = props => <ConnectionsPane profile={this.profile} {...props} />
  renderFamilyTreePane = props => <FamilyTreePane profile={this.profile} {...props} />
  renderMemorialsPane = props => <MemorialsPane profile={this.profile} {...props} />

  render (): JSX.Element {

    const { profile } = this;

    if (!this.profile) {
      return <div className="no-mobile-padding dark-blue fill-window-height" />
    }

    if (this.error) {
      return (
        <NotFound
          header="Profile not found"
          message="Either the Profile you are trying to access does not exist or you do not have permission to view it."
        />
      );
    }

    const profileURI = '/profile/' + this.props.match.params.profileID;
    const connectionRequests = profile.connectionRequests.length;
    const {
      match
    } = this.props;

    const { familyTreeViewable } = profile;

    return (
      <Grid className="no-mobile-padding dark-blue fill-window-height">
        <Container>

          <ProfileHeader
            profile={profile}
            onEditClick={() => this.editModalOpen = true}
          />

          <Grid.Row className="menu-tabs">
            <Menu tabular widths={familyTreeViewable ? 3 : 2} className="section-tabs">

              <LinkContainer
                to={`${profileURI}/memorials`}
                isActive={(match, location) => location.pathname === profileURI || location.pathname === `${profileURI}/` || location.pathname.match(`${profileURI}/memorials`)}
              >
                <Menu.Item>
                  <Icon name="heart" />
                  <span>Memorials</span>
                </Menu.Item>
              </LinkContainer>

              <LinkContainer to={`${profileURI}/connections`}>
                <Menu.Item>
                  <Icon name="users" />
                  <span>Connections</span>
                  {profile.userIsOwner && connectionRequests > 0 &&
                    <div className="tab-notification">
                      {connectionRequests}
                    </div>
                  }
                </Menu.Item>
              </LinkContainer>

              {familyTreeViewable && (
                <LinkContainer to={`${profileURI}/familytree`}>
                  <Menu.Item>
                    <Icon name="tree" />
                    <span>Family Tree</span>
                  </Menu.Item>
                </LinkContainer>
              )}
              

            </Menu>
          </Grid.Row>

          <Grid.Row className="tab-content no-mobile-padding">
            <Switch>
              <Route exact path={`${match.url}`} render={this.renderMemorialsPane} />
              <Route path={`${match.url}/memorials`} render={this.renderMemorialsPane} />
              <Route path={`${match.url}/connections`} component={this.renderConnectionsPane} />
              <Route path={`${match.url}/familytree`} component={this.renderFamilyTreePane} />
            </Switch>
          </Grid.Row>

          {this.editModalOpen && (
            <AsyncComponent
              moduleProvider={() => import(/* webpackChunkName "ProfileEditModal" */ './ProfileEditModal')}
              showLoadingBar

              profile={this.profile}
              open={this.editModalOpen}
              onClose={() => this.editModalOpen = false}
            />
          )}

          {/*}
          <div style={{paddingTop:"71px"}}>
            <AdBanner />
          </div>*/}
          
        </Container>
      </Grid>

    );
  }
};

const notImplemented = () => (
  <div>
    <h2>Not implemented</h2>
  </div>
);



export default ProfileContainer;
