import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';

import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import extend from 'lodash/extend';

import {
  Profile
} from 'state/objects';

import FormBaseClass from './baseClass';
import { createRuleString } from './utils';

//The ProfileForm object contains all the fields, their values, their error messages,
//and the error rules
class ProfileForm extends FormBaseClass {

  profile: Profile;

  @observable form = {
    fields: {
      firstName: {
        value: '',
        error: null,
        rule: 'string|required|max:70'
      },
      lastName: {
        value: '',
        error: null,
        rule: 'string|required|max:70'
      },
      gender: {
        value: "",
        error: null,
        rule: 'string|required'
      },
      email: {
        value: '',
        error: null,
        rule: 'email|required|max:150'
      },
      password: {
        value: '',
        error: null,
        rule: 'string|required|min:8'
      },
      'password confirmation': {
        value: '',
        error: null,
        rule: 'string|min:8|required|same:password'
      },
      acceptsTOS: {
        value: false,
        error: null,
        rule: 'boolean|required|accepted'
      },
    },

    meta: {
      isValid: true,
      error: <string | null>null
    }
  }

  @observable loaded: boolean = false;

  //loads information from an already existing profile
  //and puts into the fields

  constructor() {
    super();
  }

  //gets the rules for all the form fields from the back end
  @action
  async loadRules() {
    return this;
  }

  //executes when you hit "save" on the form
  @action
  async save() {

  }
}

export default ProfileForm;

