import {
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

import pick from 'lodash/pick';
import extend from 'lodash/extend';

import { Profile } from 'state/objects';
import { ConnectionStore } from 'state/stores';
import { accountStore } from 'state';
import { IConnection } from 'state/apiClient/ServiceTypes';
import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import RootStore from 'state/rootStore';

class Connection {

  rootStore: RootStore;
  store: ConnectionStore;

  @observable _id: number;
  @observable relation: string;
  @observable status: 'accepted' | 'requested' | 'pending';

  @observable profileId: number;
  @observable contactId: number;

  /**
   * 
   * @param store - Store holding the connection
   * @param connection - Connection data
   */
  constructor(rootStore: RootStore, store: ConnectionStore, connection: IConnection) {
    this.store = store;
    this.rootStore = rootStore;

    const data = pick(connection, [
      '_id',
      'relation',
      'status',
      'contactId',
      'profileId'
    ]);
    extend(this, data);
    //this.profile = new Profile(connection.profile);
  }

  @computed
  get userIsOwner(): boolean {
    return this.rootStore.accountStore.profileId === this.profileId;
  }

  @computed
  get ownerProfile(): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.profileId);
  }

  @computed
  get contact(): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.contactId);
  }

  @computed
  get relationship(): string {
    const profile = this.ownerProfile;
    if (profile) {
      if (!profile.familyTree) {
        this.rootStore.familyTreeStore.fetchById(profile.familyTreeId);
        return '';
      }
      const personInFamilyTree = profile.familyTree.persons.find(p => p.connectedPageId === this.profileId);
      const contactInFamilyTree = profile.familyTree.persons.find(p => p.connectedPageId === this.contactId);
      if (contactInFamilyTree && personInFamilyTree) {
        return contactInFamilyTree.relationshipToPerson(personInFamilyTree);
      }
    }
    return ''
  }
}

export default Connection;