import * as React from 'react';
import {
  observable,
  action,
  computed,
  autorun
} from 'mobx';

import Progress from 'react-progress-2';
import {
  Profile,
  Memorial
} from 'state/objects';

class uiStore {

  @observable showChatModal = false;
  @observable showLoginModal = false;
  @observable storeActive = false;

  //Topbar hooking
  @observable blockTopBar: boolean = false;
  blockTopBarMsg: string;
  //****************************** */

  //TreeJoin
  @observable treeJoinRequestingProfile: Profile | Memorial | undefined;
  @observable treeJoinTargetProfile: Profile | Memorial | undefined;
  //****************************** */

  @observable sidebarOpen = false;
  sidebarNode: HTMLElement | null = null;
  sidebarHandler: any;

  constructor() {
    this.sidebarNode = document.getElementById('sidebar');
    this.sidebarHandler = autorun(() => {
      this.sidebarNode!.classList.toggle('visible', this.sidebarOpen);
    });
  }

  @action
  blockTopBarOn(message: string) {
    this.blockTopBar = true;
    this.blockTopBarMsg = message;
  }

  @action
  blockTopBarOff() {
    this.blockTopBar = false;
    this.blockTopBarMsg = "";
  }

  @action
  toggleLoginModal() {
    this.showLoginModal = !this.showLoginModal;
  }

  @action
  toggleChatModal() {
    this.showChatModal = !this.showChatModal;
  }

  @action
  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  @action
  closeSidebar() {
    this.sidebarOpen = false;
  }

  @action
  openSidebar() {
    this.sidebarOpen = true;
  }

  @action
  showPageLoader() {
    Progress.show();
  }

  @action
  hidePageLoader() {
    Progress.hide();
  }

}

export default uiStore;
