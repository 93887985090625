import * as React from 'react';
import { observer } from 'mobx-react';
import { Connection as ConnectionType } from 'state/objects';
import {
  Grid,
  Button,
  Header,
  Icon,
  Dropdown
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const buttonStyle = {
  float: 'right'
};

export interface ConnectionProps {
  connection: ConnectionType;
  request?: boolean;
  onAccept?(): any;
  onDecline?(): any;
  onDelete?(): any;
}

function Connection({ connection, request, onAccept, onDecline, onDelete }: ConnectionProps) {

  const { contact, relationship } = connection;

  if (!contact) {
    return null;
  }

  return (
    <Grid.Column
      mobile={12}
      tablet={6}
      computer={4}
    >

      <div className="connection">

        <Link to={`/profile/${contact._id}`}>
          <img src={contact.avatar} className="left" />
        </Link>

        <Grid>

          <Grid.Row>

            <Grid.Column width={6} style={{ paddingBottom: 0 }}>
              <Link to={`/profile/${contact._id}`}>
                <Header as="h4" color="red">
                  {contact.displayName}
                  <Header.Subheader>{relationship}</Header.Subheader>
                </Header>
              </Link>
            </Grid.Column>

            {request && (
              <Grid.Column width={12}>
                <Button.Group compact fluid size="tiny">
                  <Button onClick={onDecline}>
                    Decline
                  </Button>
                  <Button primary onClick={onAccept}>
                    Accept
                  </Button>
                </Button.Group>
              </Grid.Column>
            )}

            {!request && connection.userIsOwner && (
              <Grid.Column width={6}>
                <Dropdown
                  id={`connections-menu-${connection._id}`}
                  text="Edit"
                  icon="setting"
                  size="tiny"
                  button
                  className='icon connection-button'
                  style={{ float: "right" }}
                  onClick={onAccept}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item text="Remove" onClick={onDelete} />
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            )}

          </Grid.Row>
        </Grid>

      </div>

    </Grid.Column>

  );
}

export default observer(Connection);
