import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';

import find from 'lodash/find';
import remove from 'lodash/remove';

import { Service } from '@feathersjs/feathers';
import { IConnection } from 'state/apiClient/ServiceTypes';
import { service } from 'state/apiClient';
import { Profile } from 'state/objects';
import { Connection } from 'state/objects';
import RootStore from 'state/rootStore';

class ConnectionStore {

  rootStore: RootStore;

  /** API service used to fetch connections belonging to the profile */
  service: Service<IConnection> = service('connections');

  @observable connections: Connection[] = [];
  /**
   * Creates a new store
   * @param profile - Profile the connections belong to
   */
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.initEvents();
  }

  initEvents() {
    this.service.on('created', c => this.onCreatedOrUpdated(c));
    this.service.on('patched', c => this.onCreatedOrUpdated(c));
    this.service.on('updated', c => this.onCreatedOrUpdated(c));
    this.service.on('removed', c => this.onRemoved(c));
  }

  /** Fetches all connections from the server */
  @action
  async fetchAll() {
    throw new Error('ConnectionStore.fetchAll() not implemented');
  }

  @action
  addToArray(data: IConnection): Connection {
    let connection = find(this.connections, c => c._id === data._id);

    if (connection) {
      Object.assign(connection, data);
    } else {
      connection = new Connection(this.rootStore, this, data);
      this.connections.push(connection);
    }
    return connection;
  }

  @action
  clearRemoved(profileId: number, connectionIds: number[]) {
    remove(this.connections, c => c.profileId === profileId && connectionIds.indexOf(c._id) < 0);
  }

  @action
  async fetchById(id: number): Promise<Connection> {
    const myProfile = this.rootStore.accountStore.myProfile;
    if (!myProfile) {
      throw new Error('ConnectionStore.fetchById() only available when logged in');
    }
    try {
      const response = await this.service.get(id);

      await this.rootStore.profileStore.fetchById(response.contactId);
      return this.addToArray(response);
    } catch (err) {
      throw new Error('ConnectionStore.fetchById() ' + err);
    }
  }

  @action
  async fetchByProfileId(id: number): Promise<Connection[]> {
    try {
      const params = {
        query: {
          profileId: id
        }
      };
      const response = await this.service.find(params) as IConnection[];

      const connectionIds = response.map(c => c._id);
      this.clearRemoved(id, connectionIds);

      const profileIds = response.map(c => c.contactId);
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const connections = response.map(c => this.addToArray(c));
      return connections;
    } catch (err) {
      throw new Error('ConnectionStore.fetchByProfileId() ' + err);
    }
  }

  @action
  async fetchUserConnections(): Promise<Connection[]> {
    if (!this.rootStore.accountStore.myProfile) {
      throw new Error('ConnctionStore.fetchUserConnections() - Not logged in');
    }
    return this.fetchByProfileId(this.rootStore.accountStore.myProfile._id);
  }

  /** The resulting filtered connection that depending on .filter */
  /*
  @computed
  get filteredConnections (): Connection[] {

    if (this.filter === '') return this.connections;

    return this.connections
      .filter(con => con.profile.displayName.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1);
  }
  */

  /**
   * Sends a connections request to the profile this store belongs to 
   * @param relation - type of relation. E.g. brother, mother. NOT IN USE ATM
   */
  @action
  async sendRequest(id: number, type: string): Promise<Connection> {
    const loggedInProfile = this.rootStore.accountStore.myProfile;
    if (!loggedInProfile) {
      throw new Error('ConnectionStore.sendRequest - Not logged in');
    }

    try {
      const response = await this.service.create({ type, contactId: id });
      const connection = this.addToArray(response);

      return connection;
    } catch (e) {
      throw new Error('ConnectionsStore.sendRequest() ' + e);
    } finally {

    }
  }

  /**
   * Accepts a connection request the user has received
   * @param id - id number of the connection object. NOT profile id
   */
  @action
  async acceptConnection(id: number): Promise<Connection> {
    try {
      const response = await this.service.patch(id, { status: 'accepted' });
      const connection = this.addToArray(response);
      return connection;
    } catch (e) {
      throw new Error('ConnectionsStore.acceptConnection() ' + e);
    }
  }

  /**
   * Declines a connection request the user has received
   * @param id - id number of the connection object. NOT profile id
   */
  @action
  async declineConnection(id: number) {
    try {
      await this.service.remove(id);
      runInAction('Connection declined', () => {
        remove(this.connections, c => c._id === id);
      });
    } catch (e) {
      throw new Error('ConnectionsStore.declineConnection() ' + e);
    }
  }

  /**
   * Removes one of the user's connections
   * @param id - id number of the connection object. NOT profile id
   */
  @action
  async removeConnection(id: number) {

    //finds other profile's connection to remove if saved locally
    const connection = this.connections.find(c => c._id === id);
    let otherProfilesConnection;
    if (connection) {
      otherProfilesConnection = this.connections.find(c => c._id === connection.contactId)
    }

    try {
      await this.service.remove(id);
      runInAction('Connection removed', () => {
        remove(this.connections, c => c._id === id);
        if (otherProfilesConnection) {
          remove(this.connections, c => c._id === otherProfilesConnection.id);
        }
      });
    } catch (e) {
      throw new Error('ConnectionsStore.removeConnection() ' + e);
    }
  }

  @action
  onCreatedOrUpdated(connection: IConnection) {
    this.addToArray(connection);
  }

  @action
  onRemoved(connection: IConnection) {
    remove(this.connections, c => c._id === connection._id);
  }
}

export default ConnectionStore;
