import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  Search,
} from 'semantic-ui-react';

import SearchStore from 'state/search';

import RenderSearchResult from './SearchResult';

export interface SearchBarProps extends RouteComponentProps<any> {
  searchStore?: SearchStore,
  style: any;
}

@inject('searchStore') @observer
class SearchBar extends React.Component<SearchBarProps, {}> {

  @observable value = '';

  constructor(props: SearchBarProps, context) {
    super(props, context);
  }

  onSuggestionsFetchRequested = (e, { value }) => {
    this.props.searchStore!.query = value;
    this.value = value;
  }

  onSuggestionsClearRequested = () => {
    this.props.searchStore!.query = '';
  }

  onSuggestionSelected = (event, { result }) => {

    event.preventDefault();

    const url = (result.id);
    this.props.history.push(url);
    this.props.searchStore!.query = '';
    this.value = '';
  }

  onFocus = () => {
    this.props.searchStore!.loadProfiles();
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.history.push(`/search?q=${encodeURIComponent(this.value)}`);
    this.value = '';
    this.props.searchStore!.query = '';
    const active = document.activeElement as any;
    active.blur();
  }

  render() {

    const { searchStore, style } = this.props;
    const suggestions = searchStore!.suggestions;

    const inputProps = {
      placeholder: 'Search',
      value: this.value,
      onFocus: this.onFocus,
      style: style
    };

    return (

      <form onSubmit={this.onSubmit} style={{marginBottom: 0}}>
        <Search
          fluid
          input={{icon: 'search', iconPosition: 'left', style: {width: '100%'}}}
          loading={false}
          results={suggestions}
          onSearchChange={this.onSuggestionsFetchRequested}
          onResultSelect={this.onSuggestionSelected}
          resultRenderer={RenderSearchResult as any}
          {...inputProps}
        />
      </form>
    );
  }
}

export default withRouter(SearchBar);
