import * as React from 'react';
import {
  Image,
  Icon
} from 'semantic-ui-react';

interface ProfileOptionProps {
  children: JSX.Element[];
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus(option: any, e: any): any;
  onSelect(option: any, e: any): any;
  option: any;
}

class ProfileOption extends React.Component<ProfileOptionProps, {}> {
  handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.onSelect(this.props.option, event);
  }
  
	handleMouseEnter (event) {
		this.props.onFocus(this.props.option, event);
  }
  
	handleMouseMove (event) {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
  }
  
  render () {

    const {
      className,
      option,
      children
    } = this.props;

    return (
      <div
        className={className}
        onMouseDown={e => this.handleMouseDown(e)}
        onMouseEnter={e => this.handleMouseEnter(e)}
        onMouseMove={e => this.handleMouseMove(e)}
        title={option.title}
      >
        <Image avatar src={option.image} />
        <span>{children}</span>
      </div>
    );
  }
}

export default ProfileOption;