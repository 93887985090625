import {
  ProfileStore,
  MemorialStore,
  GuestbookEntryStore,
  CommentStore,
  VideoStore,
  PhotoStore,
  ConnectionStore,
  ChatStore,
  NotificationStore,
  MessageStore,
  FamilyTreeStore,
} from 'state/stores';
import AccountStore from 'state/account';
import CookieStore from 'state/cookieStore';
import ReportStore from 'state/reportStore';

class RootStore {

  accountStore: AccountStore;
  memorialStore: MemorialStore;
  profileStore: ProfileStore;
  guestbookEntryStore: GuestbookEntryStore;
  commentStore: CommentStore;
  videoStore: VideoStore;
  photoStore: PhotoStore;
  connectionStore: ConnectionStore;
  chatStore: ChatStore;
  notificationStore: NotificationStore;
  messageStore: MessageStore;
  familyTreeStore: FamilyTreeStore;
  cookieStore: CookieStore;
  reportStore: ReportStore;
}

export default RootStore;