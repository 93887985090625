import * as React from 'react';
import { observable, autorun } from "mobx";
import { observer, inject } from "mobx-react";
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {
  Profile,
  Chat,
  Message
} from 'state/objects';
import {
  ChatStore,
  ProfileStore
} from 'state/stores';
import AccountStore from 'state/account';
import ImageUploader from 'components/ImageUploader';

import ChatView from 'react-chatview';

import avatarPlaceholder from 'images/avatar_placeholder.jpeg';

import {
  Modal,
  Form,
  Input,
  Button,
  Image,
  Grid,
  Header,
  Card,
  Icon,
  Popup,
  TextArea,
  Checkbox,
  Divider,
  Label,
  Segment,
  List,
  Search,

} from 'semantic-ui-react';
import * as moment from 'moment';

import Select from 'react-select';

const styles = {
  closeIcon: {
    marginLeft: 0
  }
};

export interface ChatModalProps {
  open: boolean;
  onClose(): any;

  chatStore?: ChatStore;
  accountStore?: AccountStore;
  profileStore?: ProfileStore;
}

@inject('chatStore', 'accountStore', 'profileStore') @observer
class ChatModal extends React.Component<ChatModalProps, {}> {

  chatStore: ChatStore;
  accountStore: AccountStore;
  profileStore: ProfileStore;

  @observable text: string = '';
  @observable activeChatId: number;
  @observable activeChat: Chat;

  @observable searchText: string = '';

  @observable creatingNewChat: boolean = false;
  @observable profileSelectValue: any = '';

  constructor(props: ChatModalProps, context) {
    super(props, context);
    this.chatStore = props.chatStore!;
    this.accountStore = props.accountStore!;
    this.profileStore = props.profileStore!;
  }

  componentDidMount() {
    /*
    this.chatStore.fetchAll()
      .then(chats => {
        if (chats.length > 0) {
          this.selectChat(chats[0]._id);
        }
      });
    */
    autorun(() => {
      if (this.activeChat && this.activeChat.messages && this.props.open) {
        this.activeChat.readMessages();
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open && nextProps.open) {
      this.chatStore.fetchAll()
        .then(chats => {
          if (chats.length > 0 && !this.creatingNewChat) {
            this.activeChatId = chats[0]._id;
          }
        })
    }
  }

  //done to ensure the first modal is still scrollable
  setBodyScrolling() {
    setTimeout(() => {
      document.body.classList.toggle('scrolling', true);
    }, 0);
  }

  onTextAreaKeyDown(e) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.sendMessage();
    }
  }

  selectChat(id: number) {
    this.creatingNewChat = false;
    this.activeChatId = id;
    const chat = this.chatStore.chats.find(c => c._id === id);
    if (chat) {
      chat.fetchMessages();
      //chat.readMessages();
      this.activeChat = chat;
    }
  }

  sendMessage() {
    if (this.creatingNewChat && this.profileSelectValue) {

      const existingChat = this.chatStore.chats.find(c => c.participantIds.map(p => p.id).indexOf(this.profileSelectValue.value) > -1);

      if (existingChat) {
        existingChat.sendMessage(this.text)
          .then(() => {
            this.text = '';
            this.activeChatId = existingChat._id;
            this.creatingNewChat = false;
            this.profileSelectValue = '';
          })
      } else {
        this.chatStore.add([this.profileSelectValue.value])
          .then(chat => {
            return chat.sendMessage(this.text)
              .then(() => {
                this.activeChatId = chat._id;
                this.creatingNewChat = false;
                this.text = '';
                this.profileSelectValue = '';
              });
          })
      }
    } else {
      const chat = this.chatStore.chats.find(c => c._id === this.activeChatId);
      if (chat) {
        chat.sendMessage(this.text)
          .then(() => {
            this.text = '';
          });
      }
    }
  }

  startNewChat() {
    this.activeChatId = 0;
    this.creatingNewChat = true;
    this.profileStore.fetchAll();
  }

  selectProfile(val: any) {
    this.profileSelectValue = val;
  }

  renderChatMenuItem(chat: Chat): JSX.Element {
    const recipient = chat.participants[0];
    return (

      <List.Item
        key={chat._id}
        onClick={() => this.selectChat(chat._id)}
        style={{
          cursor: 'pointer',
          padding: "15px",
          backgroundColor: this.activeChatId === chat._id ? "#f7faff" : "#ffffff"
        }}
      >
        <Image avatar src={recipient!.avatar} />
        <List.Content>
          <List.Header>
            {recipient!.displayName}
            {chat.unreadMessages > 0 && ` (${chat.unreadMessages})`}
          </List.Header>
          <List.Description
            style={{ overflow: "hidden", textOverflow: "ellipsis", width: 210, height: 16, whiteSpace: "nowrap" }}
          >
            {chat.lastMessage && chat.lastMessage.text}
          </List.Description>
        </List.Content>
      </List.Item>

    )
  }

  renderMessage(message: Message): JSX.Element {

    return (
      <div
        key={message._id}
        style={{
          overflow: "auto",
          paddingBottom: "5px",
        }}
      >

        <div
          style={{
            width: "50%",
            float:
              message.authorIsUser ? "right" : "left"
          }}
        >
          <div >
            <div
              style={{
                padding: "5 0 3 0"
              }}
            >
              {message.author!.displayName}, {message.createdAt.fromNow()}
            </div>

            <div
              className=
              {message.authorIsUser ? "light-blue" : "light-gray"}
              style={{
                padding: "10px",
                borderRadius: ".28571429rem",
              }}
            >
              <p style={{ wordWrap: "break-word" }}>
                {message.text}
              </p>
            </div>

          </div>

        </div>

      </div>

    );
  }

  render() {

    let {
      open,
      onClose
    } = this.props;

    const chats = this.chatStore.chats;
    const activeChat = chats.find(c => c._id === this.activeChatId);

    const myProfile = this.accountStore.myProfile;
    let profileOptions: any[] = [];
    if (myProfile) {
      profileOptions = this.profileStore.profiles
        .filter(p => p._id !== myProfile._id)
        .sort((a, b) => a.displayName.toLocaleLowerCase() < b.displayName.toLocaleLowerCase() ? -1 : 1)
        .sort((a, b) => a.relationToUser ? (b.relationToUser ? 0 : -1) : (b.relationToUser ? 1 : 0))
        .map(p => {
          return {
            value: p._id,
            label: p.displayName,
            image: p.avatar
          };
        });
    }


    return (
      <div>

        <Modal
          open={open}
          onClose={onClose}
        >

          <div style={{ display: "block", width: "100%", }}>

            <Label size="large" className="close" floating onClick={onClose}>
              <Icon size="large" name="close" style={styles.closeIcon} />
            </Label>

          </div>

          <Grid style={{ margin: 0 }}>
            {/*List of chats*/}
            <Grid.Column
              width={4}
              style={{
                padding: "0 0 0 0",
                borderRight: "1px solid #eaeaea",

              }}
            >
              <div
                style={{
                  padding: "15 15 15 15",
                  borderBottom: "1px solid #eaeaea",
                }}
              >

                <Input
                  id="chatFilterQuery"
                  type="text"
                  value={this.searchText}
                  onChange={e => this.searchText = e.currentTarget.value}
                  icon
                  placeholder="Search..."
                  style={{
                    width: '100%',
                  }}
                >
                  <Icon name="search" />
                  <input />
                </Input>

              </div>

              <div
                style={{
                  padding: "15 15 15 15",
                  borderBottom: "1px solid #eaeaea",
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <a onClick={() => this.startNewChat()}>
                    {/*<Icon name="plus" />*/}
                    <Image
                      avatar src={avatarPlaceholder} />
                    <span style={{
                      paddingLeft: "7",
                      paddingTop: "5"
                    }}>
                      Start new chat...
                      </span>
                  </a>
                </div>

              </div>

              <List
                style={{
                  marginTop: "0px",
                  height: "548",
                  overflowY: "scroll",
                }}>
                {this.chatStore.chatsByDate
                  .filter(c => {
                    const recipient = c.participants[0];
                    return recipient.displayName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
                  })
                  .map(c => this.renderChatMenuItem(c))}
              </List>

            </Grid.Column>

            {/*
              *******************************************************************
              Chat Window
              *******************************************************************
              */}
            <Grid.Column
              width={8}
              style={{
                padding: "0px"
              }}
            >
              {/*Header*/}
              <div style={{
                minHeight: "69px",
                borderBottom: "1px solid #eaeaea",
                padding: "15px"
              }}>

                {this.creatingNewChat ? (
                  <div>
                    <Select
                      name="chat-select-user"
                      placeholder="Type a name..."
                      value={this.profileSelectValue}
                      onChange={val => this.selectProfile(val)}
                      options={profileOptions}
                      optionComponent={ProfileOption}
                    />
                  </div>
                ) : (
                    <div style={{ textAlign: "center" }}>
                      {activeChat &&
                        <Link
                          to={`/profile/${activeChat.participants[0]._id}`}
                          onClick={() => this.props.onClose()}
                          style={{
                            height: "36",
                            display: "inline-block",
                            margin: "0 auto"
                          }}>
                          <Image
                            avatar
                            src={activeChat.participants[0].avatar}
                            style={{
                              height: "36px",
                              width: "36px"
                            }}
                          />
                          <span style={{
                            padding: "15 0 0 6",
                            fontWeight: "bold"
                          }}>
                            {activeChat.participants[0].displayName}
                          </span>
                        </Link>
                      }
                    </div>

                  )}

              </div>

              {/*
                Message window
                */}
              <div
                style={{ height: "450" }}
              >

                {activeChat && (
                  <ChatView
                    className="message-list"
                    flipped={true}
                    reversed={true}
                    scrollLoadThreshold={50}
                    onInfiniteLoad={() => { }}
                  >
                    {activeChat.messages
                      .map(m => this.renderMessage(m))}
                  </ChatView>
                )}

              </div>

              {/*Message prompt*/}
              <div
                style={{
                  padding: "15px",
                  borderTop: "1px solid #eaeaea",
                  overflow: "hidden"
                }}
              >
                <Form style={{ margin: 0 }}>
                  <TextArea
                    value={this.text}
                    onChange={e => this.text = e.currentTarget.value}
                    onKeyDown={e => this.onTextAreaKeyDown(e)}
                    rows={3}
                    style={{
                      fontSize: '1em',
                      marginBottom: "15px",
                      resize: "none"
                    }}
                    placeholder="Write a message..."
                  />

                  <Button
                    type="submit"
                    primary
                    style={{ float: "right" }}
                    onClick={() => this.sendMessage()}
                  >
                    Send Message
                    </Button>
                </Form>

              </div>

            </Grid.Column>
          </Grid>

        </Modal>

      </div>

    );
  }
}

interface ProfileOptionProps {
  children: JSX.Element[];
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus(option: any, e: any): any;
  onSelect(option: any, e: any): any;
  option: any;
}

class ProfileOption extends React.Component<ProfileOptionProps, {}> {
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {

    const {
      className,
      option,
      children
    } = this.props;

    return (
      <div
        className={className}
        onMouseDown={e => this.handleMouseDown(e)}
        onMouseEnter={e => this.handleMouseEnter(e)}
        onMouseMove={e => this.handleMouseMove(e)}
        title={option.title}
      >
        <Image avatar src={option.image} />
        <span>{children}</span>
      </div>
    )
  }
}


export default ChatModal;
