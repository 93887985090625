process.hrtime = require('browser-process-hrtime');
import populate from 'state/apiClient/hooks/populate';

import {
  debug
} from 'feathers-hooks-common';

import {
  rename,
  remove,
  negate,
  parseDateFromServer
} from './hooks';

const renameBefore = [
];

const renameAfter = [
];


const hooks = {
  before: {
    all: [],
    find: [],
    get: [],
    create: [
      ...renameBefore
    ],
    update: [...renameBefore],
    patch: [...renameBefore],
    remove: []
  },

  after: {
    all: [
      ...renameAfter,
      parseDateFromServer('createAt')
    ],
    find: [
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function () {

  const app = this;
  const service = app.service('notifications');
  service.hooks(hooks);

};
