import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Memorial, Profile } from 'state/objects';
import {
  Grid,
  Header,
  Image,
  Dropdown,
  Button,
  Label,
  Icon,
  Divider
} from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import UserAvatar from 'components/Generic/Avatar';

function MemorialFooter({ memorial }: MemorialFooterProps) {
  const { contributors } = memorial;

  return (
    <Grid.Row className="tab-content" style={{ marginTop: "15px" }}>

      <Header as="h3" color="red">Owners</Header>
      <Divider />

      <Grid>
        {renderContributor(memorial.ownerProfile)}
        {memorial.admins.map(a => renderContributor(a))}
      </Grid>

      {/*
      <Header as="h3" color="red">Contributors</Header>
      <Divider />

      <Grid style={{ paddingBottom: "25px" }}>
        {contributors.map(c => renderContributor(c))}
      </Grid>
      */}
      
    </Grid.Row>
  );
}

function renderContributor(contributor?: Profile) {
  if (!contributor) return null;

  return (
    <Grid.Column key={contributor._id} computer={3} tablet={4} mobile={6}>
      <UserAvatar
        profile={contributor}
        size={34}
        style={"avatar"}
        noShadow={true}
      />
    </Grid.Column>
  );
}

export interface MemorialFooterProps {
  memorial: Memorial;
}

export default observer(MemorialFooter);
