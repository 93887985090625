import * as React from 'react';

import {
  Grid, 
  Container,
  Message,
  Menu,
  Icon,
} from 'semantic-ui-react';


const baseContainer = ({children}) => (
    <div>
        <Grid className="dark-blue no-mobile-padding fill-window-height">
          <Container>
            <Grid centered>
              <Grid.Column computer={8} tablet={10} mobile={12}>
                <Message floating style={{marginTop: '40px'}}>
                  <Message.Content>
                    {children}
                  </Message.Content>
                </Message>
              </Grid.Column>
            </Grid>
          </Container>
        </Grid>
    </div>
);

export default baseContainer;