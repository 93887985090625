import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import * as classnames from 'classnames';
import { Memorial } from 'state/objects';
import {
  VideoStore,
  PhotoStore
} from 'state/stores';

import {
  Card,
  Grid,
  Loader,
  Dimmer,
  Image,
  Segment,
  Button,
  Icon,
  Modal
} from 'semantic-ui-react';

import Dropzone from 'react-dropzone';

const styles = {
  dropzone: {
    text: {
      lineHeight: '55px',
      textAlign: 'center' as 'center'
    },
    normal: {
      width: '100%',
      minHeight: 75,
      borderWidth: 2,
      borderColor: '#666',
      borderStyle: 'dashed',
      borderRadius: 5,
      cursor: 'pointer',
      cursorEvents: 'none'
    },
    accept: {
      borderStyle: 'solid',
      borderColor: '#6c6',
      color: '#6c6',
    },
    reject: {
      borderStyle: 'solid',
      borderColor: '#c66',
      color: '#c66',
    }
  }
};

export interface MutipleImageUploaderProps {
  profile: Memorial;
  acceptedFileType: string;
  onClose(): any;

  photoStore?: PhotoStore;
  videoStore?: VideoStore;
}

function dataURLtoBlob( dataUrl, callback )
{
    var req = new XMLHttpRequest;

    req.open( 'GET', dataUrl );
    req.responseType = 'arraybuffer'; // Can't use blob directly because of https://crbug.com/412752

    req.onload = function fileLoaded(e)
    {
        // If you require the blob to have correct mime type
        var mime = this.getResponseHeader('content-type');

        callback( new Blob([this.response], {type:mime as any}) );
    };

    req.send();
}

class FileToUpload {

  @observable file;
  @observable status: string;
  @observable preview;

  constructor (file) {
    this.file = file;
    this.status = 'waiting';

    switch (file.type.split('/')[0]) {

      case 'image':
        this.preview = file.preview;
        break;
      case 'video':

        const videoURL = URL.createObjectURL(file);

        const video = document.createElement('video');

        video.preload = 'metadata';
        video.src = videoURL;
        video.currentTime = 0.1;

        video.onloadeddata = () => {
          if (!this.preview) {

            const canvas = document.createElement('canvas');
            canvas.width = 100;
            canvas.height = 100;
            canvas.getContext('2d')!.drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();

            this.preview = image;

            URL.revokeObjectURL(videoURL);
            if (video.parentNode) video.parentNode!.removeChild(video);
            if (canvas.parentNode) canvas.parentNode!.removeChild(canvas);
          }
        };

        break;

      default:
    }
  }
}

@inject('photoStore', 'videoStore') @observer
class MutipleImageUploader extends React.Component<MutipleImageUploaderProps, {}> {

  videoStore: VideoStore;
  photoStore: PhotoStore;

  @observable files: FileToUpload[] = [];
  @observable showFinishedModal: boolean = false;

  dropzone: any;

  constructor (props: MutipleImageUploaderProps, context) {
    super(props, context);
    this.photoStore = props.photoStore!;
    this.videoStore = props.videoStore!;
  }

  onDrop (files: File[]) {

    const newFiles = files.map(file => new FileToUpload(file));
    this.files = [...this.files, ...newFiles];
  }

  removeFile (index: number, e: any) {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    this.files.splice(index, 1);
  }

  onAcceptUpload () {
    this.files.forEach(file => {
      if (file.status !== 'waiting') {
        return;
      }
      file.status = 'loading';
      switch (file.file.type.split('/')[0]) {
        case 'image':
          this.photoStore.add(this.props.profile._id, file.file)
            .then(() => file.status = 'success')
            .catch(() => file.status = 'rejected');
          break;

        case 'video':
          dataURLtoBlob(file.preview, blob => {
            this.videoStore.add(this.props.profile._id, file.file, blob)
            .then(() => file.status = 'success')
            .catch(() => file.status = 'rejected');
          })
          break;
        default:

      }
    });
  }

  componentWillUnmount () {
    //prevent memory leaks
    this.files.forEach(file => {
      window.URL.revokeObjectURL(file.file.preview);
      window.URL.revokeObjectURL(file.preview);
    });
  }

  renderThumbnail (file, index) {

    const cardColor = classnames(
      {'green': file.status === 'success'},
      {'red': file.status === 'rejected'}
    );

    const spinnerClass = classnames(
      'spinner',
      {hidden: file.status !== 'loading'}
    );

    const fileName = file.file.name.length > 13 ? file.file.name.substring(0, 13) + '...' : file.file.name;

    let cardIcon: JSX.Element | null = null;

    switch (file.status) {
      case "success":
        cardIcon = <Icon name="checkmark" size="large" color="green" style={{float: 'right'}}/>;
        break;
      case "rejected":
        cardIcon = <Icon name="remove" size="large" color="red" style={{float: 'right'}}/>;
        break;
      case "waiting":
        cardIcon =
          <Button icon style={{float: 'right'}} onClick={(e) => this.removeFile(index, e)}>
            <Icon name="trash" size="small" />
          </Button>;
        break;
    }

    return (
      <Grid.Column
        key={index}
        computer={4}
        tablet={4}
        mobile={6}
      >
        <Card color={cardColor} as={Segment} fluid>

          <Dimmer active={file.status === 'loading'}>
            <Loader />
          </Dimmer>

          <Image src={file.preview} style={{objectFit: 'cover', height: 95}}/>
          <Card.Content>
            <Card.Description>
              {fileName}
              {cardIcon}
            </Card.Description>
          </Card.Content>
        </Card>
      </Grid.Column>
    );
  }

  render () {

    const filesWaiting = this.files.filter(f => f.status === 'waiting').length > 0;

    let acceptedFileTypes;
    if (this.props.acceptedFileType === "image") {
      acceptedFileTypes = "image/jpeg, image/png"
    } else if (this.props.acceptedFileType === "video") {
      acceptedFileTypes = "video/mpeg, video/avi, video/quicktime, video/mp4";
    }

    return (

      <Grid >
        <Grid.Row >
          <Grid.Column width={12} centered>
            <Grid.Row centered >
              <div style={{width:"95%"}}>
                <Grid>
                  {this.files.length > 0 && (
                    this.files.filter(file => file.status === "waiting").length > 0 ? (
                      <Grid.Column width={12}>
                        <h4>
                          Add or Remove {this.props.acceptedFileType === "image" ? ("Images") : ("Videos")} to Upload
                        </h4>
                      </Grid.Column>
                    ) : this.files.filter(file => file.status === "loading" ).length > 0 ? (  
                      <Grid.Column width={12}>
                        <h4>
                          Upload In Progress...
                        </h4>
                      </Grid.Column>                   
                    ) : (
                      <Grid.Column width={12}>
                        <h4>
                          Upload Completed
                        </h4>
                      </Grid.Column>
                    )      
                  )}
                  <Grid.Column width={this.files.length === 0 ? 12 : 9}>
                    <Dropzone
                      onDrop={this.onDrop.bind(this)}
                      accept={acceptedFileTypes}
                      style={styles.dropzone.normal}
                      activeStyle={styles.dropzone.accept}
                      rejectStyle={styles.dropzone.reject}
                      ref={dz => this.dropzone = dz}
                    >
                      {this.files.length === 0 ? (
                        <h4 style={styles.dropzone.text}>
                          Click here or drop {this.props.acceptedFileType === "image" ? ("images") : ("videos")} from your computer to upload
                        </h4>
                      ) : (
                        <Grid columns={12} style={{padding: 15}}>
                          {this.files.map((file, i) => this.renderThumbnail(file, i))}
                        </Grid>
                      )}
                    </Dropzone>
                  </Grid.Column>
                  {this.files.length > 0 && (
                    this.files.filter(file => file.status === "waiting" || file.status === "loading" ).length > 0 ? (
                      <Grid.Column width={3}>
                        <Button primary fluid disabled={!filesWaiting} onClick={() => this.onAcceptUpload()} style={{ marginBottom: "7px" }}>
                          Upload All
                        </Button>
                        <Button fluid disabled={!filesWaiting} onClick={() => this.files = []} style={{ marginBottom: "7px" }}>
                          Discard All
                        </Button>   
                      </Grid.Column>
                    ) : (
                      <Grid.Column width={3}>
                        <Button primary fluid onClick={() => this.files = []} style={{ marginBottom: "7px" }}>
                          Upload More
                        </Button>
                        <Button fluid onClick={() => {this.files = []; this.props.onClose();}} style={{ marginBottom: "7px" }}>
                          Exit Uploader
                        </Button>   
                      </Grid.Column>
                    )
                  )}
                </Grid>
              </div>    
            </Grid.Row >
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }


}

export default MutipleImageUploader;
