import * as React from 'react';
import mobx, { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { GuestbookEntry } from 'state/objects';
import { MemoryForm } from 'state/stores/forms';
import {
  Button,
  Header,
  Divider,
  Grid,
  Input,
  TextArea,
  Icon,
  Modal,
  Form,
  Message
} from 'semantic-ui-react';


@inject('accountStore') @observer
class MemoriesPane extends React.Component<MemoriesPaneProps, {}> {

  @observable form: MemoryForm | null;

  constructor (props: MemoriesPaneProps, context) {
    super(props, context);
  }

  componentDidMount () {
    this.props.memory.getForm()
      .then(form => {
        this.form = form
      });
  }

  saveMemory (event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (this.form) {
      this.form.save()
      .then(() => {
        this.props.onClose();
      })
    }
  }

  cancelEdit () {
    this.props.memory.resetEdit();
    this.props.onClose();
  }

  render () {

    let {
      memory,
      onClose
    } = this.props;

    const form = this.form;

    if (!form) {
      return null;
    }

    const fields = form.form.fields;

    return (

      <Modal
        open
        size="tiny"
        onClose={() => this.cancelEdit()}
      >

        <Modal.Header>
          Sign Guestbook
        </Modal.Header>

        <Modal.Content>
          <Form error>

            <Form.Field required>
              <label>Message:</label>
              <TextArea
                value={fields.text.value}
                onChange={e => form.onFieldChange('text', e.currentTarget.value)}
                rows={10}
                style={{
                  fontSize: '1em'
                }}
              />
              <span style={{float:"right", marginTop:"0px", fontSize:"0.9em"}}>
                {`(${fields.text.value.length}/${form.getMaxCharacters('text')} characters)`}
              </span>
            </Form.Field>
            <Message error>{fields.text.error}</Message>

            <Message error>{form.form.meta.error}</Message>

          </Form>

        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={() => this.cancelEdit()}
          >
            Cancel
          </Button>
          <Button
            primary
            disabled={!form.form.meta.isValid}
            onClick={e => this.saveMemory(e)}
          >
            Post
          </Button>
        </Modal.Actions>

      </Modal>
    );

  }
};

export interface MemoriesPaneProps {
  memory: GuestbookEntry;
  onClose(): any;
}

export default MemoriesPane;
