import {
  observable,
  action,
  computed
} from 'mobx';

import jsCookie from 'js-cookie';
//import * as ReactGA from 'react-ga';
import history from '../history';
import RootStore from 'state/rootStore';

export default class CookieStore {

  @observable acceptsCookies: boolean = false;
  rootStore: RootStore;

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore;
    this.initCookies();
  }

  @action
  initCookies () {
    if (jsCookie.get('acceptsCookies') == "true") {
      this.acceptsCookies = true;
      this.initGA();
    }
  }

  @action
  acceptCookies () {
    jsCookie.set('acceptsCookies', 'true');
    this.acceptsCookies = true;
    this.initGA();
  }

  @action
  disableCookies() {
    jsCookie.remove('acceptsCookies');
    this.acceptsCookies = false;
    this.disableGA();
  }

  @action
  initGA () {
    /*
    ReactGA.initialize('UA-102076899-1', {
      debug: true
    });

    history.listen(logURLtoGA);

    function logURLtoGA (location) {
      ReactGA.set({ page: location.pathname + location.search });
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    //force log initial url
    logURLtoGA(history.location);
    */
  }

  @action
  disableGA () {
    console.log("TURN OFF GOOGLE ANALYTICS AND DELETE COOKIES")
  }

}