import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import * as qs from 'qs';

import {
  Grid,
  Header,
  Divider,
  Container
} from 'semantic-ui-react';

import SearchStore from 'state/search';
import SearchResults from './SearchResults';

export interface Props {
  searchStore?: SearchStore,
  location: any;
}

const getSearchTerm = string => qs.parse(string, { ignoreQueryPrefix: true }).q;

@inject('searchStore') @observer
class SearchPage extends React.Component<Props, {}> {

  searchStore: SearchStore;

  constructor (props: Props, context) {
    super(props, context);
    this.searchStore = this.props.searchStore!;

    const query = getSearchTerm(props.location.search);

    this.searchStore.loadProfiles();
    this.searchStore.searchTerm = query;
  }

  componentWillReceiveProps (nextProps) {

    const oldSearchTerm = getSearchTerm(this.props.location.search);
    const newSearchTerm = getSearchTerm(nextProps.location.search);

    if (newSearchTerm !== oldSearchTerm) {
      this.searchStore.searchTerm = newSearchTerm;
    }
  }

  render () {

    return (

      <div className="dark-blue no-mobile-padding">
        <Container>
          <div className="fill-window-height" style={{backgroundColor: "white"}}>
            <Grid container style={{paddingTop: 50}}>
              <Grid.Column width={12} style={{paddingBottom: 0}}>
                <Header color="red">
                  Search Results
                </Header>
                <Divider style={{marginBottom: 0}}/>
              </Grid.Column>

              <Grid.Column width={12} style={{paddingTop: 0}}>
                <SearchResults
                  results={this.searchStore.results}
                />
              </Grid.Column>
              
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default SearchPage;
