import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import * as moment from 'moment';

import {
  Grid,
  Header,
  Image,
  Dropdown,
  Button,
  Label,
  Icon,
  Modal
} from 'semantic-ui-react';

import { Memorial } from 'state/objects';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderArrow from 'components/Generic/SliderArrow';
import ImageUploader from 'components/ImageUploader';
import { PhotoStore } from 'state/stores';
import ReportStore from 'state/reportStore'

import heartImage from 'images/heart-quote.png';

const nameBoxStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '15%',
  right: '7.5%',
  zIndex: 3000
};

export interface MemorialHeaderProps {
  memorial: Memorial;
  onEditClick(): any;
  photoStore?: PhotoStore;
  reportStore?: ReportStore;
}

@inject('photoStore', 'reportStore') @observer
class MemorialHeader extends React.Component<MemorialHeaderProps, {}> {

  @observable avatarModalOpen = false;
  @observable coverModalOpen = false;
  @observable contributeModalOpen = false;
  @observable carouselIndex = 0;
  
  constructor (props: MemorialHeaderProps, context) {
    super(props, context);
  }

  showAvatarModal () {
    this.avatarModalOpen = true;
    this.props.memorial.fetchPhotos();
  }

  closeAvatarModal () {
    this.avatarModalOpen = false;
  }

  uploadAvatarImage(file) {
    this.closeAvatarModal();
    this.props.memorial.uploadAvatar(file);
    this.props.photoStore!.add(this.props.memorial._id, file);
  }

  reportMemorial () {
    this.props.reportStore?.openModal('memorials', this.props.memorial._id);
  }

  render () {

    const { memorial, onEditClick } = this.props;
    const { userIsAdmin } = memorial;
    const { photos } = this.props.memorial;

    //The main picture
    let avatarUrls: string[] = [];
    if (memorial.avatarUrl) {
      avatarUrls.push(memorial.avatarUrl);
    }
    avatarUrls = avatarUrls.concat(memorial.photos.map(p => p.url));

    //Unused
    let coverUrls: string[] = [];
    if (memorial.coverUrl) {
      coverUrls.push(memorial.coverUrl);
    }
    coverUrls = coverUrls.concat(memorial.photos.map(p => p.url));

    const carousel: string[] = [];
    carousel.push(memorial.avatar);
    photos.map(
      (photo, idx) => (
        carousel.push(photo.url)
        )
    );
 
      /*: (
        <Button primary onClick={() => this.contributeModalOpen = true}><Icon name="plus" />Contribute to Memorial</Button>
      )}*/

    const rightSideButton = userIsAdmin ? (
      <Button onClick={() => onEditClick()}><Icon name="edit" />Manage Memorial</Button>
    ) : (
      <Dropdown icon="ellipsis horizontal" direction="left" button style={{ paddingLeft: '0.7em', paddingRight: '0.2em'}}>
        <Dropdown.Menu>
          <Dropdown.Item text="Report memorial" onClick={() => this.reportMemorial()} />
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <div style={{backgroundColor: 'white', marginBottom:"-15px"}}>
        <div style={{position: 'relative'}}>

          <div id="memorial-slider-container">
            <div id="memorial-slider">
              <Slider 
                dots= {false}
                infinite={true}
                arrows={true}
                speed= {500}
                slidesToShow={1}
                slidesToScroll={1}
                prevArrow={ <SliderArrow direction="left" />}
                nextArrow={ <SliderArrow direction="right" />}          
              >
                {carousel.map(image => (
                  <img
                    key={image}
                    src={image} 
                  />
                ))}
              </Slider>
            </div>
          </div>

          <div style={nameBoxStyle}>
            <div className="pseudo-button red-background left">{memorial.displayName}</div>
            <div className="clear" />
            {memorial.yearRange && <div className="pseudo-button dark-blue right">{memorial.yearRange}</div>}
          </div>
          
          {userIsAdmin && (
            <div style={{position: 'absolute',
              bottom: '4%',
              left: '2.5%',
              zIndex: 3000}}
            >
              <Button onClick={() => this.avatarModalOpen = true}>
                <Icon name="write"/>Change Main Picture
              </Button>             
            </div>
          )}

          {(!memorial.obituary || memorial.featured) && (
            <div style={{position: 'absolute',
              bottom: '4%',
              right: '2.5%',
              zIndex: 3000}}
            >
              {rightSideButton}   
            </div>
          )}
          

        </div>

        {(memorial.obituary || memorial.featured) &&
          <div className="profile-header-info">
            <Grid>
              <Grid.Row>

                <Grid.Column computer={9} tablet={8} mobile={6} className="profile-info">
                  
                    <div>
                      <img src={heartImage} className="heart" />
                      <h1 className="epitaph-quote">
                        <i>{'"'}</i>
                        {memorial.obituary}
                        <i>{'"'}</i>
                      </h1>
                    </div>
                  
                  
                </Grid.Column>

                <Grid.Column computer={3} tablet={4} mobile={6} verticalAlign="middle" textAlign="right">
                  {memorial.featured ? (
                    <Label
                      color="red"
                      size="big"
                      basic
                    >
                      Sample Memorial
                    </Label>
                  ) : rightSideButton}
                  
                  
                </Grid.Column>

              </Grid.Row>
            </Grid>
          </div>
        }

        {this.avatarModalOpen && (
          <Modal open basic closeIcon size="large" onClose={() => this.closeAvatarModal()}>
            <Modal.Content>
              <ImageUploader
                photos={avatarUrls}
                aspectRatio={16/10}
                onUpload={img => this.uploadAvatarImage(img)}
              />
            </Modal.Content>
          </Modal>
        )}

        {this.contributeModalOpen && (
          <Modal
            open
            size="tiny"
            onClose={() => this.contributeModalOpen = false}
          >
            <Modal.Header>
            Contribute to Memorial
            </Modal.Header>
            <Modal.Content>
              <p>The owner of this memorial needs to give you permission before
              you can contribute to the memorial. If they accept, you'll be able to
              contribute memories, and upload photos, videos and files to the memorial. The
              owner will be added to your connections.
              <br /><br />
              Send request to contribute now?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.contributeModalOpen = false}>Cancel</Button>
              <Button primary>Send Request</Button>
            </Modal.Actions>
          </Modal>
        )}

      </div>
    );
  }
}

export default MemorialHeader;
