import * as React from 'react';
import {
  Input,
  Form
} from 'semantic-ui-react';

function FormInput(props) {

  const {
    error,
    ...rest
    } = props;

  const style = {marginBottom: error ? '0px' : '20px'};

  return (
    <Form.Field>
      <Input
        style={style}
        required
        iconPosition="left"
        {...rest}
       />
      <span className="form-error">{error}</span>
    </Form.Field>
  );
}

export default FormInput;
