import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';

import {
  Loader,
} from 'semantic-ui-react';

import UIStore from 'state/ui';
import AccountStore from 'state/account';
import BaseContainer from "components/Generic/baseContainer";

export interface Props {
  history?: any;
  accountStore?: AccountStore;
  uiStore: UIStore;
  match?: any;
}

@inject('accountStore', "uiStore") @observer
class VerifyEmailPage extends React.Component<Props, {}> {

  accountStore: AccountStore;
  uiStore: UIStore;

  @observable success = false;
  @observable failed = false;

  constructor (props, context) {
    super(props, context);
    this.uiStore = this.props.uiStore!;
    this.accountStore = this.props.accountStore!;
  }

  componentDidMount () {
    this.confirm();
  }

  confirm () {
    this.accountStore.verifyEmail(this.props.match.params.token)
    .then(() => {
      this.success = true;
      this.accountStore.tryLogin();
    }).catch((error) => {
      console.log(error);
      this.failed = true;
    });
    //one way to log in when email is verified
    //one way to automatically redirect to profile if logged in
  }

  render () {

    if (this.success) {
      return (
        <BaseContainer>
          <div style={{padding: "25px 0 50px 0",  textAlign: "center" }}>
            <h1 className="memtree-cursive">
              Success!
            </h1>
            <h3 style={{ textAlign: "center" }}>
              Your email has been verified!     
            </h3>
            <p style={{position:"relative", marginTop:"70px"}}>Log in&nbsp;
              <a style={{cursor:"pointer"}} onClick={() => this.uiStore.showLoginModal = true}>
                here
              </a>
              !
            </p>
          </div>
        </BaseContainer>
      );
    }

    if (this.failed) {
      return (
        <BaseContainer>
          <div style={{padding: "25px 0 50px 0",  textAlign: "center" }}>
            <h1 className="memtree-cursive">
              Error!
            </h1>
            <h3 style={{ textAlign: "center" }}>
              Unable to verify your E-mail.
            </h3>
            <p style={{position:"relative"}}>Perhaps you have already verified your E-mail? If so, log in&nbsp;
              <a style={{cursor:"pointer"}} onClick={() => this.uiStore.showLoginModal = true}>
                here
              </a>
              !       
            </p>
          </div>
        </BaseContainer>
      );
    }

    return (
      <BaseContainer>
        <div style={{textAlign: 'center', padding: "25px 0 50px 0"}}>
          <h1 className="memtree-cursive">
            Verifying E-mail...
          </h1>
          <div style={{position:"relative", marginTop:"50px"}}>
            <Loader active />
          </div>
        </div>
      </BaseContainer>
    );
    
  }

}

export default withRouter(VerifyEmailPage);


