import * as React from 'react';

import {
  Image,
  Header,
  Icon,
} from 'semantic-ui-react';

import UserAvatar from 'components/Generic/Avatar';
import {Profile, Memorial } from 'state/objects';

export interface Props {
  profile: Profile | Memorial;
  title: string;
  description?: string;
  image?: string;
  type?: string;
  gender?: string;
}

const RenderSearchResult = ({profile, title, description, image, type, gender, ...props }: Props) => {
  
  return (
    <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", position: "relative"}}>
      {/*}
      {type == "memorial" ? (
        <Image src={image} style={{
            position: "relative",
            float: 'left',
            width: 52,
            height: 52,
            objectFit: "cover",
            margin: '0px auto 0px auto',
            boxShadow: `0px 0px ${4}px 0px rgba(0,0,0,0.75)`,
            border: `${4}px solid`,
            borderRadius: "50%",
            borderColor: gender === 'male' ? "rgb(87, 114, 144)" : "rgb(239, 81, 136)",}}
          className={gender}
        />
      ) : (
        <Image style={{
          position: "relative",
          float: 'left',
          width: 50,
          height: 50,
          objectFit: "cover",
          margin: '0px auto 0px auto',
         }}
        className={gender}>
         <img src={image}  style={{ 
          border: `${2}px solid`,
          borderRadius: "50%",
          borderColor: "grey"}}
        />
          <div style={{float:"right", position:"relative", bottom:"20px", right:"-8px"}}>
            <Icon name="user" color="blue" size="large"/>
          </div>
        </Image> 
         )}*/}

      <div style={{textAlign: 'left', marginTop: 0, display:"inline-block", minHeight:"52px",}}>
        <UserAvatar
          profile={profile}
          size={52}
          style={"default"}
          noText={true}
          noLink={true}
        />
      </div>
      
      <div style={{textAlign: 'left', marginTop: 0, marginLeft:"7px", display:"inline-block", }}>
        <Header as="h4"  style={{textAlign: 'left', marginTop: 0, position: "relative", bottom: type != "memorial" ? 15 : 0,}}>
          {type == "memorial" ?
            <span className="text-blue"> 
              {title} 
            </span>
          :
            <span> 
              {title}
            </span>
          }
          <Header.Subheader>
            {type == "memorial" ?
              <span className="text-red"> 
                {description}
              </span>
            :
              <span> 
                {description}
              </span>
            }        
          </Header.Subheader>
        </Header>
      </div>
    </div>
  );
};

export default RenderSearchResult;
