import {
  observable,
  action,
  computed
} from 'mobx';

import RootStore from 'state/rootStore';

import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import { IReport } from 'state/apiClient/ServiceTypes';

type ResourceKinds = 'memorials' | 'profiles' | 'guestbookEntries' | 'comments' | 'photos' | 'videos';

export default class ReportStore {

  rootStore: RootStore;

  service: Service<IReport>;

  readonly MAX_MESSAGE_SIZE = 300;

  @observable modalOpen: boolean = false;
  @observable loading: boolean = false;

  @observable message: string = '';
  @observable resourceKind: ResourceKinds | '' = '';
  @observable resourceId: number;

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore;
    this.service = service('report');
  }

  @action
  reset () {
    this.message = '';
    this.resourceId = 0;
    this.resourceKind = '';
  }

  @action
  changeMessage (text: string) {
    if (text.length > this.MAX_MESSAGE_SIZE) return;
    this.message = text;
  }

  @action
  openModal (resourceKind: ResourceKinds, resourceId: number) {
    this.resourceKind = resourceKind;
    this.resourceId = resourceId;
    this.modalOpen = true;
  }

  @action
  closeModal () {
    this.reset();
    this.modalOpen = false;
  }

  @action
  async submitReport () {
    if (!this.resourceId || !this.resourceKind) {
      throw new Error('ReportStore.submitReport(), resourceId and resouceKind need to be specified');
    }

    this.loading = true;

    const response = await this.service.create({
      resourceId: this.resourceId,
      resourceKind: this.resourceKind,
      message: this.message
    });

    this.loading = false;
    this.closeModal();
  }
}