//Login form at /login

import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Switch, Route, Link, withRouter } from 'react-router-dom';

import AccountStore from 'state/account';
import uiStore from 'state/ui';

import {
  Form,
  Grid,
  Menu,
  Container,
  Button,
  Icon,
  Modal
} from 'semantic-ui-react';

interface LandingPageProps {
  history?: any;
  accountStore: AccountStore;
  uiStore: uiStore;
}

@inject('accountStore', 'uiStore',) @observer
class LandingPage extends React.Component<LandingPageProps, {}> {

  accountStore: AccountStore;

  constructor (props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
  }

  onClose = false;

  render () {

    return (

    <div>
        
        <Grid className="dark-blue no-mobile-padding fill-window-height">
            <Container>
                <Grid.Row style={{marginTop:"50px"}}>
                    <div
                        style={{
                            fontSize: "65px",
                            fontFamily: "'Cookie', sans-serif",
                            padding: "0px 11px",
                            color: "#fff",
                            textDecoration: "none",
                            textAlign:"center"}}
                    >
                            Welcome
                        </div>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={{backgroundColor:"white", padding:"30px", width:"600px", margin: "50px auto" }}>
                        <div style={{width:"100%"}}>
                            <Button
                                style={{width:"260px", marginRight:"5px", color:"#577290", backgroundColor:"white", border:"2px solid #577290", boxSizing:"border-box"}}
                                onClick={() => this.props.uiStore.showLoginModal = true}
                                >
                                Log In
                            </Button>
                            <Button primary 
                                style={{width:"260px", marginLeft:"5px"}}
                                onClick={() => {<Link to="/register"></Link>}}>
                                Register
                            </Button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Container>
        </Grid>
    </div>
    );
  }

}


export default withRouter(LandingPage);
