import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

function Photo ( {uri, ...rest} ) {
  return (
    <Link {...rest} className="">
      <div className="dummy" />
      <img src={uri} />
    </Link>
  );
}

export default observer(Photo);
