import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import {
  INotification,
  IPhoto,
  IVideo,
  IConnection,
  IGuestbookEntry,
  IComment,
  INotificationFamilyTreeData
} from 'state/apiClient/ServiceTypes';
import { NotificationStore } from 'state/stores';
import RootStore from 'state/rootStore';
import {
  Photo,
  Video,
  GuestbookEntry,
  Comment,
  Connection
} from 'state/objects';
import FamilyTree from 'state/familyTree/tree';
import { Moment } from 'moment';
import PendingFamilyTree from 'state/familyTree/PendingFamilyTree';
import Profile from './profile';
import Memorial from './memorial';
import omit from 'lodash/omit';

interface NotificationFamilyTreeData {
  requestingUserProfile?: Profile;
  requestingProfile?: Profile | Memorial;
  targetProfile?: Profile | Memorial;
  confirmedByProfile?: Profile;
}

class Notification {

  service: Service<INotification> = service('notifications');
  store: NotificationStore;
  rootStore: RootStore;

  @observable _id: number;
  @observable type: 'memorial_photo' |
    'memorial_video' |
    'guestbook_entry' |
    'connection' |
    'guestbook_comment' |
    'guestbook_comment_reply' |
    'familyTreeJoinRequest' |
    'familyTreeJoinAccept' |
    'familyTreeJoinDecline';
  @observable read: boolean;
  @observable createdAt: Moment;

  @observable relatedKind: 'connections' | 'comments' | 'guestbookEntries' | 'photos' | 'videos' | 'familyTrees';
  @observable relatedId: number;
  @observable url: string;
  @observable text: string;
  @observable image: string;

  @observable _familyTreeData: INotificationFamilyTreeData;

  constructor(rootStore: RootStore, store: NotificationStore, notification: INotification) {
    this.rootStore = rootStore;
    this.store = store;

    // @ts-ignore
    notification._familyTreeData = notification.familyTreeData;
    delete notification.familyTreeData;
    Object.assign(this, omit(notification, ['familyTreeData']));
  }

  //update with new class that extends familytree and holds requesting user, requesting tree and target tree, and type of the target profile (if several roots)
  @computed
  get treeJoin(): PendingFamilyTree | undefined {
    if (this.relatedKind !== 'familyTrees') {
      return undefined;
    }
    return this.rootStore.familyTreeStore.pendingFamilyTrees.find(c => c._id === this.relatedId);
  }

  @computed
  get familyTreeData(): NotificationFamilyTreeData | null {
    if (this._familyTreeData) {
      return {
        requestingUserProfile: this.rootStore.profileStore.profiles.find(p => p._id === this._familyTreeData.requestingUserProfileId),

        requestingProfile: this._familyTreeData.requestingProfileKind === 'profiles'
          ? this.rootStore.profileStore.profiles.find(p => p._id === this._familyTreeData.requestingProfileId)
          : this.rootStore.memorialStore.memorials.find(p => p._id === this._familyTreeData.requestingProfileId),

        targetProfile: this._familyTreeData.targetProfileKind === 'profiles'
          ? this.rootStore.profileStore.profiles.find(p => p._id === this._familyTreeData.targetProfileId)
          : this.rootStore.memorialStore.memorials.find(p => p._id === this._familyTreeData.targetProfileId),

        confirmedByProfile: this.rootStore.profileStore.profiles.find(p => p._id === this._familyTreeData.confirmedByProfileId)
      }
    }
    return null;
  }

  @computed
  get photo(): Photo | undefined {
    if (this.relatedKind !== 'photos') {
      return undefined;
    }
    return this.rootStore.photoStore.photos.find(p => p._id === this.relatedId);
  }

  @computed
  get video(): Video | undefined {
    if (this.relatedKind !== 'videos') {
      return undefined;
    }
    return this.rootStore.videoStore.videos.find(v => v._id === this.relatedId);
  }

  @computed
  get guestbookEntry(): GuestbookEntry | undefined {
    if (this.relatedKind !== 'guestbookEntries') {
      return undefined;
    }
    return this.rootStore.guestbookEntryStore.entries.find(m => m._id === this.relatedId);
  }

  @computed
  get comment(): Comment | undefined {
    if (this.relatedKind !== 'comments') {
      return undefined;
    }
    return this.rootStore.commentStore.comments.find(c => c._id === this.relatedId);
  }

  @computed
  get connection(): Connection | undefined {
    if (this.relatedKind !== 'connections') {
      return undefined;
    }
    return this.rootStore.connectionStore.connections.find(c => c._id === this.relatedId);
  }

  @action
  async markAsRead() {
    try {
      await this.service.patch(this._id, { read: true });
      this.read = true;
    } catch (err) {
      console.error('Notification.markAsRead()', err);
    }
  }
}

export default Notification;
