import * as React from 'react';
import mobx, { observable } from "mobx";
import { observer, inject } from "mobx-react";
import AccountStore from 'state/account';

import MemorialHeader from './MemorialHeader';
import MemorialFooterJS from './MemorialFooter';
const MemorialFooter: any = MemorialFooterJS;

import AsyncComponent from 'components/AsyncComponent';
import NotFound from 'components/NotFound';

import {
  Grid,
  Container,
  Menu,
  Icon,
  Message
} from 'semantic-ui-react';

import { LinkContainer } from 'react-router-bootstrap';
import { Switch, Route, Link } from 'react-router-dom';

import FamilyTreePane from 'components/FamilyTree/FamilyTreePane';
import GalleryPane from './GalleryPane';
import MemoriesPane from './MemoriesPane';
import AdBanner from "components/Ads/AdBanner";

//import Biography from './MemorialBiography';

import {
  Memorial
} from 'state/objects';
import { MemorialStore } from 'state/stores';
import { match } from 'react-router';

export interface MemorialContainerProps {
  accountStore: AccountStore;
  memorialStore: MemorialStore;
  match: match<{ profileID: string }>;
}

@inject('uiStore', 'accountStore', 'memorialStore') @observer
class MemorialContainer extends React.Component<MemorialContainerProps, {}> {

  accountStore: AccountStore;
  memorialStore: MemorialStore;

  @observable editModalOpen: boolean = false;
  @observable memorial: Memorial;
  @observable error: boolean = false;

  constructor(props: MemorialContainerProps, context) {
    super(props, context);

    this.accountStore = props.accountStore;
    this.memorialStore = props.memorialStore;

    this.fetchMemorial(props.match.params.profileID);
  }

  componentWillReceiveProps(nextProps: MemorialContainerProps) {

    if (this.props.match.params.profileID !== nextProps.match.params.profileID) {
      this.fetchMemorial(nextProps.match.params.profileID);
    }
  }

  fetchMemorial(id: string) {
    this.error = false;
    this.memorialStore.fetchById(id)
      .then(memorial => {
        this.memorial = memorial;
        this.memorial.fetchPhotos();
      })
      .catch(err => {
        console.log(err);
        this.error = true;
      });

    window.scrollTo(0, 0);
  }

  renderGalleryPane = props => <GalleryPane profile={this.memorial} {...props} />
  renderFamilyTreePane = props => <FamilyTreePane profile={this.memorial} {...props} />
  renderMemoriesPane = props => <MemoriesPane memorial={this.memorial} {...props} />

  render() {

    const { memorial } = this;

    const profileURI = '/memorial/' + this.props.match.params.profileID;
    const {
      match
    } = this.props;

    if (this.error) {
      return (
        <NotFound
          header="Memorial not found"
          message="Either the Memorial you are trying to access does not exist or you do not have permission to view it."
        />
      );
    }

    if (!this.memorial) {
      return <div className="no-mobile-padding dark-blue fill-window-height" />
    }

    return (
      <Grid className="dark-blue no-mobile-padding fill-window-height">
        <Container>
          <MemorialHeader
            memorial={memorial}
            onEditClick={() => this.editModalOpen = true}
          />

          {/*}
          <div style={{marginTop: 15}}>
            <Biography
              profile={memorial}
            />
    </div>*/}

          <Grid.Row className="menu-tabs">
            <Menu tabular widths={3} className="section-tabs">
              <LinkContainer
                to={`${profileURI}/biography`}
                isActive={(match, location) =>
                  location.pathname === profileURI
                  || location.pathname === `${profileURI}/`
                  || location.pathname.match(`${profileURI}/biography`)}
              >
                <Menu.Item>
                  <Icon name="quote right" />
                  <span>Biography</span>
                </Menu.Item>
              </LinkContainer>
              <LinkContainer to={`${profileURI}/gallery`}>
                <Menu.Item>
                  <Icon name="image" />
                  <span>Gallery</span>
                </Menu.Item>
              </LinkContainer>
              <LinkContainer to={`${profileURI}/familytree`}>
                <Menu.Item>
                  <Icon name="tree" />
                  <span>Family Tree</span>
                </Menu.Item>
              </LinkContainer>
            </Menu>
          </Grid.Row>

          <Grid.Row className="tab-content no-mobile-padding">
            <Switch>
              <Route exact path={`${match.url}`} render={this.renderMemoriesPane} />
              <Route path={`${match.url}/biography`} component={this.renderMemoriesPane} />
              <Route path={`${match.url}/gallery`} component={this.renderGalleryPane} />
              <Route path={`${match.url}/familytree`} component={this.renderFamilyTreePane} />
            </Switch>
          </Grid.Row>

          <MemorialFooter
            memorial={memorial}
          />

          {this.editModalOpen && (
            <AsyncComponent
              moduleProvider={() => import(/*webpackChunkName "MemorialEditModal" */ './MemorialEditModal')}
              showLoadingBar

              memorial={this.memorial}
              mode={"edit"}
              open={this.editModalOpen}
              onClose={() => { this.editModalOpen = false }}
              onDelete={() => console.error('memorial delete needed')}
            />
          )}

          {/*
           <div style={{paddingTop:"71px"}}>
            <AdBanner />
            </div>*/}

        </Container>
      </Grid>
    );
  }
};

const notImplemented = () => (
  <div>
    <h2>Not implemented</h2>
  </div>
);



export default MemorialContainer;
