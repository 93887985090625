import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';

import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import extend from 'lodash/extend';

import Person from 'state/familyTree/person';

import FormBaseClass from './baseClass';
import { createRuleString } from './utils';
import moment from 'moment';

//The ProfileForm object contains all the fields, their values, their error messages,
//and the error rules
class ProfileForm extends FormBaseClass {

  person: Person;

  @observable form = {
    fields: {
      _firstName: {
        value: '',
        error: null,
        rule: 'string'
      },
      _lastName: {
        value: '',
        error: null,
        rule: 'string'
      },
      _dateOfBirth: {
        value: null,
        error: null,
        rule: 'date|required'
      },
      _dateOfPassing: {
        value: null,
        error: null,
        rule: 'date'
      },
      _gender: {
        value: 'female',
        error: null,
        rule: 'string|required'
      }
    },

    meta: {
      isValid: true,
      error: <string | null>null
    }
  }

  @observable loaded: boolean = false;

  //loads information from an already existing profile
  //and puts into the fields

  constructor(person: Person) {
    super();
    this.person = person;
    this.person.resetEdit();

    const fields = pick(person, [
      '_firstName',
      '_lastName',
      '_dateOfPassing',
      '_dateOfBirth',
      '_gender'
    ]);

    //apply the values from the profile 
    //object to the correct fields using a loop
    for (let key in fields) {
      if (key in this.form.fields) {
        this.form.fields[key].value = fields[key];
      }
    }
  }

  //gets the rules for all the form fields from the back end
  @action
  async loadRules() {
    return this;
  }

  //executes when you hit "save" on the form
  @action
  async save() {
    try {
      const values = mapValues(
        this.form.fields,
        o => o.value
      );

      // @ts-ignore
      if (values._dateOfBirth) values._dateOfBirth = moment(values._dateOfBirth);
      // @ts-ignore
      if (values._dateOfPassing) values._dateOfPassing = moment(values._dateOfPassing);

      extend(this.person.editModel, values);
      await this.person.saveEdit();
    } catch (err) {
      this.form.meta.error = 'Something went wrong';
      throw new Error('ProfileForm.save() - ' + err);
    }
  }
}

export default ProfileForm;

