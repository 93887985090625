import * as React from 'react';
import { observable, autorun } from "mobx";
import { observer, inject, } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';

import {
  Modal,
  Form,
  Input,
  Button,
  Grid,
  Header,
  List,
  Image,
  Icon,
  Popup,
  Checkbox,
} from 'semantic-ui-react';

import Select from 'react-select';
import ProfileOption from 'components/ProfileOption';

import {
  Memorial,
  Profile
} from 'state/objects';

import { MemorialStore, FamilyTreeStore } from 'state/stores'
import AccountStore from 'state/account';


import FamilyTree from 'state/familyTree/tree';
import UserAvatar from 'components/Generic/Avatar';

import TreeGraphic from 'images/noun_Tree_2584135.svg';
import { ReactComponent as TreeGraphic2 } from 'images/noun_Tree_2584135.svg';

export interface Props {
  accountStore?: AccountStore;
  memorialStore?: MemorialStore;
  familyTree: FamilyTree;
  open: boolean;
  onClose(): any;
}


@inject('accountStore', 'memorialStore', 'familyTreeStore') @observer
class SelectPeopleModal extends React.Component<Props, {}> {

  accountStore: AccountStore;
  memorialStore: MemorialStore;
  familyTreeStore: FamilyTreeStore;
  familyTree: FamilyTree;

  @observable selectedMembers: (Profile | Memorial) [] = [];
  @observable showConfirmModal: boolean = false;



  constructor (props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.memorialStore = this.props.memorialStore!;
    this.familyTree = this.props.familyTree!;
  }

  componentDidMount () {
    if (this.accountStore.myProfile) {
      this.accountStore.myProfile.fetchMemorials();
    }
  }

  //done to ensure the first modal is still scrollable
  setBodyScrolling () {
    setTimeout(() => {
      document.body.classList.toggle('scrolling', true);
    }, 0);
  }

  selectMember (member: Profile | Memorial) {
    //check if member is in array
    const index = this.selectedMembers.indexOf(member);
    if (index < 0 ) {
      this.selectedMembers.push(member); 
    } else {
      this.selectedMembers.splice(index, 1);
    }
  }

  disconnectFamilyTree() {
    this.props.familyTree.leaveTree(this.selectedMembers);
  }

  renderOption(member: Profile | Memorial) {

    let isSelected = this.selectedMembers.indexOf(member) > -1;

    return (
  
      <Button 
        basic = {!isSelected}
        fluid style={{ 
          marginBottom: "7px", 
          padding:"15px",
          backgroundColor: isSelected ? "rgb(209, 231, 255)" : "",
        }} 
        onClick={() => this.selectMember(member)}
      >
        <div>
          <Grid verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={10}>
                <div style={{float:"left"}}>
                  <UserAvatar
                    profile={member}
                    size={50}
                    style={"default"}
                    noText={true}
                    noLink={true}
                  />
                </div>

                <div style={{marginLeft:"65px"}}>
                
                  <Header as="h4"  style={{textAlign: 'left', marginTop: 0, position: 'relative',}}>
                    {member instanceof Memorial ?
                      <span className="text-blue"> 
                        {member.displayName} 
                      </span>
                    :
                      <span> 
                        {member.displayName} 
                      </span>
                    }
                    <Header.Subheader>
                      {member instanceof Memorial ?
                        <span className="text-red"> 
                          {member.yearRange}
                        </span>
                      :
                        <span> 
                          Your Profile
                        </span>
                      }        
                    </Header.Subheader>
                  </Header>
                </div>
              </Grid.Column>

              <Grid.Column width={2}>
                <Checkbox  
                  checked = {isSelected}
                />
              </Grid.Column>

            </Grid.Row>
          </Grid>       
        </div> 
      </Button>
  
    );
  
  };

  
  render () {

    let {open, onClose} = this.props;

    return (
      <div>
        <Modal open={true} onClose={onClose} size="tiny">
          <Modal.Header>
            Select People to Disconnect
          </Modal.Header>
          <Modal.Content scrolling>
            <p>
              Please select who of your Profile and Memorials you want to disconnect from this shared FamilyTree. 
              This will create a copy of the shared FamilyTree but only with the selected people as members. 
              Other users will no longer be able to edit the copied tree.
            </p>
            {this.familyTree.userOwnedMembers.map(member => this.renderOption(member))}    
          </Modal.Content> 
          <Modal.Actions>
            <div>
              <Button onClick={() => {this.props.onClose()}}>
                Cancel
              </Button>
              <Button secondary disabled = {this.selectedMembers.length === 0} onClick={() => {this.showConfirmModal = true}}>
                Disconnect Selected People
              </Button>
            </div>
          </Modal.Actions>
        </Modal>

        {this.showConfirmModal && (
          <Modal open size="tiny" onClose={() => { this.showConfirmModal = false }}>
            <Modal.Header>
              Confirm
            </Modal.Header>
            <Modal.Content>
              <p>
                The people you selected will be disconnected from the shared FamilyTree.
                </p>
              <p>
                They will appear in a copy of the shared FamilyTree, but an changes made by the people you shared the tree with
                will no longer show up in your tree, and any changes you make will no longer show up in their trees.
                Do you want to proceed?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => { this.showConfirmModal = false;}}>
                Cancel
              </Button>
              <Button secondary onClick={() => {
                this.showConfirmModal = false;
                this.disconnectFamilyTree();
                this.props.onClose();            
              }}>
                Disconnect From Shared FamilyTree
              </Button>
            </Modal.Actions>
          </Modal>
        )}

      </div>
    );
  }
}

   

export default SelectPeopleModal;
