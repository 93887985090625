import * as React from 'react';

import {
  Grid, 
  Container,
  Message
} from 'semantic-ui-react';

function TermsOfService (): JSX.Element {
  return (
    <Grid className="dark-blue no-mobile-padding fill-window-height">
    <Container>
      <Message floating style={{marginTop: '40px'}}>
        <Message.Content>
          <h1>THE TERMS AND CONDITIONS OF MemoriaTree</h1>
          <p>Note: These terms and conditions were written in English (US). To the extent any translated version of this agreement conflicts with the English version, the English (US) version controls.</p>
          <h5>1 ACCEPTANCE OF THE USE OF MEMORIATREE TERMS AND CONDITIONS</h5>
          <p>Your access to and use of the MemoriaTree Website (the Website), provided by MemoriaTree AB (registration nr: 559231-4057), is subject exclusively to these Terms and Conditions. You will not use the Website for any purpose that is unlawful or prohibited by these Terms and Conditions. By using the Website you are fully accepting the terms, conditions and disclaimers contained in this notice. If you do not accept these Terms and Conditions you must immediately stop using the Website.</p>
          <h5>2 USE OF THE WEBSITE</h5>
          <p>In order to access the Website you must create an account. You shall ensure that the information provided to us is correct, and that you keep your login details confidential.</p>
          <p>When you use the Website you agree to the following. You will not:</p>
          <ol start={1}>
          <li>Use the Website in any way that is or may be damaging to MemoriaTree, or in breach of these terms;</li>
          <li>Use the Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity; or</li>
          <li>Use the Website to engage in any advertising or marketing.</li>
          <li>Upload personal data of other individuals without their consent, as uploading information about other individuals may impinge upon their privacy and data protection rights.</li>
          </ol>
          <h5>3 COPYRIGHT</h5>
          <p>3.1 All copyright, trademarks and all other intellectual property rights in the Website and its content (including without limitation the Website design, text, graphics and all software and source codes connected with the Website) are owned by or licensed to MemoriaTree or otherwise used by MemoriaTree as permitted by law.</p>
          <p>3.2 In accessing the Website you agree that you will access the content solely for your personal, non-commercial use. None of the content may be downloaded, copied, reproduced, transmitted, stored, sold or distributed without the prior written consent of MemoriaTree. This excludes the downloading, copying and/or printing of pages of the Website for personal, non-commercial home use only.</p>
          <p>3.3 Although MemoriaTree presents a value added platform in which you can create Memorial Pages of deceased persons of your knowledge, you agree to do so at your own responsibility and you acknowledge and agree that MemoriaTree has no responsibility or liability for the Memorial Pages created by you or the information or content created and/or added by you. </p>
          <p>3.4 You give us the right to use the information and/or content created and added by you in order to provide the MemoriaTree Website to you, and to create and display the Memorial Pages. Your content must be your own and must not be invading any third-party's rights. MemoriaTree reserves the right to remove any of your content from the Website at any time without notice.</p>
          <p>3.5 All material contained on MemoriaTree is provided without any warranty of any kind. You use the material on MemoriaTree at your own discretion. The contents of MemoriaTree website do not constitute advice and should not be relied upon in making or refraining from making, any decision.</p>
          <h5>4 LINKS TO AND FROM OTHER WEBSITES</h5>
          <p>4.1 Throughout this Website you may find links to third party websites. The provision of a link to such a website does not mean that we endorse that website. If you visit any website via a link on this Website you do so at your own risk&nbsp;and agree that we are not responsible for the content or availability of any such sites.</p>
          <p>4.2 Any party wishing to link to this website is entitled to do so provided that the conditions below are observed:</p>
          <ol type="a">
          <li> you do not seek to imply that we are endorsing the services or products of another party unless this has been agreed with us in writing;</li>
          <li> you do not misrepresent your relationship with this website; and</li>
          <li> the website from which you link to this Website does not contain offensive or otherwise controversial content or, content that infringes any intellectual property rights or other rights of a third party.</li>
          </ol>
          <p>4.3 By linking to this Website in breach of our terms, you shall indemnify us for any loss or damage suffered to this Website as a result of such linking.</p>
          <h5>5 DISCLAIMERS AND LIMITATION OF LIABILITY</h5>
          <p>5.1 The Website is provided on an AS IS and AS AVAILABLE basis without any representation or endorsement made and without warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</p>
          <p>5.2 To the extent permitted by law, MemoriaTree will not be liable for any indirect or consequential loss or damage whatsoever (including without limitation loss of business, opportunity, data, profits) arising out of or in connection with the use of the Website.</p>
          <p>5.3 In order to use the MemoriaTree platform you need to be over the age of 18 or have the consent of a parent, guardian or educational supervisor.</p>
          <p>5.4 MemoriaTree makes no warranty that the functionality of the Website will be uninterrupted or error free, that defects will be corrected or that the Website or the server that makes it available are free of viruses or anything else which may be harmful or destructive.</p>
          <p>5.5 Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability of MemoriaTree for death or personal injury as a result of the negligence of MemoriaTree or that of its employees or agents.</p>
          <h5>6 TERMINATION</h5>
          <p>6.1 Your account will continue until terminated. </p> {/*with 30 days notice, and you will continue to have access to the MemoriaTree platform until such cancellation.*/}
          <p>6.2 You can choose to cancel your account at any time. </p>
          <p>6.3 We also have the right to cancel your account if you are in breach of these terms of use. </p>
          <h5>7 INDEMNITY</h5>
          <p>You agree to indemnify and hold MemoriaTree and its employees and agents harmless from and against all liabilities, legal fees, damages, losses, costs and other expenses in relation to any claims or actions brought against MemoriaTree arising out of your content or any breach by you of these Terms and Conditions or other liabilities arising out of your use of this Website.</p>
          <h5>8 SEVERABILITY</h5>
          <p>If any of these Terms and Conditions should be determined to be invalid, illegal or unenforceable for any reason by any court of competent jurisdiction then such Term or Conditions shall be severed and the remaining Terms and Conditions shall survive and remain in full force and effect and continue to be binding and enforceable.</p>
          <h5>9 WAIVER</h5>
          <p>If you breach these Terms and Conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these Terms and Conditions.</p>
          <h5>10 CHANGE </h5>
          <p>MemoriaTree reserves the right to:</p>
          <p>10.1 Change or remove (temporarily or permanently) the Website or any part of it without notice.</p>
          <p>10.2 Change these Terms and Conditions at any time. Any such changes are to be published on the Website and we will inform you of such changes 30 days before your access and use of the Website become subject to the changed Terms and Conditions. Your continued use of the Website thereafter shall be deemed to be your acceptance of such changes.</p>
          <h5>11 GOVERNING LAW</h5>
          <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Sweden unless the law applicable in the user's home country is more beneficial to the user. The dispute shall be handled by a competent court of law. As a consumer, you have the right to refer the matter to a court of law where you reside. You may also choose to make a complaint in the first instance to the relevant national board for consumer disputes. </p>
          <p>If you disagree with this section, MemoriaTree expects you will not use our platform.</p>
          <h5>12 OUR CONTACT DETAILS</h5>
          <p>For any further information please reach us by sending email to our support team at:</p>
          <p><a href="mailto:support@memoriatree.com">support@memoriatree.com</a></p>
          <p>MemoriaTree AB<br />Postbox 20<br />191 21, Sollentuna<br />SWEDEN</p>
          <p>This document was last updated on 8 July 2020.</p>
        </Message.Content>
      </Message>
    </Container>
  </Grid>
  );
}

export default TermsOfService;