import {
  observable,
  action,
  computed,
  toJS
} from 'mobx';
import moment from 'moment';
import find from 'lodash/find';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import pick from 'lodash/pick';
import * as uuid from 'uuid/v4';

import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';

import Person from './person';
import {
  Profile,
  Memorial
} from 'state/objects';
import calculateRanks from './calculateRank';
import Partnership from 'state/familyTree/partnership';

import FamilyTree from 'state/familyTree/tree';
import { FamilyTreeStore } from 'state/stores'
import RootStore from 'state/rootStore';
import { IFamilyTree, IFamilyTreePerson, IFamilyTreePartnership, IFamilyTreeManagement } from 'state/apiClient/ServiceTypes';

interface Move {
  source?: Person;
  target: Person;
  parentPartnershipCreated?: boolean;
  partnershipCreated?: boolean;
}

class FamilyTreeMerger {

  rootStore: RootStore;

  treeManagementService: Service<IFamilyTreeManagement>;
  treeService: Service<IFamilyTree>;

  @observable dragTree: FamilyTree;
  @observable dropTree: FamilyTree;

  reqProfileKind: string;
  reqProfileId: string | number;
  targetProfileKind: string;
  targetProfileId: string | number;
  baseTreeId: number;
  mergeTreeId: number;

  moves: Move[] = [];

  @observable targetNode: Person | null = null;
  @observable sourceNode: Person | null = null;

  constructor(rootStore: RootStore, reqType: string, reqProfileId: string | number, targetType: string, targetProfileId: string | number) {
    this.rootStore = rootStore;
    this.treeManagementService = service('family-tree-management');
    this.treeService = service('family-trees');

    this.reqProfileId = reqProfileId;
    this.reqProfileKind = reqType;
    this.targetProfileKind = targetType;
    this.targetProfileId = targetProfileId;
  }

  @computed
  get loaded(): boolean {
    return !!(this.dragTree && this.dropTree);
  }

  @computed
  get allRootsInDropTree(): boolean {
    if (!this.loaded) return false;

    return [
      ...this.dragTree.roots,
      ...this.dropTree.roots
    ]
      .map(root => !!this.dropTree.persons.find(p => p.connectedPageId === root.connectedPageId))
      .reduce((acc, cur) => acc && cur, true);
  }

  @computed
  get treeCanBeSubmited(): boolean {
    return this.allRootsInDropTree;
  }

  @computed
  get nodeMergeModalOpen(): boolean {
    return !!(this.sourceNode && this.targetNode);
  }

  @action
  async setTreeIds(baseTreeId: number, mergeTreeId: number) {
    this.baseTreeId = baseTreeId;
    this.mergeTreeId = mergeTreeId;
    return this.fetchTrees(baseTreeId, mergeTreeId);
  }

  @action
  async fetchTrees(dragTreeId: number, dropTreeId: number) {
    try {
      const [dragTree, dropTree] = await Promise.all([
        this.rootStore.familyTreeStore.fetchCopyById(dragTreeId),
        this.rootStore.familyTreeStore.fetchCopyById(dropTreeId)
      ]);

      this.dragTree = dragTree;
      this.dropTree = dropTree;

      dragTree.familyTreeMerger = this;
      dropTree.familyTreeMerger = this;

      this.dragTree.persons
        .forEach((person, index) => {
          const personInDropTree = this.dropTree.persons.find(p => p.connectedPageId === person.connectedPageId);
          if (person.connectedPageId && personInDropTree) {
            person.mergeState = 'DRAGGED';
            personInDropTree.mergeState = 'INSERTED';
            if (this.dragTree.roots.find(r => r === person) && !this.dropTree.roots.find(r => r === personInDropTree)) {
              this.dropTree.roots.push(personInDropTree);
            }
          }
        })

    } catch (err) {
      throw new Error('FamilyTreeMerger.fetchTrees() - ' + err);
    }
  }

  @action
  reset() {
    return this.fetchTrees(this.dragTree._id, this.dropTree._id);
  }

  @action
  switchTrees() {
    return this.fetchTrees(this.dropTree._id, this.dragTree._id);
  }

  @action
  dropPerson(source: Person, target: Person) {
    this.sourceNode = source;
    this.targetNode = target;
  }

  @action
  closeNodeMergeModal() {
    this.sourceNode = null;
    this.targetNode = null;
  }

  @action
  replacePerson(source: Person, target: Person) {
    const data = {
      familyTreeId: this.dropTree._id,
      ...pick(source, [
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing',
        'connectedPageKind',
        'connectedPageId',
        '_avatar'
      ])
    };

    // source = moved

    Object.assign(target, data);

    if (this.dragTree.roots.find(r => r === source)) {
      this.dropTree.roots.push(target);
    }

    source.mergeState = 'DRAGGED';
    target.mergeState = 'REPLACED';

    this.moves.push({
      source,
      target
    });
  }

  @action
  addParent(source: Person, target: Person) {
    const data = {
      _id: uuid(),
      familyTreeId: this.dropTree._id,
      partnershipIds: [],
      ...pick(source, [
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing',
        'connectedPageKind',
        'connectedPageId',
        '_avatar'
      ])
    };

    // source = moved

    const parent = new Person(this.dropTree, data);
    this.dropTree.persons.push(parent);

    if (this.dragTree.roots.find(r => r === source)) {
      this.dropTree.roots.push(parent);
    }

    let partnershipCreated = false;

    if (target.parentPartnership) {
      if (!target.parentPartnership.parent1) {
        target.parentPartnership.parent1 = parent;
      } else {
        target.parentPartnership.parent2 = parent;
      }
      parent.partnerships.push(target.parentPartnership);
    } else {
      const partnership = new Partnership({
        _id: uuid()
      });
      partnership.parent1 = parent;
      parent.partnerships.push(partnership);

      partnership.children.push(target);
      target.parentPartnership = partnership;
      this.dropTree.partnerships.push(partnership);

      partnershipCreated = true;
    }

    source.mergeState = 'DRAGGED';
    parent.mergeState = 'INSERTED';

    this.moves.push({
      source,
      target: parent,
      partnershipCreated
    });

    this.dropTree.calculateNodes();
  }

  @action
  addPartner(source: Person, target: Person) {
    const data = {
      _id: uuid(),
      familyTreeId: this.dropTree._id,
      partnershipIds: [],
      ...pick(source, [
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing',
        'connectedPageKind',
        'connectedPageId',
        '_avatar'
      ])
    };

    const partner = new Person(this.dropTree, data);
    this.dropTree.persons.push(partner);

    if (this.dragTree.roots.find(r => r === source)) {
      this.dropTree.roots.push(partner);
    }

    let partnershipCreated = false;

    if (target.partnerships.length > 0) {
      const partnership = target.partnerships[0];
      if (!partnership.parent1) {
        partnership.parent1 = partner;
      } else {
        partnership.parent2 = partner;
      }
      partner.partnerships.push(partnership);
    } else {
      const partnership = new Partnership({
        _id: uuid()
      });

      partnership.parent1 = target;
      partnership.parent2 = partner;

      partner.partnerships.push(partnership);
      target.partnerships.push(partnership);

      this.dropTree.partnerships.push(partnership);
      partnershipCreated = true;
    }

    source.mergeState = 'DRAGGED';
    partner.mergeState = 'INSERTED';

    this.moves.push({
      source,
      target: partner,
      partnershipCreated
    });

    this.dropTree.calculateNodes();
  }

  @action
  addChild(source: Person, target: Person) {
    const data = {
      _id: uuid(),
      familyTreeId: this.dropTree._id,
      partnershipIds: [],
      ...pick(source, [
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing',
        'connectedPageKind',
        'connectedPageId',
        '_avatar'
      ])
    };

    const child = new Person(this.dropTree, data);
    this.dropTree.persons.push(child);

    if (this.dragTree.roots.find(r => r === source)) {
      this.dropTree.roots.push(child);
    }

    let parentPartnershipCreated = false;

    if (target.partnerships.length > 0) {
      const partnership = target.partnerships[0];
      partnership.children.push(child);
      child.parentPartnership = partnership;
    } else {
      const partnership = new Partnership({
        _id: uuid()
      });

      partnership.parent1 = target;
      target.partnerships.push(partnership);

      partnership.children.push(child);
      child.parentPartnership = partnership;

      this.dropTree.partnerships.push(partnership);
      parentPartnershipCreated = true;
    }

    source.mergeState = 'DRAGGED';
    child.mergeState = 'INSERTED';

    this.moves.push({
      source,
      target: child,
      parentPartnershipCreated
    });

    this.dropTree.calculateNodes();
    this.closeNodeMergeModal();
  }

  @action
  addNewPartner(partner: Person, target: Person) {
    partner._id = uuid();
    this.dropTree.persons.push(partner);

    let partnershipCreated = false;

    if (target.partnerships.length > 0) {
      const partnership = target.partnerships[0];
      if (!partnership.parent1) {
        partnership.parent1 = partner;
      } else {
        partnership.parent2 = partner;
      }
      partner.partnerships.push(partnership);
    } else {
      const partnership = new Partnership({
        _id: uuid()
      });

      partnership.parent1 = target;
      partnership.parent2 = partner;

      partner.partnerships.push(partnership);
      target.partnerships.push(partnership);

      this.dropTree.partnerships.push(partnership);
      partnershipCreated = true;
    }

    partner.mergeState = 'INSERTED';
    this.moves.push({
      target: partner
    });

    this.dropTree.calculateNodes();
  }

  @action
  addNewChild(child: Person, target: Person) {
    child._id = uuid();
    this.dropTree.persons.push(child);

    let parentPartnershipCreated = false;

    if (target.partnerships.length > 0) {
      const partnership = target.partnerships[0];
      partnership.children.push(child);
      child.parentPartnership = partnership;
    } else {
      const partnership = new Partnership({
        _id: uuid()
      });

      partnership.parent1 = target;
      target.partnerships.push(partnership);

      partnership.children.push(child);
      child.parentPartnership = partnership;

      this.dropTree.partnerships.push(partnership);
      parentPartnershipCreated = true;
    }

    child.mergeState = 'INSERTED';
    this.moves.push({
      target: child
    });
    this.dropTree.calculateNodes();
  }

  @action
  addNewParent(parent: Person, target: Person) {
    parent._id = uuid();
    this.dropTree.persons.push(parent);

    let partnershipCreated = false;

    if (target.parentPartnership) {
      if (!target.parentPartnership.parent1) {
        target.parentPartnership.parent1 = parent;
      } else {
        target.parentPartnership.parent2 = parent;
      }
      parent.partnerships.push(target.parentPartnership);
    } else {
      const partnership = new Partnership({
        _id: uuid()
      });
      partnership.parent1 = parent;
      parent.partnerships.push(partnership);

      partnership.children.push(target);
      target.parentPartnership = partnership;
      this.dropTree.partnerships.push(partnership);

      partnershipCreated = true;
    }

    parent.mergeState = 'INSERTED';
    this.moves.push({
      target: parent
    });

    this.dropTree.calculateNodes();
  }

  @action
  resetTarget(target: Person) {
    const move = this.moves.find(m => m.target === target);

    const originalRoots = this.dropTree.originalData.rootIds;
    const isRemovable = target.isRemovableFromMerge && originalRoots.indexOf(target._id) === -1;

    if (move && isRemovable) {
      const { source, target } = move;
      source && source.resetMerge();
      remove(this.dropTree.roots, r => r === target);

      if (target.mergeState === 'REPLACED') {
        target.resetMerge();
      } else if (target.mergeState === 'INSERTED') {
        remove(this.dropTree.persons, p => p === target);

        //children
        if (target.parentPartnership) {
          remove(target.parentPartnership.children, c => c === target);
        }

        if (target.partnerships.length > 0) {
          const partnership = target.partnerships[0];

          if (!target.hasPartner) {
            remove(this.dropTree.partnerships, p => p === partnership);
            partnership.children.forEach(child => {
              child.parentPartnership = null;
            })
          }

          const { parent1, parent2 } = partnership;
          if (parent1 === target) {
            partnership.parent1 = null;
          }
          if (parent2 === target) {
            partnership.parent2 = null;
          }
        }
      }
    }
    remove(this.moves, m => m === move);
    this.dropTree.calculateNodes();
  }

  @action
  async saveTree(): Promise<IFamilyTreeManagement> {
    try {

      const data: any = {
        action: 'merge_trees',
        value: {
          requestingProfileId: this.reqProfileId,
          requestingProfileKind: this.reqProfileKind,
          targetProfileId: this.targetProfileId,
          targetProfileKind: this.targetProfileKind,
          baseTreeId: this.baseTreeId,
          mergeTreeId: this.mergeTreeId,
          newTreeData: Object.assign({}, this.dropTree.toJSON(), {
            parentIds: uniqBy([
              ...this.dragTree.parentIds,
              ...this.dropTree.parentIds
            ], 'id')
          })
        }
      };

      // @ts-ignore
      const response = await this.treeManagementService.create(data) as IFamilyTreeManagement;

      const promises = response.parentIds.map(parent => {
        if (parent.kind === 'profiles') {
          return this.rootStore.profileStore.fetchById(parent.id);
        }
        return this.rootStore.memorialStore.fetchById(parent.id);
      });

      // @ts-ignore
      await Promise.all<(Profile | Memorial)[]>(promises);

      return response;
    } catch (err) {
      throw new Error('FamilyTreeMerger.saveTree() - ' + err);
    }
  }
}

export default FamilyTreeMerger;