import * as React from 'react';
import { observable, computed } from "mobx";
import { observer, inject } from "mobx-react";

import {
  ConnectionStore
} from 'state/stores';
import { Profile, Connection } from 'state/objects';
import AccountStore from 'state/account';

import {
  Grid,
  Header,
  Button,
  Icon,
  Divider,
  Input,
  Modal
} from 'semantic-ui-react';

import ConnectionBox from './Connection';

export interface ConnectionsPaneProps {
  profile: Profile;

  connectionStore?: ConnectionStore;
  accountStore?: AccountStore;
}

@inject('accountStore', 'connectionStore') @observer
class ConnectionsPane extends React.Component<ConnectionsPaneProps, {}> {

  connectionStore: ConnectionStore;
  accountStore: AccountStore;

  @observable showDeleteModal: boolean = false;
  @observable filter: string = '';
  deletedConnection: Connection;

  constructor (props: ConnectionsPaneProps, context) {
    super(props, context);
    this.connectionStore = props.connectionStore!;
    this.accountStore = props.accountStore!;
  }

  componentWillMount () {
    this.props.profile.fetchConnections();
  }

  showDeleteConnectionModal ( connection ) {
    this.showDeleteModal = true;
    this.deletedConnection = connection;
  }

  deleteConnection () {
    this.connectionStore.removeConnection(this.deletedConnection._id);
    this.showDeleteModal = false;
  }

  @computed
  get filteredConnections (): Connection[] {

    if (this.filter === '') return this.props.profile.connections;

    return this.props.profile.connections
      .filter(con => con.contact && con.contact.displayName.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1);
  }

  render () {

    const requests = this.props.profile.connectionRequests;
    const connections = this.filteredConnections;
    const familyConnections = connections.filter(connection => connection.relationship);
    const friendConnections = connections.filter(connection => !connection.relationship);

    return (

      <Grid>

      {this.props.profile.userIsOwner && requests.length > 0 && (

        <Grid.Column width={12}>
            <Header as="h3" color="red">Connection Requests</Header>
            <Divider style={{paddingBottom: 15}} />
          <Grid>
          {requests.map(
            connection => (
              <ConnectionBox
                key={connection._id}
                connection={connection}
                request
                onAccept={() => this.connectionStore.acceptConnection(connection._id)}
                onDecline={() => this.connectionStore.declineConnection(connection._id)}
              />
            )
          )}
          </Grid>
        </Grid.Column>

      )}

      <Grid.Row style={{paddingBottom: 0}}>
        <Grid.Column width={12}>
          <Header as="h3" color="red">Connections</Header>
          <Divider />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row style={{paddingTop: 0}}>
        <Grid.Column width={12}>
        <Input
          id="connectionsFilterQuery"
          type="text"
          icon
          iconPosition="left"
          placeholder="Search your connections..."
          value={this.filter}
          onChange={(e) => this.filter = e.currentTarget.value}
          style={{width: '100%'}}
        >
          <Icon name="search" />
          <input />
        </Input>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row style={{paddingTop: 0}}>
        <Grid.Column width={12}>

          {familyConnections.length > 0 ? ( 

            <Grid>

              <Grid.Row style={{paddingBottom:"0"}}>
                <Grid.Column width={12} style={{paddingBottom:"1rem"}}>
                  <Header as="h4" color="red">Family</Header>
                  <Divider />
                </Grid.Column>
                {familyConnections.map(
                  (connection) => (
                    <ConnectionBox
                      key={connection._id}
                      connection={connection}
                      onDelete={() => this.showDeleteConnectionModal(connection)} />
                  )
                )}                
              </Grid.Row>

              {friendConnections.length > 0 && (
                <Grid.Row style={{paddingBottom:"0"}}>
                  <Grid.Column width={12} style={{paddingBottom:"1rem"}}>
                    <Header as="h4" color="red">Friends</Header>
                    <Divider />
                  </Grid.Column>          
                  {friendConnections.map(
                    (connection) => (
                      <ConnectionBox
                        key={connection._id}
                        connection={connection}
                        onDelete={() => this.showDeleteConnectionModal(connection)} />
                    )
                  )}
                </Grid.Row>    
              )}

            </Grid>

          ) : ( 

            <Grid>
              <Grid.Row style={{paddingTop:"2rem"}}>
                {connections.map(
                  (connection) => (
                    <ConnectionBox
                      key={connection._id}
                      connection={connection}
                      onDelete={() => this.showDeleteConnectionModal(connection)} />
                  )
                )}
              </Grid.Row>    
            </Grid>

          )}

        </Grid.Column>
      </Grid.Row>

      {this.showDeleteModal && (
        <Modal
          open
          size="tiny"

        >
          <Modal.Header>
            Warning
          </Modal.Header>
          <Modal.Content>
             <strong>
             This will remove your connection with {this.deletedConnection.contact!.displayName}.
             Are you sure you want to continue?
             </strong>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.showDeleteModal = false}>Cancel</Button>
            <Button secondary type="submit" onClick={() => this.deleteConnection()}>Delete Connection</Button>
          </Modal.Actions>
        </Modal>
      )}
    </Grid>

    );
  }

};

export default ConnectionsPane;
