import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import {
  Form,
  Button,
  Divider,
  Message,
  Loader,
  Dimmer
} from 'semantic-ui-react';

import AccountStore from 'state/account';

export interface Props {
  accountStore?: AccountStore;
}

@inject("cookieStore", "accountStore") @observer
class PasswordTab extends React.Component<Props, {}> {

  accountStore: AccountStore;

  @observable oldPassword: string = "";
  @observable newPassword: string = "";
  @observable confirmPassword: string = "";
  @observable error: string = "";
  @observable success: boolean = false;
  @observable loading: boolean = false;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
  }

  changePassword(e) {

    if (this.newPassword !== this.confirmPassword) {
      this.error = "The new passwords do not match."
      return;
    }
    if (!this.newPassword || !this.confirmPassword) {
      this.error = "You need to enter a valid password."
      return;
    }

    this.loading = true;
    this.success = false;

    this.accountStore.changePassword(this.oldPassword, this.newPassword)
      .then(() => {
        this.newPassword = "";
        this.confirmPassword = "";
        this.oldPassword = "";
        this.error = "";
        this.loading = false;
        this.success = true;
      })
      .catch(err => {
        console.log(err);
        this.error = err.message;
        this.loading = false;
      })
  }


  render() {

    if (this.accountStore.isFacebookConnected) {
      return (
        <div>
          <h3>Password Settings</h3>
          <Divider />
          <h4>This account is connected to a facebook account and does not use a password.</h4>
        </div>
      );
    }

    return (
      <div>
        <h3>Password Settings</h3>
        <Divider />

        <div>
          <Loader active={this.loading} size="large" />
          <h4>Change password:</h4>
          <Form>
            <Form.Input
              type="password"
              label="Current Password"
              placeholder=""
              value={this.oldPassword}
              onChange={e => (this.oldPassword = e.currentTarget.value)}
            />
            <Form.Input
              type="password"
              label="New Password"
              placeholder=""
              value={this.newPassword}
              onChange={e => (this.newPassword = e.currentTarget.value)}
            />
            <Form.Input
              type="password"
              label="Confirm New Password"
              placeholder=""
              value={this.confirmPassword}
              onChange={e => (this.confirmPassword = e.currentTarget.value)}
            />
            <span className="form-error">{this.error}</span>
            {this.success && (
              <Message
                color="green"
                icon="checkmark"
                header="Success!"
                content="Your password has been changed."
                onDismiss={() => this.success = false}
              />
            )}
          </Form>
          <Button primary type="submit"
            onClick={e => this.changePassword(e)}
          >
            Change Password
          </Button>

        </div>
      </div>

    );
  }

}

export default PasswordTab;