import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  Icon,
  Modal,
  Form,
  Radio
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import { Profile, Memorial } from 'state/objects';
import uiStore from 'state/ui';

import ChooseMemorialModal from 'components/FamilyTree/TreeJoin/ChooseMemorialModal';
import familyTreePeople from 'state/apiClient/services/family-tree-people';

import ChooseTreeModal from 'components/FamilyTree/TreeJoin/ChooseTreeModal';
import SelectPeopleToDisconnectModal from 'components/FamilyTree/TreeJoin/SelectPeopleToDisconnectModal';
import profileOLD from 'state/objects/profileOLD';
import { FamilyTree } from 'state/familyTree';

export interface Props {
  history?: any;
  profile: Profile | Memorial;
  accountStore?: AccountStore;
  uiStore: uiStore;
}


@inject('accountStore', 'uiStore') @observer
class FamilyTreeControls extends React.Component<Props, {}> {

  requestingProfile: Profile | Memorial | undefined;
  uiStore: uiStore;

  @observable showChooseTreeModal: boolean = false;
  @observable showChooseProfileOrMemorial: boolean = false;
  @observable showChooseMemorial: boolean = false;

  @observable showSettingsModal: boolean = false;

  @observable showDisconnectModal: boolean = false;
  @observable disconnectRadioValue: string = "single";
  @observable showResetModal: boolean = false;

  constructor(props, context) {
    super(props, context);
  }

  setMergeTargetTrees(profileType: String, memorial?: Memorial) {

    let reqProf: Profile | Memorial | undefined;
    let requestingURI;
    let targetURI;
    if (profileType === "user") {
      reqProf = this.props.accountStore!.myProfile;
      requestingURI = `profiles/${reqProf!._id}`;
    } else if (profileType === "memorial") {
      reqProf = memorial;
      requestingURI = `memorials/${reqProf!._id}`;
    }

    if (this.props.profile instanceof Profile) {
      targetURI = `/profiles/${this.props.profile._id}`;
    } else {
      //@ts-ignore
      targetURI = `/memorials/${this.props.profile._id}`;
    }

    this.props.history.push("/jointrees/" + requestingURI + targetURI);
  }

  setMergeTargetTreesNew(reqProf: Profile | Memorial | undefined) {

    let requestingURI;
    let targetURI;

    if (reqProf instanceof Profile) {
      requestingURI = `profiles/${reqProf!._id}`;
    } else {
      //@ts-ignore
      requestingURI = `memorials/${reqProf!._id}`;
    }

    if (this.props.profile instanceof Profile) {
      targetURI = `/profiles/${this.props.profile._id}`;
    } else {
      //@ts-ignore
      targetURI = `/memorials/${this.props.profile._id}`;
    }

    this.props.history.push("/jointrees/" + requestingURI + targetURI);
  }

  resetFamilyTree() {
    this.props.profile.resetFamilyTree();
  }

  checkIfConnected() {

    if (this.props.profile.userIsOwner) 
    return true;

    let connections = this.props.profile.familyTree!.treeUsers
      .filter(member => {
        //check if connected
        if(member instanceof Profile) {
          return member.relationToUser && member.relationToUser.status === 'accepted';
        }
        //check if connected to owner of memorial
        if(member instanceof Memorial) {
          if (member.ownerProfile) {
            return member.ownerProfile.relationToUser && member.ownerProfile.relationToUser.status === 'accepted';
          }
        }
      });
    return connections.length > 0;
  }

  render() {

    return (

      <div style={{ backgroundColor: "#fff", padding: 0 }}>
        {this.props.profile.familyTree && (
          <div style={{ padding: "25px 0px" }}>
            <Grid>
              <Grid.Row style={{ paddingBottom: 0 }} >

                <Grid.Column computer={3} tablet={4} mobile={12}>
                  <Button primary fluid onClick={() => { this.showChooseTreeModal = true; }}>
                    <Icon name="plus" /> Connect Family Tree
                  </Button>
                </Grid.Column>

                {this.props.profile.userIsOwner && (
                  <Grid.Column computer={3} tablet={4} mobile={12}>
                    <Button fluid onClick={() => { this.showSettingsModal = true; }}>
                      <Icon name="cog" /> Tree Settings
                    </Button>
                  </Grid.Column>
                )}

              </Grid.Row>
            </Grid>
          </div>
        )}

        {/**********************
           Select memorial modal
        *********************** */}

        
        {this.showChooseTreeModal && (
          this.checkIfConnected() ? (
            <ChooseTreeModal
              open={true}
              onClose={() => { this.showChooseTreeModal = false }}
              targetName={this.props.profile.displayName}
              familyTree={this.props.profile.familyTree!}
              treeIsLocked={this.props.profile.familyTree!.locked}
              onSelect={(requestingProfile) => this.setMergeTargetTreesNew(requestingProfile)}
            />
          ) : (
            <Modal open size="tiny" onClose={() => { this.showChooseTreeModal = false }}>
              <Modal.Header>
                Cannot Connect To This FamilyTree! 
              </Modal.Header>
              <Modal.Content>
                <p>
                  You have not yet added any of the profiles sharing this FamilyTree to your connections. 
                  Please connect with at least one of the users in this FamilyTree and try again!
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => {this.showChooseTreeModal = false;}}>
                  Cancel
                </Button>
                <Button primary onClick={() => {this.showChooseTreeModal = false;}}>
                  Ok
                </Button>
              </Modal.Actions>
            </Modal>
        ))}

        {this.showDisconnectModal && (
          <SelectPeopleToDisconnectModal
            open={true}
            onClose={() => { this.showDisconnectModal = false; this.showSettingsModal = false; }}
            familyTree={this.props.profile.familyTree!}
          />
        )}


        {this.showSettingsModal && (
          <Modal open size="tiny" onClose={() => { this.showChooseProfileOrMemorial = false }}>
            <Modal.Header>
              FamilyTree Settings
            </Modal.Header>
            <Modal.Content>

              {this.props.profile.familyTree!.parentIds.length > 1 &&
                <Button fluid style={{ marginBottom: "7px" }} onClick={() => this.showDisconnectModal = true}>
                  <Icon name='user delete' /> Disconnect FamilyTree
                </Button>
              }
           
             {this.props.profile.familyTree!.parentIds.length < 2 && 
                <Button fluid style={{ marginBottom: "7px" }} onClick={() => this.showResetModal = true}>
                  <Icon name='warning sign' /> Reset Family Tree
                </Button>
             }

              <Button fluid onClick={() => { this.showSettingsModal = false }}>
                Cancel
              </Button>

            </Modal.Content>
          </Modal>
        )}

        {this.showResetModal && (
          <Modal open size="tiny" onClose={() => { this.showResetModal = false }}>
            <Modal.Header>
              Reset FamilyTree
            </Modal.Header>
            <Modal.Content>
              Warning: This will completely delete all people from your tree! This action CANNOT be undone.
              Are you sure you want to proceed?
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => { this.showResetModal = false; }}>
                Cancel
              </Button>
              <Button secondary onClick={() => {
                this.showSettingsModal = false;
                this.showResetModal = false;
                this.resetFamilyTree();
              }}>
                Reset FamilyTree
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
};

export default withRouter(FamilyTreeControls);
