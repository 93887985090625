import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';
import {
  Profile,
  Memorial
} from 'state/objects';

import AccountStore from 'state/account';

import {
  Modal,
  Form,
  Input,
  Button,
  Image,
  Header,
  Card,
  Icon,
  Popup,
  Checkbox,
  Divider,
  Label,
  Segment,
  Reveal,
  Message,
  Dropdown,
  Radio
} from 'semantic-ui-react';

import FormInput from '../formInput';
import FormSubmit from 'components/FormBox/FormSubmit';
import { PersonForm } from 'state/stores/forms';

export interface LoginModalProps {
  open: boolean
  onClose(): any;
  history?: any;
  accountStore?: AccountStore;
  size: any;
}

@inject('accountStore') @observer
class LoginModal extends React.Component<LoginModalProps, {}> {

  accountStore: AccountStore;

  @observable email = '';
  @observable password = '';
  @observable error = '';
  @observable showLogin: boolean = false;
  onClose = false;



  @observable form: PersonForm | null;
  @observable profileSelectValue: string = '';
  @observable avatarModalOpen: boolean = false;


  constructor(props: LoginModalProps, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
  }

  //done to ensure the first modal is still scrollable
  setBodyScrolling() {
    setTimeout(() => {
      document.body.classList.toggle('scrolling', true);
    }, 0);
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.password || !this.email) return;

    this.accountStore.login(
      'local',
      this.email,
      this.password
    ).then(() => {
      this.password = '';
      this.email = '';
      this.props.history.push(`/profile/${this.accountStore.myProfile!._id}`);
      this.props.onClose();
    })
      .catch((error) => this.error = error)
  }

  clearJWT() {
    window.localStorage.removeItem('feathers-jwt');
  }

  render() {

    let {
      open,
      onClose,
      size
    } = this.props;


    return (

      <Modal open={open} onClose={onClose} size={size} dimmer="blurring">
        <Modal.Content>

          <Label size="large" className="close" floating onClick={onClose}>
            <Icon size="large" name="close" style={{ marginLeft: 0 }} />
          </Label>

          <h1 className="memtree-cursive">
            Sign In
          </h1>
          {/*
          <Button fluid className="login-facebook" href="/auth/facebook" color="facebook" onClick={() => this.clearJWT()}>
            <div style={{ padding: "6px" }}>
              <Icon name='facebook official' />
              Sign in with Facebook
            </div>
          </Button>
          
          <div style={{ padding: "10px" }}></div>
          <Button fluid color="google plus">
            <Icon name='google' />
            Sign in with Google
          </Button>
          */}
          <Divider horizontal style={{ textTransform: 'none' }} ></Divider>
          
          <Form onSubmit={e => this.onSubmit(e)}>
            <span className="form-error">{this.error}</span>
            <FormInput
              type="email"
              placeholder="Enter your email"
              icon="envelope"
              value={this.email}
              onChange={e => this.email = e.target.value}
            />
            <FormInput
              type="password"
              placeholder="Enter your password"
              icon="lock"
              value={this.password}
              onChange={e => this.password = e.target.value}
            />
            <div style={{ margin: "20px 0% 20px 0%" }}>
              <FormSubmit text="Login" />
            </div>
            <div style={{ textAlign: "center" }}>
              Don't have an account?&nbsp;
                            <Link to="/signup" onClick={() => this.props.onClose()}>
                Sign up here!
                            </Link><br />
              <div style={{ paddingTop: "10px" }}>
                <Link to="/iforgot" onClick={() => this.props.onClose()} >
                  I forgot my password
                                </Link>
              </div>
            </div>
          </Form>

        </Modal.Content>
      </Modal>
    );
  }
}

// @ts-ignore
export default withRouter(LoginModal);
