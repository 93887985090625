import * as classnames from 'classnames';

export const createRuleString = (current: string, rules: any) => {
  //creates a rule string for validator.js
  //ex: 'string|max:80|required
  return classnames(
    current,
    { required: rules.required },
    { [`max:${rules['max_length']}`]: rules['max_length'] }
  ).split(' ').join('|');
}