import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';

import {
  IMemorial,
  IGuestbookEntry,
  IComment,
  IPhoto,
  IVideo,
  IConnection,
  IProfile
} from 'state/apiClient/ServiceTypes';
import RootStore from 'state/rootStore';
import { observable } from 'mobx';

abstract class StoreBaseClass {

  rootStore: RootStore;
  service: Service<IProfile | IMemorial | IGuestbookEntry | IComment | IPhoto | IVideo | IConnection>;
  @observable loading: boolean = false;

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  abstract async fetchAll ();
  
  //abstract async fetchById (id: number);
  
  //abstract async fetchByIds (ids: number[]);

  abstract async remove (id: number);

}

export default StoreBaseClass;