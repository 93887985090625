import {
  Hook, 
  HookContext
} from '@feathersjs/feathers';
import { alterItems } from 'feathers-hooks-common';
import cloneDeep from 'lodash/cloneDeep';

export default (field: string, newName: string): Hook => context => {
  alterItems(item => {
    if (item !== undefined && item[field] !== undefined) {
      item[newName] = cloneDeep(item[field]);
      delete item[field];
    }
  })(context);

  const query = context.params ? (context.params.query || {}) : {};
  if (query[field] !== undefined) {
    query[newName] = cloneDeep(query[field]);
    delete query[field];
  }
};


/*
  
*/