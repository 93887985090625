import {
  observable,
  action,
  computed,
  toJS
} from 'mobx';
import moment from 'moment';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import pick from 'lodash/pick';

import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';

import Person from './person';
import {
  Profile,
  Memorial,
  ProfileBaseClass
} from 'state/objects';
import calculateRanks from './calculateRank';
import Partnership from 'state/familyTree/partnership';

import { FamilyTreeStore } from 'state/stores'
import RootStore from 'state/rootStore';
import { IFamilyTree, IFamilyTreePerson, IFamilyTreePartnership } from 'state/apiClient/ServiceTypes';
import FamilyTreeMerger from './familyTreeMerger';

interface IShortestPaths {
  dist: Map<Person, number>;
  pred: Map<Person, Person | null>;
}

interface IParentId {
  kind: 'memorials' | 'profiles';
  id: number;
}

class FamilyTree {

  rootStore: RootStore;
  store: FamilyTreeStore

  service: Service<IFamilyTree>;
  personService: Service<IFamilyTreePerson>;
  partnershipService: Service<IFamilyTreePartnership>;
  treeService: Service<IFamilyTree>;

  @observable _id: number;
  @observable parentId: number;
  @observable parentIds: IParentId[] = [];
  @observable kind: 'memorials' | 'profiles';
  @observable rootId: number;
  @observable rootIds: number[] = [];

  @observable root: Person;
  @observable roots: Person[] = [];
  @observable persons: Person[] = [];
  @observable partnerships: Partnership[] = [];

  @observable requestPending: boolean = false;
  @observable lockedAsTarget: boolean = false;
  @observable lockedAsRequester: boolean = false;
  @observable lockedByTreeId: number;

  originalData: IFamilyTree;
  familyTreeMerger: FamilyTreeMerger;

  constructor(rootStore: RootStore, store: FamilyTreeStore, data: IFamilyTree) {
    this.rootStore = rootStore;
    this.store = store;

    this.service = service('family-trees');
    this.personService = service('family-tree-people');
    this.partnershipService = service('family-tree-partnerships');
    this.treeService = service('family-tree-management');

    this.originalData = cloneDeep(data);

    Object.assign(this, pick(data, [
      'rootId',
      'rootIds',
      'kind',
      'parentId',
      'parentIds',
      '_id',
      'personIds',
      'partnershipIds',
      'requestPending',
      'lockedAsTarget',
      'lockedAsRequester',
      'lockedByTreeId'
    ]))

    this.parseData(data);

    this.calculateNodes();
  }

  @computed
  get rootIsUser(): boolean {
    return (this.root) && this.root.connectedPageId === this.rootStore.accountStore.profileId;
  }

  @computed
  get locked(): boolean {
    return this.lockedAsTarget || this.lockedAsRequester;
  }

  @computed
  get connectedIds(): number[] {
    return this.persons
      .filter(p => p.connectedPageId)
      .map(p => p.connectedPageId);
  }

  @computed
  get personsToRender(): Person[] {
    return this.persons.filter(p => p.node.shouldRender);
  }

  @computed
  get treeIsEmpty(): boolean {
    return this.persons.length === 0;
  }

  @computed
  get treeUsers(): (Profile | Memorial)[] {
    return this.parentIds
      .map(parent => {
        if (parent.kind === 'profiles') {
          return this.rootStore.profileStore.profiles.find(p => p._id === parent.id);
        } else {
          return this.rootStore.memorialStore.memorials.find(m => m._id === parent.id);
        }
      })
      .reduce((acc, cur) => cur ? [...acc, cur] : acc, [])
  }

  @computed
  get userOwnedMembers(): (Profile | Memorial)[] {
    return this.treeUsers
      .filter(member => {
        if (member instanceof Profile) {
          return member.userIsOwner;
        }
        if (member instanceof Memorial) {
          return member.userIsCreator;
        }
      }
      );
  }

  @computed
  get shortestPaths(): IShortestPaths {
    const dist = new Map<Person, number>();
    const pred = new Map<Person, Person | null>();
    const visited = new Map<Person, boolean>();

    this.persons.forEach(person => {
      dist.set(person, Infinity);
      pred.set(person, null);
      visited.set(person, false);
    });

    dist.set(this.root, 0);

    const queue: Person[] = [];

    queue.push(this.root);
    visited.set(this.root, true);

    function visit(person: Person | undefined | null, current: Person) {
      if (person && !visited.get(person)) {
        queue.push(person);
        visited.set(person, true);

        const distance: number = dist.get(current)! + 1;

        if (distance < dist.get(person)!) {
          pred.set(person, current);
          dist.set(person, distance);
        }
      }
    }

    while (queue.length > 0) {
      const current = queue.shift() as Person;

      if (current.parentPartnership) {
        const { parent1, parent2 } = current.parentPartnership;
        visit(parent1, current);
        visit(parent2, current);
      }

      for (let i = 0; i < current.partnerships.length; i++) {
        const partnership = current.partnerships[i];
        const { parent1, parent2 } = partnership;
        visit(parent1, current);
        visit(parent2, current);

        for (let j = 0; j < partnership.children.length; j++) {
          const child = partnership.children[j];
          visit(child, current);
        }
      }
    }

    const display = {};

    this.persons.forEach(person => {

      let path = '';
      let pre = pred.get(person);

      while (pre) {
        path += `-> ${pre.displayName} `;
        pre = pred.get(pre);
      }

      display[person.displayName] = [path, dist.get(person)];
    });

    //console.table(display);

    return {
      dist,
      pred
    };
  }

  @action
  setRootPerson(person: Person) {

    this.root.isRoot = false;
    this.root = person;
    this.root.isRoot = true;

    this.calculateNodes();

  }

  @action
  setRootByProfileId(id: number) {
    const person = this.persons.find(p => p.connectedPageId === id);
    if (!person) {
      throw new Error('FamilyTree.setRootByProfileId - No Profile or Memorial with that id.');
    }
    this.setRootPerson(person);
  }

  @action
  async addChild(person: Person, child: Person): Promise<Person> {

    if (this.familyTreeMerger) {
      this.familyTreeMerger.addNewChild(child, person);
      return child;
    }

    try {
      const childData: Partial<IFamilyTreePerson> = pick(child, [
        'connectedPageKind',
        'connectedPageId',
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing'
      ]);

      const data: any = {
        action: 'add_child',
        familyTreeId: this._id,
        value: {
          personId: person._id,
          childData
        }
      };

      const result: any = await this.treeService.create(data);

      this.parseData(result);
      this.calculateNodes();

      const parsedChild = this.persons.find(p => p._id === result.createdPerson._id);
      if (!parsedChild) {
        throw new Error('FamilyTree.addChild() failed');
      }

      return parsedChild;

    } catch (err) {
      throw err;
      //throw new Error('FamilyTree.addChild() - ' + err);
      //@ts-ignore
      return;
    }

    /*
    let partnership = person.partnerships[0];
    try {

      if (!partnership) {
        partnership = await this.addPartnership(person);
      }

      const data: Partial<IFamilyTreePerson> = pick(child, [
        'connectedPageKind',
        'connectedPageId',
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing'
      ]);

      data.parentPartnershipId = partnership._id;
      data.familyTreeId = this._id;

      const result = await this.personService.create(data);
      const childResult = new Person(this, result);

      this.persons.push(childResult);
      partnership.children.push(childResult);
      childResult.parentPartnership = partnership;

      if (child.avatarBlob) {
        childResult.avatarBlob = child.avatarBlob;
        childResult._avatar = child._avatar;
        childResult.uploadAvatar();
      }

      this.partnershipService.patch(partnership._id, {
        // @ts-ignore
        $addToSet: { childIds: childResult._id }
      });

      this.calculateNodes();

      return childResult;

    } catch (err) {
      throw new Error('FamilyTree.addChild() - ' + err);
    }
    */
  }

  @action
  async addParent(person: Person, parent: Person): Promise<Person> {

    if (this.familyTreeMerger) {
      this.familyTreeMerger.addNewParent(parent, person);
      return parent;
    }

    let { parentPartnership } = person;

    if (parentPartnership) {
      if (parentPartnership.hasBothParents) {
        throw new Error('FamilyTree.addParent() - Person already has two parents');
      }
    }

    try {
      const data: any = {
        action: 'add_parent',
        familyTreeId: this._id,
        value: {
          personId: person._id,
          parentData: pick(parent, [
            'connectedPageKind',
            'connectedPageId',
            '_firstName',
            '_lastName',
            '_gender',
            '_dateOfBirth',
            '_dateOfPassing'
          ])
        }
      };

      const result: any = await this.treeService.create(data);
      this.parseData(result);
      this.calculateNodes();

      const parsedParent = this.persons.find(p => p._id === result.createdPerson._id);
      if (!parsedParent) {
        throw new Error('FamilyTree.addParent() failed');
      }

      return parsedParent;

      /*
      const parentResult = new Person(this, result);
      this.persons.push(parentResult);

      const partnerData: Partial<IFamilyTreePartnership> = parentResult.gender === 'female' ? {
        motherId: parentResult._id
      } : {
          fatherId: parentResult._id
        };

      if (parentPartnership) {
        const updatedPartnership = await this.partnershipService.patch(parentPartnership._id, partnerData);
        Object.assign(parentPartnership, updatedPartnership);
      } else {
        partnerData.familyTreeId = this._id;
        partnerData.childIds = [person._id];
        const response = await this.partnershipService.create(partnerData);
        parentPartnership = new Partnership(response);
        this.partnerships.push(parentPartnership);

        await this.personService.patch(person._id, {
          parentPartnershipId: parentPartnership._id,
          connectedPageId: person.connectedPageId,
          connectedPageKind: person.connectedPageKind
        });
      }

      if (parentResult.gender === 'female') {
        parentPartnership.mother = parentResult;
      } else {
        parentPartnership.father = parentResult;
      }

      parentResult.partnerships.push(parentPartnership);
      person.parentPartnership = parentPartnership;

      if (parent.avatarBlob) {
        parentResult.avatarBlob = parent.avatarBlob;
        parentResult._avatar = parent._avatar;
        parentResult.uploadAvatar();
      }

      this.calculateNodes();
      return parentResult;
      */

    } catch (err) {
      throw new Error('FamilyTree.addParent() - ' + err);
    }
  }

  @action
  async addPartner(person: Person, partner: Person): Promise<Person> {
    if (person.gender === partner.gender) {
      throw new Error('FamilyTree.addPartner() - Cant add a partner with same gender');
    }

    if (this.familyTreeMerger) {
      this.familyTreeMerger.addNewPartner(partner, person);
      return partner;
    }

    try {
      const partnerData: Partial<IFamilyTreePerson> = pick(partner, [
        'connectedPageKind',
        'connectedPageId',
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing'
      ]);

      const data: any = {
        action: 'add_partner',
        familyTreeId: this._id,
        value: {
          personId: person._id,
          partnerData
        }
      };

      const result: any = await this.treeService.create(data);
      this.parseData(result);
      this.calculateNodes();

      const parsedPartner = this.persons.find(p => p._id === result.createdPerson._id);
      if (!parsedPartner) {
        throw new Error('FamilyTree.addPartner() failed');
      }

      return parsedPartner;
    } catch (err) {
      throw new Error('FamilyTree.addPartner() - ' + err);
    }

    /*
    let partnership = person.partnerships[0];
    const partnerData: Partial<IFamilyTreePerson> = pick(partner, [
      'connectedPageKind',
      'connectedPageId',
      '_firstName',
      '_lastName',
      '_gender',
      '_dateOfBirth',
      '_dateOfPassing'
    ]);
    partnerData.familyTreeId = this._id;

    try {
      if (!partnership) {
        partnership = await this.addPartnership(person);
      }

      partnerData.partnershipIds = [partnership._id];
      const partnerResponse = await this.personService.create(partnerData);

      const partnerResult = new Person(this, partnerResponse);

      const partnershipData = partnerResult.gender === 'female' ? {
        motherId: partnerResult._id
      } : {
          fatherId: partnerResult._id
        };
      const updatedPartnership = await this.partnershipService.patch(partnership._id, partnershipData);
      Object.assign(partnership, updatedPartnership);

      partnerResult.partnerships.push(partnership);

      if (partnerResult.gender === 'female') {
        partnership.mother = partnerResult;
      } else {
        partnership.father = partnerResult;
      }

      this.persons.push(partnerResult);

      if (partner.avatarBlob) {
        partnerResult.avatarBlob = partner.avatarBlob;
        partnerResult._avatar = partner._avatar;
        partnerResult.uploadAvatar();
      }

      this.calculateNodes();
      return partnerResult;

    } catch (err) {
      throw new Error('FamilyTree.addPartner() - ' + err);
    }
    */
  }

  /*
  @action
  async addPartnership(person: Person): Promise<Partnership> {
    if (person.partnerships.length > 0) {
      throw new Error(`FamilyTree.addPartnership() - ${person.displayName} already has a partnership`);
    }

    const data: Partial<IFamilyTreePartnership> = person.gender === 'female' ? {
      motherId: person._id
    } : {
        fatherId: person._id
      };
    data.familyTreeId = this._id;

    try {
      const result = await this.partnershipService.create(data);
      const partnership = new Partnership(result);
      this.partnerships.push(partnership);
      person.partnerships.push(partnership);
      if (person.gender === 'female') {
        partnership.mother = person;
      } else {
        partnership.father = person;
      }
      return partnership;
    } catch (err) {
      throw new Error('FamilyTree.addPartnership() - ' + err);
    }
  }
  */

  @action
  async editPerson(person: Person): Promise<Person> {

    const { editModel } = person;
    let body: Partial<IFamilyTreePerson>;

    if (editModel.connectedPageId) {
      // @ts-ignore
      body = pick(editModel, [
        'connectedPageKind',
        'connectedPageId'
      ]);
    } else {
      // @ts-ignore
      body = pick(editModel, [
        '_firstName',
        '_lastName',
        '_gender',
        '_dateOfBirth',
        '_dateOfPassing'
      ]);
      // @ts-ignore
      body.connectedPageKind = null;
      // @ts-ignore
      body.connectedPageId = null;
    }

    try {
      const response = await this.personService.patch(person._id, body);
      Object.assign(person, response);
      this.calculateNodes();
      return person;
    } catch (err) {
      throw new Error('FamilyTree.editPerson() - ' + err);
    }
  }

  @action
  async removePerson(person: Person) {
    if (!person.isRemovable) {
      throw new Error(`${person.displayName} cannot be deleted`);
    }

    try {
      const data: any = {
        action: 'remove_person',
        familyTreeId: this._id,
        value: {
          personId: person._id
        }
      };
      const result: any = await this.treeService.create(data);
      this.parseData(result);
      this.calculateNodes();

    } catch (err) {
      throw new Error('FamilyTree.removePerson() - ' + err);
    }

    /*
    try {
      const response = await this.personService.remove(person._id);
      if (person.parentPartnership) {
        remove(person.parentPartnership.children, child => child._id === person._id);
      }
      const partnership = person.partnerships[0];
      if (partnership) {
        if (partnership.mother === person) partnership.mother = null;
        if (partnership.father === person) partnership.father = null;
        if (!partnership.father && !partnership.mother) {
          partnership.children.forEach(child => {
            child.parentPartnership = null;
          });
          remove(this.partnerships, p => p._id === partnership._id);
        }
      }
      remove(this.persons, p => p._id === person._id);
      this.calculateNodes();
    } catch (err) {
      throw new Error('FamilyTree.removePerson() - ' + err);
    }
    */
  }

  @action
  async leaveTree(profiles: ProfileBaseClass[]) {
    return this.store.leaveTree(this._id, profiles);
  }

  @action
  async resetTree(profile: ProfileBaseClass) {
    return this.store.resetTree(this._id, profile);
  }

  @action
  async cancelMergeRequest() {
    if (!this.lockedAsRequester || !this.lockedByTreeId) {
      throw 'This tree is not locked by a outgoing merge request';
    }
    return this.store.cancelMerge(this.lockedByTreeId);
  }

  @action
  parseData(data: IFamilyTree) {
    //console.log('parsing data...');
    //console.log(JSON.parse(JSON.stringify(data)));
    this.persons = [];
    this.partnerships = [];
    this.roots = [];

    const root = data.people.find(p => p._id === data.rootIds[0]);

    if (!root) {
      throw new Error('FamilyTree.parseData() - NO ROOT IN TREE');
    }

    this.root = this.parsePerson(data, root);
    this.root.isRoot = true;
  }

  @action
  parsePerson(data: IFamilyTree, personData: IFamilyTreePerson): Person {
    const alreadyParsed = this.persons.find(p => p._id === personData._id);
    if (alreadyParsed) return alreadyParsed;

    if (personData._dateOfBirth) personData._dateOfBirth = moment(personData._dateOfBirth);
    if (personData._dateOfPassing) personData._dateOfPassing = moment(personData._dateOfPassing);

    const person = new Person(this, personData);
    this.persons.push(person);
    if (data.rootIds.indexOf(person._id) > -1) {
      this.roots.push(person);
    }
    remove(data.people, p => p._id === personData._id);

    // parse partners and children
    personData.partnershipIds.forEach(id => {

      let partnership = this.partnerships.find(p => p._id === id);

      if (partnership) {
        if (partnership.parent1Id === person._id) partnership.parent1 = person;
        if (partnership.parent2Id === person._id) partnership.parent2 = person;
        person.partnerships.push(partnership);
      } else {

        const partnershipData = data.partnerships.find(p => p._id === id);

        if (partnershipData) {
          partnership = new Partnership(partnershipData);
          remove(data.partnerships, p => p._id === id);
          this.partnerships.push(partnership);
          person.partnerships.push(partnership);

        } else {
          throw new Error(`FamilyTree.parsePerson - Could not find partnership with id ${id}`)
        }
      }

      // partner
      let partner: IFamilyTreePerson | undefined;
      if (partnership.parent1Id === person._id) {
        partnership.parent1 = person;
        partner = data.people.find(p => p._id === partnership!.parent2Id);
      }
      if (partnership.parent2Id === person._id) {
        partnership.parent2 = person;
        partner = data.people.find(p => p._id === partnership!.parent1Id);
      }

      if (partner) {
        const parsedPartner = this.parsePerson(data, partner);
      }

      // children
      partnership.childIds.forEach(childId => {
        const childData = data.people.find(p => p._id === childId);
        if (childData) {
          const child = this.parsePerson(data, childData);
          child.parentPartnership = partnership!;
          partnership!.children.push(child);
        }
      });
    });

    // parents
    if (personData.parentPartnershipId) {

      const parentPartnershipData = data.partnerships.find(partnership => partnership._id === personData.parentPartnershipId);
      if (parentPartnershipData) {
        const partnership = new Partnership(parentPartnershipData);
        partnership.children.push(person);
        person.parentPartnership = partnership;

        this.partnerships.push(partnership);
        remove(data.partnerships, p => p._id === partnership._id);

        let parent: IFamilyTreePerson | undefined;
        if (partnership.parent1Id) {
          parent = data.people.find(p => p._id === partnership.parent1Id);
        } else if (partnership.parent2Id) {
          parent = data.people.find(p => p._id === partnership.parent2Id);
        }

        if (parent) {
          this.parsePerson(data, parent);
        }
      } else if (!this.partnerships.find(partnership => partnership._id === personData.parentPartnershipId)) {
        throw new Error(`FamilyTree.parsePerson - Could not find parent partnership with id ${personData.parentPartnershipId}`)
      }
    }

    return person;
  }


  @action
  calculateNodes() {

    this.persons.forEach(person => {
      person.node.hasCalculated = false;
      person.rank = null;
    });

    calculateRanks(this.root, 0);

    this.setNodePosition(this.root, 0, 0);
  }

  @action
  setNodePosition(person: Person, x: number, y: number, goingDown?: boolean) {
    if (person.node.hasCalculated || person.rank === null) return;

    /*
    console.log('setting pos', person.firstName);
    console.log(person);
    console.log('x', x);
    console.log('y', y);
    console.log('ancestorwid', person.node.ancestorWidth);
    */

    const { node } = person;
    node.x = x;
    node.y = y;
    node.hasCalculated = true;

    if (person.isRoot && person.hasPartner) {
      const partner = person.partners[0];

      if (partner.node.isLeftAligned) {
        this.setNodePosition(partner, x - 1, y);
      } else {
        this.setNodePosition(partner, x + 1, y);
      }
    }

    // this block concerning ancestors
    if (person.parentPartnership && person.parentsToRender.length > 0 && !goingDown) {
      const { mother, father } = person.parentPartnership;

      if (person.isRoot) {

        let parentXOffset = x + person.parents[0].node.childWidth / 2 - person.node.leftWidth;

        /*
        const { siblings } = person;
        if (siblings.length > 0) {
          parentXOffset = 
            (person.parents[0].node.childWidth 
            - (person.node.leftWidth) 
            - (siblings[siblings.length-1].node.rightWidth))
            / 2;

          if (person.gender === 'male') {
            parentXOffset -= 0.5;
          }
        }
        */

        /*
        console.log('parent', person.parents[0].node.childWidth);
        console.log('person', person.node.childWidth);
        console.log('sibling', siblings[siblings.length-1].node.childWidth);
        console.log('offse', parentXOffset);
        */

        if (person.parentPartnership.hasBothParents) {
          this.setNodePosition(mother!, x + parentXOffset - 0.5, y - 1);
          this.setNodePosition(father!, x + parentXOffset + 0.5, y - 1);
        } else {
          this.setNodePosition(person.parents[0], x + parentXOffset, -1);
        }

      } else { //not root

        if (person.parentPartnership.hasBothParents) {
          if (person.isOnMotherSide) { //left side of tree
            this.setNodePosition(father!, x, y - 1);
            this.setNodePosition(mother!, x - father!.node.ancestorWidth, y - 1);
          } else { //right side of tree
            this.setNodePosition(mother!, x, y - 1);
            this.setNodePosition(father!, x + mother!.node.ancestorWidth, y - 1);
          }
        } else {
          this.setNodePosition(person.parents[0], x, y - 1);
        }
      }
    }

    // children
    if (person.rank > 2) return;

    if (person.isAnscestorOfRoot && person.rank === 1) {
      //mother or father of root
      const siblings = person.children.filter(child => child !== this.root);
      let xPositon = this.root.node.x + (this.root.node.rightWidth) + 0.5

      for (let i = 0; i < siblings.length; i++) {
        const sibling = siblings[i];
        const childNode = sibling.node;
        const childOffset = Math.max(childNode.childWidth, sibling.partners.length + 1) / 2 - 0.5;

        if (sibling.hasPartner) {
          const partner = sibling.partners[0];
          if (partner.node.isLeftAligned) {
            this.setNodePosition(partner, xPositon + childOffset - 0.5, y + 1, true);
            this.setNodePosition(sibling, xPositon + childOffset + 0.5, y + 1, true);
          } else {
            this.setNodePosition(sibling, xPositon + childOffset - 0.5, y + 1, true);
            this.setNodePosition(partner, xPositon + childOffset + 0.5, y + 1, true);
          }
        } else {
          this.setNodePosition(childNode.person, xPositon + childOffset, y + 1, true);
        }
        xPositon += childNode.childWidth;
      }
      return;
    }



    if (person.rank === 2) {
      //highest rank allowed children who are not ancestors to root
      const parentOfRoot = person.children.find(child => child.isAnscestorOfRoot);
      const children = person.children.filter(child => child !== parentOfRoot);

      if (parentOfRoot) {
        if (person.isOnMotherSide) {
          let xPositon = parentOfRoot.node.x - (parentOfRoot.node.childWidth / 2);

          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const childNode = child.node;
            const childOffset = Math.max(childNode.childWidth, child.partners.length + 1) / 2 - 0.5;

            if (child.hasPartner) {

              const partner = child.partners[0];
              if (partner.node.isLeftAligned) {
                this.setNodePosition(child, xPositon - childOffset + 0.5, y + 1, true);
                this.setNodePosition(partner, xPositon - childOffset - 0.5, y + 1, true);
              } else {
                this.setNodePosition(partner, xPositon - childOffset + 0.5, y + 1, true);
                this.setNodePosition(child, xPositon - childOffset - 0.5, y + 1, true);
              }
            } else {
              this.setNodePosition(childNode.person, xPositon - childOffset, y + 1, true);
            }
            xPositon -= Math.max(childNode.childWidth, child.partners.length + 1);
          }

        } else {
          let xPositon = parentOfRoot.node.x + (parentOfRoot.node.childWidth / 2);

          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const childNode = child.node;
            const childOffset = Math.max(childNode.childWidth, child.partners.length + 1) / 2 - 0.5;

            if (child.hasPartner) {

              const partner = child.partners[0];
              if (partner.node.isLeftAligned) {
                this.setNodePosition(partner, xPositon + childOffset - 0.5, y + 1, true);
                this.setNodePosition(child, xPositon + childOffset + 0.5, y + 1, true);
              } else {
                this.setNodePosition(child, xPositon + childOffset - 0.5, y + 1, true);
                this.setNodePosition(partner, xPositon + childOffset + 0.5, y + 1, true);
              }
            } else {
              this.setNodePosition(childNode.person, xPositon + childOffset, y + 1, true);
            }
            xPositon += Math.max(childNode.childWidth, child.partners.length + 1);
          }
        }
      }
      return
    }

    //console.log(person.firstName, 'has reached last part')
    //all lower ranks including root
    let xPositon = - (node.childWidth / 2 - 0.5);
    if (person.hasPartner) {
      if (person.partners[0].node.isLeftAligned) {
        xPositon -= 0.5;
      } else {
        xPositon += 0.5;
      }
    }
    for (let i = 0; i < person.childrenToRender.length; i++) {
      const child = person.childrenToRender[i];
      const childNode = child.node;
      const childOffset = Math.max(childNode.childWidth, child.partners.length + 1) / 2 - 0.5;

      /*
      console.log(person.node.childWidth);
      console.log('rendering child', child.firstName);
      console.log('xpos', xPositon);
      console.log('offset', childOffset);
*/

      if (child.hasPartner) {

        const partner = child.partners[0];
        if (partner.node.isLeftAligned) {
          this.setNodePosition(partner, x + xPositon + childOffset - 0.5, y + 1, true);
          this.setNodePosition(child, x + xPositon + childOffset + 0.5, y + 1, true);
        } else {
          this.setNodePosition(child, x + xPositon + childOffset - 0.5, y + 1, true);
          this.setNodePosition(partner, x + xPositon + childOffset + 0.5, y + 1, true);
        }
      } else {
        this.setNodePosition(childNode.person, x + xPositon + childOffset, y + 1, true);
      }

      xPositon += Math.max(childNode.childWidth, child.partners.length + 1);
    }
  }

  toJSON() {
    return {
      ...pick(this, [
        '_id',
        'parentIds',
      ]),
      persons: this.persons.map(p => p.toMergeJson()),
      partnerships: this.partnerships.map(p => p.toMergeJson()),
      rootIds: this.roots.map(root => root._id)
    };
  }

  /*
  @action
  calculateNodes () {

    this.persons.sort((a, b) => {
      if (a.rank === null || b.rank === null) {
        throw new Error('Rank not calculated')
      }
      if (a.rank !== b.rank) {
        return a.rank - b.rank;
      }
      if (a.isOnMotherSide !== b.isOnMotherSide) {
        return a.isOnMotherSide ? -1 : 1;
      }
      if (a.gender !== b.gender) {
        return a.gender === 'female' ? -1 : 1;
      }
      return 0;
    })

    let id = 1;
    let currentRank = this.persons[0].rank as number;
    
    let data: any = {
      name: "",
      id: id++,
      hidden: true,
      children: []
    };


  }
  */

  /*
  @action
  calculatePositions () {

    for (let i = 0; i < this.persons.length; i++) {
      const node = this.persons[i].node;
      node.x = null;
      node.y = null;
    }

    this.root.node.x = 0;
    this.root.node.y = 0;
    console.log('#', this.persons.length);
    this.calculatePosition(this.root);
  }

  @action
  calculatePosition (person: Person) {
    const { node } = person;
    console.log('node', person.displayName);
    console.log('width', node.childWidth);
    console.log('x', node.x);
    console.log('y', node.y);

    if (node.x !== null) {

      

      for (let i = 0; i < person.children.length; i++) {
        const child = person.children[i].node;
        const childOffset = child.childWidth / 2 - 0.5;

        child.x = node.x + xPositon + childOffset;
        child.y = node.y! + 1;

        xPositon += child.childWidth;

        this.calculatePosition(child.person);
      }

      if (person.parent !== null && node.parent.x === null) {
        this.calculatePosition(node.parent);
      }
    } else {
      let positionedChild = null;
      let positionedChildIndex;

      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];

        if (child.x !== null) {
          positionedChild = child;
          positionedChildIndex = i;
          break;
        }
      }

      if (positionedChild) {
        const widthLeftOfChild = node.children
          .slice(0, positionedChildIndex)
          .reduce((s, c) => s + c.childWidth, 0);

        const leftPostion = positionedChild.x - positionedChild.childWidth / 2 + 0.5 - widthLeftOfChild;
        node.x = leftPostion + node.childWidth / 2 - 0.5;
        node.y = positionedChild.y - 1;
        this.calculatePosition (node);
      } else {
        throw 'Node has no position and no positioned children';
      }

    }

  }
  */

}

export default FamilyTree;
