import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';
import {
  Header,
  Grid,
  Image,
  Icon,
  List
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import { Profile, Memorial } from 'state/objects';
import FamilyTree from 'state/familyTree/tree';

import UserAvatar from 'components/Generic/Avatar';


export interface Props {
  familyTree: FamilyTree;
  history?: any;
  accountStore?: AccountStore;
  formatting?: "centered";
  noLinks?: boolean;
}


@inject('accountStore') @observer
class TreeUsers extends React.Component<Props, {}> {

  constructor(props, context) {
    super(props, context);
  }

  renderContributor(contributor?: Profile | Memorial) {

    if (!contributor) return null;

    return (
      <Grid.Column key={contributor._id} computer={2} tablet={3} mobile={4} columns={6}>
        <UserAvatar
          profile={contributor}
          size={30}
          style={"avatar"}
          noShadow={true}
          noLink={this.props.noLinks}
        />
      </Grid.Column>
    );
  }

  render() {

    return (

      <div style={{ backgroundColor: "#fff", padding: 0 }}>
        {this.props.familyTree && (

          <div style={{ paddingTop: "25px" }}>

            <Grid
              style={this.props.formatting === "centered" ? { textAlign: "center" } : { textAlign: "left" }}
              centered={this.props.formatting === "centered"} columns={6}
            >

              {/**********************
                SHARED WITH
              ******************** */}

              <Header as="h5" color="red">
                This family tree is shared by
              </Header>

              <Grid.Row style={{ padding: 0, marginTop: "-14px" }}  >
                <Grid.Column computer={12} tablet={12} mobile={12}>

                  <Grid
                    style={{ marginTop: "0px", marginBottom: "0px" }}
                    centered={this.props.formatting === "centered"} columns={6}
                  >
                    {this.props.familyTree.treeUsers.map(user => this.renderContributor(user))}
                    {/*memorial.admins.map(a => this.renderContributor(a))*/}
                  </Grid>

                </Grid.Column>
              </Grid.Row>

            </Grid>


            {/**********************
                  COLLABORATORS
             *********************** */}
            {/*
            <Header as="h5" color="red">
              Collaborators
            </Header>
            <Grid>
              {/*this.renderContributor(this.props.profile)*/}
            {/*contributors.map(c => this.renderContributor(c))*/}
            {/*
            </Grid>*/}
          </div>
        )}

      </div>
    );
  }
};

export default withRouter(TreeUsers);
