import { Application, HooksObject } from '@feathersjs/feathers';
import serviceTypes from '../ServiceTypes';
import {
  debug
} from 'feathers-hooks-common';

import populate from 'state/apiClient/hooks/populate';
import { renameField } from 'state/apiClient/hooks';
import {
  rename,
  remove,
  negate,
  parseDateFromServer,
  parseDateFromClient,
  setField
} from './hooks';

/*
const populateSchema = {
  include: {
    service: 'profiles',
    nameAs: 'ownerProfile',
    parentField: 'owner',
    childField: 'id'
  }
};
*/

const getContributors = () => hook => {

  const memorials = Array.isArray(hook.result) ? hook.result : [ hook.result ];
  if (memorials.length === 0) {
    return hook;
  }

  return Promise.all(
    memorials.map(memorial => {
      return hook.app.service('memorials/:profileId/contributors').find({ url: { profileId: memorial.id }})
        .then(response => {
          memorial.contributorIds = response.contributors;
        })
    })
  ).then(() => hook);
  
}

const renameBefore = [
  //renameField('id', '_id')
];

const renameAfter = [
  //renameField('_id', 'id')
];

const afterAllExceptRemove = [
  parseDateFromServer('dateOfBirth', 'dateOfPassing')
];

const hooks: HooksObject = {
  before: {
    all: [ ],
    find: [],
    get: [],
    create: [
      parseDateFromClient('dateOfBirth', 'dateOfPassing'),
      ...renameBefore
    ],
    update: [
      parseDateFromClient('dateOfBirth', 'dateOfPassing'),
      ...renameBefore
    ],
    patch: [
      parseDateFromClient('dateOfBirth', 'dateOfPassing'),
      ...renameBefore
    ],
    remove: [ ]
  },

  after: {
    all: [
      ...renameAfter
    ],
    find: [
      ...afterAllExceptRemove
    ],
    get: [
      ...afterAllExceptRemove
    ],
    create: [
      ...afterAllExceptRemove
    ],
    update: [
      ...afterAllExceptRemove
    ],
    patch: [
      ...afterAllExceptRemove
    ],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function (app: Application<serviceTypes>) {
  const memorialsService = app.service('memorials');
  memorialsService.hooks(hooks);
};
