import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Switch, Route, Link, withRouter } from 'react-router-dom';

import {
  Form,
  Grid,
  Menu,
  Container,
  Button,
  Icon,
  Modal,
  Message,
  Header
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import uiStore from 'state/ui';
import { Memorial } from 'state/objects';
import { MemorialStore } from 'state/stores';

import FeaturedMemorials from 'components/FrontPage/FeaturedMemorials';
import RecentMemorials from 'components/FrontPage/RecentMemorials';
import RecentActivity from 'components/FrontPage/RecentActivity';
import QuickLinks from 'components/FrontPage/QuickLinks';

//import treePic from "images/family_tree2.png";
import treePic from "images/TreeBlueMirrorCrop.jpg";
import oldPhotosPic from 'images/old-photos2.jpg'

interface InfoBoxProps {
  title: string;
  img: string;
  children: React.ReactNode;
}

const InfoBox = ({title, children, img}: InfoBoxProps) => (
  <div style={{ color: '#FFFFFF', textAlign: 'center' }}>
    <img 
      src={img}
      style={{
        width: '200px',
        height: '200px',
        marginTop: '30px',
        border: '8px solid #98b9dc',
        borderRadius: '50%',
        objectFit: 'cover'
      }}
    />
    <Header as="h3" style={{ color: '#FFFFFF' }}>{title}</Header>
    <div style={{ textAlign: 'justify' }}>
      {children}
    </div>
  </div>
)

interface Props {
  history?: any;
  accountStore: AccountStore;
  memorialStore?: MemorialStore;
  uiStore: uiStore;
}

@inject('accountStore', 'uiStore', 'memorialStore') @observer
class PlaceholderFrontPage extends React.Component<Props, {}> {

  accountStore: AccountStore;
  memorialStore: MemorialStore;
  uiStore: uiStore;

  @observable memorials: Memorial[] = [];
  @observable featuredMemorials: Memorial[] = [];

  constructor(props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.memorialStore = this.props.memorialStore!;
    this.uiStore = this.props.uiStore!;
  }

  componentDidMount() {
    this.memorialStore.fetchRecent()
      .then(memorials => {
        this.memorials = memorials;
      })
      .catch(err => console.log(err));

    this.memorialStore.fetchFeatured()
      .then(memorials => {
        this.featuredMemorials = memorials;
      })
      .catch(err => console.log(err));

    //this.fakeMemorials = FakeMemorials;
    //if (this.accountStore.myProfile && this.accountStore.myProfile._id)
    //return this.props.history.push('/profile/' + this.accountStore.myProfile._id);
  }

  render() {

    /*
    console.log('myProf');
    console.log(this.accountStore.myProfile);
    console.log(this.accountStore.profileId);
    */

    const loggedIn = !!this.accountStore.myProfile;

    /*
    {/*
              <div style={{ position: "relative", minHeight: "400px" }}>
            <div style={{paddingLeft:"63%", marginBottom:"-150px"}}>
              <img src={treePic} style={{maxWidth:"100%", maxHeight:"100%"}}/>
            </div>
            <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
    */


    return (
      <div 
        className="dark-blue tree-background" 
        style={{ 
          padding: "1px 0px 71px 0px"
        }}>

        {!loggedIn && (
          <div id="front-page-background">
            <Container style={{ position: 'relative' }}>
              <Grid>
                <Grid.Row style={{ marginTop: "100px", textAlign: "center" }}>
                  <Grid.Column>
                    <h1 className="memtree-cursive" style={{ color: "white" }}>
                      Welcome to MemoriaTree
                    </h1>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginBottom: "50px" }}>
                  <Grid.Column style={{ padding: "0px 30px", width: "600px", margin: "0px auto" }}>
                    <div style={{ width: "100%" }}>
                      <Button
                        style={{ width: "260px", marginRight: "5px", color: "#577290", backgroundColor: "white", boxSizing: "border-box" }}
                        onClick={() => this.uiStore.showLoginModal = true}>
                        Sign In
                      </Button>
                      <Link to="/signup">
                        <Button primary
                          style={{ width: "260px", marginLeft: "5px" }}>
                          Sign Up
                        </Button>
                      </Link>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginTop: "0", textAlign: "center" }}>
                  <Grid.Column>
                    <h1 className="memtree-cursive" style={{ color: "white", fontSize: "62px"  }}>
                      Celebrate Your Family History
                    </h1>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} style={{ marginBottom: '100px' }}>
                  <Grid.Column mobile={12} tablet={6} computer={6}>
                    <InfoBox
                      title="SHARE MEMORIES"
                      img={oldPhotosPic}
                    >
                      <p>
                        Create <Link to={`/memorial/${this.featuredMemorials[0]?._id}`} style={{color: '#98b9dc'}}>memorial pages</Link> of your loved ones, and share stories, pictures and videos to remember and celebrate their lives. You can invite other family members to contribute to the memorials, and choose to set the memorial pages to be either private or public.
                      </p>
                    </InfoBox>
                  </Grid.Column>
                  <Grid.Column mobile={12} tablet={6} computer={6}>
                    <InfoBox
                      title="CONNECT LINEAGES"
                      img={treePic}
                    >
                      Build your <Link to={`/memorial/${this.featuredMemorials[0]?._id}/familytree`} style={{color: '#98b9dc'}}>family tree</Link> with our easy to use interface, and invite your family members to help you. Connect your family tree to memorial pages, user profiles, or even other family trees, and watch it grow ever larger!
                    </InfoBox>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        )}

        <div style={{ position: 'relative', ...(!loggedIn && { backgroundColor: "white" }) }}>
          <Container style={{ margin: "0rem 0 0rem 0", }}>
            <FeaturedMemorials memorials={this.featuredMemorials} whiteText={loggedIn}/>
          </Container>
        </div>
        
        {loggedIn && (
          <div style={{ backgroundColor: "white" }}>
            <Container style={{ margin: "0rem 0 0rem 0", }}>
              <RecentMemorials memorials={this.memorials} />
            </Container>
          </div>
        )}
        {/*}
        <div style={{backgroundColor:"white"}}>
          <Container style={{margin: "0rem 0 0rem 0",}}>
            <Message floating style={{marginTop: '2rem'}}>
              <Message.Content>
                <RecentActivity />
              </Message.Content>
            </Message> 
          </Container>
        </div>*/}

        {loggedIn && (
          <div style={{ padding: "3rem 0 0 0" }}>
            <Container style={{ margin: "0rem 0 0rem 0", }}>
              <QuickLinks />
            </Container>
          </div>
        )}

        {!loggedIn && (
          <Container>
            <Grid style={{
              marginTop: "71px", 
              marginBottom: "14px" 
            }}>
              <Grid.Row style={{ textAlign: "center" }}>
                <Grid.Column>
                  <h1 className="memtree-cursive" style={{ color: "white" }}>
                    Join MemoriaTree Today!
                  </h1 >
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{ padding: "30px", width: "600px", margin: "0px auto" }}>
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ width: "260px", marginRight: "5px", color: "#577290", backgroundColor: "white", boxSizing: "border-box" }}
                      onClick={() => this.uiStore.showLoginModal = true}>
                      Sign In
                    </Button>
                    <Link to="/signup">
                      <Button primary
                        style={{ width: "260px", marginLeft: "5px" }}>
                        Sign Up
                      </Button>
                    </Link>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        )}
      </div>
    );
  }
}

export default withRouter(PlaceholderFrontPage);
