import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { withRouter } from 'react-router-dom';

import {
  Form,
  Button,
  Modal,
  Icon,
  Divider,
} from 'semantic-ui-react';

import AccountStore from 'state/account';

export interface Props {
  accountStore?: AccountStore;
  history?: any;
}

@inject("accountStore") @observer
class AdvancedTab extends React.Component<Props, {}> {

  accountStore: AccountStore;

  @observable showDeleteModal: boolean = false;
  @observable showDeleteConfirmModal: boolean = false;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
  }

  deleteAccount(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.showDeleteModal = false;
    this.showDeleteConfirmModal = false;
    this.accountStore.deleteAccount().then(() => 
      {
        this.props.history.push("/");
      }    
    );
    
  }

  showDeleteModalFunc() {
    this.showDeleteModal = true;
  }

  render() {

    return (

      <div>
        <h3>Advanced Settings</h3>
        <Divider />

        <div>
          <h4>Delete Account</h4>
          <p>
            From this page you can delete your MemoriaTree account.
            Please understand that this will PERMANENTLY DELETE your account, profile and memorials from the MemoriaTree website.
            You will NOT be able to undo this action once the deletion process is complete!
          </p>
          <p>
            When you click the Delete Account button below you will be asked to confirm your choice by clicking an additional 
            "Confirm Delete Account" button. Once you have clicked the "Confirm Delete Account" button
            your account will be PERMANENTLY DELETED from the MemoriaTree website and servers, 
            with no possibility of retrieval.
          </p>
          <p>
            By starting the deletion process, you confirm that you have understood what is stated above.
          </p>
          <Button onClick={() => this.showDeleteModalFunc()} ><Icon name='warning sign' />
            Delete Account
          </Button>
        </div>

        {this.showDeleteModal && (
          <Modal
            open
            size="small"
          >
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
              <strong>This will PERMANENTLY delete your MemoriaTree account, all your Memorials and FamilyTrees from the MemoriaTree servers.
                Once your account is deleted from our servers, this action CANNOT be undone. Are you sure you want to continue?</strong>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.showDeleteModal = false}>Cancel</Button>
              <Button secondary type="submit" onClick={() => {this.showDeleteConfirmModal = true;}}>Delete Account</Button>
            </Modal.Actions>
          </Modal>
        )}
        {this.showDeleteConfirmModal && (
          <Modal
            open
            size="small"
          >
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
              <strong>Your account will be instantly and irreversibly deleted once you click the "Delete Account" button below. To cancel this process, click the "Cancel" button now.</strong>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.showDeleteConfirmModal = false}>Cancel</Button>
              <Button secondary type="submit" onClick={e => this.deleteAccount(e)}>Delete Account</Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>

    );
  }

}

//@ts-ignore
export default withRouter(AdvancedTab);