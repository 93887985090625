import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import {
  Form,
  Button,
  Radio,
  Divider,
  Loader
} from 'semantic-ui-react';

import AccountStore from 'state/account';

export interface Props {
  accountStore?: AccountStore;
}

@inject("accountStore") @observer
class EmailTab extends React.Component<Props, {}> {

  accountStore: AccountStore;

  @observable currentEmail: string = "";
  @observable newEmail: string = "";
  @observable confirmEmail: string = "";
  @observable password: string = "";
  @observable error: string = "";

  @observable emailFreqLoading: boolean = false;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;

    this.currentEmail = this.accountStore.email;
    this.newEmail = "";
    this.confirmEmail = "";
    this.password = "";
    this.error = "";

  }

  toggleEmailUpdateSettings(value) {

    this.emailFreqLoading = true;
    this.accountStore.setEmailFreq(value)
      .then(() => {
        this.emailFreqLoading = false;
      })
      .catch(err => {
        console.log(err);
        this.emailFreqLoading = false;
      });
  }

  changeEmail(e) {
    if (this.newEmail !== this.confirmEmail) {
      this.error = "The e-mail adresses do not match."
      this.password = "";
      return;
    }
    this.accountStore.changeEmail(this.currentEmail, this.newEmail, this.password)
      .then(() => {
        this.newEmail = "";
        this.confirmEmail = "";
        this.password = "";
        this.error = "";
      })
      .catch((err: Error) => {
        console.log(err);
        this.error = err.message
        this.password = "";
      });
  }


  render() {

    const { emailFreq } = this.accountStore;

    return (
      <div>
        <h3>E-mail Settings</h3>
        <Divider />

        <div style={{ padding: "0px 0px 15px 0px" }}>
          <h4>E-mail me about new messages or notifications:</h4>
          <Form.Field>
            <Radio
              label='Daily'
              name='emailUpdateRadioGroup'
              value='daily'
              checked={emailFreq === "daily"}
              onChange={(e, { value }) => {
                this.toggleEmailUpdateSettings(value);
              }}
            />
            <Radio
              style={{ paddingLeft: "13px" }}
              label="Weekly"
              name='emailUpdateRadioGroup'
              value='weekly'
              checked={emailFreq === "weekly"}
              onChange={(e, { value }) => {
                this.toggleEmailUpdateSettings(value);
              }}
            />
            <Radio
              style={{ paddingLeft: "13px" }}
              label="Monthly"
              name='emailUpdateRadioGroup'
              value='monthly'
              checked={emailFreq === "monthly"}
              onChange={(e, { value }) => {
                this.toggleEmailUpdateSettings(value);
              }}
            />
            <Radio
              style={{ paddingLeft: "13px" }}
              label="Never"
              name='emailUpdateRadioGroup'
              value='never'
              checked={emailFreq === "never"}
              onChange={(e, { value }) => {
                this.toggleEmailUpdateSettings(value);
              }}
            />
            <Loader
              active={this.emailFreqLoading}
              inline
              size="mini"
            />
          </Form.Field>
          <p>(MemoriaTree will not E-mail you if you have not recieved any new messages or notifications)</p>
        </div>

        <div style={{ padding: "15px 0px" }}>
          <Form>
            <h4>Change login and contact E-mail:</h4>
            <Divider />
            <p>Current email:</p>
            <p><strong>{this.currentEmail}</strong></p>
            <br />
            <Form.Input
              type="email"
              label="New E-mail"
              placeholder=""
              value={this.newEmail}
              onChange={e => (this.newEmail = e.currentTarget.value)}
            />
            <Form.Input
              type="email"
              label="Confirm New E-mail"
              placeholder=""
              value={this.confirmEmail}
              onChange={e => (this.confirmEmail = e.currentTarget.value)}
            />
            <Form.Input
              type="password"
              label="Password"
              placeholder=""
              value={this.password}
              onChange={e => (this.password = e.currentTarget.value)}
            />
            <span>{this.error}</span>
          </Form>
          <Button primary type="submit" onClick={e => this.changeEmail(e)} >
            Change E-mail
          </Button>
        </div>
      </div>

    );
  }

}

export default EmailTab;