import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Header } from 'semantic-ui-react';

import { Profile } from 'state/objects';
import { ProfileStore } from 'state/stores';
import FamilyTreeView from 'components/FamilyTree/FamilyTreeWindow/FamilyTreeView';
import FamilyTreeControls from 'components/FamilyTree/FamilyTreePane/FamilyTreeButtons';
import TreeUsers from 'components/FamilyTree/TreeJoin/FamilyTreeUsers';

export interface Props {
  profile: Profile;
  profileStore: ProfileStore;
}

@inject('profileStore') @observer
class FamilyTreePane extends React.Component<Props, {}> {

  profileStore: ProfileStore;
  @observable height = 200;

  constructor (props, context) {
    super(props, context);
    this.profileStore = this.props.profileStore;

    this.setHeight = this.setHeight.bind(this);
    this.setHeight();

    window.addEventListener('resize', this.setHeight);

    this.props.profile.fetchFamilyTree()
    .then(familyTree => {
      familyTree.setRootByProfileId(this.props.profile._id);
    })
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setHeight);
  }

  setHeight () {
    this.height = window.innerHeight - 70 >= 200 ? window.innerHeight - 70 : 200;
  }

  render () {

    return (
      <div>
        <div style={{backgroundColor:"#fff", padding: 0}}>
          {this.props.profile.familyTree && (
            <div>
              <FamilyTreeView
                height={this.height}
                width="100%" 
                profile={this.props.profile}
                familyTree={this.props.profile.familyTree}
                mode={"normal"}
              />
              <FamilyTreeControls
                profile={this.props.profile}
              />
              <TreeUsers 
                familyTree={this.props.profile.familyTree}            
              />
            </div>
          )}
        </div>
      </div>
    );
  }

};

export default FamilyTreePane;
