import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';
import * as moment from 'moment';
import remove from 'lodash/remove';
import find from 'lodash/find';

import { Service } from '@feathersjs/feathers';
import { service } from 'state/apiClient';
import StoreBaseClass from 'state/stores/storeBaseClass';
import RootStore from 'state/rootStore';
import {
  Profile,
  Memorial
} from 'state/objects'
import { IMemorial } from 'state/apiClient/ServiceTypes';

/** Store containing all memorials owned by a profile */
class MemorialStore extends StoreBaseClass {

  @observable memorials: Memorial[] = [];
  service: Service<IMemorial>;

  /**
   * Creates a new store
   * @param store - Profile owner of the memorials
   */
  constructor(rootStore: RootStore) {
    super(rootStore);
    this.service = service('memorials');

    this.initEvents();
  }

  initEvents() {
    this.service.on('patched', m => this.onPatched(m));
    this.service.on('updated', m => this.onPatched(m));
  }

  /** Fetches the memorials from the server */
  @action
  async fetchAll(): Promise<Memorial[]> {
    this.loading = true;
    try {
      const response = await this.service.find!() as IMemorial[];

      const profileIds = response
        .map(m => m.adminIds)
        .reduce((acc, cur) => [...acc, ...cur], []);
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const memorials = response.map(m => this.addToArray(m));
      return memorials;
    } catch (err) {
      throw new Error('MemorialStore.fetchAll()' + err);
    } finally {
      this.loading = false;
    }
  }

  @action
  async fetchRecent(): Promise<Memorial[]> {
    this.loading = true;
    try {
      const response = await this.service.find!({
        query: {
          $limit: 12,
          $sort: {
            createdAt: -1
          }
        }
      }) as IMemorial[];

      const profileIds = response
        .map(m => m.adminIds)
        .reduce((acc, cur) => [...acc, ...cur], []);
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const memorials = response.map(m => this.addToArray(m));
      return memorials;
    } catch (err) {
      throw new Error('MemorialStore.fetchRecent()' + err);
    } finally {
      this.loading = false;
    }
  }

  @action
  async fetchFeatured(): Promise<Memorial[]> {
    this.loading = true;
    try {
      const response = await this.service.find!({
        query: {
          featured: true
        }
      }) as IMemorial[];

      const profileIds = response
        .map(m => m.adminIds)
        .reduce((acc, cur) => [...acc, ...cur], []);
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const memorials = response.map(m => this.addToArray(m));
      return memorials;
    } catch (err) {
      throw new Error('MemorialStore.fetchFeatured()' + err);
    } finally {
      this.loading = false;
    }
  }

  @action
  async fetchById(id: number | string): Promise<Memorial> {
    try {
      const response = await this.service.get(id);

      await this.rootStore.profileStore.fetchByIds(response.adminIds);

      const memorial = this.addToArray(response);
      return memorial;
    } catch (err) {
      throw new Error('MemorialStore.fetchById() - ' + err);
    }
  }

  @action
  async fetchByIds(id: number[]) {

  }

  @action
  async fetchByProfileId(id: number): Promise<Memorial[]> {
    try {
      const params = {
        query: {
          adminIds: id
        }
      };
      const response = await this.service.find(params) as IMemorial[];

      const memorialIds = response.map(m => m._id);
      this.clearRemoved(id, memorialIds);

      const profileIds = response
        .map(m => m.adminIds)
        .reduce((acc, cur) => [...acc, ...cur], []);
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const memorials = response.map(m => this.addToArray(m));
      return memorials;
    } catch (err) {
      throw new Error('MemorialStore.fetchById() - ' + err);
    }
  }

  @action
  addToArray(data: IMemorial): Memorial {
    let memorial = find(this.memorials, m => m._id === data._id);

    if (memorial) {
      Object.assign(memorial, data);
    } else {
      memorial = new Memorial(this.rootStore, this, data);
      this.memorials.push(memorial);
    }

    if (!memorial.familyTree) {
      memorial.fetchFamilyTree();
    }

    return memorial;
  }

  @action
  clearRemoved(profileId: number, memorialIds: number[]) {
    remove(this.memorials, m => m.ownerId === profileId && memorialIds.indexOf(m._id) < 0);
  }

  getNewMemorial(): Memorial {
    return new Memorial(this.rootStore, this);
  }

  /**
   * Adds a memorial object. Not used to create memorial on server.
   * @see Memorial is resonsible for creating memorials on server
   * @param memorial - Object containing created memorial. 
   */
  @action
  add(memorial: IMemorial) {
    return this.addToArray(memorial);
  }

  /**
   * Deletes memorial from server
   * @param id - id number of the memorial
   */
  @action
  async remove(id: number) {
    try {
      await this.service.remove(id);
      runInAction(`memorial ${id} removed`, () => {
        const removed = remove(this.memorials, m => m._id === id);
      });
    } catch (err) {
      console.error('MemorialStore.remove() - ' + err);
    }
  }

  @action
  async changeOwner(memorialId: number, profileId: number): Promise<Memorial> {
    try {
      const response = await this.service.patch(memorialId, { ownerId: profileId });
      const memorial = this.addToArray(response);
      return memorial;
    } catch (err) {
      throw new Error(err);
    }
  }

  @action
  onPatched(memorial: IMemorial) {
    if (memorial.dateOfBirth) {
      memorial.dateOfBirth = moment(memorial.dateOfBirth);
    }
    if (memorial.dateOfPassing) {
      memorial.dateOfPassing = moment(memorial.dateOfPassing);
    }
    this.addToArray(memorial);
  }
}

export default MemorialStore;
