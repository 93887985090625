import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import remove from 'lodash/remove';
import find from 'lodash/find';

import moment from 'moment';
import { service, restClient } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import { IVideo } from 'state/apiClient/ServiceTypes';
import StoreBaseClass from 'state/stores/storeBaseClass';
import {
  Video,
  Memorial
} from 'state/objects';
import RootStore from 'state/rootStore';

/** Store containing videos uploaded to a memorial */
class VideoStore extends StoreBaseClass {

  service: Service<IVideo>;

  @observable videos: Video[] = [];

  /**
   * Creates a new store
   * @param memorial - Memorial the videos are uploaded too
   */
  constructor (rootStore: RootStore) {
    super(rootStore);
    this.service = service('videos');
  }

  /** Fetches all videos belonging to memorial from server */
  @action
  async fetchAll () {
    throw new Error('VideoStore.fetchAll() not implemented');
  }

  @action
  async fetchById (id: number): Promise<Video> {
    try {
      const response = await this.service.get(id);
      await this.rootStore.profileStore.fetchById(response.ownerId);
      return this.addToArray(response);
    } catch (err) {
      throw new Error('VideoStore.fetchById() - ' + err);
    }
  }

  @action
  async fetchByMemorialId (id: number): Promise<Video[]> {
    try {
      const params = { query: {
        kind: 'memorials',
        parentId: id
      }};
      const response = await this.service.find(params) as IVideo[];
      
      const videoIds = response.map(v => v._id);
      this.clearRemoved(id, videoIds);

      const profileIds = response.map(v => v.ownerId)
      await this.rootStore.profileStore.fetchByIds(profileIds);
      
      const videos = response.map(v => this.addToArray(v));
      return videos;
    } catch (err) {
      throw new Error('VideoStore.fetchByMemoryId() - ' + err);
    }
  }

  /**
   * Uploads a new video to the memorial
   * @param file - The video object
   */
  
  @action
  async add (profileId: number, file: File, thumbnail: File): Promise<Video> {
    
    try {
      var data = new FormData();
      data.append('video', file);
      data.append('thumbnail', thumbnail);
      data.append('kind', 'memorials');
      data.append('parentId', profileId.toString());

      const response = await restClient('videos', {
        body: data,
        method: 'POST'
      });

      const video = this.addToArray(parseVideoData(response))
      return video;
    } catch (err) {
      throw new Error('VideoStore.add() ' + err.message)
    } 
  }
  
  @action
  addToArray (data: IVideo): Video {
    let video = find(this.videos, v => v._id === data._id);

    if (video) {
      Object.assign(video, data);
    } else {
      video = new Video(this.rootStore, this, data);
      this.videos.push(video);
    }
    return video;
  }

  @action
  clearRemoved (memorialId: number, videoIds: number []) {
    remove(this.videos, v => v.parentId === memorialId && videoIds.indexOf(v._id) < 0);
  }

  /**
   * Deletes a video
   * @param id - id number of the video
   */
  @action
  async remove (id: number) {

    try {
      await this.service.remove(id);

      runInAction('video deleted', () => {
        remove(this.videos, v => v._id === id);
      });
    } catch (err) {
      throw new Error('VideoStore.remove() ' + err);
    }
  }
}

function parseVideoData (data): IVideo {
  data.createdAt = moment(data.createdAt);
  return data;
}

export default VideoStore;