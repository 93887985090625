import * as React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from "mobx-react";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AccountStore from 'state/account';
import ReportStore from 'state/reportStore';
import {
  Comment,
  Input,
  TextArea,
  Button,
  Label,
  Modal,
  Dropdown
} from 'semantic-ui-react';
import * as moment from 'moment';

import avatar from 'images/avatar_placeholder.jpeg';
import { Comment as CommentClass } from 'state/objects';

@inject('accountStore', 'reportStore') @observer
class CommentItem extends React.Component<CommentItemProps, {}> {

  @observable isEditing: boolean = false;
  @observable showDeleteModal: boolean = false;

  accountStore: AccountStore;
  reportStore: ReportStore;

  editAreaRef: TextArea;

  constructor(props: CommentItemProps, context) {
    super(props, context);
    this.accountStore = props.accountStore!;
    this.reportStore = props.reportStore!;
  }

  componentDidMount() {
    if (this.props.focus) {
      this.setFocus();
    }
  }

  componentWillReceiveProps(nextProps: CommentItemProps) {
    if (nextProps.focus && nextProps.focus !== this.props.focus) {
      this.setFocus();
    }
  }

  createNewComment() {
    this.isEditing = true;
  }

  setFocus() {
    if (!(this.props.newComment || this.isEditing)) {
      return false;
    }
    this.editAreaRef.focus();
  }

  startEditing() {
    this.isEditing = true;
  }

  cancelEditing() {
    this.props.comment.resetEdit();
    this.isEditing = false;
    if (this.props.cancel) this.props.cancel();
  }

  saveEdit() {
    this.props.saveEdit();
    this.isEditing = false;
  }

  deleteComment() {
    this.props.comment.remove();
    this.showDeleteModal = false;
  }

  reportComment() {
    this.reportStore.openModal('comments', this.props.comment._id);
  }

  render() {

    const { comment, newComment, accountStore, saveEdit, ...rest } = this.props;
    let author;

    if (newComment && accountStore!.myProfile) {
      author = accountStore!.myProfile;
    } else {
      author = comment.author;
    }

    const isEditing = this.isEditing || newComment;

    const actions: JSX.Element[] = [];

    if (!newComment) {
      actions.push(<Comment.Action key="reply" onClick={() => this.props.onReply!()}>Reply</Comment.Action>);
    }

    if (isEditing) {
      actions.push(<Comment.Action key="save" as={Label} className="pointer" color="red" onClick={() => this.saveEdit()}>Save</Comment.Action>);
      actions.push(<Comment.Action key="cancel" onClick={() => this.cancelEditing()}>Cancel</Comment.Action>);
    } else {
      if (comment.canEdit) {
        actions.push(<Comment.Action key="edit" onClick={() => this.startEditing()}>Edit</Comment.Action>);
      }
      if (comment.canDelete) {
        actions.push(<Comment.Action key="delete" onClick={() => this.showDeleteModal = true}>Delete</Comment.Action>);
      }
      if (!comment.canEdit) {
        actions.push(
          <Dropdown 
            icon="ellipsis horizontal"
            as={Comment.Action}
            key='dropdown'
          >
            <Dropdown.Menu>
              <Dropdown.Item text="Report comment" onClick={() => this.reportComment()} />
            </Dropdown.Menu>
          </Dropdown>
          );
      }
    }

    return (
      <Comment>

        <Comment.Avatar src={author.avatar} style={{ height: 'auto' }} />

        <Comment.Content>
          <Comment.Author as={Link} to={`/profile/${author._id}`}>{author.displayName}</Comment.Author>
          <Comment.Metadata>{comment.date && moment(comment.date).fromNow()}</Comment.Metadata>

          <Comment.Text>
            {isEditing ? (
              <TextArea
                style={{ width: '100%' }}
                value={comment.editModel.text}
                onChange={e => comment.setText(e.currentTarget.value)}
                ref={input => this.editAreaRef = input as TextArea}
                onBlur={this.props.onBlur ? (() => this.props.onBlur!()) : null}
              />
            ) : (
                <p>{comment.text}</p>
              )}
          </Comment.Text>

          {actions.length > 0 && (
            <Comment.Actions>
              {actions}
            </Comment.Actions>
          )}
        </Comment.Content>

        {this.showDeleteModal && (
          <Modal
            open
            size="tiny"
          >
            <Modal.Header>
              Note
            </Modal.Header>
            <Modal.Content>
              <strong>
                This will delete this comment. Are you sure you want to continue?
               </strong>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.showDeleteModal = false}>Cancel</Button>
              <Button secondary type="submit" onClick={() => this.deleteComment()}>Delete Comment</Button>
            </Modal.Actions>
          </Modal>
        )}

      </Comment>
    );
  }
}

//defines what properties this component can recieve from outside sources
export interface CommentItemProps {
  comment: CommentClass;
  newComment?: boolean;
  accountStore?: AccountStore;
  reportStore?: ReportStore;
  saveEdit(): any;
  cancel?(): any;
  focus?: boolean;
  onBlur?(): any;
  onReply?(): any;
}

export default CommentItem;
