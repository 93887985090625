import { Application, HooksObject } from '@feathersjs/feathers';
import serviceTypes from '../ServiceTypes';
import populate from 'state/apiClient/hooks/populate';
import {
  debug,
  discard
} from 'feathers-hooks-common';
import { renameField } from 'state/apiClient/hooks';
import {
  rename,
  remove,
  negate,
  parseDateFromServer
} from './hooks';


const renameBefore = [
  //renameField('id', '_id')
];

const renameAfter = [
  //renameField('_id', 'id'),
  parseDateFromServer('createdAt', 'updatedAt')
];

const afterAllButRemove = [
  
];

const hooks = {
  before: {
    all: [ ],
    find: [],
    get: [],
    create: [ 
      ...renameBefore
    ],
    update: [ ...renameBefore  ],
    patch: [ ...renameBefore ],
    remove: [ ]
  },

  after: {
    all: [
      ...renameAfter
    ],
    find: [
      ...afterAllButRemove
    ],
    get: [
      ...afterAllButRemove
    ],
    create: [
      ...afterAllButRemove
    ],
    update: [
      ...afterAllButRemove
    ],
    patch: [
      ...afterAllButRemove
    ],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function (app: Application<serviceTypes>) {
  const chatService = app.service('messages');
  chatService.hooks(hooks);
};
