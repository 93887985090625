import * as React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Grid, Container, Menu } from 'semantic-ui-react';

const memtreeFooter = () => (
  <Grid className="light-blue" style={{ paddingBottom: 100 }}></Grid>
);

const profileFooter = () => (
  <Grid className="dark-blue" style={{ paddingBottom: 100 }}></Grid>
);

const normalFooter = () => <div style={{ height: 100 }}></div>;

const Footer = () => {
  return (
    <Grid className="light-blue" style={{ height: '71px', margin: '0' }}>
      <Menu secondary>
        <Menu.Item>
          <p className="text-white">&copy; 2022 www.memoriatree.com</p>
        </Menu.Item>
        <Menu.Item>
          <Link to="/contact">Contact Us</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/tos">Terms of Service</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/privacy">Privacy Policy</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/cookies">Cookies</Link>
        </Menu.Item>
      </Menu>
    </Grid>
  );
};

/*
<Switch>
  <Route path="/profile" component={profileFooter} />
  <Route path="/memorial" component={profileFooter} />
  <Route component={normalFooter} />
</Switch>*/

export default Footer;
