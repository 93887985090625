import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import ChatStore from 'state/stores/chats';
import RootStore from 'state/rootStore';
import { Profile, Message } from 'state/objects';
import { service } from 'state/apiClient';
import moment from 'moment';
import { IChat, IParticipant } from 'state/apiClient/ServiceTypes';

class Chat {

  rootStore: RootStore;
  store: ChatStore;

  @observable _id: number;
  @observable participantIds: IParticipant[] = [];
  @observable lastMessageId: number;

  constructor (rootStore: RootStore, store: ChatStore, chatData: IChat) {
    this.rootStore = rootStore;
    this.store = store;
    
    Object.assign(this, chatData);
  }

  @computed 
  get participants (): Profile[] {
    const userProfileId = this.rootStore.accountStore.profileId;
    const participantList = this.rootStore.profileStore.profiles
      .filter(profile => profile._id !== userProfileId && this.participantIds.map(participant => participant.id).indexOf(profile._id) > -1);

    return participantList;
      
  }

  @computed
  get lastMessage (): Message | undefined {
    return this.rootStore.messageStore.messages.find(m => m._id === this.lastMessageId);
  }

  @computed
  get unreadMessages (): number {
    const userProfileId = this.rootStore.accountStore.profileId;
    const userProfileInChat = this.participantIds.find(participant => participant.id === userProfileId);
    
    if (userProfileInChat) {
        if (userProfileInChat.lastReadMessageId !== this.lastMessageId) {
          if (userProfileInChat.lastReadMessageId) {
            var lastMessageIndex = this.messages.map(m => m._id).indexOf(userProfileInChat.lastReadMessageId);
            return this.messages.length - lastMessageIndex - 1;
          }
          return this.messages.length;        
        }
    }  
    return 0;
  }

  @computed
  get messages (): Message[] {
    return this.rootStore.messageStore.messages
      .filter(m => m.chatId === this._id)
      .sort((a, b) => a.createdAt.diff(b.createdAt));
  }

  @action
  async readMessages () {
    return this.store.readChat(this._id);
  }

  @action
  async fetchMessages (): Promise<Message[]> {
    return this.rootStore.messageStore.fetchByChatId(this._id);
  }

  @action
  async sendMessage (text: string): Promise<Message> {
    return this.rootStore.messageStore.add(this._id, text);
  } 
}

export default Chat;