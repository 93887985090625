import { Application } from '@feathersjs/feathers';
import serviceTypes from '../ServiceTypes';

import memorials from './memorials';
import profiles from './profiles';
import posts from './comments';
import pictures from './photos';
import videos from './videos';
import connections from './connections';
import notifications from './notifications';
import contributors from './contributors';
import memories from './memories';
import guestbookEntries from './guestbook-entries';
import chats from './chats';
import messages from './messages';
import familyTreePeople from './family-tree-people';

export default function (app: Application<serviceTypes>) {
  app.configure(memorials);
  app.configure(profiles);
  app.configure(posts);
  app.configure(pictures);
  app.configure(videos);
  app.configure(connections);
  app.configure(notifications);
  app.configure(contributors);
  app.configure(memories);
  app.configure(guestbookEntries);
  app.configure(chats);
  app.configure(messages);
  app.configure(familyTreePeople);
};
