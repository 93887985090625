import populate from 'state/apiClient/hooks/populate';
import {
  debug,
  disableMultiItemChange,
  discard,
  iff,
  isProvider
} from 'feathers-hooks-common';
import { renameField } from 'state/apiClient/hooks';
import {
  rename,
  remove,
  negate,
  parseDateFromServer,
  parseDateFromClient
} from './hooks';

const renameBefore = [
  renameField('id', '_id'),
  rename('text', 'comment'),
];

const renameAfter = [
  rename('_id', 'id'),
  rename('comment', 'text'),
  rename('pk', 'id'),
  rename('author_profile', 'authorId'),
  rename('owner_memorial', 'memorialId'),
  rename('created', 'date'),
];

/*
const populateSchema = {
  include: {
    service: 'profiles',
    nameAs: 'author',
    parentField: 'authorId',
    childField: 'id'
  }
};
*/

const afterAllButRemove = [
  ...renameAfter,
  parseDateFromServer('date'),
  //iff(isProvider('server'),
    //populate({ schema: populateSchema })
];

const hooks = {
  before: {
    all: [ ],
    find: [],
    get: [],
    create: [ 
      parseDateFromClient('date'),
      ...renameBefore
    ],
    update: [
      disableMultiItemChange(),
      parseDateFromClient('date'),
      ...renameBefore
    ],
    patch: [
      disableMultiItemChange(),
      parseDateFromClient('date'),
      ...renameBefore
    ],
    remove: [ ]
  },

  after: {
    all: [

    ],
    find: [
      ...afterAllButRemove
    ],
    get: [
      ...afterAllButRemove
    ],
    create: [
      ...afterAllButRemove
    ],
    update: [
      ...afterAllButRemove
    ],
    patch: [
      ...afterAllButRemove
    ],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function () {

  const app = this;
  const memorialService = app.service('memorials/:profileId/posts');
  const memoryService = app.service('memorials/posts');
  memorialService.hooks(hooks);
  memoryService.hooks(hooks);
};
