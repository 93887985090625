import {
  observable,
  action,
  computed
} from 'mobx';
import Partnership from './partnership';

class FamilyTreePartnershipNode {

  @observable partnership: Partnership;

  constructor(partnership: Partnership) {
    this.partnership = partnership;
  }

  @computed
  get x(): number {
    const { parent1, parent2 } = this.partnership;

    if (parent1 && parent2) {
      return (parent1.node.middleX + parent2.node.middleX) / 2;
    }
    if (parent1) {
      return parent1.node.middleX;
    }
    if (parent2) {
      return parent2.node.middleX
    }
    return 0;
  }

  @computed
  get y(): number {
    const { parent1, parent2 } = this.partnership;

    if (parent1 && parent2) {
      return parent1 ? parent1.node.middleY : parent2.node.middleY;
    }

    if (parent1) {
      return parent1.node.bottomY;
    }
    if (parent2) {
      return parent2.node.bottomY;
    }
    return 0;
  }

  @computed
  get shouldRender(): boolean {
    const { parent1, parent2 } = this.partnership;
    if (parent1) return parent1.node.shouldRender;
    if (parent2) return parent2.node.shouldRender;
    return false;
  }

  @computed
  get hasHiddenChildren(): boolean {
    return this.partnership.children.filter(c => !c.node.shouldRender).length > 0;
  }
}

export default FamilyTreePartnershipNode;