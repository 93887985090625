import * as React from 'react';

import { 
  Icon,
} from 'semantic-ui-react';

export interface Props {
  direction: string,
  onClick?: any;
}

class CarouselArrow extends  React.Component<Props, {}> {
    render() {
      let iconName: any = "angle " + this.props.direction;
      return (

        <button type="button" 
            onClick={this.props.onClick} 
            className={`carousel-arrow-${this.props.direction}`} 
            aria-label={this.props.direction}
        >
            <Icon name={iconName} size='massive' />
        </button>
      );
    }
  }
  
  export default CarouselArrow;