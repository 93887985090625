import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link, withRouter } from 'react-router-dom';

import { setTimeout } from 'timers';
import AccountStore from 'state/account';
import { ChatStore, NotificationStore, ProfileStore, } from 'state/stores';
import uiStore from 'state/ui';

import heartImage from 'images/heart-quote.png';

import {
  Grid,
  Container,
  Menu,
  Icon,
  Input,
  Label,
  Popup,
  List,
  Image,
  Modal,
  Button
} from 'semantic-ui-react';

import UserAvatar from 'components/Generic/Avatar';
import SearchBar from '../Search/SearchBar';
import Notifications from './notifications';


export interface Props {
  open: boolean
  onClose(): any;
  match: any;
  mode: "computer" | "computer-small" | "tablet";
  history?: any;
  accountStore?: AccountStore;
  profileStore?: ProfileStore;
  chatStore: ChatStore;
  notificationStore: NotificationStore;
  uiStore: uiStore;
}

@inject('accountStore', 'profileStore', 'chatStore', 'uiStore', 'notificationStore') @observer
class ComputerTopBar extends React.Component<Props, {}> {

  @observable notificationsOpen = false;
  @observable showAccountSettings = false;

  @observable showBlockTopBarModal = false;
  blockTopBarMsg: string;
  blockTopBarContinueAction: any;

  accountStore: AccountStore;
  profileStore: ProfileStore;
  chatStore: ChatStore;
  notificationStore: NotificationStore;
  uiStore: uiStore;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.profileStore = this.props.profileStore!;
    this.chatStore = this.props.chatStore!;
    this.notificationStore = this.props.notificationStore!;
    this.uiStore = this.props.uiStore;
  }

  eventTopBarClickHook(eventFunction: any) {

    if (this.uiStore.blockTopBar === true) {
      this.showBlockTopBarModal = true;
      this.blockTopBarContinueAction = eventFunction;
    } else {
      eventFunction();
    }
  }

  openNotifications() {
    this.notificationsOpen = true;
    this.notificationStore.fetchAll();
  }

  closeNotifications(e?) {
    if (e && (e.target.id === 'yolo' || e.target.id === 'yolo2')) {
      return;
    }
    this.notificationsOpen = false;
  }

  signOut() {
    this.accountStore.logout()
      .then(() => this.props.history.push('/'));
  }

  render() {
    //this.accountStore = this.props.accountStore;
    const newNotifications = this.notificationStore.numberOfUnread;
    const unreadChats = this.chatStore.numberOfUnreadChats;

    return (
      <Menu secondary fixed="top" style={{ backgroundColor: "#98b9dc" }}>

        <Menu.Item>
          <a onClick={() => this.eventTopBarClickHook(() => this.props.history.push(`/`))}
            className="logo top button-height left pointer" style={{ cursor: 'pointer' }}
          >
            {this.props.mode === "computer" || this.props.mode === "computer-small" ?
              "MemoriaTree" :
              "M"
            }
          </a>
          {this.props.mode == "computer" &&
            <h2 className="logo-text">
              <p className="first-part">Share Memories</p>
              <p className="second-part">Connect Lineages</p>
            </h2>
          }
        </Menu.Item>
        
        {this.accountStore.myProfile && (   
          <Menu.Item>
            <SearchBar style={this.props.mode == "computer" ? { width: 300 } : { width: 200 }} />
          </Menu.Item>
        )}
        

        {/*If logged in show this */}
        {this.accountStore.myProfile ? (       

          <Menu.Menu position="right">

            <Menu.Item>
              <a href="https://goo.gl/forms/yWJ2foJq6zxMRcjs1" target="_blank" >
                <Icon name="talk" />Send feedback
              </a>
            </Menu.Item>
            
            {/*}
            <Menu.Item>
              <a style={{ cursor: 'pointer' }} onClick={() => this.eventTopBarClickHook(() => this.props.history.push(`/store`))} >
                <Icon name="shopping cart" />Shop
              </a>
            </Menu.Item>*/}

            <Popup
              id="notification_popup"
              trigger={
                <Menu.Item as="div">
                  <a style={{ cursor: 'pointer' }}>
                    <Icon size="large" name="bell" />
                    {newNotifications > 0 && (
                      <Label color="red" floating>{newNotifications}</Label>
                    )}
                  </a>
                </Menu.Item>
              }
              positionFixed
              style={{ maxWidth: '350px', position: 'fixed', maxHeight: "500px", padding: 0 }}
              open={this.notificationsOpen}
              onOpen={() => this.openNotifications()}
              onClose={(e) => this.closeNotifications(e)}
              on="click"
              position="bottom center"
              onMount={() => {
                setTimeout(() => {
                  const ele = document.getElementById("notification_popup");
                  //if (ele) ele.style.top = '57px';
                }, 0);
              }}
            >
              <Notifications
                closePopup={() => this.closeNotifications()}
              />

            </Popup>

            <Menu.Item
              as="div"
              onClick={() => this.eventTopBarClickHook(() => this.uiStore.toggleChatModal())}
            >
              <a style={{ cursor: 'pointer' }}>
                <Icon size="large" name="comments" />
                {unreadChats > 0 && (
                  <Label color="red" floating>
                    {unreadChats}
                  </Label>
                )}
              </a>
            </Menu.Item>

            <Popup
              trigger={
                <Menu.Item onClick={() => this.eventTopBarClickHook(() => this.props.history.push(`/profile/${this.accountStore.profileId}`))}>
                  <span style={{ color: "rgb(30, 112, 191)"}}>{this.accountStore.myProfile.firstName}</span>&nbsp;&nbsp;
                  <UserAvatar
                    profile={this.accountStore.myProfile}
                    size={35}
                    style={"avatar"}
                    noShadow
                    noText
                    noUserIndicator
                    noLink
                  />
                </Menu.Item>
              }
              position='bottom right'
              hoverable
            >
              <List divided relaxed>
                <List.Item >
                  <div style={{ fontWeight: "bold", paddingBottom: "0px" }}>
                    {this.accountStore.myProfile.displayName}
                  </div>
                  <br />
                  <a style={{ cursor: 'pointer' }} onClick={() => this.eventTopBarClickHook(() => this.props.history.push(`/profile/${this.accountStore.profileId}`))} className="text-normal">
                    My Profile
                  </a>
                </List.Item>
                <List.Item style={{ cursor: 'pointer' }}>
                  <a onClick={() => this.eventTopBarClickHook(() => this.props.history.push(`/settings`))} className="text-normal">
                    Account Settings
                  </a>
                </List.Item>
                <List.Item style={{ cursor: 'pointer' }}>
                  <a onClick={() => this.eventTopBarClickHook(() => {
                    this.signOut();
                  }
                  )} className="text-normal">
                    Log Out
                  </a>
                </List.Item>
              </List>
            </Popup>

          </Menu.Menu>

        ) : (

            <Menu.Menu position="right">
              <Menu.Item style={{ color: "#fff" }} className="signup-signin">
                <a onClick={() => this.uiStore.showLoginModal = true}>
                  Sign In
              </a>
                &nbsp;|&nbsp;
              <Link to="/signup">Create a Memorial and Family Tree</Link>
              </Menu.Item>
            </Menu.Menu>

          )}

        {this.showBlockTopBarModal &&
          <Modal open size="mini">
            <Modal.Header>
              Are You Sure?
            </Modal.Header>
            <Modal.Content>
              <p>
                {this.props.uiStore.blockTopBarMsg}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => {
                this.showBlockTopBarModal = false;
                this.blockTopBarContinueAction();
              }}>
                Yes
              </Button>
              <Button secondary onClick={() => this.showBlockTopBarModal = false}>
                No
              </Button>
            </Modal.Actions>
          </Modal>
        }

      </Menu>

    );
  }
};

// @ts-ignore
export default withRouter(ComputerTopBar);
