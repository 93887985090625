import {
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import { service } from 'state/apiClient';
import {
  GuestbookEntry,
  Comment
} from 'state/objects';
import Chat from 'state/objects/chat';
import { Service } from '@feathersjs/feathers';
import RootStore from 'state/rootStore';
import { IChat } from 'state/apiClient/ServiceTypes';

class ChatStore {

  rootStore: RootStore;
  service: Service<IChat>
 
  @observable chats: Chat[] = [];

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore;
    this.service = service('chats');

    this.initEvents ();
  }

  initEvents () {
    this.service.on('created', c => this.onCreated(c));
    this.service.on('patched', c => this.onPatched(c));
    this.service.on('updated', c => this.onPatched(c));
  }

  @computed
  get chatsByDate (): Chat[] {
    return this.chats.sort((a, b) => {
      if (!a.lastMessage && !b.lastMessage) {
        return 0;
      }

      if (!a.lastMessage) {
        return 1;
      }

      if (!b.lastMessage) {
        return -1;
      }
      return b.lastMessage.createdAt.diff(a.lastMessage.createdAt);
    });
  }

  @computed
  get numberOfUnreadChats () : number {
    const unreadChats = this.chats.filter(c => c.unreadMessages > 0);
    return unreadChats.length;
  }


  @action
  async fetchAll (): Promise<Chat[]> {
    try {
      const response = await this.service.find() as IChat[];

      const profileIds = flatten(response.map(c => c.participantIds.map(p => p.id)));
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const messageIds = response
        .map(c => c.lastMessageId)
        .filter(id => id !== undefined);
      await this.rootStore.messageStore.fetchByIds(messageIds);

      const chats = response.map(m => this.addToArray(m));
      return chats;
    } catch (err) {
      throw new Error('ChatStore.fetchAll ' + err);
    }
  }

  @action
  async add (participants: number[]): Promise<Chat> {
    try {
      //the data we will send to the backend
      const body = {
        participantIds: participants.map(
          id => {return { 
            id: id,
          } }
        )
      }
      const response = await this.service.create(body);
      return this.addToArray(response);
    } catch (err) {
      throw new Error('ChatStore.add() - ' + err);
    }
  }

  @action
  async readChat (id: number): Promise<Chat> {
    try {
      const response = await this.service.patch(id, {});
      return this.addToArray(response);
    } catch (err) {
      throw new Error('ChatStore.readChat() - ' + err);
    }
  }

  @action
  addToArray (data: IChat): Chat {
    let chat = find(this.chats, c => c._id === data._id);

    if (chat) {
      Object.assign(chat, data);
    } else {
      chat = new Chat(this.rootStore, this, data);
      this.chats.push(chat);
    }
    return chat;
  }

  @action
  onCreated (chat: IChat) {
    const profileIds = chat.participantIds.map(p => p.id);
    this.rootStore.profileStore.fetchByIds(profileIds)
    .then(() => {
      this.addToArray(chat);
    })
  }
  
  @action
  onPatched (chat: IChat) {
    this.addToArray(chat);
  }
}

export default ChatStore;