import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import pick from 'lodash/pick'; 
import extend from 'lodash/extend';

import { createViewModel, IViewModel } from 'mobx-utils';
import * as moment from 'moment';

import { service } from 'state/apiClient';

import { Service } from '@feathersjs/feathers';
import {
  IProfile
} from 'state/apiClient/ServiceTypes';

import ProfileBaseClass from './profileBaseClass';
import {
  MemorialStore,
  ConnectionStore,
  ProfileStore
} from 'state/stores'
import AccountStore from 'state/account';
import { 
  Memorial,
  Connection
} from 'state/objects';
import RootStore from 'state/rootStore';
import { ProfileForm } from 'state/stores/forms';

import coverPlaceholder from 'images/profiles_background.jpeg';

interface ISavedState {
  firstName: string,
  lastName: string,
  dateOfBirth: moment.Moment
  placeOfBirth: string
}
  
class Profile extends ProfileBaseClass {
  
  store: ProfileStore | AccountStore;
  service: Service<IProfile>;
  savedState: ISavedState | null;

  kind: 'profiles' = 'profiles';
  
  @observable userId: number;
  @observable familyTreeViewingPermission: 'public' | 'connections' = 'public';

  baseUrl = '/profiles';

  @observable editModel: Profile & IViewModel<Profile>;

  /**
   * Creates new profile object with data fetched from server or empty profile.
   * @param profile - Profile data. Leave empty and use .fetch() to get profile from server.
   */
  constructor (rootStore: RootStore, store?: ProfileStore | AccountStore, profile?: IProfile) {
    super (rootStore);
    this.service = service('profiles');

    if (store) {
      this.store = store;
    }

    if (profile) {
      extend(this, profile);
      this.finishedLoading = true;
      this.editModel = createViewModel(this);
    }
  }

  /** Whether the basic profile info has been loaded */
  @computed
  get loaded (): boolean {
    if (this._id) {
      return true;
    }
    return false;
  }

  /** Cover image */
  @computed
  get cover (): string {
    return this.coverUrl ? this.coverUrl : coverPlaceholder;
  }

  @computed
  get url (): string {
    return `/profile/${this._id}`
  }

  /** Whether or not the profile belongs to logged in user */
  @computed
  get userIsOwner (): boolean {
    if (!this.rootStore.accountStore.myProfile) {
      return false;
    } 
    return this.rootStore.accountStore.myProfile._id === this._id;
  }

  /** Returns how old the person is */
  @computed
  get ageString (): string {

    const { dateOfBirth } = this;

    if (dateOfBirth === null || !dateOfBirth.isValid()) {
      return '';
    }

    const now = moment();

    const differenceInDays = now.diff(dateOfBirth, 'days');
    if (isNaN(differenceInDays)) {
      return '';
    }
    if (differenceInDays < 7) {
      return `${differenceInDays} days old`;
    }
  
    const differenceInWeeks = now.diff(dateOfBirth, 'weeks');
    if (differenceInWeeks < 52) {
      return `${differenceInWeeks} weeks old`;
    }
  
    const differenceInYears = now.diff(dateOfBirth, 'years');
    return `${differenceInYears} years old`;
  }
  
  /** Returns the connection relation to logged in user */
  @computed
  get relationToUser (): IRelationToUser | null {
   
    if (!this.rootStore.accountStore.myProfile) {
      return null;
    }
    
    const {
      allConnections
    } = this.rootStore.accountStore.myProfile;

    const connection = allConnections.find(c => c.contactId === this._id);

    if (typeof connection === 'undefined') {
      return null;
    }

    return {
      id: connection._id,
      status: connection.status,
      relation: connection.relation
    };
  }

  @computed
  get familyTreeViewable (): boolean {
    return this.userIsOwner 
      || this.familyTreeViewingPermission === 'public' 
      || (!!this.relationToUser && this.relationToUser.status === 'accepted')
  }

  @computed
  get memorials (): Memorial[] {
    return this.rootStore.memorialStore.memorials.filter(m => m.adminIds.indexOf(this._id) > -1);
  }
  
  @computed
  get allConnections (): Connection[] {
    return this.rootStore.connectionStore.connections.filter(c => c.profileId === this._id);
  }
  
  @computed
  get connections (): Connection[] {
    return this.allConnections.filter(c => c.status === 'accepted')
  }
  
  @computed
  get connectionRequests (): Connection[] {
    return this.allConnections.filter(c => c.status === 'requested')
  }
  
  @computed
  get pendingOutgoingConnections (): Connection[] {
    return this.allConnections.filter(c => c.status === 'pending')
  }

  @action
  async fetchConnections(): Promise<Connection[]> {
    return this.rootStore.connectionStore.fetchByProfileId(this._id);
  }

  /** Returns form object used to edit profile info. */
  getForm (): Promise<any> {
    const form = new ProfileForm(this);
    return form.loadRules();
  }

  /** Saves edits to the server. Don't use directly. Save through the form object */
  @action
  async saveEdit () {
    if (!this.editModel.isDirty) {
      return;
    }
    this.loading = true;
    const body = pick(this.editModel, [
      'firstName',
      'lastName',
      'gender',
      'dateOfBirth',
      'placeOfBirth',
      'familyTreeViewingPermission'
    ]);

    try {
      // @ts-ignore
      const response = await this.service.patch!(this._id, body, {});

      runInAction(`profile ${this._id} updated`, () => {
        extend(this, response);
        this.editModel.reset();
      });
    } catch (err) {
      throw new Error('Profile.save()' + err);
    } finally {
      this.loading = false;
    }
  }

  /** Only used internally */
  @action
  resetEdit () {
    this.editModel.reset();
  }

  @action
  fetchMemorials (): Promise<Memorial[]> {
    return this.store.rootStore.memorialStore.fetchByProfileId(this._id);
  }
}

interface IRelationToUser {
  id: number;
  status: 'accepted' | 'pending' | 'requested';
  relation: string;
}

export default Profile;