import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import {
  Profile,
  Memorial
} from 'state/objects';
import { Service } from '@feathersjs/feathers';
import { service } from './apiClient';
import { IMemorial, IProfile } from 'state/apiClient/ServiceTypes';
import * as moment from 'moment';
import RootStore from 'state/rootStore';

class SearchStore {

  rootStore: RootStore;

  memorialService: Service<IMemorial> = service('memorials');
  profileService: Service<IProfile> = service('profiles');

  @observable memorials: Memorial[] = [];
  @observable profiles: Profile[] = [];

  @observable query: string = '';
  @observable searchTerm: string = '';

  @observable loading: boolean = false;


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.memorialService = service('memorials');
    this.profileService = service('profiles');

    this.loadProfiles();
  }

  @computed
  get suggestions(): ISearchSuggestion[] {
    if (!this.query) return [];

    let suggestions: ISearchSuggestion[] = [];

    suggestions = [
      ...this.memorials
        .filter(profile => profile.displayName.toLowerCase().indexOf(this.query.toLowerCase()) !== -1)
        .map(memorial => ({
          profile: memorial,
          title: memorial.displayName,
          description: `${memorial.yearRange}`,
          image: memorial.avatar,
          id: `/memorial/${memorial._id}`,
          type: "memorial",
          gender: memorial.gender
        })),

      ...this.profiles
        .filter(profile => profile.displayName.toLowerCase().indexOf(this.query.toLowerCase()) !== -1)
        .map(profile => ({
          profile: profile,
          title: profile.displayName,
          description: profile.relationToUser && profile.relationToUser.status === 'accepted' && "Connected",
          image: profile.avatar,
          id: `/profile/${profile._id}`,
          type: "profile",
          gender: profile.gender
        }))
    ] as any;

    return suggestions;
  }

  @computed
  get results() {
    if (!this.searchTerm) return [];

    let suggestions: any[] = [];

    suggestions = [
      ...this.memorials
        .filter(profile => profile.displayName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1)
        .map(profile => ({
          profile: profile,
          isProfile: false,
        })),
      ...this.profiles
        .filter(profile => profile.displayName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1)
        .map(profile => ({
          profile: profile,
          isProfile: true,
        }))
    ];

    return suggestions;
  }

  @action
  async loadProfiles() {

    this.loading = true;

    try {
      const memorials = await this.memorialService.find({}) as IMemorial[];
      const profile = await this.profileService.find({}) as IProfile[];

      runInAction('search results fetched', () => {
        this.memorials = memorials.map(m => new Memorial(this.rootStore, null as any, m));
        this.profiles = profile.map(p => new Profile(this.rootStore, null as any, p));
      });
    } catch (err) {
      throw new Error('SearchStore.loadProfiles ' + err);
    } finally {
      this.loading = false;
    }
  }
}

export interface ISearchSuggestion {
  title: string;
  description: string;
  image: string;
  id: string;
}

export default SearchStore;
