import { Application, HooksObject } from '@feathersjs/feathers';
import serviceTypes from '../ServiceTypes';
import {
  debug
} from 'feathers-hooks-common';

import { renameField } from 'state/apiClient/hooks';
import {
  rename,
  remove,
  negate,
  parseDateFromServer,
  parseDateFromClient,
  setField
} from './hooks';

const renameBefore = [
  //renameField('id', '_id')
];

const renameAfter = [
  //renameField('_id', 'id')
];

const afterAllExceptRemove = [
  parseDateFromServer('_dateOfBirth', '_dateOfPassing')
];

const hooks: HooksObject = {
  before: {
    all: [ ],
    find: [],
    get: [],
    create: [
      parseDateFromClient('_dateOfBirth', '_dateOfPassing'),
      ...renameBefore
    ],
    update: [
      parseDateFromClient('_dateOfBirth', '_dateOfPassing'),
      ...renameBefore
    ],
    patch: [
      parseDateFromClient('_dateOfBirth', '_dateOfPassing'),
      ...renameBefore
    ],
    remove: [ ]
  },

  after: {
    all: [
      ...renameAfter
    ],
    find: [
      ...afterAllExceptRemove
    ],
    get: [
      ...afterAllExceptRemove
    ],
    create: [
      ...afterAllExceptRemove
    ],
    update: [
      ...afterAllExceptRemove
    ],
    patch: [
      ...afterAllExceptRemove
    ],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function (app: Application<serviceTypes>) {
  const service = app.service('family-tree-people');
  service.hooks(hooks);
};
