import React, { Component } from 'react';
import {
  observable,
  when
} from 'mobx';

import {
  observer,
  inject
} from 'mobx-react';

import uiStore from 'state/ui';

@inject('uiStore') @observer
export default class AsyncComponent extends Component<IAsyncComponentProps, {}> {

  @observable Component: any = null;
  @observable error: boolean = false;
  @observable loaderDelayPassed: boolean = false;

  disposer: any;

  uiStore: uiStore;

  public static defaultProps: Partial<IAsyncComponentProps> = {
    loaderComponent: null,
    loaderDelay: 200,
    errorComponent: null,
    showLoadingBar: false
  };
  
  constructor(props: IAsyncComponentProps) {
    super(props);
    this.uiStore = props.uiStore!;
  }

  componentWillMount() {
    if(!this.Component) {
      this.props.moduleProvider().then(mod => {
        const Component = mod.default ? mod.default : mod;
        this.Component = Component;
        
        if (this.props.showLoadingBar) {
          this.uiStore.hidePageLoader();
        }
      });
    }
  }

  componentDidMount () {
    setTimeout(() => {
      this.loaderDelayPassed = true;
    }, this.props.loaderDelay);
    
    if (this.props.showLoadingBar) {
      this.disposer = when(
        () => this.loaderDelayPassed,
        () => {
          if (!this.Component) {
            this.uiStore.showPageLoader()
          }
        }
      );
    }
  }

  componentWillUnmount () {
    if (this.disposer) {
      this.disposer();
    }
  }

  render() {
    const { 
      Component, 
      error, 
      loaderDelayPassed 
    } = this;

    const { 
      moduleProvider,
      loaderComponent,
      errorComponent,
      showLoadingBar,
      loaderDelay,
      uiStore,
      ...rest 
    } = this.props;

    if (error && errorComponent) {
      return errorComponent;
    }

    if (Component) {
      return <Component {...rest} />;
    }

    if (loaderDelayPassed) {
      return loaderComponent;
    }

    return null;
  }
};

interface IAsyncComponentState {
  Component: any;
  error: boolean;
  loaderDelayPassed: boolean;
}

interface IAsyncComponentProps {
  [key: string]: any;
  moduleProvider(): Promise<any>;
  loaderComponent?: any;
  loaderDelay?: number;
  errorComponent?: any;
  showLoadingBar?: boolean;

  uiStore?: uiStore;
}