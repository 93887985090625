import Person from './person';
import Partnership from './partnership';
import {
  getParents,
  getPartners,
  getChildren
} from './utils';

const calculateRanks = (person: Person, rank: number) => {
  if (person.rank !== null) return;

  person.rank = rank;

  const parents = getParents(person);
  parents.forEach(parent => calculateRanks(parent, rank + 1));

  const partners = getPartners(person);
  partners.forEach(partner => calculateRanks(partner, rank));

  const children = getChildren(person);
  children.forEach(child => calculateRanks(child, rank - 1));
}

export default calculateRanks;