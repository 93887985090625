import * as React from 'react';

import Node from 'state/familyTree/node';
import PartnerNode from 'state/familyTree/partnerNode';
import constants from './RenderingConstants';

export interface PartnerProps {
	person: Node;
	partner: Node;
}

export interface ParentProps {
	personX: number;
	personY: number;
	parentsX: number;
	parentsY: number;
}

export interface HiddenPeopleProps {
	startX: number;
	startY: number;
	endX: number;
	endY: number;
	onClick(e: React.MouseEvent<SVGElement>): any;
}

function LineToPartner({ person, partner }: PartnerProps) {

	let startX = 0;
	let endX = 0;
	if (person.x < partner.x) {
		startX = person.rightX;
		endX = partner.leftX;
	} else {
		startX = partner.rightX;
		endX = person.leftX;
	}

	const y = person.middleY;

	/*
	console.log(`partner line ${person.person.displayName} to ${partner.person.displayName}`);
	console.log('startX', startX);
	console.log('endX', endX);
	console.log('y', y);
	console.log('');
*/
	return (
		<g key={person.person._id + partner.person._id}>
			<line
				x1={startX}
				y1={y}

				x2={endX}
				y2={y}

				strokeWidth="2"
				stroke="#b5b5b5"
			/>
		</g>
	);

}

function LineToParent({ personX, personY, parentsX, parentsY }: ParentProps) {
	//return;
	// @ts-ignore
	const startX = personX;
	const startY = personY;

	const endX = parentsX;
	const endY = parentsY;

	const middleY = startY - 10; //((endY - startY) / 2);

	/*
	console.log(`förälder line from ${person.person.displayName}`);
	console.log('startX', startX);
	console.log('startY', startY);
	console.log('endX', endX);
	console.log('endY', endY);
	console.log('middleY', middleY);
	console.log('');
*/

	return (

		<g>
			<line
				x1={startX}
				y1={startY}

				x2={startX}
				y2={middleY}

				strokeWidth="2"
				stroke="#b5b5b5"
			/>

			<line
				x1={startX}
				y1={middleY}

				x2={endX}
				y2={middleY}

				strokeWidth="2"
				stroke="#b5b5b5"
			/>

			<line
				x1={endX}
				y1={middleY}

				x2={endX}
				y2={endY}

				strokeWidth="2"
				stroke="#b5b5b5"
			/>

		</g>
	);
}

const HiddenPeopleLine = ({ startX, startY, endX, endY, onClick }: HiddenPeopleProps) => {

	const HEIGHT = 30;
	const WIDTH = 30;

	let renderX: number;
	let renderY: number;

	if (endX < startX) {
		// Offset to left
		renderX = endX - WIDTH;
		renderY = endY - HEIGHT / 2;
	} else if (endX > startX) {
		// Offset right
		renderX = endX;
		renderY = endY - HEIGHT / 2;
	} else {
		// Straight line
		renderX = endX - WIDTH / 2;
		if (endY < startY) {
			// Up
			renderY = endY - HEIGHT;
		} else {
			// Down
			renderY = endY;
		}
	}

	return (
		<g>

			<line
				x1={startX}
				y1={startY}

				x2={startX}
				y2={endY}

				strokeWidth="2"
				stroke="#b5b5b5"
			/>

			{startX !== endX && (
				<line
					x1={startX}
					y1={endY}

					x2={endX}
					y2={endY}

					strokeWidth="2"
					stroke="#b5b5b5"
				/>
			)}

			<rect
				fill="white"
				width={30}
				height={30}
				x={renderX}
				y={renderY}
				onClick={onClick}
				style={{ cursor: 'pointer' }}
			/>

			<SiteMapIcon
				fill={constants.colorMale}
				x={renderX}
				y={renderY}
				onClick={onClick}
			/>
		</g>
	);
}

const SiteMapIcon = ({ x, y, onClick, ...rest }) => (
	<g transform={`translate(${x}, ${y})`} {...rest}>
		<path
			onClick={onClick}
			style={{ cursor: 'pointer' }}
			d="m28.875,12l-2.25,0l0,2.25c0,1.03387 -0.84112,1.875 -1.875,1.875l-8.625,0l0,1.875l2.25,0c0.62133,0 1.125,0.50367 1.125,1.125l0,6.75c0,0.62133 -0.50367,1.125 -1.125,1.125l-6.75,0c-0.62133,0 -1.125,-0.50367 -1.125,-1.125l0,-6.75c0,-0.62133 0.50367,-1.125 1.125,-1.125l2.25,0l0,-1.875l-8.625,0c-1.03387,0 -1.875,-0.84112 -1.875,-1.875l0,-2.25l-2.25,0c-0.62133,0 -1.125,-0.50367 -1.125,-1.125l0,-6.75c0,-0.62133 0.50367,-1.125 1.125,-1.125l6.75,0c0.62133,0 1.125,0.50367 1.125,1.125l0,6.75c0,0.62133 -0.50367,1.125 -1.125,1.125l-2.25,0l0,1.875l8.25,0l0,-1.875l-2.25,0c-0.62133,0 -1.125,-0.50367 -1.125,-1.125l0,-6.75c0,-0.62133 0.50367,-1.125 1.125,-1.125l6.75,0c0.62133,0 1.125,0.50367 1.125,1.125l0,6.75c0,0.62133 -0.50367,1.125 -1.125,1.125l-2.25,0l0,1.875l8.25,0l0,-1.875l-2.25,0c-0.62133,0 -1.125,-0.50367 -1.125,-1.125l0,-6.75c0,-0.62133 0.50367,-1.125 1.125,-1.125l6.75,0c0.62133,0 1.125,0.50367 1.125,1.125l0,6.75c0,0.62133 -0.50367,1.125 -1.125,1.125z"
		/>
	</g>
);

export { LineToParent, LineToPartner, HiddenPeopleLine };
