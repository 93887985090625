import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import find from 'lodash/find';
import remove from 'lodash/remove';

import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';

import FamilyTree from 'state/familyTree/tree';
import { Profile, Memorial, ProfileBaseClass } from 'state/objects';
import RootStore from 'state/rootStore';
import { IFamilyTree, IFamilyTreeManagement } from 'state/apiClient/ServiceTypes';
import PendingFamilyTree from 'state/familyTree/PendingFamilyTree';
import { uniq } from 'lodash';
import { notEmpty } from 'utils';
import FamilyTreeMerger from 'state/familyTree/familyTreeMerger';

class FamilyTreeStore {

  rootStore: RootStore;
  service: Service<IFamilyTree>
  treeManagementService: Service<IFamilyTreeManagement>;

  @observable familyTrees: FamilyTree[] = [];
  @observable pendingFamilyTrees: PendingFamilyTree[] = [];

  currentlyFetching: Map<string | number, Promise<FamilyTree | PendingFamilyTree>> = new Map();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.service = service('family-trees');
    this.treeManagementService = service('family-tree-management');
  }

  initEvents() {
    this.service.on('created', t => this.onCreated(t));
    this.service.on('patched', t => this.onPatched(t));
    this.service.on('updated', t => this.onPatched(t));
  }

  @computed
  get userFamilyTrees(): FamilyTree[] {
    const userProfile = this.rootStore.accountStore.myProfile;

    if (!userProfile) return [];

    return uniq(
      [userProfile, ...userProfile.memorials]
        .map(prof => prof.familyTree)
        .filter(notEmpty)
    );
  }

  @action
  fetchById(id: number): Promise<FamilyTree | PendingFamilyTree> {

    if (this.currentlyFetching.has(id)) {
      return this.currentlyFetching.get(id)!;
    }

    const promise = this.fetchCopyById(id)
      .then(response => {
        return this.addToArray(response);
      })
      .then(tree => {
        this.currentlyFetching.delete(tree._id);
        return tree;
      })
      .catch(err => {
        console.log(err.message);
        throw new Error('FamilyTreeStore.fetchById() - ' + err);
      })

    this.currentlyFetching.set(id, promise);
    return promise;
  }

  @action
  async fetchCopyById(id: number): Promise<FamilyTree | PendingFamilyTree> {

    try {

      const response = await this.service.get(id);

      const { people } = response;

      await Promise.all(
        people.map(person => {
          if (!person.connectedPageId) return true;
          if (person.connectedPageKind === 'memorials') {
            return this.rootStore.memorialStore.fetchById(person.connectedPageId);
          }
          return this.rootStore.profileStore.fetchById(person.connectedPageId);
        }) as any
      );

      if (response.requestPending) {
        //await this.fetchById(response.)
        const parents = await Promise.all<any>(response.parentIds.map(parent => {
          if (parent.kind === "memorials") {
            return this.rootStore.memorialStore.fetchById(parent.id)
          } else {
            return this.rootStore.profileStore.fetchById(parent.id)
          }
        }) as any);

        await Promise.all(uniq(parents.map(p => p.familyTreeId)).map(id => {
          return this.fetchById(id);
        }));

        return new PendingFamilyTree(this.rootStore, this, response);
      }

      return new FamilyTree(this.rootStore, this, response);

    } catch (err) {
      console.log(err.message);
      throw new Error('FamilyTreeStore.fetchCopyById() - ' + err);
    }
  }

  @action
  addToArray(tree: FamilyTree | PendingFamilyTree): FamilyTree {
    if (tree instanceof PendingFamilyTree) {
      remove(this.pendingFamilyTrees, t => t._id === tree._id);
      this.pendingFamilyTrees.push(tree);
      return tree;
    }
    remove(this.familyTrees, t => t._id === tree._id);
    this.familyTrees.push(tree);
    return tree;
  }

  @action
  onCreated(tree: IFamilyTree) {
    this.fetchById(tree._id);
  }

  @action
  onPatched(tree: IFamilyTree) {
    this.fetchById(tree._id);
  }

  @action
  async confirmMerge(treeId: number) {
    try {

      const data: any = {
        action: 'confirm_tree_merge',
        value: {
          familyTreeId: treeId
        }
      };

      // @ts-ignore
      const response = await this.treeManagementService.create(data) as IFamilyTreeManagement;
      await this.fetchById(response._id);

    } catch (err) {
      console.log(err.message);
      throw new Error('FamilyTreeStore.confirmMerge() - ' + err);
    }
  }

  @action
  async declineMerge(treeId: number) {
    try {

      const data: any = {
        action: 'decline_tree_merge',
        familyTreeId: treeId
      };

      // @ts-ignore
      const response = await this.treeManagementService.create(data) as IFamilyTreeManagement;

    } catch (err) {
      console.log(err.message);
      throw new Error('FamilyTreeStore.declineMerge() - ' + err);
    }
  }

  @action
  async cancelMerge(treeId: number) {
    try {

      const data: any = {
        action: 'cancel_tree_merge',
        familyTreeId: treeId
      };

      // @ts-ignore
      const response = await this.treeManagementService.create(data) as IFamilyTreeManagement;

    } catch (err) {
      console.log(err.message);
      throw new Error('FamilyTreeStore.cancelMerge() - ' + err);
    }
  }

  @action
  async leaveTree(treeId: number, profiles: ProfileBaseClass[]) {
    try {

      const data: any = {
        action: 'leave_tree',
        familyTreeId: treeId,
        value: {
          profiles: profiles.map(profile => ({
            profileKind: profile instanceof Profile ? 'profiles' : 'memorials',
            profileId: profile._id
          }))
        }
      }

      // @ts-ignore
      const response = await this.treeManagementService.create(data) as IFamilyTree;
      const tree = new FamilyTree(this.rootStore, this, response);

      this.addToArray(tree);
      profiles.forEach(profile => {
        profile.familyTreeId = response._id;
      });

      return tree;
    } catch (err) {
      console.log(err.message);
      throw new Error('FamilyTreeStore.leaveTree() - ' + err);
    }
  }

  @action
  async resetTree(treeId: number, profile: ProfileBaseClass) {
    try {

      const data: any = {
        action: 'reset_tree',
        value: [{
          profileKind: profile instanceof Profile ? 'profiles' : 'memorials',
          profileId: profile._id
        }]
      };

      // @ts-ignore
      const response = await this.treeManagementService.create(data) as IFamilyTree[];
      const tree = new FamilyTree(this.rootStore, this, response[0]);

      this.addToArray(tree);
      profile.familyTreeId = tree._id;

      return tree;
    } catch (err) {
      console.log(err.message);
      throw new Error('FamilyTreeStore.resetTree() - ' + err);
    }
  }

  @action
  async autoMerge(rootProfile: Profile | Memorial, connectedProfile: Profile | Memorial) {
    try {
      const treeMerger = new FamilyTreeMerger(this.rootStore, connectedProfile.kind, connectedProfile._id, rootProfile.kind, rootProfile._id);
      await treeMerger.setTreeIds(connectedProfile.familyTreeId, rootProfile.familyTreeId);
      await treeMerger.saveTree();
    } catch (err) {
      throw err;
    }
  }

}

export default FamilyTreeStore;