//Forgot password form at /forgot_password

import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Link } from 'react-router-dom';

import {
  Form
} from 'semantic-ui-react';

import FormInput from 'components/FormBox/FormInput';
import FormSubmit from 'components/FormBox/FormSubmit';
import BaseContainer from "components/Generic/baseContainer";

import AccountStore from 'state/account';


export interface Props {
  accountStore?: AccountStore;
}

@inject('accountStore', "uiStore") @observer
class ForgotPasswordPage extends React.Component<Props, {}> {

  accountStore: AccountStore;

  @observable email = '';
  @observable success = false;

  constructor(props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!; 
    //this.accountStore.initialize();
  }

  onSubmit(e) {
    e.preventDefault();
    if(!this.email) return;
    
    this.success = true

    this.accountStore.requestPasswordReset(this.email);
  }

  render() {

    if(this.success) {
      return (
        <BaseContainer>
          <h3>An E-mail with instructions on how to reset your password has been sent, if the account you entered exists.</h3>
        </BaseContainer>
      );
    }

    return (
      <BaseContainer>
        <h2 style={{textAlign:"center"}}>Reset Your Password</h2>
        <Form onSubmit={e => this.onSubmit(e)}>
          <FormInput
            type="email"
            placeholder="Enter your email"
            icon="envelope"
            value={this.email}
            onChange={e => this.email = e.target.value} />
          <FormSubmit text="Reset Password" />
        </Form>
      </BaseContainer>

    );
  }

}

export default ForgotPasswordPage;
