import {
  action,
  observable,
  runInAction,
  computed
} from 'mobx'

import { StoreBaseClass } from 'state/stores';
import RootStore from 'state/rootStore';

abstract class ObjectBaseClass {

  store: StoreBaseClass;
  rootStore: RootStore;

  constructor (rootStore: RootStore, store: StoreBaseClass) {
    this.store = store;
    this.rootStore = rootStore;
  }

  abstract get canDelete (): boolean;

  abstract get canEdit (): boolean;

  abstract remove (): Promise<void>;
}

export default ObjectBaseClass;