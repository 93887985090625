import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Route, withRouter, Link } from 'react-router-dom';
import * as qs from 'qs';

import {
  Grid,
  Header,
  Button,
  Icon,
  Divider
} from 'semantic-ui-react';

import { Profile, Memorial } from 'state/objects';
import { MemorialStore } from 'state/stores';

import NewMemorialModal from 'components/Memorial/MemorialEditModal';
import MemorialCard from 'components/Generic/MemorialCard';
import UserAvatar from 'components/Generic/Avatar';


export interface Props {
  profile: Profile;
  memorialStore?: MemorialStore;
  location: any;
}

@inject('memorialStore') @observer
class MemorialsPane extends React.Component<Props, {}> {

  memorialStore: MemorialStore;

  @observable modalOpen = false;
  @observable newMemorial: Memorial;

  constructor(props: Props, context) {
    super(props, context);

    this.memorialStore = props.memorialStore!;
    this.props.profile.fetchMemorials();
  }

  componentDidMount() {
    if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).createMemorial === "true") {
      this.startCreatingMemorial();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profile !== nextProps.profile) {
      nextProps.profile.fetchMemorials();
    }
  }

  startCreatingMemorial() {
    this.newMemorial = this.memorialStore.getNewMemorial();
    this.modalOpen = true;
  }

  renderMemorialCard(memorial: Memorial) {
    return (
      <Grid.Column key={memorial._id} computer={3} tablet={4} mobile={12}>
        {/*<MemorialCard memorial={memorial} profile={this.props.profile} size={1.3} />*/}
        <UserAvatar
            profile={memorial}
            ownerProfile={this.props.profile}
            style={"default"}
            size={156}

          />
      </Grid.Column>
    );
  }

  render() {

    const { profile } = this.props;
    const { memorials } = profile;

    return (
      <Grid>
        {
          profile.userIsOwner ? (
            <Grid.Row>
              <Grid.Column computer={9} tablet={9} mobile={6}>
                <Header as="h3" color="red">Memorials</Header>
                <Divider />
              </Grid.Column>
              <Grid.Column computer={3} tablet={3} mobile={6}>
                <Button fluid primary onClick={() => this.startCreatingMemorial()}><Icon name="plus" />Add Memorial</Button>
              </Grid.Column>
            </Grid.Row>
          ) : (
              <Grid.Row>
                <Grid.Column computer={12} tablet={12} mobile={12}>
                  <Header as="h3" color="red">Memorials</Header>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )
        }

        <Grid.Row>
          {memorials.map(m => this.renderMemorialCard(m))}
        </Grid.Row>

        {this.modalOpen && (
          <NewMemorialModal
            memorial={this.newMemorial}
            mode={"create"}
            onClose={() => this.modalOpen = false}
            open={true}
          />
        )}
      </Grid>
    );
  }

};

export default MemorialsPane;
