import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import * as moment from 'moment';

import { Notification, Profile, Memorial } from 'state/objects';

import { NotificationStore } from 'state/stores';
import AccountStore from 'state/account';

import {
  Feed,
  Button,
  Image,
  Icon
} from 'semantic-ui-react';

function capitilize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface Props {
  accountStore?: AccountStore;
  notificationStore?: NotificationStore;

  closePopup(): any;
}

@inject('accountStore', 'notificationStore') @observer
class TopbarNotifications extends React.Component<Props, {}> {

  accountStore: AccountStore;
  notificationStore: NotificationStore;

  @observable numberOfNotifications = 5;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.notificationStore = this.props.notificationStore!;
  }

  showMoreNotifications() {
    this.numberOfNotifications = this.numberOfNotifications + 5;
  }

  readNotification(notification) {
    notification.markAsRead();
    this.props.closePopup();
  }

  readAllNotifications() {
    this.notificationStore.markAllAsRead();
  }

  render() {

    const notifications = this.notificationStore.notifications.reverse().slice(0, this.numberOfNotifications);

    if (notifications.length > 0) {

      return (
        <div>

          <div style={{
            backgroundColor: '#FFF',
            position: 'fixed',
            borderBottom: '1px solid #eaeaea',
            width: '100%',
            height: '20px',
            zIndex: 10
          }}>
            <span style={{
              marginLeft: '10px',
              fontWeight: 700
            }}>
              Notifications
            </span>
            <a onClick={() => this.readAllNotifications()}
              style={{
                float: 'right',
                marginRight: '10px',
                cursor: 'pointer'
              }}>
              Mark All as Read
            </a>
          </div>

          <Feed style={{
            overflowY: 'scroll',
            maxHeight: '500px',
            margin: 0,
            padding: '25px 0 0 10px'
          }}>
            {notifications.map((not, i) => this.renderNotification(not, i))}
            {this.numberOfNotifications < this.notificationStore.notifications.length && (
              <div
                onClick={() => this.showMoreNotifications()}
                id="yolo"
                style={{ textAlign: 'center', color: "#4183c4", cursor: "pointer" }}
              >
                <Icon id="yolo2" name="plus" size="large" />
                Show more...
              </div>
            )}
          </Feed>
        </div>
      );
    }

    return (
      <div style={{ padding: "5px" }}>
        You have not received any notifications yet!
      </div>
    );
  }

  renderNotification(notification: Notification, index: number) {

    const buttonStyle = {
      height: 'none'
    };

    let profile: Profile | null = null;
    let message: string | JSX.Element = '';
    let image: string = '';
    let url: string = '';
    let description: string = '';
    let error = false;

    try {
      switch (notification.type) {
        case 'connection':
          profile = notification.connection!.contact!;
          image = profile.avatar;
          url = profile.url;
          //description = notification.connection!.type;
          message = 'wants to connect with you';
          break;

        case 'memorial_photo':
          profile = notification.photo!.owner!;
          image = notification.photo!.url;
          url = `/memorial/${notification.photo!.parentId}/gallery#photos`;
          message = 'uploaded a photo to one of your memorials';
          break;

        case 'memorial_video':
          profile = notification.video!.owner!;
          image = profile.avatar;
          url = `/memorial/${notification.video!.parentId}/gallery#videos`;
          message = 'uploaded a video to one of your memorials';
          break;

        case 'guestbook_entry':
          profile = notification.guestbookEntry!.author!;
          image = profile.avatar;
          url = `/memorial/${notification.guestbookEntry!.memorialId}/guestbook#${notification.relatedId}`;
          message = 'posted a guestbook entry on one of your memorials';
          break;

        case 'guestbook_comment':
          profile = notification.comment!.author!;
          image = profile.avatar;
          url = `/memorial/${notification.comment!.memorial!._id}/guestbook#${notification.comment!.parentId}`;
          message = 'posted a comment to one of your guestbook entries';
          break;

        case 'guestbook_comment_reply':
          profile = notification.comment!.author!;
          image = profile.avatar;
          url = `/memorial/${notification.comment!.memorial!._id}/guestbook#${notification.comment!.parentId}`;
          message = 'posted a comment to a guestbook entry you also commented on';
          break;

        case 'familyTreeJoinRequest':
          profile = notification.treeJoin!.requestingUserProfile!;
          if (notification.treeJoin!.targetProfile instanceof Memorial) {
            message =
              <span>
                wants to connect&nbsp;
              <Link to={notification.treeJoin!.requestingProfile!.url}>
                  {notification.treeJoin!.requestingProfile!.displayName}
                </Link>
                's FamilyTree with your memorial&nbsp;
              <Link to={notification.treeJoin!.targetProfile!.url}>
                  {notification.treeJoin!.targetProfile!.displayName}
                </Link>
                's FamilyTree.
            </span>;
          } else {
            message =
              <span>
                wants to connect&nbsp;
              <Link to={notification.treeJoin!.requestingProfile!.url}>
                  {notification.treeJoin!.requestingProfile!.displayName}
                </Link>
                's FamilyTree with your Profile's FamilyTree.
            </span>;
          }
          image = profile!.avatar;
          url = `/jointrees/review/${notification.treeJoin!._id}`;
          break;

        case 'familyTreeJoinAccept':
          profile = notification.familyTreeData!.confirmedByProfile!;
          if (notification.familyTreeData!.requestingUserProfile!.userIsOwner) {
            message =
              <span>
                has accepted your request to merge &nbsp;
              <Link to={notification.familyTreeData!.requestingProfile!.url}>
                  {notification.familyTreeData!.requestingProfile!.displayName}
                </Link>'s FamilyTree with&nbsp;
              <Link to={notification.familyTreeData!.targetProfile!.url}>
                  {notification.familyTreeData!.targetProfile!.displayName}
                </Link>
                's FamilyTree.
            </span>
          } else {
            message =
              <span>
                has accepted&nbsp;
              <Link to={notification.familyTreeData!.requestingUserProfile!.url}>
                  {notification.familyTreeData!.requestingUserProfile!.displayName}
                </Link>
                's request to join one of your FamilyTrees.
            </span>
          }
          break;
        case 'familyTreeJoinDecline':
          profile = notification.familyTreeData!.confirmedByProfile!;
          if (notification.familyTreeData!.requestingUserProfile!.userIsOwner) {
            message =
              <span>
                has declined your request to merge &nbsp;
              <Link to={notification.familyTreeData!.requestingProfile!.url}>
                  {notification.familyTreeData!.requestingProfile!.displayName}
                </Link>'s FamilyTree with&nbsp;
              <Link to={notification.familyTreeData!.targetProfile!.url}>
                  {notification.familyTreeData!.targetProfile!.displayName}
                </Link>
                's FamilyTree.
            </span>
          } else {
            message =
              <span>
                has declined&nbsp;
              <Link to={notification.familyTreeData!.requestingUserProfile!.url}>
                  {notification.familyTreeData!.requestingUserProfile!.displayName}
                </Link>
                's request to join one of your FamilyTrees.
            </span>
          }
          break;


          /*
          profile = notification.treeJoin!.requestingUserProfile!;
            if (notification.treeJoin!.targetProfile instanceof Memorial) {
              if (notification.treeJoin!.requestingProfile!.userIsOwner) {
                message = 
                  <span>
                    wants to connect&nbsp;
                    <Link to={notification.treeJoin!.requestingProfile!.url}>
                      {notification.treeJoin!.requestingProfile!.displayName}
                    </Link>
                    's FamilyTree with your memorial&nbsp;
                    <Link to={notification.treeJoin!.targetProfile!.url}>
                      {notification.treeJoin!.targetProfile!.displayName}
                    </Link>
                    's FamilyTree.
                  </span>;
              } else {
                message =
                  <span>
                    wants to connect&nbsp;
                    <Link to={notification.treeJoin!.requestingProfile!.url}>
                      {notification.treeJoin!.requestingProfile!.displayName}
                    </Link>
                    's FamilyTree with your Profile's FamilyTree.
                  </span>;
              }
              image = profile!.avatar;
              url = `/jointrees/review/${notification.treeJoin!._id}`;
              break;
    
            case 'familyTreeJoinAccept':
              profile = notification.familyTreeData!.confirmedByProfile!;
              if (notification.familyTreeData!.requestingUserProfile!.userIsOwner) {
                message =
                  <span>
                    has accepted your request to merge &nbsp;
                    <Link to={notification.familyTreeData!.requestingProfile!.url}>
                      {notification.familyTreeData!.requestingProfile!.displayName}
                    </Link>'s FamilyTree with&nbsp;
                    <Link to={notification.treeJoin!.targetProfile!.url}>
                      {notification.treeJoin!.targetProfile!.displayName}
                    </Link>
                    's FamilyTree.
                  </span>
              } else {
                message =
                  <span>
                    has accepted&nbsp;
                    <Link to={notification.familyTreeData!.requestingUserProfile!.url}>
                      {notification.familyTreeData!.requestingUserProfile!.displayName}
                    </Link>
                    's request to join one of your FamilyTrees.
                  </span>
              }
    
              /*
              profile = notification.treeJoin!.requestingUserProfile!;
                if (notification.treeJoin!.targetProfile instanceof Memorial) {
                  if (notification.treeJoin!.requestingProfile!.userIsOwner) {
                    message = 
                      <span>
                        has accepted your request to merge your Profile's FamilyTree with their memorial&nbsp;
                        <Link to={notification.treeJoin!.targetProfile!.url}>
                          {notification.treeJoin!.targetProfile!.displayName}
                        </Link>
                        's FamilyTree.
                      </span>;
                  } else {
                    message = 
                      <span>
                        has accepted your request to merge&nbsp;
                        <Link to={notification.treeJoin!.requestingProfile!.url}>
                          {notification.treeJoin!.requestingProfile!.displayName}
                        </Link>
                        's FamilyTree with their memorial&nbsp;
                        <Link to={notification.treeJoin!.targetProfile!.url}>
                          {notification.treeJoin!.targetProfile!.displayName}
                        </Link>
                        's FamilyTree.
                      </span>;
                  }          
                } else {
                  if (notification.treeJoin!.requestingProfile!.userIsOwner) {
                    message = 
                      <span>
                        has accepted your request to merge your Profile's FamilyTree with their Profile's FamilyTree.
                      </span>;
                  } else {
                    message = 
                      <span>
                        has accepted your request to merge&nbsp;
                        <Link to={notification.treeJoin!.requestingProfile!.url}>
                          {notification.treeJoin!.requestingProfile!.displayName}
                        </Link>
                        's FamilyTree with their Profile's FamilyTree.
                      </span>;
                  }
                } */
          image = profile!.avatar;
          url = notification.familyTreeData!.requestingProfile!.url + '/familytree';
          break;
      }
    } catch (err) {
      error = true;
      message = "Could not load notification";
    }

    if (error) {
      return (
        <Feed.Event
          key={notification._id}
          offset={-71}
        >

          <Feed.Label>
            <Icon name="exclamation" />
          </Feed.Label>

          <Feed.Content>
            <Feed.Summary>

              &nbsp;{message}

              <Feed.Date>{`${moment(notification.createdAt).fromNow()}`}</Feed.Date>

            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      )
    }

    return (
      <Feed.Event
        key={notification._id}
        as={HashLink}
        to={url}
        offset={-71}
        onClick={() => this.readNotification(notification)}
        style={{ opacity: notification.read ? .7 : 1 }}
      >

        <Feed.Label>
          <Image src={image} />
        </Feed.Label>

        <Feed.Content>
          <Feed.Summary>

            <Feed.User as={Link} to={profile!.url}>
                {profile!.displayName}
            </Feed.User>

            &nbsp;{message}

            <Feed.Date>{`${moment(notification.createdAt).fromNow()}`}</Feed.Date>

          </Feed.Summary>

          {notification.type === 'connection' && (
            <Feed.Extra>
              <Button.Group size="tiny">
                <Button primary style={{ buttonStyle }} onClick={() => this.notificationStore.acceptConnection(notification._id)}>
                  Accept
                </Button>
                <Button style={{ buttonStyle }} onClick={() => this.notificationStore.declineConnection(notification._id)}>
                  Decline
                </Button>
              </Button.Group>
            </Feed.Extra>
          )}
        </Feed.Content>
      </Feed.Event>
    );
  }
};

export default TopbarNotifications;
