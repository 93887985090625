import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';

import pick from 'lodash/pick';
import omit from 'lodash/omit';
import extend from 'lodash/extend';
import remove from 'lodash/remove';

import { createViewModel, IViewModel } from 'mobx-utils'
import * as moment from 'moment';
import { Service } from '@feathersjs/feathers';
import { IMemorial } from 'state/apiClient/ServiceTypes';
import { service } from 'state/apiClient';
import coverPlaceholder from 'images/memorials_background.jpeg';
import {
  PhotoStore,
  VideoStore,
  GuestbookEntryStore,
  MemorialStore
} from 'state/stores';
import { accountStore } from 'state';
import ProfileBaseClass from './profileBaseClass';
import {
  Profile,
  Video,
  Photo,
  GuestbookEntry
} from 'state/objects';
import { MemorialForm } from 'state/stores/forms';
import RootStore from 'state/rootStore';

interface ISavedState {
  firstName: string,
  lastName: string,
  dateOfBirth: moment.Moment
  placeOfBirth: string
}

/** Class for the Memorial profile */
class Memorial extends ProfileBaseClass {

  @observable obituary: string = '';
  @observable biography: string = '';
  @observable dateOfPassing: moment.Moment;
  @observable placeOfPassing: string = '';
  @observable placeOfResting: string = '';
  @observable ownerId: number = 0;
  @observable adminIds: number[] = [];
  @observable viewingPermission: 'public' | 'connections' | 'private' = 'public';
  @observable allowedProfileIds: number[] = [];

  @observable featured: boolean = false;

  kind: 'memorials' = 'memorials';

  @observable contributorIds: number[] = [];

  service: Service<IMemorial>;

  store: MemorialStore;

  baseUrl = '/memorials';

  @observable editModel: Memorial & IViewModel<Memorial>;

  /**
   * 
   * @param store - Store holding memorial
   * @param memorial - Memorial data, in case of existing memorial
   */
  constructor(rootStore: RootStore, store: MemorialStore, memorial?: IMemorial) {
    super(rootStore);
    if (store) {
      this.store = store;
    }

    this.service = service('memorials');

    if (memorial) {
      const extendData = omit(memorial, ['ownerProfile']);
      Object.assign(this, extendData);
      this.finishedLoading = true;
      //this.ownerProfile = new Profile(memorial.ownerProfile);
    }
    this.editModel = createViewModel(this);
  }

  /** Cover image  */
  @computed
  get cover(): string {
    return this.coverUrl ? this.coverUrl : coverPlaceholder;
  }

  @computed
  get url(): string {
    return `/memorial/${this._id}`
  }

  /** E.g. 1964 - 2002 */
  @computed
  get yearRange(): string {
    const { dateOfBirth, dateOfPassing } = this;
    if (dateOfBirth && dateOfPassing) {
      return `${dateOfBirth.year()} - ${dateOfPassing.year()}`;
    } 
    if (dateOfBirth) {
      return `? - ${dateOfBirth.year()}`;
    } 
    if (dateOfPassing){ 
      return `${dateOfPassing.year()} - ?`;
    }
    return "";
  }

  /** String describing how long the person lived, e.g. 34 years or 15 weeks */
  @computed
  get ageString(): string {

    const { dateOfBirth, dateOfPassing } = this;

    if (!dateOfBirth || !dateOfBirth.isValid()) {
      return '';
    }

    if (!dateOfPassing || !dateOfPassing.isValid()) {
      return '';
    }

    const differenceInDays = dateOfPassing.diff(dateOfBirth, 'days');
    if (isNaN(differenceInDays)) {
      return '';
    }
    if (differenceInDays < 7) {
      return `${differenceInDays} days old`;
    }

    const differenceInWeeks = dateOfPassing.diff(dateOfBirth, 'weeks');
    if (differenceInWeeks < 52) {
      return `${differenceInWeeks} weeks old`;
    }

    const differenceInYears = dateOfPassing.diff(dateOfBirth, 'years');
    return `${differenceInYears} years old`;
  }

  /** Return whether logged in user is owner of the memorial */
  @computed
  get userIsCreator(): boolean {
    if (!accountStore.myProfile || !this.ownerId) {
      return false;
    }
    return this.ownerId === accountStore.myProfile._id;
  }

  @computed
  get userIsAdmin(): boolean {
    const myProfile = accountStore.myProfile;
    if (!myProfile) {
      return false;
    }
    return this.adminIds.indexOf(myProfile._id) > -1;
  }

  @computed
  get userIsOwner(): boolean {
    return this.userIsAdmin;
  }

  /** @todo Awaiting memorial relation functionality */
  @computed
  get relationToUser() {
    return null;
  }

  relationshipToProfile(profile: Profile): string {
    const { familyTree } = profile;
    if (!familyTree) {
      this.rootStore.familyTreeStore.fetchById(profile.familyTreeId);
    }

    return computed(() => {
      if (familyTree) {
        const memorialInFamilyTree = familyTree.persons.find(p => p.connectedPageId === this._id);
        const profileInFamilyTree = familyTree.persons.find(p => p.connectedPageId === profile._id);
        if (memorialInFamilyTree && profileInFamilyTree) {
          return memorialInFamilyTree.relationshipToPerson(profileInFamilyTree);
        }
      }
      return '';
    }).get();
  }

  @computed
  get ownerProfile(): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.ownerId);
  }

  @computed
  get photos(): Photo[] {
    return this.rootStore.photoStore.photos
      .filter(p => p.kind === 'memorials' && p.parentId === this._id)
      .sort((left, right) => left.createdAt.diff(right.createdAt))
      .reverse();
  }

  @computed
  get videos(): Video[] {
    return this.rootStore.videoStore.videos
      .filter(v => v.parentId === this._id)
      .sort((left, right) => left.createdAt.diff(right.createdAt))
      .reverse();
  }

  @computed
  get memories(): GuestbookEntry[] {
    return this.rootStore.guestbookEntryStore.entries
      .filter(m => m.memorialId === this._id)
      .sort((left, right) => left.createdAt.diff(right.createdAt))
      .reverse();
  }

  @computed
  get allowedProfiles(): Profile[] {
    return this.rootStore.profileStore.profiles
      .filter(p => this.allowedProfileIds.indexOf(p._id) > -1)
      .sort((left, right) => this.allowedProfileIds.indexOf(left._id) < this.allowedProfileIds.indexOf(right._id) ? -1 : 1);
  }

  @computed
  get admins(): Profile[] {
    return this.rootStore.profileStore.profiles
      .filter(p => this.adminIds.indexOf(p._id) > -1)
      .sort((left, right) => this.adminIds.indexOf(left._id) < this.adminIds.indexOf(right._id) ? -1 : 1)
      .filter(p => p._id !== this.ownerId);
  }

  @computed
  get contributors(): Profile[] {
    return this.rootStore.profileStore.profiles.filter(p => this.contributorIds.indexOf(p._id) > -1);
  }

  @action
  async fetchPhotos(): Promise<Photo[]> {
    return this.rootStore.photoStore.fetchByMemorialId(this._id);
  }

  @action
  async fetchVideos(): Promise<Video[]> {
    return this.rootStore.videoStore.fetchByMemorialId(this._id);
  }

  @action
  async fetchMemories(): Promise<GuestbookEntry[]> {
    return this.rootStore.guestbookEntryStore.fetchByMemorialId(this._id);
  }

  @action
  async fetchContributors(): Promise<Profile[]> {
    return this.rootStore.profileStore.fetchByIds(this.contributorIds);
  }

  /**
   * Fetches memorial from server
   * @param id id number of the memorial.
   */
  @action
  async fetch(id: number) {
    this._id = id;

    try {
      const response = await this.service.get!(this._id);

      runInAction(`profile ${this._id} fetched`, () => {
        const extendData = omit(response, ['ownerProfile']);
        extend(this, extendData);
        this.finishedLoading = true;
        this.editModel = createViewModel(this);

        //this.ownerProfile = new Profile(response.ownerProfile);
        //this.contributorStore.fetchAll();
      });
    } catch (err) {
      throw new Error('Memorial.fetch() ' + err);
    }
  }

  /** Returns the form needed to edit the memorial */
  getForm(): Promise<any> {
    const form = new MemorialForm(this as any);
    return form.loadRules();
  }

  /** Creates memorial or uploads changes to server. Don't use directly. Save through the form object */
  @action
  async saveEdit() {

    this.loading = true;
    const body = pick(this.editModel, [
      'firstName',
      'lastName',
      'gender',
      'dateOfBirth',
      'dateOfPassing',
      'placeOfBirth',
      'placeOfPassing',
      'placeOfResting',
      'obituary',
      'biography',
      'isPublic',
      'viewingPermission',
      'allowedProfileIds',
      'adminIds'
    ]);

    //done because of inexplicable bug when feathers tries to JSON.stringify the body
    body.allowedProfileIds = toJS(body.allowedProfileIds);
    body.adminIds = toJS(body.adminIds);

    try {
      let response;
      let isNew = false;
      if (this._id) {
        // @ts-ignore
        response = await this.service.patch!(this._id, body, {});
      } else {
        isNew = true;
        response = await this.service.create(body as any, {});
      }

      runInAction(`memorial ${this._id} updated`, () => {
        const extendData = omit(response, ['ownerProfile']);
        extend(this, response);
        this.editModel.reset();

        if (isNew) {
          this.store.add(response);
          this.uploadAvatar()
            .then(() => {
              this.uploadCover();
            });
        }
      });
    } catch (err) {
      throw new Error('Memorial.saveEdit()' + err);
    } finally {
      this.loading = false;
    }
  }

  /** Only internal use */
  @action
  resetEdit() {
    this.editModel.reset();
  }

  /** Deletes the memorial */
  @action
  async remove() {
    return this.store.remove(this._id);
  }

  @action
  async changeOwner(profileId: number): Promise<Memorial> {
    return this.store.changeOwner(this._id, profileId);
  }
}

export default Memorial;
