import { Application, HooksObject } from '@feathersjs/feathers';
import serviceTypes from '../ServiceTypes';
import {
  debug
} from 'feathers-hooks-common';
import { renameField } from 'state/apiClient/hooks';
import {
  rename,
  remove,
  negate,
  parseDateFromClient,
  parseDateFromServer,
  setField
} from './hooks';

const renameBefore = [
  //renameField('id', '_id')
];

const renameAfter = [
  //renameField('_id', 'id')
];

const hooks: HooksObject = {
  before: {
    all: [ ],
    find: [],
    get: [],
    create: [
      ...renameBefore
    ],
    update: [ ...renameBefore ],
    patch: [
      ...renameBefore,
    ],
    remove: [ ]
  },

  after: {
    all: [
      ...renameAfter,
      parseDateFromServer('createdAt')
    ],
    find: [
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function (app: Application<serviceTypes>) {
  const guestbookService = app.service('guestbook-entries');
  guestbookService.hooks(hooks);
};
