import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import pick from 'lodash/pick';
  
import { IPhoto } from 'state/apiClient/ServiceTypes';
import { PhotoStore } from 'state/stores';
import { Moment } from 'moment';
import ObjectBaseClass from './objectBaseClass';
import { 
  Profile,
  Memorial
} from 'state/objects';
import { accountStore } from 'state';
import RootStore from 'state/rootStore';

class Photo extends ObjectBaseClass {

  store: PhotoStore;

  @observable _id: number;
  @observable url: string;
  @observable createdAt: Moment;
  
  @observable ownerId: number;
  @observable parentId: number;
  @observable kind = 'memorials';

  /**
   * Creates new photo object from data fetched from server. NOT USED FOR UPLOADING NEW PHOTO.
   * @param store - Store holding the photo.
   * @param photo - Photo data
   */
  constructor (rootStore: RootStore, store: PhotoStore, photo: IPhoto) {
    super(rootStore, store);
    
    const data = pick(photo, [
      'url',
      'createdAt',
      '_id',
      'ownerId',
      'parentId'
    ]);

    Object.assign(this, data);
  }

  @computed 
  get owner (): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.ownerId);
  }

  @computed
  get isOwner (): boolean {
    const { myProfile } = this.rootStore.accountStore;
    if (!myProfile) {
      return false;
    }
    return myProfile === this.owner;
  }

  @computed 
  get memorial (): Memorial | undefined {
    return this.rootStore.memorialStore.memorials.find(m => m._id === this.parentId);
  }

  /** Whether or not the user is allowed to delete the photo */
  @computed
  get canDelete (): boolean {
    const profile = accountStore.myProfile;

    if (!profile) {
      return false;
    }

    return profile._id === this.ownerId
           || profile._id === this.ownerId;
  }

  get canEdit (): boolean {
    return false;
  }

  /** Deletes the photo from server */
  @action
  remove () {
    return this.store.remove(this._id);
  }
}

export default Photo;