
const relationshipNames = {
  '0': {
    neutral: 'Child',
    male: 'Son',
    female: 'Daughter'
  },

  '00': {
    neutral: 'Grandchild',
    male: 'Grandson',
    female: 'Granddaugther'
  },
  
  '000': {
    neutral: 'Great-grandchild',
    male: 'Great-grandson',
    female: 'Great-granddaugther'
  },
  
  '1': {
    neutral: 'Parent',
    male: 'Father',
    female: 'Mother'
  },

  '2': {
    neutral: 'Partner'
  },

  '11': {
    neutral: 'Grandparent',
    male: 'Grandfather',
    female: 'Grandmother'
  },

  '10': {
    neutral: 'Sibling',
    male: 'Brother',
    female: 'Sister'
  },

  '110': {
    neutral: 'Aunt/Uncle',
    male: 'Uncle',
    female: 'Aunt'
  },

  '100': {
    neutral: 'Niece/Nephew',
    male: 'Nephew',
    female: 'Niece'
  },

  '111': {
    neutral: 'Great-grandparent',
    male: 'Great-grandfather',
    female: 'Great-grandmother'
  },

  '1100': {
    neutral: 'Cousin'
  },

  '1111': {
    neutral: 'Great-great-grandparent',
    male: 'Great-great-grandfather',
    female: 'Great-great-grandmother'
  },

  '11000': {
    neutral: 'Cousin Once Removed'
  },
};

interface IRelationshipNames {
  neutral: string;
  male: string;
  female: string;
}

const relationships = new Map<string, IRelationshipNames>();

for (let key in relationshipNames) {
  if (relationshipNames.hasOwnProperty(key)) {
    const relation = relationshipNames[key];

    relationships.set(key, {
      neutral: relation.neutral,
      male: relation.male || relation.neutral,
      female: relation.female || relation.neutral
    });
  }
}

export default relationships;