import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';

import {
  Form,
  Button,
  Icon,
  Divider,
  Radio,
  Loader
} from 'semantic-ui-react';

import FormInput from '../formInput';
import FormSubmit from 'components/FormBox/FormSubmit';
import BaseContainer from "components/Generic/baseContainer";

import uiStore from 'state/ui';
import AccountStore from 'state/account';
import RegisterForm from 'state/stores/forms/registerForm';


interface Props {
  uiStore?: uiStore;
  accountStore?: AccountStore;
  history?: any;
  onClose: any;
}

@inject('accountStore', "uiStore") @observer
class SignupForm extends React.Component<Props, {}> {

  accountStore: AccountStore;
  uiStore: uiStore;

  @observable error: string = "";
  @observable success = false;

  @observable form: RegisterForm;

  constructor(props, context) {
    super(props, context);

    this.form = new RegisterForm();
    this.accountStore = this.props.accountStore!;
    this.uiStore = this.props.uiStore!;

  }

  componentDidMount() {
    if (this.accountStore.myProfile && this.accountStore.myProfile._id) {
      return this.props.history.push('/profile/' + this.accountStore.myProfile._id);
    }
  }

  onClose() {
  }

  onSubmit(e) {
    e.preventDefault();
    //if (this.password !== this.passwordConfirm) return;

    const fields = this.form.form.fields;

    this.accountStore.register(
      fields.firstName.value,
      fields.lastName.value,
      fields.gender.value,
      fields.email.value,
      fields.password.value
    )
      .then(() => {
        this.success = true;
      })
      .catch(error => {
        if (error.name === 'Conflict') {
          // @ts-ignore
          fields.email.error = 'An account with this email already exist';
        }
      });
  }

  render() {

    if (this.success) {
      return (
        <BaseContainer>
          <div style={{ paddingBottom: "50px" }}>
            <h1 className="memtree-cursive">
              Success!
            </h1>
            <h3 style={{ textAlign: "center" }}>
              Please check your E-mail to activate your account!
            </h3>
          </div>
        </BaseContainer>
      );
    }

    const { fields } = this.form.form;

    return (
      <BaseContainer>
        <div>
          <h1 className="memtree-cursive">
            Sign up to MemoriaTree
          </h1>
          {/*
          <Button fluid className="login-facebook" href="/auth/facebook" color="facebook">
            <div style={{ padding: "6px" }}>
              <Icon name='facebook official' />
              Sign up with Facebook
            </div>
          </Button>
          
          <div style={{ padding: "10px" }}></div>
          <Button fluid color="google plus">
            <Icon name='google' />
            Sign up with Google
          </Button>
          */}
          <Divider horizontal style={{ textTransform: 'none' }} ></Divider>

          {/*<h3 style={{ textAlign: "center", paddingBottom: "20px" }}>Sign up with E-mail</h3>*/}

          <Form onSubmit={e => this.onSubmit(e)}>
            <span className="form-error">{this.error}</span>
            <FormInput
              type="text"
              placeholder="Enter your first name"
              icon="user"
              value={fields.firstName.value}
              onChange={e => this.form.onFieldChange('firstName', e.target.value)}
              error={fields.firstName.error} />
            <FormInput
              type="text"
              placeholder="Enter your last name"
              icon="user"
              value={fields.lastName.value}
              onChange={e => this.form.onFieldChange('lastName', e.target.value)}
              error={fields.lastName.error} />
            <Form.Field>
              <Radio
                label='Male'
                name='genderRadioGroup'
                value='male'
                checked={fields.gender.value === "male"}
                onChange={(e) => {
                  this.form.onFieldChange('gender', 'male');
                }} />
              <Radio
                style={{ paddingLeft: "13px" }}
                label="Female"
                name='genderRadioGroup'
                value='female'
                checked={fields.gender.value === "female"}
                onChange={(e) => {
                  this.form.onFieldChange('gender', 'female');
                }} />
            </Form.Field>
            <FormInput
              type="email"
              placeholder="Enter your email"
              icon="envelope"
              value={fields.email.value}
              onChange={e => this.form.onFieldChange('email', e.target.value)}
              error={fields.email.error} />
            <FormInput
              type="password"
              placeholder="Enter your password"
              icon="lock"
              value={fields.password.value}
              onChange={e => this.form.onFieldChange('password', e.target.value)}
              error={fields.password.error} />
            <FormInput
              type="password"
              placeholder="Confirm your password"
              icon="lock"
              value={fields['password confirmation'].value}
              onChange={e => this.form.onFieldChange('password confirmation', e.target.value)}
              error={fields['password confirmation'].error}
            />

            <div style={{ margin: "20px 0% 20px 0%" }}>
              <FormSubmit
                disabled={!this.form.form.meta.isValid}
                text="Sign up"
              />
            </div>

            <div style={{ textAlign: "center" }}>
            <Form.Checkbox inline   
              label={
                <label>
                  By clicking “Sign up” I agree to the MemoriaTree <a href="/tos" target="_blank" onClick={e => e.stopPropagation()}>Terms of Service</a>,&nbsp;
                  <a href="/privacy" target="_blank" onClick={e => e.stopPropagation()}>Privacy Policy</a>&nbsp;
                  and <a href="/cookies" target="_blank" onClick={e => e.stopPropagation()}>Cookie Policy.</a>
                </label>
              }     
              checked={fields.acceptsTOS.value}
              onChange={() => this.form.onFieldChange('acceptsTOS', !fields.acceptsTOS.value)}
            />

              
              
              <div style={{ paddingTop: "10px" }}>
                Already have an account?&nbsp;
                <a style={{ cursor: "pointer" }} onClick={() => this.uiStore.showLoginModal = true}>
                  Sign in
                </a>
                <br />
              </div>
            </div>
          </Form>

        </div>
      </BaseContainer>
    );
  }

}

export default withRouter(SignupForm);
