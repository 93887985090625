import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';

import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import extend from 'lodash/extend';
import moment from 'moment';

import {
  Memorial
} from 'state/objects';

import FormBaseClass from './baseClass';
import { createRuleString } from './utils';


class MemorialForm extends FormBaseClass {

  memorial: Memorial;

  @observable form = {
    fields: {
      firstName: {
        value: '',
        error: null,
        rule: 'string|required|max:70'
      },
      lastName: {
        value: '',
        error: null,
        rule: 'string|required|max:70'
      },
      gender: {
        value: '',
        error: null,
        rule: 'string'
      },
      placeOfBirth: {
        value: '',
        error: null,
        rule: 'string|max:70'
      },
      placeOfPassing: {
        value: '',
        error: null,
        rule: 'string|max:70'
      },
      placeOfResting: {
        value: '',
        error: null,
        rule: 'string|max:70'
      },
      obituary: {
        value: '',
        error: null,
        rule: 'string|max:120'
      },
      biography: {
        value: '',
        error: null,
        rule: 'string|max:3000'
      },
      dateOfBirth: {
        value: null,
        error: null,
        rule: 'date'
      },
      dateOfPassing: {
        value: null,
        error: null,
        rule: 'date'
      },
      isPublic: {
        value: false,
        error: null,
        rule: 'boolean'
      },
      viewingPermission: {
        value: 'everyone',
        error: null,
        rule: 'required'
      }
    },

    meta: {
      isValid: true,
      error: <string | null>null
    }
  }

  @observable loaded: boolean = false;

  //loads information from an already existing memorial object
  //and puts into the fields
  constructor(memorial: Memorial) {
    super();
    this.memorial = memorial;
    this.memorial.resetEdit();

    const fields = pick(memorial, [
      'firstName',
      'lastName',
      'gender',
      'placeOfBirth',
      'placeOfPassing',
      'placeOfResting',
      'obituary',
      'biography',
      'dateOfPassing',
      'dateOfBirth',
      'isPublic',
      'viewingPermission'
    ]);

    for (let key in fields) {
      if (key in this.form.fields) {
        this.form.fields[key].value = fields[key];
      }
    }
  }

  //gets the rules for all the form fields from the back end
  @action
  async loadRules() {

    return this;

    // @ts-ignore
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json')

    try {
      const response = await fetch(
        '/rest-api/memorials', {
          method: 'OPTIONS',
          headers: myHeaders,
          credentials: 'same-origin',
        });

      const data = await response.json();

      const postRules = data.actions.POST;

      const rules = {
        firstName: postRules['first_name'],
        lastName: postRules['last_name'],
        gender: postRules['gender'],
        dateOfBirth: postRules['date_of_birth'],
        dateOfPassing: postRules['date_of_death'],
        obituary: postRules['quote'],
        biography: postRules['description'],
        placeOfBirth: postRules['place_of_birth'],
        placeOfPassing: postRules['place_of_death'],
        placeOfResting: postRules['place_of_resting'],
        viewingPermission: postRules['viewing_permission'],
      }

      for (let key in rules) {
        const field = rules[key];
        if (key in this.form.fields) {
          this.form.fields[key].rule = createRuleString(this.form.fields[key].rule, field);
        }
      }

      this.loaded = true;
      return this;

    } catch (err) {
      return this;
      //throw new Error('MemorialForm.loadRules() - ' + err);
    }
  }

  @action
  async save() {
    const values = mapValues(
      this.form.fields,
      o => o.value
    );

    // @ts-ignore
    if (values.dateOfBirth) values.dateOfBirth = moment(values.dateOfBirth);
    // @ts-ignore
    if (values.dateOfPassing) values.dateOfPassing = moment(values.dateOfPassing);

    try {
      extend(this.memorial.editModel, values);
      await this.memorial.saveEdit();
    } catch (err) {
      this.form.meta.error = 'Something went wrong';
      //throw new Error('MemorialForm.save() - ' + err);
    }
  }

}

export default MemorialForm;
