import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { createViewModel, IViewModel } from 'mobx-utils';
import { Moment } from 'moment';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import {
  Profile,
  Memorial
} from 'state/objects';
import { PersonForm } from 'state/stores/forms';
import Node from './node';
import FamilyTree from './tree';
import Partnership from './partnership';
import * as uuid from 'uuid/v4';
import {
  getParents,
  getPartners,
  getChildren,
  getSiblings,
  buildRelationshipString
} from './utils';
import { IFamilyTreePerson } from 'state/apiClient/ServiceTypes';
import { restClient } from 'state/apiClient';

import relationships from './relationships';

import avatarPlaceholder from 'images/avatar_placeholder.jpeg';

class Person {

  @observable _id: number;
  @observable node: Node;
  @observable tree: FamilyTree;

  @observable isRoot: boolean = false;

  @observable partnerships: Partnership[] = [];
  @observable parentPartnership: Partnership | null;

  //level in tree. root is 0. parents 1, grandparents 2, siblings 0, children -1 etc
  @observable rank: number | null = null;

  @observable _firstName: string = '';
  @observable _lastName: string = '';
  @observable _gender: 'female' | 'male' = 'female';
  @observable _dateOfBirth: Moment;
  @observable _dateOfPassing: Moment;
  @observable _avatar: string;
  @observable parentPartnershipId: number[];
  @observable partnershipIds: number[];

  avatarBlob: Blob | null;

  @observable connectedPageKind: 'memorials' | 'profiles' | '' = '';
  @observable connectedPageId: any = '';

  @observable editModel: Person & IViewModel<Person>;

  isNew = false;
  tempParent: Person | null;
  tempChild: Person | null;
  tempPartner: Person | null;

  originalData: IFamilyTreePerson;
  @observable mergeState: 'DRAGGED' | 'REPLACED' | 'INSERTED' | 'ADDED' | 'DEFAULT' = 'DEFAULT';

  constructor(tree: FamilyTree, data?: IFamilyTreePerson) {
    this.tree = tree;
    this.node = new Node(this);

    if (data) {
      Object.assign(this, data);
      this.originalData = data;
    }

    this.editModel = createViewModel(this);
  }

  @computed
  get connectedPage(): Profile | Memorial | null {
    if (!this.connectedPageId) return null;
    if (this.connectedPageKind === 'memorials') {
      return this.tree.rootStore.memorialStore.memorials.find(m => m._id === this.connectedPageId) || null;
    }
    return this.tree.rootStore.profileStore.profiles.find(p => p._id === this.connectedPageId) || null;
  }

  get editModelConnectedPage(): Profile | Memorial | null {
    if (!this.editModel.connectedPageId) return null;
    if (this.editModel.connectedPageKind === 'memorials') {
      return this.tree.rootStore.memorialStore.memorials.find(m => m._id === this.editModel.connectedPageId) || null;
    }
    return this.tree.rootStore.profileStore.profiles.find(p => p._id === this.editModel.connectedPageId) || null;
  }

  @computed
  get firstName(): string {
    if (this.connectedPage) {
      return this.connectedPage.firstName;
    }
    return this._firstName;
  }

  @computed
  get lastName(): string {
    if (this.connectedPage) {
      return this.connectedPage.lastName;
    }
    return this._lastName;
  }

  @computed
  get displayName(): string {
    if (this.connectedPage) {
      return this.connectedPage.displayName;
    }
    return this._firstName + ' ' + this._lastName;
  }

  @computed
  get gender(): 'female' | 'male' {
    if (this.connectedPage) {
      return this.connectedPage.gender;
    }
    return this._gender;
  }

  @computed
  get dateOfBirth(): Moment | null {
    if (this.connectedPage) {
      return this.connectedPage.dateOfBirth;
    }
    return this._dateOfBirth;
  }

  @computed
  get dateOfPassing(): Moment | null {
    if (this.connectedPage && this.connectedPage instanceof Memorial) {
      return this.connectedPage.dateOfPassing;
    }
    return this._dateOfPassing;
  }

  @computed
  get avatar(): string {
    if (this.connectedPage) {
      return this.connectedPage.avatar;
    }
    return this._avatar ? this._avatar : avatarPlaceholder;
  }

  @computed
  get relationshipToRoot(): string {
    return this.relationshipToPerson(this.tree.root);
  }

  relationshipToPerson(person: Person): string {
    return buildRelationshipString({
      people: this.tree.persons,
      source: person,
      target: this
    });
  }

  @computed
  get buildRelationshipString(): { gender: string, relation: string } {

    const { dist, pred } = this.tree.shortestPaths;

    let current: Person = this;
    let predecessor = pred.get(this);
    let relationString = '';
    let genderString = '';

    while (predecessor) {

      if (predecessor.parentPartnership) {
        const { parent1, parent2 } = predecessor.parentPartnership;
        if (parent1 === current || parent2 === current) {
          const parent = parent1 === current ? parent1 : parent2 as Person;

          relationString = '1' + relationString;
          genderString = (parent.gender === 'female' ? 'f' : 'm') + genderString;
        }
      }

      for (let i = 0; i < predecessor.partnerships.length; i++) {
        const partnership = predecessor.partnerships[i];
        const { parent1, parent2 } = partnership;
        if (parent1 === current || parent2 === current) {
          const parent = parent1 === current ? parent1 : parent2 as Person;
          relationString = '2' + relationString;
          genderString = (parent.gender === 'female' ? 'f' : 'm') + genderString;
        }

        for (let j = 0; j < partnership.children.length; j++) {
          const child = partnership.children[j];
          if (child === current) {
            relationString = '0' + relationString;
            genderString = (child.gender === 'female' ? 'f' : 'm') + genderString;
          }
        }
      }

      current = predecessor;
      predecessor = pred.get(current);
    }
    return {
      relation: relationString,
      gender: genderString
    };
  }

  @computed
  get isRemovable(): boolean {
    if (this.parentPartnership && this.children.length > 0) {
      return false;
    }

    if (!this.isAnscestorOfRoot && this.children.length > 0 && this.partners.length < 1) {
      return false;
    }

    if (this.isRelatedToRoot && this.partners.length > 0 && !this.partners[0].isRelatedToRoot) return false;

    return this.tree.rootIds.indexOf(this._id) === -1;
  }

  @computed
  get isRemovableFromMerge(): boolean {
    if (this.parentPartnership && this.children.length > 0) {
      return false;
    }
    if (this.children.length > 1 && this.partners.length < 1) {
      return false;
    }
    if (this.isRelatedToRoot && this.partners.length > 0 && !this.partners[0].isRelatedToRoot) return false;
    return true;
  }

  @computed
  get parents(): Person[] {
    return getParents(this);
  }

  @computed
  get partners(): Person[] {
    return getPartners(this);
  }

  @computed
  get children(): Person[] {
    return getChildren(this);
  }

  @computed
  get siblings(): Person[] {
    return getSiblings(this);
  }

  @computed
  get parentsToRender(): Person[] {
    return this.parents.filter(p => p.node.shouldRender);
  }

  @computed
  get partnersToRender(): Person[] {
    return this.partners.filter(p => p.node.shouldRender);
  }

  @computed
  get childrenToRender(): Person[] {
    return this.children.filter(p => p.node.shouldRender);
  }

  @computed
  get siblingsToRender(): Person[] {
    return this.siblings.filter(p => p.node.shouldRender);
  }

  @computed
  get isAnscestorOfRoot(): boolean {
    if (this.rank !== null && this.rank < 1) {
      return false;
    }
    return this.children
      .map(child => {
        return child.isRoot || child.isAnscestorOfRoot
      })
      .reduce((acc, curr) => acc || curr, false)
  }

  @computed
  get isOnMotherSide(): boolean {
    const data = this.children
      .map(child => {
        return child.isOnMotherSide || (child.isRoot && this.gender === 'female')
      });
    return data
      .reduce((acc, curr) => acc || curr, false)
  }

  @computed
  get hasPartner(): boolean {
    return this.partners.length > 0;
  }

  @computed
  get canAddMother(): boolean {
    if (!this.parentPartnership) return true;
    return !this.parentPartnership.mother;
  }

  @computed
  get canAddFather(): boolean {
    if (!this.parentPartnership) return true;
    return !this.parentPartnership.father;
  }

  @computed
  get canAddChild(): boolean {
    return this.rank !== null && this.rank !== undefined && this.rank <= 2;
  }

  @computed
  get canChangeGender(): boolean {
    if (this.tempPartner) return false;
    return this.partners.length < 1;
  }

  @computed
  get isRelatedToRoot(): boolean {
    if (this.isRoot) return true;
    if (this.isAnscestorOfRoot) return true;
    //if (this.partners.length === 0) return true;
    return [...this.parents]
      .map(person => person.isRoot || person.isRelatedToRoot)
      .reduce((acc, curr) => acc || curr, false);
  }

  @action
  async addParent(parent: Person): Promise<Person> {
    return this.tree.addParent(this, parent);
  }

  @action
  async addChild(child: Person): Promise<Person> {
    return this.tree.addChild(this, child);
  }

  @action
  async addPartner(partner: Person): Promise<Person> {
    return this.tree.addPartner(this, partner);
  }

  getForm(): Promise<PersonForm> {
    const form = new PersonForm(this);
    return form.loadRules();
  }

  @action
  async saveEdit() {

    if (this.isNew) {
      if (this.editModel._dateOfBirth) this._dateOfBirth = this.editModel._dateOfBirth;
      if (this.editModel._dateOfPassing) this._dateOfPassing = this.editModel._dateOfPassing;
      this.editModel.submit();

      if (this.tempParent) {
        await this.tempParent.addChild(this);
        this.tempParent = null;
      } else if (this.tempChild) {
        await this.tempChild.addParent(this);
        this.tempChild = null;
      } else if (this.tempPartner) {
        await this.tempPartner.addPartner(this);
        this.tempPartner = null;
      }
      this.isNew = false;
    } else {
      await this.tree.editPerson(this);
      this.editModel.reset();
    }
  }

  @action
  async uploadAvatar(file?: Blob) {
    if (!file && !this.avatarBlob) return;

    //person does not yet exist, only update locally
    if (!this._id) {
      const image = URL.createObjectURL(file);
      this._avatar = image;
      this.avatarBlob = file!;
      return;
    }

    if (!file) {
      file = this.avatarBlob!;
    }

    const data = new FormData();
    data.append('avatar', file);

    try {
      const response = await restClient(`family-tree-people/${this._id}`, {
        body: data,
        method: 'PATCH'
      });

      runInAction('avatar uploaded', () => {
        if (this.avatarBlob) {
          this.avatarBlob = null;
          URL.revokeObjectURL(this._avatar);
        }
        this._avatar = response._avatar;
      })
    } catch (err) {
      throw new Error('Person.uploadAvatar() - ' + err);
    }
  }

  /** Only used internally */
  @action
  resetEdit() {
    this.editModel.reset();
  }

  @action
  connectPage() { }

  @action
  resetMerge() {
    Object.assign(this, this.originalData);
    this.mergeState = 'DEFAULT';
  }

  @action
  remove() {
    if (this.isRemovable) {
      this.tree.removePerson(this);
    }
  }

  toJSON() {
    return pick(this, [
      '_id',
      '_firstName',
      '_lastName',
      '_gender',
      '_dateOfBirth',
      '_dateOfPassing',
      'connectedPageKind',
      'connectedPageId',
      'parentPartnershipId',
      'partnershipIds'
    ]);
  }

  toMergeJson() {
    return Object.assign({}, this.toJSON(), {
      ...(this.parentPartnership && { parentPartnershipId: this.parentPartnership._id }),
      partnershipIds: this.partnerships.map(p => p._id),
      newNode: this.mergeState === 'DRAGGED' || this.mergeState === 'INSERTED' || this.mergeState === 'REPLACED'
    });
  }
}

export default Person;
