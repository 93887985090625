import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import {
  Grid,
  Button,
  Header,
  Divider,
  Image
} from 'semantic-ui-react';

import Dropzone from 'react-dropzone';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';

const styles = {
  dropzone: {
    text: {
      lineHeight: '55px',
      textAlign: 'center' as 'center'
    },
    normal: {
      width: '80%',
      height: 75,
      borderWidth: 2,
      borderColor: '#666',
      borderStyle: 'dashed',
      borderRadius: 5,
      cursor: 'pointer',
      margin: "auto",
    },
    accept: {
      borderStyle: 'solid',
      borderColor: '#6c6',
      color: '#6c6',
      backgroundColor: '#eee'
    },
    reject: {
      borderStyle: 'solid',
      borderColor: '#c66',
      color: '#c66',
      backgroundColor: '#eee'
    }
  },
  container: {

  }
};

interface ImageUploaderProps {
  /** Urls to photos to chose from instead of uploading a new */
  photos?: string[];
  aspectRatio: number;
  onUpload(file: Blob): any;
}

@observer
class ImageUploader extends React.Component<ImageUploaderProps, {}> {

  @observable file: string = '';
  @observable rotation: number | string = 0;
  cropper: any;

  constructor(props, context) {
    super(props, context);
  }

  onDrop(files) {

    if (this.file) //prevent memory leak
      window.URL.revokeObjectURL(this.file);

    this.file = files[0].preview;
  }

  componentWillUnmount() {
    if (this.file) //prevent memory leak
      window.URL.revokeObjectURL(this.file);
  }

  rotate(degree: number | string) {
    this.rotation = degree;
    this.cropper.rotateTo(degree);
  }

  submit() {
    this.cropper.getCroppedCanvas().toBlob(blob => {
      this.props.onUpload(blob);
    });
  }

  renderThumbnail(url: string) {
    return (
      <Image
        key={url}
        src={url}
        onClick={() => this.file = url}
        style={{ objectFit: 'cover', height: 150 }}
      />
    );
  }

  render() {

    const photos = this.props.photos;

    return (

      <Grid >
        <Grid.Row >
          <Grid.Column width={12} centered>

            {!this.file ? (
              <Grid.Row centered >

                <Dropzone
                  onDrop={this.onDrop.bind(this)}
                  accept="image/jpeg, image/png"
                  style={styles.dropzone.normal}
                  activeStyle={styles.dropzone.accept}
                  rejectStyle={styles.dropzone.reject}
                >
                  <h4 style={styles.dropzone.text}>Click here or drop an image from your computer to upload</h4>
                </Dropzone>
              </Grid.Row>
            ) : (

                <Grid centered>

                  <Grid.Column centered width={12}>
                    <Header style={{ textAlign: "center" }} inverted as="h2">Edit and upload image</Header>
                  </Grid.Column>

                  <Grid.Column mobile={12} tablet={8} computer={8}>
                    <Cropper
                      ref={cropper => this.cropper = cropper}
                      src={this.file}
                      //style={{width: this.file}}
                      aspectRatio={this.props.aspectRatio}
                      viewMode={1}
                      modal={false}
                      background={false}
                      preview=".img-preview"
                      style={{ width: '95%', maxHeight: 400 }}

                    />
                  </Grid.Column>

                  <Grid.Column mobile={12} tablet={4} computer={4} >
                    <Grid>
                      <Grid.Column centered width={12} only="computer tablet" >
                        <div className="img-preview" style={{ margin: "auto", width: "100%", height: "200px", overflow: "hidden" }} />
                      </Grid.Column>

                      {/*}
                    <Grid.Column width={12}>
                      <label htmlFor="rotationSlider">
                        {`Rotation: ${this.rotation}°`}
                      </label>
                      <input
                        id="rotationSlider"
                        type="range"
                        max="180"
                        min="-180"
                        step="1"
                        value={this.rotation}
                        onChange={e => this.rotate(e.currentTarget.value)}
                      />
                    </Grid.Column>*/}

                      <Grid.Column width={12}>
                        <Button fluid primary onClick={() => this.submit()} style={{ marginBottom: "7px" }}>
                          Upload
                      </Button>
                        <Button fluid onClick={() => this.file = ''} style={{ marginBottom: "7px" }}>
                          Discard
                      </Button>
                      </Grid.Column>

                    </Grid>
                  </Grid.Column>
                </Grid>
              )}
          </Grid.Column>
        </Grid.Row>

        {photos && photos.length > 0 && (
          <Grid.Row>
            <Grid.Column centered width={12}>
              <Header style={{ textAlign: "center" }} inverted as="h2">Or pick one of your pictures</Header>
              <Divider inverted />
            </Grid.Column>

            <Grid.Column width={12}>
              <Image.Group size="small">
                {photos.map(i => this.renderThumbnail(i))}
              </Image.Group>
            </Grid.Column>
          </Grid.Row>
        )}

      </Grid>
    );
  }
}



export default ImageUploader;