import * as React from 'react';
import { observer, inject } from "mobx-react";
import { Link } from 'react-router-dom';

import {
  Icon,
  Button,
  Grid,
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import { Memorial } from 'state/objects';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderArrow from 'components/Generic/SliderArrow';
import MemorialCard from 'components/Generic/MemorialCard';
import MemorialsPane from 'components/Profile/MemorialsPane';
import UserAvatar from 'components/Generic/Avatar';

export interface Props {
  memorials: Memorial[];
  accountStore?: AccountStore;
  whiteText?: boolean;
}

@inject('accountStore') @observer
class FeaturedMemorials extends React.Component<Props, {}> {

  accountStore: AccountStore;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
  }

  renderMemorialCard(memorial: Memorial) {
    return (
      <Grid.Column key={memorial._id} computer={3} tablet={3} mobile={6}>
        {/*<MemorialCard memorial={memorial} size={1.5} />*/}
        <UserAvatar
          profile={memorial}
          style={"default"}
          size={180}
          nameColor={this.props.whiteText ? "text-white" : ""}
          dateColor={this.props.whiteText ? "text-white": ""}
        />
      </Grid.Column>
    );
  }

  renderFeaturedGroups(memorials: Memorial[]) {
    const groups: any = [];
    for (let i = 0; i < memorials.length; i = i + 4) {
      groups.push(
        <div key={i}>
          <Grid>
            <Grid.Row>
              {memorials
                .slice(i, i + 4)
                .map(m => this.renderMemorialCard(m))
              }
            </Grid.Row>
          </Grid>
        </div>
      );
    }
    return groups;

  }

  render() {

    return (
      <Grid style={{ padding: "3rem 0rem 3rem 0rem" }}>

        <Grid.Row>
          <Grid.Column>
            <div style={{ padding: "0rem 0 2rem 0" }}>
              <h1 
                className="memtree-cursive" 
                style={{
                  ...(this.props.whiteText && {color: "white"}),
                  fontSize: "62px" 
                }}>
                Sample Memorials
              </h1 >
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ padding: "0", marginTop: "0px" }}>
          <Grid.Column width={12} style={{ minWidth: '100%' }}>
            <Slider
              dots={false}
              infinite={true}
              arrows={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              prevArrow={<SliderArrow direction="left" />}
              nextArrow={<SliderArrow direction="right" />}
            >
              {this.renderFeaturedGroups(this.props.memorials)}
            </Slider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered columns={1}>
          <Grid.Column computer={3} tablet={3} mobile={6}>
            <div style={{ padding: "2rem 0 1rem 0" }}>
              {this.accountStore.myProfile ? (
                <Link to={this.accountStore.myProfile.url}>
                  <Button fluid primary><Icon name="plus" />Create Memorial</Button>
                  {/*<Link to="/store">
                  <Button fluid style={{ backgroundColor: "#D4AF37" }} ><Icon name="plus" />Feature My Memorial</Button>
                  </Link>*/}
                </Link>
                
              ) : (
                  <Link to="/signup">
                    <Button fluid primary><Icon name="plus" />Create Memorial</Button>
                  </Link>
                )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

};

export default FeaturedMemorials;
