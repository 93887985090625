import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import {
  Card,
  Image,
  Icon,
  Button,
  Grid,
  Segment,
  Dimmer,
  Loader,
  Divider,
  List,
  Item,
  Label
} from 'semantic-ui-react';

import heartImage from 'images/heart-quote.png';


export interface Props {
  itemName: string;
  descr: string;
  price: string;
  image?: any;
}

const StoreItem = (props: Props) => {

  return (
  
      <Item style={{padding:"14px 0 14px 0"}}>
        <Item.Image src='https://react.semantic-ui.com/images/wireframe/image.png' />

        <Item.Content>
          <Item.Header as='a'>{props.itemName}</Item.Header>
          <Item.Meta>
            <span className='cinema'>{props.price}</span>
          </Item.Meta>
          <Item.Description>
            {props.descr}
          </Item.Description>
          <Item.Extra>
           <Button icon><Icon name="shopping cart" /> Add to cart</Button>
          </Item.Extra>
        </Item.Content>
      </Item>
  );
};

export default observer(StoreItem);
