import {
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import find from 'lodash/find';
import remove from 'lodash/remove';
import StoreBaseClass from 'state/stores/storeBaseClass';
import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import { IGuestbookEntry, IComment } from 'state/apiClient/ServiceTypes';
import {
  Memorial,
  GuestbookEntry
} from 'state/objects';
import RootStore from 'state/rootStore';

/** Store containing memories/guestbook entries posted to a memorial */
class GuestbookEntryStore extends StoreBaseClass {

  service: Service<IGuestbookEntry>;

  @observable entries: GuestbookEntry[] = [];

  /**
   * Creates a new store
   * @param memorial - Memorial the memories belong to
   */
  constructor (rootStore: RootStore) {
    super (rootStore);
    this.service = service('guestbook-entries');
  }

  /** Fetches all memories posted to memorial from server */
  @action
  async fetchAll (): Promise<GuestbookEntry[]> {
    throw new Error('MemoryStore.fetchAll() not implemented');
  }

  @action
  async fetchById (id: number): Promise<GuestbookEntry> {
    try {
      const response = await this.service.get(id);
      await this.rootStore.profileStore.fetchById(response.authorId);
      return this.addToArray(response);
    } catch (err) {
      throw new Error('MemoryStore.fetchById() - ' + err);
    }
  }

  @action
  async fetchByMemorialId (id: number): Promise<GuestbookEntry[]> {
    try {
      const params = {
        query: {
          memorialId: id
        }
      };
      const response = await this.service.find(params) as IGuestbookEntry[];

      const memoryIds = response.map(m => m._id);
      this.clearRemoved(id, memoryIds);
      
      const profileIds = response.map(m => m.authorId)
      await this.rootStore.profileStore.fetchByIds(profileIds);
      
      const memories = response.map(m => this.addToArray(m));
      
      return memories;
    } catch (err) {
      throw new Error('MemoryStore.fetchByMemoryId() - ' + err);
    }
  }

  /**
   * Creates a memory on the server
   * @param title - Title text
   * @param text - Main text
   */
  @action
  async add (memorialId: number, text: string): Promise<GuestbookEntry> {

    const body = {
      memorialId: memorialId,
      text
    };

    const params = { url: { profileId: memorialId }};

    try {
      const response = await this.service.create(body, params);
      const memory = this.addToArray(response);
      return memory;
    } catch (err) {
      throw new Error('MemoryStore.add() ' + err);
    }
  }

  @action
  addToArray (data: IGuestbookEntry): GuestbookEntry {
    let entry = find(this.entries, c => c._id === data._id);

    if (entry) {
      Object.assign(entry, data);
    } else {
      entry = new GuestbookEntry(this.rootStore, this, data);
      this.entries.push(entry);
    }
    return entry;
  }

  @action
  clearRemoved (memorialId: number, memoryIds: number []) {
    remove(this.entries, m => m.memorialId === memorialId && memoryIds.indexOf(m._id) < 0);
  }

  /**
   * Deletes a memory from the server
   * @param id - id number of the memory
   */
  @action
  async remove (id: number) {
    try {
      await this.service.remove(id);

      runInAction(`memory ${id} removed`, () => {
        remove(this.entries, m => m._id === id);
      });
    } catch (err) {
      throw new Error('MemoryStore.remove() ' + err);
    }
  }

  getNewMemory (memorialId?: number): GuestbookEntry {
    const data = memorialId ? { memorialId } : {}
    return new GuestbookEntry(this.rootStore, this, data as any);
  }

}

export default GuestbookEntryStore;