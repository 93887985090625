import * as React from 'react';
import { observer, inject } from "mobx-react";
import { Link } from 'react-router-dom';

import {
  Icon,
  Button,
  Grid,
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import { Memorial } from 'state/objects';
import MemorialCard from 'components/Generic/MemorialCard';
import UserAvatar from 'components/Generic/Avatar';

export interface Props {
  memorials: Memorial[];
  accountStore?: AccountStore;
}

@inject('accountStore') @observer
class RecentMemorials extends React.Component<Props, {}> {

  accountStore: AccountStore;

  constructor (props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
  }

  renderMemorialCard (memorial: Memorial) {
    return (
      <Grid.Column key={memorial._id} computer={2} tablet={3} mobile={6}>
       {/*} <MemorialCard memorial={memorial} size={1}/>*/}
        <UserAvatar
          profile={memorial}
          style={"default"}
          size={120}
        />
      </Grid.Column> 
    );
  }

  render() {    
    return (
      <Grid style = {{padding: "1rem 1rem 1rem 1rem"}}>
        <Grid.Row>
          <Grid.Column>
            <h1 className="memtree-cursive">
              Recent Memorials
            </h1 >
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{padding:"0", marginTop:"0px"}}>
          {this.props.memorials.slice(0, 12).map(m => this.renderMemorialCard(m))}
        </Grid.Row>
        <Grid.Row centered columns={1} >
          <Grid.Column computer={3} tablet={3} mobile={6}>
            {this.accountStore.myProfile ? (
              <Link to={`/profile/${this.accountStore.profileId}/memorials?createMemorial=true`}>
                <Button fluid primary><Icon name="plus" />Create Memorial</Button>
              </Link>       
            ) : (
              <Link to="/signup">
                <Button fluid primary><Icon name="plus" />Create Memorial</Button>
              </Link>
            )}           
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  
};

export default RecentMemorials;
