import * as React from 'react';
import mobx, { observable } from "mobx";
import { observer, inject } from "mobx-react";
import ReportStore from 'state/reportStore';
import {
  Button,
  Header,
  Divider,
  Grid,
  Input,
  TextArea,
  Icon,
  Modal,
  Form,
  Message
} from 'semantic-ui-react';


@inject('reportStore') @observer
class ReportModal extends React.Component<ReportModalProps, {}> {

  reportStore: ReportStore;

  constructor (props: ReportModalProps, context) {
    super(props, context);

    this.reportStore = props.reportStore!;
  }

  render () {

    const { reportStore } = this;

    if (!reportStore.modalOpen) {
      return null;
    }

    return (

      <Modal
        open
        size="tiny"
        onClose={() => reportStore.closeModal()}
      >

        <Modal.Header>
          Report Content
        </Modal.Header>

        <Modal.Content>
          <Form error>

            <Form.Field>
              <label>Message (optional):</label>
              <TextArea
                value={reportStore.message}
                onChange={e => reportStore.changeMessage(e.currentTarget.value)}
                rows={10}
                style={{
                  fontSize: '1em'
                }}
              />
              <span style={{float:"right", marginTop:"0px", fontSize:"0.9em"}}>
                {`(${reportStore.message.length}/${reportStore.MAX_MESSAGE_SIZE} characters)`}
              </span>
            </Form.Field>

          </Form>

        </Modal.Content>

        <Modal.Actions>
          <Button
            onClick={() => reportStore.closeModal()}
          >
            Cancel
          </Button>
          <Button
            primary
            loading={reportStore.loading}
            onClick={() => reportStore.submitReport()}
          >
            Submit
          </Button>
        </Modal.Actions>

      </Modal>
    );

  }
};

export interface ReportModalProps {
  reportStore?: ReportStore;
}

export default ReportModal;
