import * as React from 'react';
import { observer } from 'mobx-react';
import LocationStore from 'state/stores/locations';

import {
  Form,
  Search,
  SearchProps
} from 'semantic-ui-react';

interface Props extends SearchProps {
  onChange(any): any;
  label: string;
}

@observer
class LocationSuggest extends React.Component<Props> {

  locationStore: LocationStore;

  constructor (props, context) {
    super(props, context);
    this.locationStore = new LocationStore();
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.value !== nextProps.value) {
      this.locationStore.search(nextProps.value);
    }
  }

  render() {

    const {
      onChange,
      label,
      ...rest
    } = this.props;

    return (
      <div className="field">
        <label>{label}</label>
        <Search
          className="location-suggest"
          input={{ fluid: true, icon: false }}
          results={this.locationStore.results}
          onSearchChange={(e, d) => onChange({target: d})}
          onResultSelect={(e, d) => onChange({target: {value: d.result.title}})}
          minCharacters={3}
          {...rest}
        />
      </div>
    );
  }
}

export default LocationSuggest;
