import * as React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from "mobx-react";
import classNames from 'classnames';
import avatar from 'images/avatar_placeholder.jpeg';
import { Link } from 'react-router-dom';
import {
  Comment,
  Input,
  TextArea,
  Button,
  Label
} from 'semantic-ui-react';
import * as moment from 'moment';

@observer
class ProfileComment extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

  render () {

    const {
      children,
      author,
      date,
      url,
      image,
      actions,
      isEditing,
      ...rest
    } = this.props;

    return (

      <Comment>

        <Comment.Avatar src={image} style={{height: 'auto'}}/>

        <Comment.Content>
          <Comment.Author as={Link} to={url}>{author}</Comment.Author>
          <Comment.Metadata>{moment(date).fromNow()}</Comment.Metadata>

          <Comment.Text>
              {isEditing ? (
                <TextArea
                  style={{width: '100%'}}
                  {...rest}
                />
              ) : (
                <p>{rest.value}</p>
              )}
          </Comment.Text>

          {actions && actions.length > 0 && (
            <Comment.Actions>
              {actions}
            </Comment.Actions>
          )}
        </Comment.Content>


        {children && children.length > 0 && (
          <Comment.Group>
            {children}
          </Comment.Group>
        )}

      </Comment>
      
    );
  }
}

export default ProfileComment;
