import {
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import moment from 'moment';
import {
  Message,
  Chat
} from 'state/objects';
import RootStore from 'state/rootStore';
import { IMessage } from 'state/apiClient/ServiceTypes';

class MessageStore {

  rootStore: RootStore;
  service: Service<IMessage>;

  @observable messages: Message[] = [];

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore;
    this.service = service('messages');

    this.initEvents();
  }

  initEvents () {
    this.service.on('created', m => this.onCreated(m));
  }

  @action
  async fetchByChatId (chatId: number): Promise<Message[]> {
    try {
      const params = {
        query: { chatId }
      };
      const response = await this.service.find(params) as IMessage[];

      const profileIds = response.map(c => c.authorId);
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const messages = response.map(m => this.addToArray(m));
      return messages;
    } catch (err) {
      throw new Error('MessageStore.fetchByChatId ' + err);
    }
  }

  @action
  async fetchByIds (ids: number[]): Promise<Message[]> {
    ids = uniq(ids);
    return Promise.all(
      ids.map(id => {
        return this.service.get(id)
          .then(response => {
            const messages = this.addToArray(response);
            return messages;
          })
      })
    )
  }

  @action
  addToArray (data: IMessage): Message {
    let message = find(this.messages, m => m._id === data._id);

    if (message) {
      Object.assign(message, data);
    } else {
      message = new Message(this.rootStore, this, data);
      this.messages.push(message);
    }
    return message;
  }

  @action
  async add (chatId: number, text: string): Promise<Message> {
    try {
      const body = {
        chatId,
        text
      };
      const message = await this.service.create(body);
      return this.addToArray(message);
    } catch (err) {
      console.log(err);
      throw new Error('MessageStore.add() - ' + err);
    }
  }

  @action
  onCreated (message: IMessage) {
    message.createdAt = moment(message.createdAt);
    this.addToArray(message);
  }

}

export default MessageStore;