import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import pick from 'lodash/pick'; 
  
import { IVideo } from 'state/apiClient/ServiceTypes';
import { VideoStore } from 'state/stores';
import ObjectBaseClass from './objectBaseClass';
import {
  Profile,
  Memorial
} from 'state/objects';
import { Moment } from 'moment';
import { accountStore } from 'state';
import RootStore from 'state/rootStore';

class Video extends ObjectBaseClass {

  store: VideoStore;

  @observable _id: number;
  @observable url: string;
  @observable thumbnail: string;
  @observable createdAt: Moment;

  @observable ownerId: number;
  @observable parentId: number;
  @observable kind = 'memorials';

  /**
   * Creates new video object. Only used for already existing videos. To upload new video use the video store.
   * @param store - Store holding the video
   * @param video - Video data from server
   */
  constructor (rootStore: RootStore, store: VideoStore, video: IVideo) {
    super(rootStore, store);
    
    const data = pick(video, [
      'url',
      'createdAt',
      '_id',
      'thumbnail',
      'ownerId',
      'parentId'
    ]);

    Object.assign(this, data);
  }

  @computed 
  get owner (): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.ownerId);
  }

  @computed
  get isOwner (): boolean {
    const { myProfile } = this.rootStore.accountStore;
    if (!myProfile) {
      return false;
    }
    return myProfile === this.owner;
  }

  @computed 
  get memorial (): Memorial | undefined {
    return this.rootStore.memorialStore.memorials.find(m => m._id === this.parentId);
  }

  /** Whether or not the user is allowed to delete the video */
  @computed
  get canDelete (): boolean {
    const profile = accountStore.myProfile;

    if (!profile) {
      return false;
    }

    return profile._id === this.ownerId
           || profile._id === this.ownerId;
  }

  get canEdit (): boolean {
    return false;
  }

  /** Deletes video from server */
  @action
  remove () {
    return this.store.remove(this._id);
  }
}

export default Video;