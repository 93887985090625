import {
  debug
} from 'feathers-hooks-common';
  
import populate from 'state/apiClient/hooks/populate';

import {
  rename,
  remove,
  negate,
  setField
} from './hooks';

const populateSchema = {
  include: {
    service: 'profiles',
    nameAs: 'profile',
    parentField: 'id',
    childField: 'id'
  }
};

const resultArrayToObjects = () => context => {
  context.result = context.result.contributors
    .map(id => {
      return { id }; 
    });
};

const flattenResult = () => context => {
  context.result = context.result
    .map(contributor => {
      return contributor.profile;
    });
};

const renameBefore = [
];

const renameAfter = [
];

const hooks = {
  before: {
    all: [ ],
    find: [],
    get: [],
    create: [
      ...renameBefore
    ],
    update: [ ...renameBefore  ],
    patch: [
      ...renameBefore
    ],
    remove: [ ]
},

  after: {
    all: [
      resultArrayToObjects(),
      populate({schema:populateSchema}),
      flattenResult(),
      ...renameAfter
    ],
    find: [
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function () {

  const app = this;
  const service = app.service('memorials/:id/contributors');
  service.hooks(hooks);
};
  