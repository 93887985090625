import * as React from 'react';
import {
  Grid,
  Message,
  Container,
  Icon
} from 'semantic-ui-react';

interface NotFoundProps {
  header?: string;
  message?: string;
  icon?: string;
}

const NotFound: React.SFC<NotFoundProps> = ({header, message, icon}) => (
  <Grid className="dark-blue no-mobile-padding fill-window-height">
    <Container>
      <Message floating style={{marginTop: '40px'}} icon>
        <Icon name="warning" />
        <Message.Content>
          <Message.Header>{header || 'Not Found'}</Message.Header>
          <p>{message || 'The page you are looking for does not exist.'}</p>
        </Message.Content>
      </Message>
    </Container>
  </Grid>
);



export default NotFound;
