import MemorialForm from './memorialForm';
import ProfileForm from './profileForm';
import MemoryForm from './memoryForm';
import PersonForm from './familytreePersonForm';

export {
  MemorialForm,
  ProfileForm,
  MemoryForm,
  PersonForm
};
