import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from 'mobx-react';

import {
  Grid,
  Header,
  Image,
  Dropdown,
  Button,
  Label,
  Icon,
  Modal
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import ReportStore from 'state/reportStore';
import { Profile } from 'state/objects';
import { ConnectionStore } from 'state/stores';

import ImageUploader from 'components/ImageUploader';

export interface ProfileHeaderProps {
  profile: Profile;
  name: string;
  onAddConnection(relation: string): any;
  onEditClick(): any;

  accountStore?: AccountStore;
  connectionStore?: ConnectionStore;
  reportStore?: ReportStore;
}

@inject('accountStore', 'connectionStore', 'reportStore') @observer
class ProfileHeader extends React.Component<ProfileHeaderProps> {

  accountStore: AccountStore;
  connectionStore: ConnectionStore;

  @observable showAvatarModal = false;
  @observable showCoverModal = false;

  constructor(props: ProfileHeaderProps, context) {
    super(props, context);
    this.accountStore = props.accountStore!;
    this.connectionStore = props.connectionStore!;
  }

  uploadProfileImage(file) {
    this.closeAvatarModal();
    this.props.profile.uploadAvatar(file);
  }

  uploadBackgroundImage(file) {
    this.closeCoverModal();
    this.props.profile.uploadCover(file);
  }

  closeCoverModal() {
    this.showCoverModal = false;
  }

  closeAvatarModal() {
    this.showAvatarModal = false;
  }

  reportProfile () {
    this.props.reportStore?.openModal('profiles', this.props.profile._id);
  }

  render() {

    const { name, profile, onAddConnection, ...props } = this.props;
    const relations = [
      'Brother',
      'Sister',
      'Mother',
      'Father',
      'Other'
    ];
    const { relationToUser } = profile;

    let connectionArea = (
      <Button
        primary style={{ minWidth: "100px", }}
        onClick={() => this.connectionStore.sendRequest(profile._id, "other")}
      >
        <Icon name="users" />
        Add Connection
        {/*capitalize(relationToUser.relation)*/}
      </Button>
    );

    if (relationToUser) {
      if (relationToUser.status === 'pending') {
        connectionArea = (
          <Button
            color="red" size="small" icon="close"
            label={{ basic: true, content: 'Request Pending' }} labelPosition='left'
            style={{ float: "right" }}
            onClick={() => this.connectionStore.removeConnection(relationToUser.id)}
          />
        );
      } else if (relationToUser.status === 'requested') {
        connectionArea = (
          <Dropdown
            id="answer-request-dropdown"
            className="icon red small"
            button primary labeled
            icon="users" text="Answer Request">
            <Dropdown.Menu>
              <Dropdown.Item text="Accept Connection" onClick={() => this.connectionStore.acceptConnection(relationToUser.id)} />
              <Dropdown.Item text="Decline Connection" onClick={() => this.connectionStore.declineConnection(relationToUser.id)} />
            </Dropdown.Menu>
            {/*<div>
              <Label basic>Connect as '{relationToUser.relation}'
              <Button.Group size="tiny">
                <Button primary icon="check" onClick={() => connectionStore.acceptConnection(relationToUser.id)}/>
                <Button icon="close" onClick={() => connectionStore.declineConnection(relationToUser.id)}/>
              </Button.Group>
              </Label>
            </div>*/}
          </Dropdown>
        );
      } else if (relationToUser.status === 'accepted') {
        connectionArea = (
          <Button style={{
            minWidth: "100px",
            backgroundColor: "#fff",
            cursor: "default",
            pointerEvents: "none!important",
            border: "1px solid"
          }}>
            Connected
          </Button>
        );
      }
    }

    return (
      //The profile
      //style={styles.avatar}
      <div style={{ backgroundColor: 'white', marginBottom: '-14px', }}>
        <div className="profile-cover-image">
          <Image fluid style={{
            display: "block",
            minWidth: "100%",
            maxHeight: "100%",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            objectFit: "cover",
          }}>
            <img src={profile.cover} />
            {profile.userIsOwner && (
              <Label as='a' corner="left" icon="write" onClick={() => this.showCoverModal = true} style={{ borderRadius: "2% 0% 0% 0%" }} />
            )}
          </Image>
        </div>

        <div className="profile-header-info">
          <Grid>
            <Grid.Row>
              <Grid.Column computer={9} tablet={9} mobile={12}>
                <div className="profile_picture">
                  <Image >
                    <img src={profile.avatar} style={{ objectFit: 'cover', height: '100%' }} />
                    {/* SHOW EDIT PICTURE BUTTON IF ITS THE USER*/}
                    {profile.userIsOwner && (
                      <Label as="a" corner="left" icon="write" onClick={() => this.showAvatarModal = true} />
                    )}
                  </Image>
                </div>
                <Header as="h1" className="profile_name">
                  {profile.displayName}
                  <Header.Subheader>{profile.dateOfBirth && profile.dateOfBirth.format('MMM D, YYYY')}</Header.Subheader>
                </Header>
              </Grid.Column>

              <Grid.Column computer={3} tablet={3} mobile={12} verticalAlign="middle" textAlign="right">
                {!profile.userIsOwner && (
                  <div>
                    {connectionArea}
                    <Dropdown icon="ellipsis horizontal" direction="left" button style={{ paddingLeft: '0.7em', paddingRight: '0.2em'}}>
                      <Dropdown.Menu>
                        <Dropdown.Item text="Report profile" onClick={() => this.reportProfile()} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                {profile.userIsOwner && (
                  <Button onClick={() => this.props.onEditClick()}><Icon name="edit" />Edit</Button>
                )}
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </div>

        {this.showAvatarModal && (
          <Modal
            open
            basic
            closeIcon
            size="large"
            onClose={() => this.closeAvatarModal()}
          >
            <Modal.Content>
              <ImageUploader
                aspectRatio={1/1}
                onUpload={img => this.uploadProfileImage(img)}
              />
            </Modal.Content>
          </Modal>
        )}

        {this.showCoverModal && (
          <Modal
            open
            basic
            closeIcon
            size="large"
            onClose={() => this.closeCoverModal()}
          >
            <Modal.Content>
              <ImageUploader
                aspectRatio={5 / 1}
                onUpload={img => this.uploadBackgroundImage(img)}
              />
            </Modal.Content>
          </Modal>
        )}

      </div>
    );
  }
}


/*
<img src="http://alpha.memoriatree.com/themes/mt/assets/images/heart-comment.png" className="heart" />
<h1 className="quote">
  <i>"</i>
  A quote
  <i>"</i>
</h1>


<div className="col-xs-3 text-right">
  {!profile.isProfile && profile.owner && false && (
    <h2 className="quoter">
      <img className="right" src="//www.gravatar.com/avatar/215963702b02d1a4d4300d2dcf363e07?s=100&d=mm" />
       {"Created by"} <br />
      {profile.owner}
  </h2>
)}
*/

export default ProfileHeader;
