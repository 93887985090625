import {
  observable,
  action,
  toJS
} from 'mobx';
import * as Validator from 'validatorjs';
import moment, { Moment } from 'moment';

abstract class FormBaseClass {

  @observable form: any;

  getFlattenedValues = (valueKey: string = 'value') => {
    let data = {};
    let form = toJS(this.form).fields;
    Object.keys(form).map(key => {
      data[key] = form[key][valueKey];
    });
    return data;
  };

  @action
  onFieldChange(field: string, value: string | boolean | Moment | Date | null) {

    value = moment.isMoment(value) ? value.toDate() : value;

    this.form.fields[field].value = value;
    var validation = new Validator(
      this.getFlattenedValues('value'),
      this.getFlattenedValues('rule')
    );

    validation.setAttributeFormatter(function (attribute) {
      const spaced = attribute.replace(/([A-Z])/g, " $1");
      return spaced.charAt(0).toUpperCase() + spaced.slice(1);
    });

    this.form.meta.isValid = validation.passes();
    this.form.fields[field].error = validation.errors.first(field);
  };

  @action
  setError = (errMsg) => {
    this.form.meta.error = errMsg;
  }

  getMaxCharacters(field: string): number | boolean {
    //rule ex: 'string|max:90|required

    if (field in this.form.fields) {
      const rules = this.form.fields[field].rule.split('|');
      for (let rule of rules) {
        if (rule.indexOf('max:') > -1) {
          return rule.substring(4);
        }
      }
    }
    return false;
  }

  abstract async loadRules();

  abstract async save();
}

export default FormBaseClass;