import * as React from 'react';
import mobx, { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Memorial, GuestbookEntry, Comment as CommentType } from 'state/objects';
import { CommentStore } from 'state/stores';
import ReportStore from 'state/reportStore';
import {
  Button,
  Header,
  Divider,
  Grid,
  Input,
  TextArea,
  Icon,
  Modal,
  Form,
  Item,
  Image,
  List,
  Comment,
  Dropdown
} from 'semantic-ui-react';

import * as moment from 'moment';
import { Link } from 'react-router-dom';
import CommentItem from './CommentItem';
import MemoryEditModal from './MemoryEditModal';

@inject('commentStore', 'reportStore') @observer
class MemoryItem extends React.Component<MemoryItemProps, {}> {

  commentStore: CommentStore;
  reportStore: ReportStore;

  @observable expanded: boolean = false;
  @observable isEditing: boolean = false;
  @observable isReplying: boolean = false;

  @observable newComment: CommentType;
  @observable newCommentFocus: boolean = false;

  @observable showDeleteModal = false;

  constructor(props: MemoryItemProps, context) {
    super(props, context);
    this.commentStore = props.commentStore!;
    this.reportStore = props.reportStore!;

    this.newComment = this.commentStore.getNewComment(this.props.memory._id, 'guestbookEntries');
  }

  setExpanded() {
    if (this.expanded == true) {
      this.expanded = false;
      this.isReplying = false;
    } else {
      this.expanded = true;
      this.isReplying = false;
    }
  }

  componentWillMount() {
    this.props.memory.fetchComments();
  }

  startEditing() {
    this.isEditing = true;
  }

  stopEditing() {
    this.isEditing = false;
  }

  startCommenting() {
    this.expanded = true;
    this.newCommentFocus = true;
    this.isReplying = true;
  }

  postComment() {
    this.newComment!.saveEdit()
    this.newComment = this.commentStore.getNewComment(this.props.memory._id, 'guestbookEntries');
    this.isReplying = false;
  }

  unFocusNewComment() {
    this.newCommentFocus = false;
  }

  deleteMemory() {

    this.props.memory.remove()
      .then(() => {
        this.showDeleteModal = false;
      });
  }

  cancelComment() {
    this.isReplying = false;
  }

  openReportModal () {
    this.reportStore.openModal('guestbookEntries', this.props.memory._id);
  }

  renderComment(comment: CommentType) {

    const { author } = comment;

    return (
      <CommentItem
        key={comment._id}
        comment={comment}
        saveEdit={() => comment.saveEdit()}
        onReply={() => this.startCommenting()}
      />
    );
  }



  render() {

    const { memory } = this.props;
    const { author } = memory;

    const memoryIsTrimmed = memory.text.length > 580;

    return (

      <Grid.Row style={{ padding: "0px 0px 15px 0px" }} id={memory._id}>

        <Grid.Column computer={2} tablet={3} mobile={5} style={{ paddingTop: "15px" }}>
          <Image src={author!.avatar} style={{ width: "100%" }} />
        </Grid.Column>

        <Grid.Column computer={3} tablet={3} mobile={7} style={{ paddingTop: "15px" }}>
          <List>
            <List.Item>{'Author: '}
              <Link to={`/profile/${author!._id}`}>{author!.displayName}</Link>
            </List.Item>
            <List.Item>
              Posted {moment(memory.createdAt).format('MMM D, YYYY')}
            </List.Item>
            <List.Item>
              <a onClick={() => this.setExpanded()}>
                <Icon name="comments" />
                {memory.comments.length} Replies
              </a>
            </List.Item>
            <List.Item>

              <a
                onClick={() => this.startCommenting()}
                style={{ marginRight: '0.75em' }}
              >
                Reply
              </a>
              {memory.canEdit && (
                <a
                  onClick={() => this.startEditing()}
                  style={{ marginRight: '0.75em' }}
                >
                  Edit
                </a>
              )}
              {memory.canDelete && (
                <a 
                  onClick={() => this.showDeleteModal = true}
                  style={{ marginRight: '0.75em' }}
                >
                  Delete
                </a>
              )}
              {!memory.canEdit && (
                <Dropdown 
                  icon="ellipsis horizontal"
                  as="a"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item text="Report comment" onClick={() => this.openReportModal()} />
                  </Dropdown.Menu>
                </Dropdown>
              )}

            </List.Item>
          </List>
        </Grid.Column>

        <Grid.Column computer={7} tablet={6} mobile={12} style={{ paddingTop: "15px" }}>

          {memoryIsTrimmed && !this.expanded ? (
            <div>
              <span>{memory.text.substring(0, 580) + '...'}</span>

              <div style={{ textAlign: 'left', color: '#4183c4' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => this.expanded = true}>
                  Show more <Icon name="plus" />
                </span>
              </div>

            </div>
          ) : (
            <div>
              <span>{memory.text}</span>

              {memoryIsTrimmed && (
                <div style={{ textAlign: 'left', color: '#4183c4' }}>
                  <span style={{ cursor: 'pointer' }} onClick={() => this.expanded = false}>
                    Show less <Icon name="minus" />
                  </span>
                </div>
              )}
            </div>
          )}

        </Grid.Column>

        <Grid.Column computer={5} tablet={6} mobile={12} />

        {this.expanded && (
          <Grid.Column computer={6} tablet={12} mobile={12} style={{ paddingTop: 15 }}>


            {/* fråga jotta om cancelediting */}
            <Comment.Group>
              {memory.comments.map(r => this.renderComment(r))}
              {this.isReplying && (
                <CommentItem
                  newComment
                  comment={this.newComment}
                  cancel={() => this.cancelComment()}
                  saveEdit={() => this.postComment()}
                  focus={this.newCommentFocus}
                  onBlur={() => this.unFocusNewComment()}
                />
              )}
            </Comment.Group>

          </Grid.Column>
        )}

        <Divider />

        {this.isEditing && (
          <MemoryEditModal
            memory={memory}
            onClose={() => this.stopEditing()}
          />
        )}

        {this.showDeleteModal && (
          <Modal
            open
            size="tiny"

          >
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
              <strong>
                This will delete this Guestbook entry. This action cannot be undone.
                Are you sure you want to continue?
               </strong>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.showDeleteModal = false}>Cancel</Button>
              <Button secondary type="submit" onClick={() => this.deleteMemory()}>Delete Entry</Button>
            </Modal.Actions>
          </Modal>
        )}
 
      </Grid.Row>
    );
  }
};

export interface MemoryItemProps {
  memory: GuestbookEntry;

  commentStore?: CommentStore;
  reportStore?: ReportStore;
}

export default MemoryItem;
