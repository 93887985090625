import {
  observable,
  action,
  computed
} from 'mobx';
import pick from 'lodash/pick';
import FamilyTree from './tree';
import RootStore from 'state/rootStore';
import { FamilyTreeStore } from 'state/stores';
import { IPendingFamilyTree } from 'state/apiClient/ServiceTypes';
import { Profile, Memorial } from 'state/objects';

class PendingFamilyTree extends FamilyTree {

  requestingUserProfileId: number;
  requestingProfileId: number;
  requestingProfileKind: 'memorials' | 'profiles';
  newPersonIds: number[];

  constructor(rootStore: RootStore, store: FamilyTreeStore, data: IPendingFamilyTree) {
    super(rootStore, store, data);

    Object.assign(this, pick(data, [
      'requestingUserProfileId',
      'requestingProfileId',
      'requestingProfileKind',
      'newPersonIds'
    ]));

    data.newPersonIds.forEach(id => {
      const person = this.persons.find(p => p._id === id);
      if (person) {
        person.mergeState = 'ADDED';
      }
    })
  }

  @computed
  get requestingUserProfile(): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.requestingUserProfileId);
  }

  @computed
  get requestingProfile(): Profile | Memorial | undefined {
    if (this.requestingProfileKind === 'profiles') {
      return this.rootStore.profileStore.profiles.find(p => p._id === this.requestingProfileId);
    } else if (this.requestingProfileKind === 'memorials') {
      return this.rootStore.memorialStore.memorials.find(m => m._id === this.requestingProfileId);
    }
    return undefined;
  }

  @computed
  get targetProfile(): Profile | Memorial | undefined {
    const userProfile = this.rootStore.accountStore.myProfile;
    if (!userProfile) return undefined;

    const parentIds = this.parentIds.map(p => p.id);
    if (parentIds.indexOf(userProfile._id) > -1) {
      return userProfile;
    }

    const userMemorials = parentIds
      .map(id => this.rootStore.memorialStore.memorials.find(m => m._id === id))
      .filter(m => !!m)
      .filter(m => m!.userIsOwner);

    if (userMemorials.length < 1) {
      return undefined;
    }

    return userMemorials[0]!;
  }

  @computed
  get originalTree(): FamilyTree | undefined {
    const userProfile = this.rootStore.accountStore.myProfile;
    if (!userProfile) return undefined;

    const parentIds = this.parentIds.map(p => p.id);
    if (parentIds.indexOf(userProfile._id) > -1) {
      return userProfile.familyTree;
    }

    const userMemorials = parentIds
      .map(id => this.rootStore.memorialStore.memorials.find(m => m._id === id))
      .filter(m => !!m)
      .filter(m => m!.userIsOwner);

    if (userMemorials.length < 1) {
      return undefined;
    }

    return userMemorials[0]!.familyTree;
  }

  @action
  async confirmMerge() {
    this.store.confirmMerge(this._id);
  }

  @action
  async declineMerge() {
    this.store.declineMerge(this._id);
  }
}

export default PendingFamilyTree;