import * as React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from "mobx-react";
import avatar from 'images/avatar_placeholder.jpeg';
import { Link } from 'react-router-dom';
import {
  Comment,
  Input,
  TextArea,
  Button,
  Label
} from 'semantic-ui-react';

import CommentContainer from './Comment';
import CommentObject from 'state/objects/comment';

@inject('accountStore') @observer
class CommentBranch extends React.Component {

  @observable isReplying = false;
  @observable reply = null;

  constructor(props, context) {
    super(props, context);
    this.commentStore = props.store;
    this.accountStore = props.accountStore;
  }

  startReplying () {
    this.isReplying = true;
    this.reply = new CommentObject (this.commentStore);
  }

  cancelReplying () {
    this.isReplying = false;
    this.reply = null;
  }

  sendReply () {
    this.commentStore.addReply(this.props.comment.id, this.reply.text);
    this.isReplying = false;
  }

  render () {

    const {
      comment
    } = this.props;

    const {
      author,
      date,
      text,
      replies
    } = comment;

    const actions = [];

    if (comment.isEditing) {
      actions.push(<Comment.Action as={Label} className="pointer" color="red" onClick={() => comment.saveEdit()}>Save</Comment.Action>);
      actions.push(<Comment.Action onClick={() => comment.stopEdit()}>Cancel</Comment.Action>);
    } else {
      actions.push(<Comment.Action onClick={() => this.startReplying()}>Reply</Comment.Action>);
      if (comment.canEdit)
        actions.push(<Comment.Action onClick={() => comment.startEditing()}>Edit</Comment.Action>);
      if (comment.canDelete)
        actions.push(<Comment.Action onClick={() => this.commentStore.deletePost(comment.id)}>Delete</Comment.Action>);
    }

    const myProfile = this.accountStore.myProfile;

    return (
      <CommentContainer
        author={`${author.firstName} ${author.lastName}`}
        url={`/profile/${author.id}`}
        date={date}
        image={author.avatar}
        value={comment.text}
        onChange={e => comment.text = e.target.value}
        isEditing={comment.isEditing}
        actions={actions}
      >
        {comment.replies.map(r => this.renderReply(r))}
        {this.isReplying && (
          <CommentContainer
            author={myProfile.displayName}
            url={`/profile/${myProfile.id}`}
            image={myProfile.avatar}
            value={this.reply.text}
            onChange={e => this.reply.text = e.target.value}
            isEditing
            actions={[
              <Comment.Action key="send" as={Label} className="pointer" color="red" onClick={() => this.sendReply()}>Send</Comment.Action>,
              <Comment.Action key="cancel" onClick={() => this.cancelReplying()}>Cancel</Comment.Action>
            ]}
          />
        )}
      </CommentContainer>
    );
  }

  renderReply (comment) {

    const { author, date } = comment;

    const actions = [];

    if (comment.isEditing) {
      actions.push(<Comment.Action key="save" as={Label} color="red" onClick={() => comment.saveEdit()}>Save</Comment.Action>);
      actions.push(<Comment.Action key="stop" onClick={() => comment.stopEdit()}>Cancel</Comment.Action>);
    } else {
      actions.push(<Comment.Action key="reply" onClick={() => this.startReplying()}>Reply</Comment.Action>);
      if (comment.canEdit)
        actions.push(<Comment.Action key="Edit" onClick={() => comment.startEditing()}>Edit</Comment.Action>);
      if (comment.canDelete)
        actions.push(<Comment.Action key="delete" onClick={() => this.commentStore.deleteReply(this.props.comment.id, comment.id)}>Delete</Comment.Action>);
    }

    return (
      <CommentContainer
        key={comment.id}
        author={`${author.firstName} ${author.lastName}`}
        url={`/profile/${author.id}`}
        date={date}
        image={author.avatar}
        value={comment.text}
        onChange={e => comment.text = e.target.value}
        isEditing={comment.isEditing}
        actions={actions}
      />
    );
  }
}

export default CommentBranch;
