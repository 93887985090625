import * as React from 'react';
import { observer } from "mobx-react";
import { LinkContainer } from 'react-router-bootstrap';

import {
  Header,
  List,
  Image,
  Dropdown,
  Button
} from 'semantic-ui-react';

import RenderSearchResult from './SearchResult';
import { Profile } from 'state/objects';

const SearchResults = ({ results, ...props }) => {

  return (

    <List divided relaxed>
      {results.map(r => renderItem(r))}
    </List>

  );

};

const renderItem = (item) => {

  const profile = item.profile;
  const key = (item.isProfile ? 'profile' : 'memorial') + `_${profile._id}`;

  const { relationToUser } = profile;

  return (

    <LinkContainer
      key={key}
      to={`${item.isProfile ? 'profile' : 'memorial'}/${profile._id}`}
      className="search-result"
    >
      <List.Item style={{ cursor: 'pointer', padding: "50px 0px" }}>
        <RenderSearchResult
          profile={profile}
          title={profile.displayName}
          description={!item.isProfile ?
            profile.yearRange
            :
            profile.relationToUser && profile.relationToUser.status === 'accepted' && "Connected"}
          image={profile.avatar}
          type={item.isProfile ? "profile" : "memorial"}
          gender={profile.gender}
        />
      </List.Item>
    </LinkContainer>

  );
};

export default observer(SearchResults);
