import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

declare const google: any;

const googleAPIKey = 'AIzaSyCBnyTzPsoug4grZ-pAhIFEBmnh32aTdv0';

class LocationStore {

  query = '';
  @observable results = [];

  constructor() {
  }

  @action
  async search(input: string) {

    if (input.length < 3) {
      this.results = [];
      return;
    };

    this.query = input;

    const params = {
      input: input,
      types: ['(cities)']
    };

    try {

      var service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(params, (predictions, status) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
          throw status;
          return;
        }

        this.results = predictions.map(prediction => ({
          title: prediction.description
        }));
      });

    } catch (err) {
      console.error('LocationStore.search()', err);
    }
  }
}

export default LocationStore;
