import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Link } from 'react-router-dom';

import {
  Form,
  Loader
} from 'semantic-ui-react';

import FormInput from 'components/FormBox/FormInput';
import FormSubmit from 'components/FormBox/FormSubmit';
import BaseContainer from "components/Generic/baseContainer";

import AccountStore from 'state/account';
import UIStore from 'state/ui';

export interface Props {
  accountStore?: AccountStore;
  uiStore?: UIStore;
  match?: any;
}

@inject('accountStore', 'uiStore') @observer
class EnterNewPasswordPage extends React.Component<Props, {}> {

  accountStore: AccountStore;
  uiStore: UIStore;

  @observable password = '';
  @observable confirmPassword = '';
  @observable success = false;
  @observable error = '';

  constructor(props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.uiStore = this.props.uiStore!;
    //this.accountStore.initialize();
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.password !== this.confirmPassword) return;
  
    this.accountStore.passwordReset(
      this.props.match!.params.token,
      this.password
    )
    .then(() => this.success = true)
    .catch(err => this.error = err);
  }

  render() {

    if(this.success) {
      return (
        <BaseContainer>
          <div style={{paddingBottom: "50px",  textAlign: "center" }}>
            <h1 className="memtree-cursive">
              Success!
            </h1>
            <h3 style={{ textAlign: "center" }}>
              Your password has been changed. &nbsp;
              <a style={{cursor:"pointer"}} onClick={() => this.uiStore.showLoginModal = true}>Log in</a>.   
            </h3>
          </div>
        </BaseContainer>
      );
    }

    return (
      <BaseContainer>
        <h1 className="memtree-cursive">
          Enter Your New Password
        </h1>
        <Form onSubmit={e => this.onSubmit(e)}>
          <FormInput
            type="password"
            placeholder="Enter your password"
            icon="lock"
            value={this.password}
            onChange={e => this.password = e.target.value} 
          />
          <FormInput
            type="password"
            placeholder="Confirm your password"
            icon="lock"
            value={this.confirmPassword}
            onChange={e => this.confirmPassword = e.target.value} 
          />
          {this.error && <span>{this.error}</span>}
          <FormSubmit text="Change Password" />
        </Form>
      </BaseContainer>

    );
  }

}

export default EnterNewPasswordPage;
