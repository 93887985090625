import * as React from 'react';
import { observable, computed } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Dropdown, Menu, Icon, Modal, Button, Popup } from 'semantic-ui-react';

import {
  scale,
  translate,
  fromObject,
  applyToPoint,
  toSVG,
  identity,
  transform,
  inverse,
} from 'transformation-matrix';

import Person from 'state/familyTree/person';

import FamilyTreeStore from 'state/familyTree/tree';

import {
  Profile,
  Memorial
} from 'state/objects';

import NameBox, { NameBoxDraggingVisual } from 'components/FamilyTree/FamilyTreeWindow/NameBox_new';
import PersonEditModal from 'components/FamilyTree/FamilyTreeWindow/personEditModal';

import { LineToParent, LineToPartner, HiddenPeopleLine } from 'components/FamilyTree/FamilyTreeWindow/renderFamilyTreeLines';
import renderingConstants from 'components/FamilyTree/FamilyTreeWindow/RenderingConstants';

function getSVGPoint(value, viewerX, viewerY): {x: number; y: number } {
  let matrix = fromObject(value);

  let inverseMatrix = inverse(matrix);
  // @ts-ignore
  return applyToPoint(inverseMatrix, { x: viewerX, y: viewerY } as myPoint);
}

interface Coords {
  x: number;
  y: number;
}

export interface FamilyTreeProps extends RouteComponentProps<any> {
  width: number;
  height: number;
  profile: Profile | Memorial;
  familyTree: FamilyTreeStore;
  mode: 'normal' | 'drag' | 'drop' | 'inactive';
  //history?: any;
}

@observer
class FamilyTree extends React.Component<FamilyTreeProps, {}> {

  @observable personClicked: Person | null = null;
  @observable personToEdit: Person | null = null;
  @observable showTutorial: boolean = false;
  @observable showConfirmWithdrawRequestModal: boolean = false;

  //**************************
  //Node menu
  //**************************
  //is set to true if the user viewing the component is the owner of the profile or owner/admin of the memorial
  userCanEdit: boolean = false;
  nodeMenuRef: any = null;
  menuX = 0;
  menuY = 0;

  //**************************
  //SVG box
  //**************************
  @observable svgX = 0;
  @observable svgY = 0;
  //For use when I have a way to get the tree x and tree y
  @observable treeWidth = 0;
  @observable treeHeight = 0;
  //********************************************* */
  @observable transformationMatrix = identity();
  dragging: boolean = false;
  zoomLevel = 1;
  coords: Coords = {
    x: 0,
    y: 0
  };
  svgDOM: SVGSVGElement;

  @observable draggingNameBox: boolean = false;

  constructor(props, context) {
    super(props, context);

    //Needed to get the event listeners to work
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.closeNodeMenu = this.closeNodeMenu.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);

    if (props.profile.userIsOwner || props.profile.userIsAdmin || props.mode === "drop") {
      this.userCanEdit = true;
    }
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove, false);
    document.addEventListener('mouseup', this.handleMouseUp, false);
    document.addEventListener('mousedown', this.closeNodeMenu);
    document.addEventListener('wheel', this.closeNodeMenu);

    window.addEventListener('resize', this.updateDimensions);

    this.svgDOM.addEventListener('wheel', this.handleMouseWheel, { passive: false });

    this.updateDimensions();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove, false);
    document.removeEventListener('mouseup', this.handleMouseUp, false);
    document.removeEventListener('mousedown', this.closeNodeMenu);
    document.removeEventListener('wheel', this.closeNodeMenu);

    window.removeEventListener('resize', this.updateDimensions);

    this.svgDOM.removeEventListener('wheel', this.handleMouseWheel);
  }

  updateDimensions() {
    var treeDivSize = document.getElementById("treeWindow")!.getBoundingClientRect();
    this.svgX = treeDivSize.width;
    this.svgY = treeDivSize.height;
  }

  //**********************************************************
  // Code for dragging and zooming the tree view
  //**********************************************************

  handleMouseDown(e: React.MouseEvent<SVGElement>) {
    if (this.draggingNameBox) return;

    this.dragging = true;

    let { left, top } = this.svgDOM.getBoundingClientRect();
    let x = e.clientX - Math.round(left);
    let y = e.clientY - Math.round(top);

    this.coords = {
      x: x,
      y: y
    };
  }

  handleMouseUp() {
    this.draggingNameBox = false;
    if (this.dragging) {
      this.dragging = false;
      this.coords = { x: 0, y: 0 };
    }
  }

  handleMouseMove(e) {
    //If we are dragging
    if (this.dragging) {
      e.preventDefault();

      let { left, top } = this.svgDOM.getBoundingClientRect();
      let x = e.clientX - Math.round(left);
      let y = e.clientY - Math.round(top);

      let start = getSVGPoint(this.transformationMatrix, this.coords.x, this.coords.y);
      let end = getSVGPoint(this.transformationMatrix, x, y);

      //Get mouse change differential 
      var xDiff = end.x - start.x,
        yDiff = end.y - start.y;
      //Update to our new coordinates 
      this.coords.x = x;
      this.coords.y = y;

      //Adjust our x,y based upon the x/y diff from before
      let matrix = transform(
        fromObject(this.transformationMatrix),
        translate(xDiff, yDiff)
      );

      this.transformationMatrix = matrix;
    }
  }

  handleMouseWheel(e: WheelEvent) {

    //console.log(e.deltaY)
    //console.log(e.deltaX)

    e.preventDefault();
    var ZOOM_STEP = .08;
    let zoomFactor;

    if (!this.isNegative(e.deltaY) && this.zoomLevel > 0.5) {
      zoomFactor = 1 - ZOOM_STEP;
      this.zoomLevel = this.zoomLevel * zoomFactor;
      this.zoom(e, zoomFactor);
    }
    if (this.isNegative(e.deltaY) && this.zoomLevel < 1.5) {
      zoomFactor = 1 + ZOOM_STEP;
      this.zoomLevel = this.zoomLevel * zoomFactor;
      this.zoom(e, zoomFactor);
    }
  }

  zoom(e, zoomFactor) {

    let { left, top } = this.svgDOM.getBoundingClientRect();
    let x = e.clientX - Math.round(left);
    let y = e.clientY - Math.round(top);

    let SVGPoint = getSVGPoint(this.transformationMatrix, x, y);

    let matrix = transform(
      fromObject(this.transformationMatrix),
      translate(SVGPoint.x, SVGPoint.y),
      scale(zoomFactor, zoomFactor),
      translate(-SVGPoint.x, -SVGPoint.y)
    );

    this.transformationMatrix = matrix;

  }

  //needed to check very small values of deltaY
  isNegative(n) {
    return ((n = +n) || 1 / n) < 0;
  }

  resetView() {

    this.transformationMatrix = identity();
    //reset zoom
    this.zoomLevel = 1;

  }

  //**********************************************************
  // Code for drag n drop
  //**********************************************************

  handleNameboxStartDrag() {
    if (this.props.mode === 'drag') {
      this.draggingNameBox = true;
    }
  }

  //**********************************************************
  // Code for adding nodes
  //**********************************************************

  addFather(person: Person) {
    this.personToEdit = new Person(this.props.familyTree);
    this.personToEdit.tempChild = person;
    this.personToEdit._gender = 'male';
    this.personToEdit.isNew = true;

    if (person.parentPartnership && person.parentPartnership.mother) {
      this.personToEdit.tempPartner = person.parentPartnership.mother;
    }

    this.personClicked = null;
  }

  addMother(person: Person) {
    this.personToEdit = new Person(this.props.familyTree);
    this.personToEdit.tempChild = person;
    this.personToEdit._gender = 'female';
    this.personToEdit.isNew = true;

    if (person.parentPartnership && person.parentPartnership.father) {
      this.personToEdit.tempPartner = person.parentPartnership.father;
    }

    this.personClicked = null;
  }

  addChild(person: Person) {
    this.personToEdit = new Person(this.props.familyTree);
    this.personToEdit.isNew = true;
    this.personToEdit.tempParent = person;
    this.personToEdit._gender = 'female';

    this.personClicked = null;
  }

  addPartner(person: Person) {
    this.personToEdit = new Person(this.props.familyTree);
    this.personToEdit.isNew = true;
    this.personToEdit.tempPartner = person;
    this.personToEdit._gender = person.gender === 'female' ? 'male' : 'female';

    this.personClicked = null;
  }

  //*********************************************************
  // Code for the menu that appears when you click node
  //*********************************************************

  setNodeMenuRef(node) {
    this.nodeMenuRef = node;
  }

  //if user can edit (is owner or admin of page), show the editing menu, else if a page is connected to the node, go to that page
  nodeClicked(person: Person, event) {

    switch (this.props.mode) {
      case "normal":
      case "drop":
        if (this.userCanEdit) {
          this.personClicked = person;
          // rect is a DOMRect object with eight properties: left, top, right, bottom, x, y, width, height
          var rect = event.currentTarget.getBoundingClientRect();
          var picDiameter = renderingConstants.pictureWidth * person.node.nodeScale * this.zoomLevel;

          this.menuX = rect.right - (rect.width - picDiameter) / 2 + 6;
          this.menuY = rect.top + picDiameter / 2 - 20;

          /*
          console.log("node stuff");
          console.log("right coordinate: " + rect.right);
          console.log("rect width: " + rect.width)
          console.log("picture width constant: " + renderingConstants.pictureWidth);
          console.log("picture width actual: " + renderingConstants.pictureWidth * person.node.nodeScale * this.zoomLevel);
          console.log("rect width minus picture width: " + (rect.width - renderingConstants.pictureWidth * person.node.nodeScale * this.zoomLevel));
          console.log("rect width minus picture width, divided by 2: " + (rect.width - renderingConstants.pictureWidth * person.node.nodeScale * this.zoomLevel) / 2);
          console.log("right coordinate minus this: " + (rect.right - (rect.width - renderingConstants.pictureWidth * person.node.nodeScale * this.zoomLevel) / 2));
          console.log("menuX should be the same: " + this.menuX);
          console.log(this.zoomLevel);
          console.log(2 - this.zoomLevel);
          */
        } else {
          if (person.connectedPage && this.props.mode === "normal") {
            this.props.history!.push(person.connectedPage.url);
          }
        }
        break;

    }
  }

  setRootPerson(person: Person) {
    const matrix = transform(
      fromObject(this.transformationMatrix),
      translate(person.node.renderX, person.node.renderY)
    );
    this.transformationMatrix = matrix;

    this.props.familyTree.setRootPerson(person);
  }

  closeNodeMenu(event) {
    //if the event indicates any mousewheel whatsoever, close menu
    if (event.deltaY) {
      this.personClicked = null;
      return;
    }
    //if the event is a click outside the node, close menu
    if (this.nodeMenuRef && !this.nodeMenuRef.contains(event.target)) {
      this.personClicked = null;
      //console.log("close menu");
      //console.log(this.personClicked);
    }
  }

  //**********************************************************
  // RENDER
  //**********************************************************

  //Renders the draggable window'
  //code should be changed so the total width and height of the SVG is based on the family tree content
  render() {

    const {
      width,
      height,
      profile
    } = this.props;

    const userCanEdit = profile instanceof Memorial ? profile.userIsAdmin : profile.userIsOwner;

    /*
    console.log("render SVG with these dimensions")
    console.log(this.svgX);
    console.log(this.svgY);
    */

    const counts = this.props.familyTree.personsToRender
      .reduce((count: Object, person) => {
        if (count.hasOwnProperty(person._id)) {
          count[person._id] = count[person._id] + 1;
        } else {
          count[person._id] = 1;
        }
        return count;
      }, {});

    /*
    console.log('NEw RENDER');
    console.log('Total:', this.props.familyTree.personsToRender.length);
    console.table(counts);
    console.log('\n');*/

    return (

      <div>

        <div className="text-blue familytree-controls" style={{ overflow: "visible", position: "relative", padding: "0", maxHeight: "0px", }}>
          <div style={{ width: "50%", float: "left", padding: "5px", margin: "0", position: "absolute", top: "0px", left: "3px" }}>
            <a onClick={() => this.resetView()}>
              <Icon name="compress" />Reset view
            </a>
          </div>
          {this.props.mode == "normal" &&
            <div style={{ width: "50%", float: "right", padding: "5px", margin: "0", position: "absolute", top: "0px", right: "6px" }}>
              <a onClick={() => this.showTutorial = true} style={{ float: "right" }}>
                <Icon name="help circle" />Help
              </a>
            </div>
          }
        </div>

        <Popup
          content={
            this.props.familyTree.lockedAsTarget ? (
              <div>
                <p>
                  This FamilyTree cannot currently be edited because a user has requested to merge one of their FamilyTrees with this FamilyTree.
                </p>
                <Button fluid primary onClick={() => { this.props.history.push(`/jointrees/review/${this.props.familyTree.lockedByTreeId}`) }}>
                  Review Request
                </Button>
              </div>
            ) : this.props.familyTree.lockedAsRequester && (
              <div>
                <p>
                  This FamilyTree cannot currently be edited because you have sent a request to merge it with another user's FamilyTree which has not yet been accepted.
                </p>
                <Button fluid primary onClick={() => this.showConfirmWithdrawRequestModal = true}>
                  Withdraw Request
                </Button>
              </div>
            )

          }
          open={this.props.familyTree.locked && this.props.profile.userIsOwner && !this.showConfirmWithdrawRequestModal}
          position='bottom center'
          trigger={<div style={{ position: "relative", bottom: "-28px" }}></div>}
        />

        {this.showConfirmWithdrawRequestModal &&
          <Modal open={true} size="tiny">
            <Modal.Header>
              Are You Sure?
            </Modal.Header>
            <Modal.Content>
              <p>
                This will withdraw your request to merge this FamilyTree with your friend's FamilyTree.
                Are you sure you want to proceed?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => { this.props.familyTree.cancelMergeRequest(); this.showConfirmWithdrawRequestModal = false; }}>
                Yes
              </Button>
              <Button secondary onClick={() => this.showConfirmWithdrawRequestModal = false}>
                No
              </Button>
            </Modal.Actions>
          </Modal>
        }

        <div
          id="treeWindow"
          style={{
            width: width,
            height: height,
            backgroundColor: "white",
            overflow: 'hidden',
            boxShadow: "inset 0px 0px 2px 0px rgba(0,0,0,0.75)"
          }}>
          <svg
            width={this.svgX}
            height={this.svgY}
            fill="yellow"
            onMouseDown={(e) => this.handleMouseDown(e)}
            ref={r => this.svgDOM = r!}
          >

            <g transform={toSVG(this.transformationMatrix)}>
              <g transform={`translate(${this.svgX / 2 - renderingConstants.pictureWidth / 2}, ${this.svgY / 2 - renderingConstants.pictureWidth / 2})`}>

                {this.props.familyTree.personsToRender.map(p => this.renderPerson(p))}
                {this.props.familyTree.partnerships
                  .filter(p => p.node.shouldRender && p.node.hasHiddenChildren)
                  .map(p => {

                    const startX = p.node.x;
                    const startY = p.node.y;
                    const endY = p.hasBothParents ? p.node.y + 110 : p.node.y + 50;
                    let endX: number;

                    const visibleChild = p.children.filter(c => c.node.shouldRender)[0];

                    if (!visibleChild) return null;
                    
                    if (visibleChild.node.middleX < startX) {
                      endX = startX + 25;
                    } else if (visibleChild.node.middleX > startX) {
                      endX = startX - 25;
                    } else {
                      if (p.isOnMotherSide) {
                        endX = startX - 25;
                      } else {
                        endX = startX + 25;
                      }
                    }

                    return (
                      <HiddenPeopleLine
                        startX={startX}
                        startY={startY}
                        endX={endX}
                        endY={endY}
                        onClick={() => this.setRootPerson(p.parent1 ? p.parent1 : p.parent2!)}
                      />
                    )
                  })
                }

              </g>
            </g>

          </svg>

          {/*this.props.familyTree.locked && this.props.profile.userIsOwner &&
            <Modal open={true} size="tiny">
              <Modal.Content>
                <p>
                  A user has requested to merge one of their FamilyTrees with this FamilyTree.
                </p>
              </Modal.Content> 
              <Modal.Actions>
                <Button fluid  onClick={() => {this.props.history.push(`/jointrees/review/${this.props.familyTree.lockedByTreeId}`)}}>
                  Review
                </Button>
              </Modal.Actions>
            </Modal>
          */}

          {this.personToEdit && (
            <PersonEditModal
              onClose={() => {
                this.personToEdit = null;
              }}
              treeRootPerson={this.props.profile}
              person={this.personToEdit}
              open
            />
          )}

          {this.personClicked && (
            <div className="familytree-menu"
              ref={(node) => this.setNodeMenuRef(node)}
              style={{
                left: this.menuX,
                top: this.menuY,
              }}
            >
              <Menu vertical >

                {/* Show the option to visit Memorial or Profile page if the person has a connected page AND the state of the tree is default (not merging)*/}
                {this.personClicked.connectedPage && this.personClicked.mergeState === "DEFAULT" && (
                  <Menu.Item onClick={() => this.props.history!.push(this.personClicked!.connectedPage!.url)}>
                    <div>
                      <Icon name='user' /> {this.personClicked.connectedPage instanceof Memorial ? "Visit Memorial" : "Visit Profile"}
                    </div>
                  </Menu.Item>
                )}

                {/* Show the option to edit the person if it's not root AND the state of the tree is default (not merging)*/}
                {!this.personClicked.isRoot && this.personClicked.mergeState === "DEFAULT" && this.props.mode !== "drop" && (
                  <Menu.Item disabled={this.props.familyTree.locked} onClick={() => this.personToEdit = this.personClicked}>
                    <div>
                      <Icon name='setting' /> Edit
                    </div>
                  </Menu.Item>
                )}

                {this.personClicked.canAddMother && (
                  <Menu.Item disabled={this.props.familyTree.locked} onClick={() => this.addMother(this.personClicked!)}>
                    <div>
                      <Icon name='user plus' /> Add Mom
                    </div>
                  </Menu.Item>
                )}

                {this.personClicked.canAddFather && (
                  <Menu.Item disabled={this.props.familyTree.locked} onClick={() => this.addFather(this.personClicked!)}>
                    <div>
                      <Icon name='user plus' /> Add Dad
                    </div>
                  </Menu.Item>
                )}

                <Menu.Item disabled={this.props.familyTree.locked} onClick={() => this.addChild(this.personClicked!)}>
                  <div>
                    <Icon name='user plus' /> Add Child
                  </div>
                </Menu.Item>

                {!this.personClicked.hasPartner && (
                  <Menu.Item disabled={this.props.familyTree.locked} onClick={() => this.addPartner(this.personClicked!)}>
                    <div>
                      <Icon name='user plus' /> Add Partner
                    </div>
                  </Menu.Item>
                )}

                {(this.personClicked.mergeState === "INSERTED" ||
                  this.personClicked.mergeState === "REPLACED") &&
                  this.personClicked.isRemovable && (
                    <Menu.Item onClick={() => this.personClicked!.tree.familyTreeMerger.resetTarget(this.personClicked!)}>
                      <div>
                        <Icon name='trash' /> Remove
                    </div>
                    </Menu.Item>
                  )}

              </Menu>
            </div>
          )} {/*End this.personClicked*/}
        </div>

        {this.showTutorial && (
          <Modal
            open
            onClose={() => { this.showTutorial = false }}
            size="small"
          >
            <Modal.Content className="no-header-margin">
              <h4>Pan around the tree</h4>
              <p>
                Click and hold down the left
                mouse button anywhere in the white
                space around the tree and move the mouse to pan.
              </p>

              <h4>Zoom</h4>
              <p>
                To zoom in and out, roll the scroll wheel.
              </p>

              <h4>Reset view</h4>
              <p>
                To reset the family tree to its original position and zoom,
                click "Reset view" in the bottom left corner.
              </p>

              <h4>Visit a person's Profile or Memorial</h4>
              <p>
                If you are an owner of the tree, click on a person to
                bring out the Editing Menu and choose Visit Profile/Memorial.
                Else, simply click on the person whose Profile/Memorial you would like to visit.
                Note: This feature is only available on persons who are connected to a Profile/Memorial page, who can be recognized by their thicker borders.
              </p>

              <h4>Edit a person's details</h4>
              <p>
                If you are an owner of the tree, click on a person to bring out the Editing Menu and choose Edit.
              </p>

              <h4>Connect a person to a Profile or Memorial</h4>
              <p>
                Click on a person to bring out the Editing Menu and choose Edit.
              </p>

              <h4>Delete a person</h4>
              <p>
                Click on a person to bring out the Editing Menu and choose Edit. Then choose "Delete".
                Persons who have both parents and children cannot be deleted, only edited.
              </p>
            </Modal.Content>
          </Modal>
        )}

        <NameBoxDraggingVisual />

      </div>
    );
  }


  //This renders an individual node. It gets proeprties like width and height from the node object attached to the person. 
  //Change this to a general setting? Unnecessary to have on every object when the numbers are the same
  renderPerson(person: Person) {

    const { node } = person;

    return (

      <g key={person._id}>

        {person.partners.map(partner => (
          <LineToPartner
            key={`${person._id}-${partner._id}`}
            person={person.node}
            partner={partner.node}
          />
        ))}

        {person.parentPartnership && (
          person.parentPartnership.node.shouldRender ? (
            <LineToParent
              personX={person.node.middleX}
              personY={person.node.topY}
              parentsX={person.parentPartnership!.node.x}
              parentsY={person.parentPartnership!.node.y}
            />
          ) : (
              <HiddenPeopleLine
                startX={node.middleX}
                startY={node.topY}
                endX={node.middleX}
                endY={node.topY - 10}
                onClick={() => this.setRootPerson(person)}
              />
            )
        )}

        <NameBox
          x={person.node.renderX}
          y={person.node.renderY}
          mode={this.props.mode}
          person={person}

          userCanEdit={this.userCanEdit}
          openMenu={(event) => this.nodeClicked(person, event)}
          setRoot={(event) => this.setRootPerson(person)}

          onDragStart={() => this.handleNameboxStartDrag()}
        />

      </g>


    );
  }


};

export default withRouter(FamilyTree);


