import {
  observable,
  action,
  computed
} from 'mobx';
import { createViewModel, IViewModel } from 'mobx-utils';
import { Moment } from 'moment';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import {
  Profile,
  Memorial
} from 'state/objects';
import { PersonForm } from 'state/stores/forms';
import Node from './node';
import PartnerNode from './partnerNode';
import FamilyTree from './tree';
import Person from './person';
import * as uuid from 'uuid/v4';
import { IFamilyTreePartnership } from 'state/apiClient/ServiceTypes';

class Partnership {

  @observable _id: number;
  @observable parent1Id: number;
  @observable parent2Id: number;
  @observable childIds: number[];
  @observable familyTreeId: number;

  @observable parent1: Person | null;
  @observable parent2: Person | null;

  @observable children: Person[] = [];

  @observable node: PartnerNode;

  constructor(data?: Partial<IFamilyTreePartnership>) {
    Object.assign(this, data);
    this.node = new PartnerNode(this);
  }

  @computed
  get mother(): Person | null {
    if (this.parent1 && this.parent1.gender === 'female') {
      return this.parent1;
    }
    if (this.parent2 && this.parent2.gender === 'female') {
      return this.parent2;
    }
    if (this.parent1 && this.parent2) {
      return this.parent1;
    }
    return null;
  }

  @computed
  get father(): Person | null {
    if (this.parent2 && this.parent2.gender === 'male') {
      return this.parent2;
    }
    if (this.parent1 && this.parent1.gender === 'male') {
      return this.parent1;
    }
    if (this.parent1 && this.parent2) {
      return this.parent2;
    }
    return null;
  }

  @computed
  get hasBothParents(): boolean {
    return !!(this.parent1 && this.parent2);
  }

  @computed
  get hasChildren(): boolean {
    return this.children.length > 0;
  }

  @computed
  get isOnMotherSide(): boolean {
    const { parent1, parent2 } = this;
    if (parent1 && parent1.isOnMotherSide) return true;
    if (parent2 && parent2.isOnMotherSide) return true;
    return false;
  }

  @action
  addChild(child: Person) {
    this.children.push(child);
    child.parentPartnership = this;
  }

  getPartner(person: Person): Person | null {
    const { parent1, parent2 } = this;
    if (parent1 && parent1 !== person) return parent1;
    if (parent2 && parent2 !== person) return parent2;
    return null;
  }

  toJSON() {
    return pick(this, [
      '_id',
      'parent1Id',
      'parent2Id',
      'familyTreeId',
      'childIds'
    ]);
  }

  toMergeJson() {
    return Object.assign({}, this.toJSON(), {
      ...(this.parent1 && { parent1Id: this.parent1._id }),
      ...(this.parent2 && { parent2Id: this.parent2._id }),
      childIds: this.children.map(c => c._id),
    })
  }
}

export default Partnership;
