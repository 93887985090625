import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link, withRouter } from 'react-router-dom';

import {
  Grid,
  Container,
  Menu,
  Icon,
  Input
} from 'semantic-ui-react';

import SearchBar from '../Search/SearchBar';

const styles = {
  buttons: {
    width: '13%'
  },
  logo: {
    width: '74%',
    paddingTop: 5,
    paddingBottom: 5
  },
  menu: {
    height: '71px'
  }
};

@inject('accountStore', 'uiStore') @observer
class MobileTopBar extends React.Component {

  @observable isSearching = false;

  constructor(props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore;
    this.uiStore = this.props.uiStore;
  }

  render() {
    this.accountStore = this.props.accountStore;
    return (
      <Menu fixed="top" widths={3} style={styles.menu}>
        <Menu.Item
          style={styles.buttons}
          onClick={() => this.uiStore.toggleSidebar()}
        >
          <Icon name="content" />
        </Menu.Item>

        <Menu.Item
          style={styles.logo}>
          {this.isSearching ? (
            <SearchBar style={{width: '100%'}} />
          ) : (
            <Link to="/" className="logo top button-height left pointer">MemoriaTree</Link>
          )}
        </Menu.Item>

        <Menu.Item
          style={styles.buttons}
          onClick={() => this.isSearching = !this.isSearching}
        >
          <Icon name="search" />
        </Menu.Item>
      </Menu>
    );
  }

  signOut() {
    this.accountStore.logout(() => this.props.history.push('/'));
  }

};

export default withRouter(MobileTopBar);
