import * as React from 'react';

import {
  Grid, 
  Container,
  Message
} from 'semantic-ui-react';

function CookiesInfo (): JSX.Element {
  return (
    <Grid className="dark-blue no-mobile-padding fill-window-height">
    <Container>
      <Message floating style={{marginTop: '40px'}}>
        <Message.Content>

        <h1>Cookie Information for MemoriaTree</h1>
        <p>This is the Cookie Information for MemoriaTree, accessible from www.memoriatree.com</p>
        <h5>What Are Cookies</h5>
        <p>As is common practice with almost all professional websites, this site uses cookies, which are tiny files that are downloaded to your computer, tablet, mobile phone or similar devices to improve your experience. There are two types of cookies: persistent cookies and session cookies. The persistent cookies are stored on your device for a longer period of time after your browser has been closed. Session cookies are stored temporarily in your browser and are erased automatically after your browser is closed. There are also third-party cookies, meaning that the cookies do not originate from us, but from someone else. This page describes what information the cookies gather, how we use them and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the functionality of the site.</p>
        <h5>What Is Browser Storage</h5>
        <p>In addition to cookies, the web browser offers an alternative to store data on your device called Local Storage and Session Storage. This works mostly like the cookies, with some technical differences, such as not being automatically sent to the website with each request allowing to store more data without affecting performance. Local Storage is the equivalent of persistent cookies, the data in there is stored on your device after the browser has been closed, and Session Storage is the equivalent of session cookies, the data is cleared when the browser is closed.</p>
        <h5>How We Use Cookies And Local Storage</h5>
        <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. If you block or limit cookies on our site, it is possible that you will not be able to use all of the services and functions on the site. </p>
        <h5>The Data We Store on Your Device</h5>
        <ul>
          <li>Login related<br /><br />We store some data in Local Storage when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page or when you restart your browser. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</li>
        </ul>
        <h5>Third Party Cookies</h5>
        <p>We are currently not using any cookies originating from third parties.</p>
        <h5>Disabling Cookies</h5>
        <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies can result in disabling certain functionality and features of this site. </p>
        <h5>More Information</h5>
        <p>If you are still looking for more information, or if you have any questions regarding this Cookie Information, please contact us through one of our preferred contact methods:</p>
        <p><a href="mailto:support@memoriatree.com">support@memoriatree.com</a></p>
        <p>MemoriaTree AB<br />Postbox 20<br />191 21, Sollentuna<br />SWEDEN</p>
        <p>This Information was last updated on 29 December 2020.</p>

        </Message.Content>
      </Message>
    </Container>
  </Grid>
  );
}

export default CookiesInfo;