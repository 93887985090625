import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';

import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import extend from 'lodash/extend';

import moment, { Moment } from 'moment';

import {
  Profile
} from 'state/objects';

import FormBaseClass from './baseClass';
import { createRuleString } from './utils';

//The ProfileForm object contains all the fields, their values, their error messages,
//and the error rules
class ProfileForm extends FormBaseClass {

  profile: Profile;

  @observable form = {
    fields: {
      firstName: {
        value: '',
        error: null,
        rule: 'string|required|max:70'
      },
      lastName: {
        value: '',
        error: null,
        rule: 'string|required|max:70'
      },
      gender: {
        value: '',
        error: null,
        rule: 'string'
      },
      placeOfBirth: {
        value: '',
        error: null,
        rule: 'string|max:70'
      },
      dateOfBirth: {
        value: null,
        error: null,
        rule: 'date'
      },
      familyTreeViewingPermission: {
        value: 'public',
        error: null,
        rule: 'required'
      }
    },

    meta: {
      isValid: true,
      error: <string | null>null
    }
  }

  @observable loaded: boolean = false;

  //loads information from an already existing profile
  //and puts into the fields

  constructor(profile: Profile) {
    super();
    this.profile = profile;
    this.profile.resetEdit();

    const fields = pick(profile, [
      'firstName',
      'lastName',
      'gender',
      'placeOfBirth',
      'dateOfBirth',
      'familyTreeViewingPermission'
    ]);

    //apply the values from the profile 
    //object to the correct fields using a loop
    for (let key in fields) {
      if (key in this.form.fields) {
        this.form.fields[key].value = fields[key];
      }
    }
  }

  //gets the rules for all the form fields from the back end
  @action
  async loadRules() {

    return this;

    // @ts-ignore
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json')

    try {
      const response = await fetch(
        `/rest-api/profiles/${this.profile._id}`, {
          method: 'OPTIONS',
          headers: myHeaders,
          credentials: 'same-origin',
        });

      const data = await response.json();

      const postRules = data.actions.PUT;

      //renames the properties from the server to names we use in frontend
      const rules = {
        firstName: postRules['first_name'],
        lastName: postRules['last_name'],
        gender: postRules['gender'],
        dateOfBirth: postRules['date_of_birth'],
        placeOfBirth: postRules['place_of_birth'],
      }

      //creates the rules using utils.ts and applies to correct field
      for (let key in rules) {
        const field = rules[key];
        if (key in this.form.fields) {
          this.form.fields[key].rule = createRuleString(this.form.fields[key].rule, field);
        }
      }

      this.loaded = true;
      return this;

    } catch (err) {
      return this;
      //throw new Error('ProfileForm.loadRules() - ' + err);
    }
  }

  //executes when you hit "save" on the form
  @action
  async save() {
    try {
      const values = mapValues(
        this.form.fields,
        o => o.value
      );

      // @ts-ignore
      if (values.dateOfBirth) values.dateOfBirth = moment(values.dateOfBirth);

      extend(this.profile.editModel, values);
      await this.profile.saveEdit();
    } catch (err) {
      this.form.meta.error = 'Something went wrong';
      throw new Error('ProfileForm.save() - ' + err);
    }
  }


}

export default ProfileForm;

