import * as React from 'react';
import { observable, autorun } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';
import { match } from 'react-router';

import MouseBackEnd from 'react-dnd-mouse-backend';

import {
	Modal,
	Form,
	Input,
	Button,
	Image,
	Grid,
	Header,
	Card,
	Icon,
	Container,
	Message,
	Loader,
} from 'semantic-ui-react';

import BaseContainer from "components/Generic/baseContainer";

import {
	Profile,
	Memorial
} from 'state/objects';

import uiStore from 'state/ui';
import FamilyTree from 'components/FamilyTree/FamilyTreeWindow/FamilyTreeView';
import FamilyTreeMerger from 'state/familyTree/familyTreeMerger';
import { ProfileStore } from 'state/stores';
import renderingConstants from 'components/FamilyTree/FamilyTreeWindow/RenderingConstants';

import { LineToParent, LineToPartner } from 'components/FamilyTree/FamilyTreeWindow/renderFamilyTreeLines';
import NameBox from 'components/FamilyTree/FamilyTreeWindow/NameBox_new';

export interface Props {
	treeMerger: FamilyTreeMerger;
}

@observer
class NodeMergeModal extends React.Component<Props, {}> {

	@observable sourceNodeX: number;
	@observable sourceNodeY: number;
	@observable sourceNodeScale: number = 1.1;
	@observable targetNodeTrans: number = 1;
	@observable isHovering: boolean = false;

	//**************************
	//SVG box
	//**************************
	@observable svgX = 0;
	@observable svgY = 0;
	//For use when I have a way to get the tree x and tree y
	@observable treeWidth = 0;
	@observable treeHeight = 0;

	constructor(props, context) {
		super(props, context);

		//Needed to get the event listeners to work
		this.updateDimensions = this.updateDimensions.bind(this);
		this.sourceVisualDefault();
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);

		setTimeout(this.updateDimensions, 0);

		this.updateDimensions();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	componentDidUpdate() {
		this.updateDimensions();
	}

	updateDimensions() {
		if (document.getElementById("treeNodes")) {
			var treeDivSize = document.getElementById("treeNodes")!.getBoundingClientRect();
			this.svgX = treeDivSize.width;
			this.svgY = treeDivSize.height;
		}

	}

	sourceVisualHover(x: number, y: number, scale: number) {
		this.sourceNodeX = x;
		this.sourceNodeY = y;
		this.sourceNodeScale = scale;
		this.isHovering = true;
	}

	sourceVisualDefault() {
		this.sourceNodeX = -1;
		this.sourceNodeY = -0.7;
		this.sourceNodeScale = 1.1;
		this.isHovering = false;
	}

	render() {

		const {
			treeMerger
		} = this.props;

		const {
			sourceNode,
			targetNode,
		} = treeMerger;

		if (sourceNode !== null && targetNode !== null) {

			return (

				<Modal open size="tiny">

					<Modal.Header>
						What do you want to do with {sourceNode.displayName}?
					</Modal.Header>
					<Modal.Content>
						<div
							id="treeNodes"
							style={{
								width: "100%",
								height: "400px",
								backgroundColor: "white",
								overflow: 'hidden',
							}}>
							<svg
								width={this.svgX}
								height={this.svgY}
								fill="yellow"
							>
								<g transform={`
									translate(
										${this.svgX / 2 - renderingConstants.pictureWidth / 2}, 
										${this.svgY / 2 - renderingConstants.pictureWidth / 2})
									scale(0.9)
								`}>
									<g opacity={this.targetNodeTrans} >
										<NameBox
											x={0}
											y={-0.1 * targetNode.node.boxHeight}
											mode={"inactive"}
											person={targetNode}
											userCanEdit={false}
										/>
									</g>
									{this.isHovering && this.sourceNodeY === -0.9 ? (
										<g>
											<LineToParent
												personX={0 + targetNode.node.width / 2}
												personY={-0.1 * targetNode.node.boxHeight}
												parentsX={0 + sourceNode.node.width / 2}
												parentsY={this.sourceNodeY * sourceNode.node.boxHeight + sourceNode.node.height}
											/>
										</g>
									) : this.isHovering && this.sourceNodeY === 0.8 && (
										<g>
											<LineToParent
												personX={0 + sourceNode.node.width / 2}
												personY={this.sourceNodeY * sourceNode.node.boxHeight}
												parentsX={0 + targetNode.node.width / 2}
												parentsY={-0.1 * targetNode.node.boxHeight + targetNode.node.height}
											/>
										</g>
									)}
									<g transform={`scale(${this.sourceNodeScale})`} >
										<NameBox
											x={this.sourceNodeX * sourceNode.node.boxWidth}
											y={this.sourceNodeY * sourceNode.node.boxHeight}
											mode={"inactive"}
											person={sourceNode}
											userCanEdit={false}
										/>
										{!this.isHovering &&
											<text x={0} y={-75} textAnchor="middle" fill="rgb(87, 114, 144)">
												<tspan fontSize="118">
													?
											</tspan>
											</text>
										}
									</g>
									{/*
									{person.partners.map(p => this.drawLineToPartner(person.node, p.node))}
									{person.parentPartnership && person.parentPartnership.node.shouldRender && this.drawLineToParent(person.node, person.parentPartnership.node)}
									*/}
								</g>
							</svg>
						</div>

						<Button primary fluid style={{ marginBottom: "7px" }}
							onMouseOver={() => this.sourceVisualHover(0, 0.8, 1)}
							onMouseOut={() => this.sourceVisualDefault()}
							onClick={() => {
								treeMerger.addChild(sourceNode, targetNode);
								treeMerger.closeNodeMergeModal();
							}}>
							Add as Child
						</Button>

						<Button primary fluid style={{ marginBottom: "7px" }}
							onMouseOver={() => this.sourceVisualHover(0, -0.9, 1)}
							onMouseOut={() => this.sourceVisualDefault()}
							onClick={() => {
								treeMerger.addParent(sourceNode, targetNode);
								treeMerger.closeNodeMergeModal();
							}}>
							Add as {sourceNode.gender == "male" ? "Father" : "Mother"}
						</Button>

						<Button primary fluid style={{ marginBottom: "7px" }}
							onMouseOver={() => this.sourceVisualHover(1, -0.1, 1)}
							onMouseOut={() => this.sourceVisualDefault()}
							onClick={() => {
								treeMerger.addPartner(sourceNode, targetNode);
								treeMerger.closeNodeMergeModal();
							}}>
							Add as Partner
							</Button>
						{targetNode.tree.roots.indexOf(targetNode) === -1 && (
							<Button primary fluid style={{ marginBottom: "7px" }}
								onMouseOver={() => {
									this.sourceVisualHover(0, -0.1, 1);
									this.targetNodeTrans = 0;
								}}
								onMouseOut={() => {
									this.sourceVisualDefault();
									this.targetNodeTrans = 1;
								}}
								onClick={() => {
									treeMerger.replacePerson(sourceNode, targetNode);
									treeMerger.closeNodeMergeModal();
								}}>
								Replace {targetNode.displayName}
							</Button>
						)}
						<Button fluid onClick={() => {
							treeMerger.closeNodeMergeModal();
						}}>
							Cancel
						</Button>

					</Modal.Content>

				</Modal>
			);
		}
	}
}


export default NodeMergeModal