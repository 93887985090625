import * as React from 'react';
import { observable, autorun } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';
import { match } from 'react-router';


import {
  Modal,
  Form,
  Input,
  Button,
  Image,
  Grid,
  Header,
  Card,
  Icon,
  Container,
  Message,
  Loader,
  Popup,
} from 'semantic-ui-react';

import BaseContainer from "components/Generic/baseContainer";

import {
  Profile,
  Memorial
} from 'state/objects';

import uiStore from 'state/ui';
import FamilyTreeMerger from 'state/familyTree/familyTreeMerger';
import { ProfileStore, MemorialStore, FamilyTreeStore } from 'state/stores';

import FamilyTreeView from 'components/FamilyTree/FamilyTreeWindow/FamilyTreeView';
import NodeMergeModal from 'components/FamilyTree/TreeJoin/NodeMergeModal'
import TreeUsers from 'components/FamilyTree/TreeJoin/FamilyTreeUsers';

import { ProfileForm } from 'state/stores/forms';
import { FamilyTree } from 'state/familyTree';
import PendingFamilyTree from 'state/familyTree/PendingFamilyTree';

export interface Props {
  uiStore?: uiStore;
  history?: any;
  match?: any;
  profileStore?: ProfileStore;
  memorialStore?: MemorialStore;
  familyTreeStore?: FamilyTreeStore;
  mergeMode: "merge" | "review";
}

@inject('profileStore', 'memorialStore', 'familyTreeStore', 'uiStore') @observer
class TreeJoinPage extends React.Component<Props, {}> {

  height: "200";
  uiStore: uiStore;
  profileStore: ProfileStore;
  memorialStore: MemorialStore;
  familyTreeStore: FamilyTreeStore;

  @observable screenHeight: number = window.innerHeight;

  @observable requestingProfile: Profile | Memorial | undefined;
  @observable targetProfile: Profile | Memorial | undefined;
  //for merging
  @observable treeMerger: FamilyTreeMerger;
  //for reviewing
  @observable reviewNewTree: PendingFamilyTree;
  @observable reviewOldTree: FamilyTree;
  @observable requestingUser: Profile;

  @observable treesLoaded = false;

  @observable showTutorial = true;

  @observable showNodeMergeModal: boolean = false;

  @observable showConfirmMergeModal: boolean = false;
  @observable userIsOwnerOfMergeTree: boolean = false;
  @observable showCancelMergeModal: boolean = false;

  @observable showAcceptMergeModal: boolean = false;
  @observable showRejectMergeModal: boolean = false;


  constructor(props: Props, context) {
    super(props, context);
    this.uiStore = this.props.uiStore!;
    this.profileStore = this.props.profileStore!;
    this.memorialStore = this.props.memorialStore!;
    this.familyTreeStore = this.props.familyTreeStore!;

    const { params } = props.match;
    this.treeMerger = new FamilyTreeMerger(this.props.profileStore!.rootStore, params.reqType, params.requestingProfileId, params.tarType, params.targetProfileId);

  }

  componentDidUpdate(prevProps) {
    if (prevProps.mergeMode !== this.props.mergeMode) {
      return this.fetchFamilyTrees();
    }

    const prevParams = prevProps.match.params;
    const { params } = this.props.match;

    if (this.props.mergeMode === 'merge') {
      if (prevParams.requestingProfileId !== params.requestingProfileId || prevParams.targetProfileId !== params.targetProfileId) {
        return this.fetchFamilyTrees();
      }
    } else if (this.props.mergeMode === 'review') {
      if (prevParams.newTreeId !== params.newTreeId) {
        this.fetchFamilyTrees();
      }
    }
  }

  componentDidMount() {
    this.fetchFamilyTrees();
  }

  componentWillUnmount() {
    this.uiStore.blockTopBarOff();
  }

  async fetchFamilyTrees() {
    this.treesLoaded = false;
    const { params } = this.props.match;

    this.uiStore.blockTopBarOn(
      "Clicking this will quit the TreeMerger Tool without saving any changes. Are you sure you want to continue?"
    );

    //Set up view
    window.scrollTo(0, 0);
    this.screenHeight = window.innerHeight;

    //setup depending on if in merge mode or review mode
    if (this.props.mergeMode === "merge") {

      //Set up requesting profile
      let requestingProfile: Profile | Memorial;
      if (params.reqType === 'profiles') {
        requestingProfile = await this.profileStore.fetchById(params.requestingProfileId);
      } else {
        requestingProfile = await this.memorialStore.fetchById(params.requestingProfileId);
      }

      //Set up target profile
      let targetProfile: Profile | Memorial;
      if (params.tarType === 'profiles') {
        targetProfile = await this.profileStore.fetchById(params.targetProfileId);
      } else {
        targetProfile = await this.memorialStore.fetchById(params.targetProfileId);
      }

      this.requestingProfile = requestingProfile;
      this.targetProfile = targetProfile;

      await this.treeMerger.setTreeIds(requestingProfile.familyTreeId, targetProfile.familyTreeId);

      this.treesLoaded = true;

    } else if (this.props.mergeMode === "review") {

      this.reviewNewTree = await this.familyTreeStore.fetchById(params.newTreeId) as PendingFamilyTree;
      this.reviewOldTree = this.reviewNewTree.originalTree!;

      this.requestingProfile = this.reviewNewTree.requestingProfile;
      this.targetProfile = this.reviewNewTree.targetProfile;
      this.requestingUser = this.reviewNewTree.requestingUserProfile!;

      this.treesLoaded = true;

    }
  }

  confirmMergeTree() {
    this.uiStore.blockTopBarOff();

    this.treeMerger.saveTree()
      .then(tree => {
        this.userIsOwnerOfMergeTree = !tree.requestPending;
        this.showConfirmMergeModal = true;
      });

  }

  acceptMergeTree() {
    this.uiStore.blockTopBarOff();
    this.reviewNewTree.confirmMerge();
    this.showAcceptMergeModal = true;
  }

  rejectMergeTree() {
    this.uiStore.blockTopBarOff();
    this.reviewNewTree.declineMerge();
    this.showRejectMergeModal = false;
    this.exitTreeJoinPage();
  }

  //Used to return you to the profile/memorial you were joining once the process is completed/cancelled, i e the targeted profile
  exitTreeJoinPage() {
    this.uiStore.blockTopBarOff();

    if (this.targetProfile instanceof Profile) {
      this.props.history.push(`/profile/${this.targetProfile!._id}/familytree`);
    } else if (this.targetProfile instanceof Memorial) {
      this.props.history.push(`/memorial/${this.targetProfile!._id}/familytree`);
    }
  }

  render() {

    if (!this.treesLoaded) {
      return (

        <BaseContainer>
          <h3 style={{ textAlign: "center" }}>
            Loading, please wait...
          </h3>
          <div style={{ position: "relative", marginTop: "16px", paddingBottom: "70px" }}>
            <Loader active />
          </div>
        </BaseContainer>

      );
    }

    let interfaceHeight = this.screenHeight - 200;

    let
      modalHeader,
      modalTutorial,
      backButtonText,
      backButtonAction,
      continueButtonText,
      continueButtonAction;

    switch (this.props.mergeMode) {

      case "merge":
        modalHeader = "FamilyTree Merger";
        modalTutorial = <div style={{ paddingBottom: "14px" }}>
          <p>
            Drag and drop people from {this.requestingProfile!.displayName}'s
                            tree on top of people in {this.targetProfile!.displayName}'s tree to either connect them to
            or replace the targeted person.
                          </p>
          <p>
            Click on a person you have added and select Remove to undo the change.
                          </p>
          <p>
            You can also add new people to the targeted tree by clicking on people in the tree and choosing
            Add Child/Father/Mother/Partner.
                          </p>
        </div>;

        backButtonText = "Cancel";
        backButtonAction = () => this.showCancelMergeModal = true;

        continueButtonText = "Confirm Changes";
        continueButtonAction = () => this.confirmMergeTree();

        break;

      case "review":
        modalHeader = "Review Changes";
        modalTutorial = <div style={{ paddingBottom: "14px" }}>
          <p>
            {this.requestingUser!.displayName} has requested to merge {this.requestingProfile!.displayName}'s
                            FamilyTree with {this.targetProfile!.displayName} FamilyTree. To the left you can see the current version of
                            {this.targetProfile!.displayName}'s FamilyTree, and to the right you can see the new, merged version
                            proposed by {this.requestingUser!.displayName}.
                          </p>
          <p>
            If you want to accept the proposed changes and allow {this.requestingProfile!.displayName} to share the same
                            FamilyTree as {this.targetProfile!.displayName}, click "Accept and Merge Trees", otherwise click "Reject".`
                          </p>
        </div>;
        backButtonText = "Reject";
        backButtonAction = () => this.showRejectMergeModal = true;

        continueButtonText = "Accept and Merge Trees";
        continueButtonAction = () => this.acceptMergeTree();
        break;

    }

    return (

      <div style={{ minHeight: `${interfaceHeight}px` }}>


        <div >
          <Grid style={{ margin: 0 }} >

            {/*}
            <Grid.Column width={12} style={{ padding: "5px", textAlign: "center" }}>
              <h4>
                {modalTutorial}
              </h4>
    </Grid.Column>*/}


            <Grid.Column width={6} style={{ padding: 0 }}>
              <div style={{
                padding: "0 0 0 0",
                overflow: "hidden",
                maxHeight: `${interfaceHeight}px`
              }}>
                {this.props.mergeMode === "merge" ? (
                  <FamilyTreeView
                    mode="drag"
                    height={interfaceHeight}
                    width="100%"
                    profile={this.requestingProfile}
                    familyTree={this.treeMerger.dragTree}
                  />
                ) : this.props.mergeMode === "review" && (
                  <FamilyTreeView
                    mode="inactive"
                    height={interfaceHeight}
                    width="100%"
                    profile={this.requestingProfile}
                    familyTree={this.reviewOldTree}
                  />
                )}
              </div>
            </Grid.Column>

            <div style={{ overflow: "visible", position: "relative", padding: "0", maxHeight: "0px" }}>
              <Icon name="arrow right" size="massive" color="green" style={{ position: "absolute", top: `${interfaceHeight / 2 - 56}`, right: "-70px", zIndex: "1000" }} />
              <Popup
                content={
                  <div>
                    {modalTutorial}
                    <Button fluid primary onClick={() => this.showTutorial = false}>
                      Hide Tutorial
                    </Button>
                  </div>}
                open={this.showTutorial}
                pinned
                wide
                position='top center'
                trigger={<Button icon="question" onClick={() => this.showTutorial = !this.showTutorial} style={{ position: "absolute", top: `${interfaceHeight - 20}`, right: "-21", zIndex: "1000" }} />}
              />
            </div>

            <Grid.Column width={6} style={{ padding: 0 }}>
              <div style={{
                padding: "0 0 0 0",
                overflow: "hidden",
                maxHeight: `${interfaceHeight}px`
              }}>
                {this.props.mergeMode === "merge" ? (
                  <FamilyTreeView
                    mode="drop"
                    height={interfaceHeight}
                    width="100%"
                    profile={this.targetProfile}
                    familyTree={this.treeMerger.dropTree}
                  />
                ) : this.props.mergeMode === "review" && (
                  <FamilyTreeView
                    mode="inactive"
                    height={interfaceHeight}
                    width="100%"
                    profile={this.targetProfile}
                    familyTree={this.reviewNewTree}
                  />
                )}
              </div>
            </Grid.Column>

            <Grid.Row style={{
              background: "FFF",
              borderTop: "1px solid rgba(34, 36, 38, 0.15)",
              paddingTop: "7px",
            }}>

              <Grid.Column width={6}>
                <TreeUsers familyTree={this.requestingProfile!.familyTree} noLinks={true} />
              </Grid.Column>

              <Grid.Column width={6}>
                <TreeUsers familyTree={this.targetProfile!.familyTree} noLinks={true} />
              </Grid.Column>

            </Grid.Row>

          </Grid>
        </div>


        <div style={{
          background: "FFF",
          borderTop: "1px solid rgba(34, 36, 38, 0.15)",
          overflow: "auto",
        }}>

          <div style={{ width: "50%", float: "left", textAlign: "left", padding: "1rem 1rem", }}>
            <Button onClick={backButtonAction} color='red'>
              <Icon name='remove' /> {backButtonText}
            </Button>
            {/*
              FIX THIS LATER
              <Button onClick={() => this.treeMerger.switchTrees()} color='red'>
                <Icon name='remove' /> 
                Switch
              </Button>
            */}
          </div>

          <div style={{ width: "50%", float: "left", textAlign: "right", padding: "1rem 1rem", }}>
            <Button color='green'
              onClick={continueButtonAction}
              disabled={!this.treeMerger.treeCanBeSubmited && this.props.mergeMode == "merge"}
            >
              <Icon name='checkmark' /> {continueButtonText}
            </Button>
          </div>

        </div>

        {this.treeMerger.nodeMergeModalOpen && (
          <NodeMergeModal
            treeMerger={this.treeMerger}
          />
        )}

        {this.showConfirmMergeModal && (
          <Modal open size="mini">
            <Modal.Header>Success!</Modal.Header>
            <Modal.Content>
              {this.userIsOwnerOfMergeTree ? (
                <p>
                  {this.requestingProfile!.displayName}'s FamilyTree has been successfully connected
                  with {this.targetProfile!.displayName}'s FamilyTree!
                </p>
              ) : (
                  <p>
                    The owner of {this.targetProfile!.displayName}'s FamilyTree has been notified
                    of your request to connect {this.requestingProfile!.displayName}'s
                    FamilyTree with {this.targetProfile!.displayName}'s FamilyTree.
                    You will be notified when they accept or reject your request.
                </p>
                )}
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={() => { this.showConfirmMergeModal = false; this.exitTreeJoinPage();/*onClose();*/ }}>
                Ok!
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        {this.showCancelMergeModal && (
          <Modal open size="mini">
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
              <p>
                This will cancel the process to merge {this.requestingProfile!.displayName}'s FamilyTree with {this.targetProfile!.displayName}'s FamilyTree.
                Are you sure you want to continue?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => { this.showCancelMergeModal = false; this.exitTreeJoinPage();/*onClose();*/ }}>Yes</Button>
              <Button secondary onClick={() => this.showCancelMergeModal = false}>No</Button>
            </Modal.Actions>
          </Modal>
        )}

        {this.showAcceptMergeModal && (
          <Modal open size="mini">
            <Modal.Header>Success!</Modal.Header>
            <Modal.Content>
              <p>
                Congratulations! {this.targetProfile!.displayName} and {this.requestingProfile!.displayName} now
                share the same family tree!
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={() => {
                this.showAcceptMergeModal = false;
                this.exitTreeJoinPage();
              }
              }>
                Ok!
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        {this.showRejectMergeModal && (
          <Modal open size="mini">
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
              <p>
                This will reject {this.requestingUser!.displayName}'s request to merge {this.requestingProfile!.displayName}'s
                tree with {this.targetProfile!.displayName}'s tree.
                Are you sure you want to continue?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => {
                this.showRejectMergeModal = false;
                this.rejectMergeTree();
              }
              }>
                Yes</Button>
              <Button secondary onClick={() => this.showRejectMergeModal = false}>No</Button>
            </Modal.Actions>
          </Modal>
        )}

      </div>
    );
  }
}

// @ts-ignore
export default withRouter(TreeJoinPage);
