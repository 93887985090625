import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import * as moment from 'moment';
import { Service } from '@feathersjs/feathers';
import { IProfile, IMemorial } from 'state/apiClient/ServiceTypes';
import AccountStore from 'state/account';
import FamilyTree from 'state/familyTree/tree';
import { StoreBaseClass } from 'state/stores';
import RootStore from 'state/rootStore';
import avatarPlaceholder from 'images/avatar_placeholder.jpeg';
import { IViewModel } from 'mobx-utils';
import { Moment } from 'moment';
import { restClient } from 'state/apiClient';
import { Profile, Memorial } from '.';

interface IRelationToUser {
  id: number,
  status: string,
  relation: string
};

abstract class ProfileBaseClass {

  service: Service<IProfile | IMemorial>;
  store: StoreBaseClass | AccountStore;
  rootStore: RootStore;

  @observable _id: number;
  @observable firstName: string = '';
  @observable lastName: string = '';
  @observable gender: 'female' | 'male';
  @observable dateOfBirth: moment.Moment | null = null;
  @observable placeOfBirth: string = '';

  abstract kind: 'memorials' | 'profiles';

  /** Don't use this. Use .avatar instead */
  @observable avatarUrl: string;
  /** Don't use this. Use .cover instead */
  @observable coverUrl: string;

  @observable familyTreeId: number;

  @observable loading: boolean;
  /** Is profile fetched from server? */
  @observable finishedLoading: boolean = false;

  /** Ignore. Only used internally */
  avatarBlob: Blob | null;

  /** Ignore. Only used internally */
  coverBlob: Blob | null;

  baseUrl: String;

  /** Ignore. Only used internally */
  @observable editModel: ProfileBaseClass & IViewModel<ProfileBaseClass>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /** Profile/Memorial avatar image */
  @computed
  get avatar(): string {
    return this.avatarUrl ? this.avatarUrl : avatarPlaceholder;
  }

  abstract get cover(): string;

  abstract get userIsOwner(): boolean;

  /** Returns the full name */
  @computed
  get displayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get familyTree(): FamilyTree | undefined {
    const familyTree = this.rootStore.familyTreeStore.familyTrees.find(tree => tree._id === this.familyTreeId);
    if (!familyTree) {
      this.fetchFamilyTree();
    }
    return familyTree;
  }

  @computed
  get relationshipToUser(): string {
    const userProfile = this.rootStore.accountStore.myProfile;
    if (userProfile) {
      if (!userProfile.familyTree) {
        this.rootStore.familyTreeStore.fetchById(userProfile.familyTreeId);
        return '';
      }
      const { familyTree } = userProfile;
      const personInFamilyTree = familyTree.persons.find(p => p.connectedPageId === this._id);
      if (personInFamilyTree) {
        return personInFamilyTree.relationshipToRoot;
      }
    }
    return '';
  }

  abstract get ageString(): string;

  abstract get relationToUser(): IRelationToUser | null;

  abstract async saveEdit();

  abstract resetEdit();

  @action
  setFirstName(value: string) {
    this.editModel.firstName = value;
  }

  @action
  setLastName(value: string) {
    this.editModel.lastName = value;
  }

  @action
  setPlaceOfBirth(value: string) {
    this.editModel.placeOfBirth = value;
  }

  @action
  setDateOfBirth(value: Moment) {
    this.editModel.dateOfBirth = value;
  }

  @action
  async fetchFamilyTree(): Promise<FamilyTree> {
    return this.rootStore.familyTreeStore.fetchById(this.familyTreeId);
  }

  /**
   * Uploads avatar image to the server
   * or saves locally if profile is not created yet
   * to upload on profile save
   * @param file The image blob
   */
  @action
  async uploadAvatar(file?: Blob) {

    if (!file && !this.avatarBlob) {
      return;
    }

    //profile/memorial does not yet exist, only update locally
    if (!this._id) {
      const image = URL.createObjectURL(file);
      this.avatarUrl = image;
      this.avatarBlob = file!;
      return;
    }

    if (!file) {
      file = this.avatarBlob!;
    }

    const data = new FormData();
    data.append('avatar', file);

    try {
      const response = await restClient(`${this.baseUrl}/${this._id}`, {
        body: data,
        method: 'PATCH'
      }, evt => {
        if (evt.lengthComputable) {
          var percentComplete = evt.loaded / evt.total;
        }
      });

      runInAction('avatar uploaded', () => {
        if (this.avatarBlob) {
          this.avatarBlob = null;
          URL.revokeObjectURL(this.avatarUrl);
        }
        this.avatarUrl = response.avatarUrl;
      })
    } catch (err) {
      throw new Error('ProfileBaseClass.uploadAvatar() - ' + err);
    }
  }

  /**
   * Uploads cover image to the server
   * or saves locally if profile is not created yet
   * to upload on profile save
   * @param file The image blob
   */
  @action
  async uploadCover(file?: Blob) {

    if (!file && !this.coverBlob) {
      return;
    }

    //profile/memorial does not yet exist, only update locally
    if (!this._id) {
      const image = URL.createObjectURL(file);
      this.coverUrl = image;
      this.coverBlob = file!;
      return;
    }

    if (!file) {
      file = this.coverBlob!;
    }

    const data = new FormData();
    data.append('cover', file);

    try {
      const response = await restClient(`${this.baseUrl}/${this._id}`, {
        body: data,
        method: 'PATCH'
      });

      runInAction('cover uploaded', () => {
        if (this.coverBlob) {
          this.coverBlob = null;
          URL.revokeObjectURL(this.coverUrl);
        }
        this.coverUrl = response.coverUrl;
      })
    } catch (err) {
      throw new Error('ProfileBaseClass.uploadCover()');
    }
  }

  @action
  leaveFamilyTree() {
    if (!this.userIsOwner) {
      throw new Error('Not owner of profile/memorial');
    }
    if (!this.familyTree) {
      throw new Error('Family tree not found');
    }
    return this.familyTree.leaveTree([this]);
  }

  @action
  async resetFamilyTree() {
    if (!this.userIsOwner) {
      throw new Error('Not owner of profile/memorial');
    }
    if (!this.familyTree) {
      throw new Error('Family tree not found');
    }
    this.familyTree.resetTree(this);
  }
}

export default ProfileBaseClass;