import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Memorial } from 'state/objects';
import Person from 'state/familyTree/person';
import constants from 'components/FamilyTree/FamilyTreeWindow/RenderingConstants';
import ReactDND, { DragSource, DragLayer, DropTarget } from 'react-dnd';

import Checkmark from 'images/iconmonstr-check-mark-1.svg';

interface NameBoxVisualProps {
  person: Person;
  relationshipToRoot?: string;
  userCanEdit?: boolean;
  mergeMode?: boolean;
  openMenu?(e: React.MouseEvent<SVGElement, MouseEvent>): any;
  setRoot?(e: React.MouseEvent<SVGElement, MouseEvent>): any;
  mode?: 'normal' | 'drag' | 'drop' | 'inactive';
}

interface NameBoxProps extends NameBoxVisualProps {
  x: number;
  y: number;

  onDragStart?(): any;
  onDragEnd?(): any;

}

@observer
class NameBoxVisual extends React.Component<NameBoxVisualProps, {}> {

  render() {
    const {
      person,
      userCanEdit,
      openMenu,
      relationshipToRoot: relationshipToRootProp,
      setRoot,
      mode,
    } = this.props;

    let nodeClass = "";

    //********************************************************* */
    //set up lifespan
    //********************************************************* */

    let dateString = "";
    const { dateOfBirth, dateOfPassing } = person;
    const birthYear = dateOfBirth ? dateOfBirth.year() : null;
    const deathYear = dateOfPassing ? dateOfPassing.year() : null;
    if (deathYear) {
      dateString = birthYear + " - " + deathYear;
    } else
      if (birthYear) {
        dateString = "Born " + birthYear;
      }

    //********************************************************* */
    //set up basics for the node picture
    //********************************************************* */

    const picWidth = constants.pictureWidth;

    let picBorderWidth;
    if (person.connectedPage) {
      picBorderWidth = constants.frameBorderWidthConnected;
    } else {
      picBorderWidth = constants.frameBorderWidth;
    }

    let picBorderColor;
    if (person.gender === 'male') {
      picBorderColor = constants.colorMale
    } else {
      picBorderColor = constants.colorFemale
    }

    let picBorderShadow = constants.frameBorderShadow;
    let picCheckmark = false;

    //********************************************************* */

    const relationshipToRoot = relationshipToRootProp ? relationshipToRootProp : person.relationshipToRoot;
    const mergeState = person.mergeState;

    //import scale data from node
    //scale could be increased on hover if you add an event listener for that
    let scale = person.node.nodeScale;
    let opacity = 1;



    //change defaults based on mergeState
    switch (mergeState) {
      case "DRAGGED":
        picCheckmark = true;
        opacity = 0.45;
        scale -= 0.2;
        break;
      case 'ADDED':
      case "REPLACED":
      case "INSERTED":
        picCheckmark = true;
        nodeClass = "editable-node";
        scale += 0.2;
        picBorderWidth += 4;
        picBorderShadow = constants.frameBorderShadowDropped;
        break;
      case "DEFAULT":
      default:
        if (person.connectedPage || userCanEdit) nodeClass = "editable-node";
        break;
    }

    //set positioning coords using constants and scale data
    const visualsX = (constants.nodeWidth - (constants.nodeWidth * scale)) / 2;
    const visualsY = (constants.nodeHeight - (constants.nodeHeight * scale)) / 2;

    return (

      <g
        className="familytree-box"
        onClick={(event) => {
          if (event.shiftKey && setRoot) return setRoot(event);
          if (mode === "normal" || mode === "drop") {
            //check these buttons arent down
            !event.ctrlKey && !event.altKey && !event.shiftKey &&
              //Check openMenu function exists and run it
              openMenu && openMenu(event);
          }
        }}
      >

        <g className={nodeClass}
          transform={
            `translate(${visualsX}, ${visualsY}), 
            scale(${scale})`}
          opacity={opacity}
        >

          <clipPath id="clipCircle">
            <circle r={picWidth / 2} cx={picWidth / 2} cy={picWidth / 2} />
          </clipPath>

          <rect
            width={picWidth}
            height={picWidth}
            fill="none"
            stroke={picBorderColor}
            strokeWidth={picBorderWidth}
            strokeOpacity={1}
            style={{ filter: picBorderShadow }}
            rx={50}
            ry={50}
          />

          <rect
            width={picWidth}
            height={picWidth}
            fill="lightgrey"
            rx={50}
            ry={50}
          />

          <image
            xlinkHref={person.avatar}
            width={picWidth}
            height={picWidth}
            preserveAspectRatio="xMidYMid slice"
            clipPath="url(#clipCircle)"
          />

          {picCheckmark && (
            <g transform={`translate(50, 45)`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
                <path fill="rgb(33, 186, 69)" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
              </svg>
            </g>
          )}


          <g>
            <text x={picWidth / 2} y={picWidth + 5} textAnchor="middle" fill="rgb(87, 114, 144)">
              <tspan x={picWidth / 2} dy="1.2em" className="name">
                {person.displayName}
              </tspan>
              {relationshipToRoot !== "" && (
                <tspan x={picWidth / 2} dy="1.5em" className="relationship" >
                  {relationshipToRoot}
                </tspan>)}
              <tspan x={picWidth / 2} dy="1.3em" className="dates">
                {dateString}
              </tspan>
            </text>
          </g>

        </g>
      </g>
    );
  }
}

{/*
{relationshipToRoot !== "" && (
                <tspan x={picWidth / 2} dy="1.5em" className="relationship" >
                  {relationshipToRoot}
                </tspan>)}
*/}

@observer
class NameBox extends React.Component<NameBoxProps, {}> {

  handleMouseDown(e: React.MouseEvent<SVGElement, MouseEvent>) {
    if (this.props.onDragStart) {
      this.props.onDragStart();
    }
  }

  handleMouseUp(e: React.MouseEvent<SVGElement, MouseEvent>) {
    if (this.props.onDragEnd) {
      this.props.onDragEnd();
    }
  }

  render() {
    const {
      x,
      y,
      person,
      userCanEdit,
      openMenu,
      relationshipToRoot: relationshipToRootProp,
      mode
    } = this.props;

    switch (mode) {
      case 'drag':
        return (
          <g
            transform={`translate(${x}, ${y})`}
            onMouseDown={e => this.handleMouseDown(e)}
          >
            <NameBoxDragSource {...this.props} />
          </g>
        );

      case 'drop':
        return (
          <g
            transform={`translate(${x}, ${y})`}
            onMouseDown={e => this.handleMouseDown(e)}
            onClick={event => {
              if (event.altKey) {
                person.tree.familyTreeMerger.resetTarget(person);
                event.stopPropagation();
              };
            }}
          >
            <NameBoxDropTarget {...this.props} />
          </g>
        );

      case 'normal':
      default:
        return (
          <g
            transform={`translate(${x}, ${y})`}
            onMouseDown={e => this.handleMouseDown(e)}
          >
            <NameBoxVisual {...this.props} />
          </g>
        );
    }
  }
}

//*********************************************************
// Drag Source 
//*********************************************************

interface NameBoxDragSourceProps extends NameBoxProps {
  connectDragSource: ReactDND.ConnectDragSource;
  isDragging: boolean;
}

const NameBoxDragSourceComponent = ({ isDragging, connectDragSource, ...rest }: NameBoxDragSourceProps) => {
  return connectDragSource(

    <g opacity={isDragging ? 0.45 : 1}>
      <NameBoxVisual {...rest} />
    </g>

  );
}

const beginDraggingNameBoxActions = {
  beginDrag(props) {
    return {
      person: props.person,
      relationship: props.person.relationshipToRoot
    };
  },
  canDrag(props) {
    return props.person.mergeState !== "DRAGGED";
  }
}

const sourceCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

interface NameBoxDragSourceCollectedProps {
  isDragging: boolean;
  connectDragSource: ReactDND.ConnectDragSource;
}

const NameBoxDragSource = DragSource<NameBoxVisualProps, NameBoxDragSourceCollectedProps>(
  'NameBox',
  beginDraggingNameBoxActions,
  sourceCollect)
  (observer(NameBoxDragSourceComponent));

//*********************************************************
// Drop Target 
//*********************************************************

const nameBoxTarget = {
  drop(props: NameBoxVisualProps, monitor, component) {

    const { person } = monitor.getItem();

    if (person) {
      props.person.tree.familyTreeMerger.dropPerson(person, props.person);
    }
  },

  hover(props, monitor, component) {
  }
}

const targetCollect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

interface NameBoxDropTargetCollectedProps {
  connectDropTarget: ReactDND.ConnectDropTarget;
  isOver: boolean;
}

interface NameBoxDropTargetProps extends NameBoxProps, NameBoxDropTargetCollectedProps {
  onHoverStart?(): any;
  onHoverEnd?(): any;
}

@observer
class NameBoxDropTargetComponent extends React.Component<NameBoxDropTargetProps> {

  componentDidUpdate(prevProps: NameBoxDropTargetProps) {
    if (prevProps.isOver !== this.props.isOver) {
      if (this.props.isOver && this.props.onHoverStart) {
        this.props.onHoverStart();
      } else if (this.props.onHoverEnd) {
        this.props.onHoverEnd();
      }
    }
  }

  render() {
    const {
      connectDropTarget,
      isOver,
      ...rest
    } = this.props;

    return connectDropTarget(
      <g opacity={isOver ? 0.45 : 1}>
        <NameBoxVisual {...rest} />
      </g>
    );
  }
}

const NameBoxDropTarget = DropTarget<NameBoxVisualProps, NameBoxDropTargetCollectedProps>('NameBox', nameBoxTarget, targetCollect)(NameBoxDropTargetComponent);

//*********************************************************
// Drag Preview 
//*********************************************************

const defaultStyle = (item, currentOffset) => (
  {
    left: currentOffset.x - 50,
    top: currentOffset.y - 50,
    position: 'fixed' as 'fixed'
  }
)

const DraggingVisual = (props) => {
  const {
    isDragging,
    currentOffset,
    item
  } = props;

  if (!isDragging || !currentOffset || !item) {
    return null;
  }

  const { person, relationship } = item;
  const { node } = person;

  return (
    <svg
      style={defaultStyle(item, currentOffset)}
      width={node.width * 2}
      height={node.height * 2}
    >
      <g
        transform={`translate(${50}, ${50})`}
      >
        <NameBoxVisual
          person={person}
          relationshipToRoot={relationship}
        />
      </g>
    </svg>
  )
}

const NameBoxDraggingVisual = DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
}))(observer(DraggingVisual));

export { NameBoxDraggingVisual };
export default NameBox;
