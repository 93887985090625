import {
  Hook,
  HookContext
} from '@feathersjs/feathers';
import cloneDeep from 'lodash/cloneDeep';
import * as Cookies from 'js-cookie';
import * as moment from 'moment';

export const addCSRFToken = () => context => {
  const csrf = Cookies.get('csrftoken');

  if (!context.params.headers)
    context.params.headers = {};

  context.params.headers['X-CSRFToken'] = csrf;
};

export const setField = (field: string, value: any): Hook => async context => {

  context  = applyToElement(context, element => {
    element[field] = value;
  });

  return context;
};

export const parseDateFromServer = (...fields: string[]): Hook => async context => {

  context = applyToElement(context, element => {
    fields.forEach(field => {
      if (element[field] !== undefined) {
        if(!element[field]) {
          element[field] = null;
        } else {
          const date = moment(element[field]);
          element[field] = date;
        }
      }

    });
  });

  return context;
};

export const parseDateFromClient = (...fields: string[]): Hook => async context => {

  context = applyToElement(context, element => {
    fields.forEach(field => {
      if (element[field] !== undefined) {
        if(!element[field]) {
          element[field] = null;
        } else {
          const date = element[field] as moment.Moment;
          element[field] = date.format('YYYY-MM-DD');
        }
      }

    });
  });

  return context;
};

export const negate = (field: string): Hook => async context => {

  context = applyToElement(context, (element) => {
    if (element[field] !== undefined && typeof element[field] === 'boolean') {
      element[field] = !element[field];
    }
  });

  return context;
};

export const rename = (field: string, newName: string): Hook => async context => {

  context = applyToElement(context, (element) => {
    if (element[field] !== undefined) {
      element[newName] = cloneDeep(element[field]);
      delete element[field];
    }
  });

  return context;
};

export const remove = (...fields: string[]): Hook => async context => {

  context = applyToElement(context, element => {
    fields.forEach(field => {
      if (element[field] !== undefined)
        delete element[field];
    });
  });

  return context;
};

const applyToElement = (context: HookContext<any>, func: Function) => {


  if (context.data) {

    if (Array.isArray(context.data)) {
      context.data.forEach(element => {
        func(element);
      });
    } else {
      func(context.data);
    }
  }

  if (context.result) {

    if (Array.isArray(context.result)) {
      context.result.forEach(element => {
        func(element);
      });
    } else {
      func(context.result);
    }
  }

  return context;
};


/*
export const rename = (field, newName) => context => {

  if (context.data) {

    if (Array.isArray(context.data)) {
      context.data.forEach(element => {
        if (element[field] !== undefined) {
          element[newName] = element[field];
          delete element[field];
        }
      });
    } else {
      if (context.data[field] !== undefined) {
        context.data[newName] = context.data[field];
        delete context.data[field];
      }
    }
  }

  if (context.result) {

    if (Array.isArray(context.result)) {
      context.result.forEach(element => {
        if (element[field] !== undefined) {
          element[newName] = element[field];
          delete element[field];
        }
      });
    } else {
      if (context.result[field] !== undefined) {
        context.result[newName] = context.result[field];
        delete context.result[field];
      }
    }
  }

  return context;
};
*/
