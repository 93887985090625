import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { withRouter, RouteComponentProps  } from 'react-router-dom';

import {
  Memorial,
  Profile
} from 'state/objects';
import {
  MemorialForm
} from 'state/stores/forms';
import AccountStore from 'state/account';

import ImageUploader from 'components/ImageUploader';

import LocationSuggest from 'components/LocationSuggest';

import Select from 'react-select';
import ProfileOption from 'components/ProfileOption';

import {
  Modal,
  Form,
  Input,
  Button,
  Image,
  Header,
  Card,
  Icon,
  Popup,
  Grid,
  Message,
  Radio
} from 'semantic-ui-react';

import DateSelect from "components/Generic/DateSelecter";
import * as moment from 'moment';
import { PhotoStore } from 'state/stores';
import { MemorialStore } from 'state/stores';
import UserAvatar from 'components/Generic/Avatar';

export interface Props extends RouteComponentProps<any> {
  accountStore?: AccountStore;
  memorialStore?: MemorialStore;

  memorial: Memorial;
  mode: "create" | "edit";
  open: boolean;
  onClose(): any;
  onDelete?(): any;
  photoStore?: PhotoStore;
}

@inject('accountStore', "photoStore", "memorialStore") @observer
class MemorialEditModal extends React.Component<Props, {}> {

  accountStore: AccountStore;
  memorialStore: MemorialStore;

  @observable avatarModalOpen = false;
  @observable coverModalOpen = false;
  @observable deleteMemorialOpen = false;

  @observable form: MemorialForm | null;

  //Temporary
  @observable profileSelectValue: string = '';
  @observable adminProfileSelectValue: string = '';

  

  constructor (props: Props, context) {
    super(props, context);
    this.accountStore = props.accountStore!;
    this.memorialStore = props.memorialStore!;
  }

  componentDidMount () {
    this.props.memorial.getForm()
      .then(form => {
        this.form = form;
      });

    if (this.accountStore.myProfile) {
      this.accountStore.myProfile.fetchConnections();
    }
  }

  showAvatarModal () {
    this.avatarModalOpen = true;
    this.props.memorial.fetchPhotos();
  }

  closeAvatarModal () {
    this.avatarModalOpen = false;
    this.setBodyScrolling();
  }

  uploadAvatarImage(file) {
    this.closeAvatarModal();
    this.props.memorial.uploadAvatar(file);
    this.props.photoStore!.add(this.props.memorial._id, file);
  }

  showCoverModal () {
    this.coverModalOpen = true;
    this.props.memorial.fetchPhotos();
  }

  closeCoverModal () {
    this.coverModalOpen = false;
    this.setBodyScrolling();
  }

  closeDeleteModal () {
    this.deleteMemorialOpen = false;
    this.setBodyScrolling();
  }

  saveMemorial (event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (this.form) {
      this.form.save()
      .then(() => {
        this.props.onClose();
      })
    }
  }

  //deletes the memorial by calling the remove function in the memorial object (state/objects/memorial)
  //then takes you back to your profile
  deleteMemorial () {
    this.setBodyScrolling();

    this.props.memorial.remove()
    .then(() => {
      this.props.onClose();
      this.props.history.push(`/profile/${this.props.memorial.ownerId}`);
    });
  }

  cancelEdit () {
    this.props.memorial.resetEdit();
    this.props.onClose();
  }

  uploadProfileImage(file) {

    this.closeAvatarModal();
    this.props.memorial.uploadAvatar(file);
  }

  uploadBackgroundImage(file) {

    this.closeCoverModal();
    this.props.memorial.uploadCover(file);
  }

  selectProfile (val: any) {
    this.accountStore.rootStore.profileStore.fetchById(val.value)
    .then(profile => {
      const ids = this.props.memorial.editModel.allowedProfileIds;
      this.props.memorial.editModel.allowedProfileIds = [...ids, profile._id];
    });
  }

  deselectProfile (id: number) {
    const newIds = this.props.memorial.editModel.allowedProfileIds.filter(pId => pId !== id);
    this.props.memorial.editModel.allowedProfileIds = newIds;
  }
  
  addAdmin (val: any) {
    this.accountStore.rootStore.profileStore.fetchById(val.value)
    .then(profile => {
      const ids = this.props.memorial.editModel.adminIds;
      this.props.memorial.editModel.adminIds = [...ids, profile._id];
    });
  }

  removeAdmin (id: number) {
    const newIds = this.props.memorial.editModel.adminIds.filter(pId => pId !== id);
    this.props.memorial.editModel.adminIds = newIds;
  }

  //done to ensure the first modal is still scrollable
  setBodyScrolling () {
    setTimeout(() => {
      document.body.classList.toggle('scrolling', true);
    }, 0);
  }

  renderSelectedProfile (profile: Profile) {
    return (
      <div key={profile._id} style={{display: 'inline-block'}}>
        {/*<Image avatar src={profile.avatar} />*/}
        <UserAvatar
          profile={profile}
          style={"avatar"}       
        /> 
        <span>
          <Icon 
            name="close" 
            color="red"
            onClick={() => this.deselectProfile(profile._id)} 
          />
        </span>
      </div>
    )
  }
  
  renderAdminProfile (profile: Profile) {
    return (
      <Grid.Column computer={3} tablet={4} mobile={4} key={profile._id}>
        {/*<Image avatar src={profile.avatar} />*/}
        <div>
          <div style={{display:"inline-block", overflow:"hidden", textOverflow: "ellipsis"}}>
            <UserAvatar
              profile={profile}
              style={"avatar"}       
            /> 
          </div>
          <div style={{display:"inline-block"}}>            
            {this.props.memorial.userIsCreator && !profile.userIsOwner && (
              <div style={{cursor:"pointer", float:"right",}}>
                <Icon 
                  name="close" 
                  color="red"     
                  onClick={() => this.removeAdmin(profile._id)} 
                />
              </div>
            )}
          </div>
        </div>
        
        
      </Grid.Column>
    )
  }

  render () {


    let {
      memorial,
      open,
      onClose,
      onDelete
    } = this.props;

    const form = this.form;

    if (!form) {
      return null;
    }

    const fields = form.form.fields;

   

    let avatarUrls: string[] = [];
    let coverUrls: string[] = [];
    if (memorial.avatarUrl) {
      avatarUrls.push(memorial.avatarUrl);
    }
    if (memorial.coverUrl) {
      coverUrls.push(memorial.coverUrl);
    }
    avatarUrls = avatarUrls.concat(memorial.photos.map(p => p.url));
    coverUrls = coverUrls.concat(memorial.photos.map(p => p.url));

    let profileOptions: any[] = [];
    let adminOptions: any[] = [];
    const myProfile = this.accountStore.myProfile;

    if (myProfile) {

      const selectedProfileIds = memorial.editModel.allowedProfileIds;

      profileOptions = myProfile.connections
      .filter(c => selectedProfileIds.indexOf(c.contactId) === -1)
      .map(c => {
        return {
          value: c.contactId,
          label: c.contact!.displayName,
          image: c.contact!.avatar
        };
      })
      
      const adminProfileIds = memorial.editModel.adminIds;

      adminOptions = myProfile.connections
      .filter(c => adminProfileIds.indexOf(c.contactId) === -1)
      .map(c => {
        return {
          value: c.contactId,
          label: c.contact!.displayName,
          image: c.contact!.avatar
        };
      })
    }

    

    return (
      <div>
        <Modal size="small" open onClose={onClose}>
          <Modal.Header>
            {this.props.mode === "create" ? (
              "Create New Memorial"
            ) : (
              "Manage Memorial"
            )}
          </Modal.Header>
          <Modal.Content>
            <Form error>
              {this.props.mode === "create" && (
                <div style={{paddingBottom:"1.5rem", textAlign:"center"}}> 
                  <span style={{margin: "0 auto"}}>
                    <UserAvatar
                      profile={{  avatar: memorial.avatar, 
                                  displayName: fields.firstName.value + " " + fields.lastName.value, 
                                  gender: fields.gender.value,
                                  yearRange: fields.dateOfBirth.value + " " + fields.dateOfPassing.value,      
                                }}
                      style={"default"}
                      size={150}
                      noLink={true}
                      noText={true}
                      onClick={() => {this.avatarModalOpen = true;}} //funkar inte
                    />
                  </span>
                </div>         
              )}

              <Form.Input
                label="First Name"
                placeholder="Enter Name"
                value={fields.firstName.value}
                onChange={e => form.onFieldChange('firstName', e.currentTarget.value)}
              />
              <Message error>{fields.firstName.error}</Message>

              <Form.Input
                label="Last Name"
                placeholder="Enter Name"
                value={fields.lastName.value}
                onChange={e => form.onFieldChange('lastName', e.currentTarget.value)}
              />
              <Message error>{fields.lastName.error}</Message>
 
              <Form.Field>
                <label>
                  Gender:
                </label>
                <Radio
                  label='Female'
                  name='genderRadioGroup'
                  value='true'
                  checked={fields.gender.value === 'female'}
                  onChange={(e) => {
                    form.onFieldChange('gender', 'female');
                  }}
                />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Male"
                    name='genderRadioGroup'
                    value='false'
                    checked={fields.gender.value === 'male'}
                    onChange={(e) => {
                      form.onFieldChange('gender', 'male');
                    }}
                  />
                </Form.Field>               
              <Message error>{fields.gender.error}</Message>

             {/*}
              <div className="field">
                <label>Date of Birth</label>
                <DatePicker
                  className="ui input"
                  placeholderText="Enter Date"
                  showYearDropdown
                  dropdownMode="select"
                  selected={fields.dateOfBirth.error ? null : fields.dateOfBirth.value}
                  value={fields.dateOfBirth.error ? fields.dateOfBirth.value : null}
                  onChange={d => form.onFieldChange('dateOfBirth', d)}
                  onChangeRaw={d => form.onFieldChange('dateOfBirth', d)}
                />
              </div>
              <Message error>{fields.dateOfBirth.error}</Message>*/}
              <div className="field">                    
                <label>Date of Birth</label>
                <DateSelect 
                  value= {fields.dateOfBirth.value ? fields.dateOfBirth.value : null}
                  onChange = {d => form.onFieldChange('dateOfBirth', d)}
                />
              </div>
              <Message error>{fields.dateOfBirth.error}</Message>

              {/*}
              <div className="field">
                  <label>Date of Passing</label>
                  <DatePicker
                  className="ui input"
                  placeholderText="Enter Date"
                  showYearDropdown
                  dropdownMode="select"
                  selected={fields.dateOfPassing.error ? null : fields.dateOfPassing.value}
                  value={fields.dateOfPassing.error ? fields.dateOfPassing.value : null}
                  onChange={d => form.onFieldChange('dateOfPassing', d)}
                  onChangeRaw={d => form.onFieldChange('dateOfPassing', d)}
                  />
              </div>
                                    <Message error>{fields.dateOfPassing.error}</Message>
              */}
              <div className="field">                    
                <label>Date of Passing</label>
                <DateSelect 
                  value= {fields.dateOfPassing.value ? fields.dateOfPassing.value : null}
                  onChange = {d => form.onFieldChange('dateOfPassing', d)}
                />
              </div>

              <Message error>{fields.dateOfPassing.error}</Message>
    

              <LocationSuggest
                label="Place of Birth"
                placeholder="Enter Location"
                value={fields.placeOfBirth.value}
                onChange={e => form.onFieldChange('placeOfBirth', e.target.value)}
              />
              <Message error>{fields.placeOfBirth.error}</Message>

              <LocationSuggest
                label="Place of Passing"
                placeholder="Enter Location"
                value={fields.placeOfPassing.value}
                onChange={e => form.onFieldChange('placeOfPassing', e.target.value)}
              />
              <Message error>{fields.placeOfPassing.error}</Message>

              <LocationSuggest
                label="Location of Resting Place"
                placeholder="Enter Location"
                value={fields.placeOfResting.value}
                onChange={e => form.onFieldChange('placeOfResting', e.target.value)}
              />
              <Message error>{fields.placeOfResting.error}</Message>

              <Form.Field>
                <label>
                  Obituary
                  <Popup
                    trigger={<Icon name="question" />}
                    content="Enter a short obituary for the person."
                  />
                </label>
                <Form.Input
                  placeholder="Enter Obituary"
                  value={fields.obituary.value}
                  onChange={e => form.onFieldChange('obituary', e.currentTarget.value)}
                />
                <span style={{float:"right", marginTop:"-7px", fontSize:"0.9em"}}>
                  {`(${fields.obituary.value.length}/${form.getMaxCharacters('obituary')} characters)`}
                </span>
              </Form.Field>
              <Message error>{fields.obituary.error}</Message>

              <Form.Field>
                <label>
                  Biography
                  <Popup
                    trigger={<Icon name="question" />}
                    content="Enter a short biography about the person"
                  />
                </label>
                <Form.TextArea
                  placeholder="Enter Biography"
                  value={fields.biography.value}
                  onChange={e => form.onFieldChange('biography', e.currentTarget.value)}
                />
                <span style={{float:"right", marginTop:"-7px", marginBottom:"0px", fontSize:"0.9em"}}>
                  {`(${fields.biography.value.length}/${form.getMaxCharacters('biography')} characters)`}
                </span>
              </Form.Field>
              <Message error>{fields.biography.error}</Message>

              <Form.Field>
              <label>
                  Choose who can see this memorial:
                </label>
                <Radio
                  label='Everyone'
                  name='publicRadioGroup'
                  value='true'
                  checked={fields.viewingPermission.value === 'public'}
                  onChange={(e) => {
                    form.onFieldChange('viewingPermission', 'public');
                    // @ts-ignore
                    memorial.editModel.resetProperty('allowedProfileIds');
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Administrator's Connections"
                  name='publicRadioGroup'
                  value='false'
                  checked={fields.viewingPermission.value === 'connections'}
                  onChange={(e) => {
                    form.onFieldChange('viewingPermission', 'connections');
                    // @ts-ignore
                    memorial.editModel.resetProperty('allowedProfileIds');
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label='Only administrators and invited users'
                  name='publicRadioGroup'
                  value='false'
                  checked={fields.viewingPermission.value === 'private'}
                  onChange={(e) => form.onFieldChange('viewingPermission', 'private')}
                />
              </Form.Field>
              
              <Message error>{fields.viewingPermission.error}</Message>

              <Message error>{form.form.meta.error}</Message>
            </Form>
            {fields.viewingPermission.value === 'private' && (
              <div>
                <div style={{paddingBottom: '15px'}}>
                  <Form.Field>
                    <label
                      style={{
                        display: 'block',
                        marginBottom: '.28571429rem',
                        color: 'rgba(0,0,0,.87)',
                        fontSize: '.92857143em',
                        fontWeight: 700,
                      }}
                    >
                      Users allowed to see memorial:
                    </label>
                    <Select
                      name="memorial-viewing-permissions"
                      placeholder="Add user..."
                      value={this.profileSelectValue}
                      onChange={val => this.selectProfile(val)}
                      options={profileOptions}
                      optionComponent={ProfileOption}
                    />
                  </Form.Field>
                </div>
                {
                  this.accountStore.rootStore.profileStore.profiles
                    .filter(p => memorial.editModel.allowedProfileIds.indexOf(p._id) > -1)
                    .sort((left, right) => memorial.editModel.allowedProfileIds.indexOf(left._id) < memorial.editModel.allowedProfileIds.indexOf(right._id) ? -1 : 1)
                    .map(p => this.renderSelectedProfile(p))
                }
              </div>
            )}
            {this.props.mode !== "create" && 
              <div style={{paddingBottom: '15px', paddingTop: '15px'}}>         
                <label style={{display: 'block', marginBottom: '.28571429rem', color: 'rgba(0,0,0,.87)', fontSize: '.92857143em', fontWeight: 700,}}>
                  Memorial Administrators:
                </label>    
                {memorial.userIsCreator && (
                  <Form.Field>
                    <Select
                      name="memorial-admins"
                      placeholder="Add administrator..."
                      value={this.adminProfileSelectValue}
                      onChange={val => this.addAdmin(val)}
                      options={adminOptions}
                      optionComponent={ProfileOption}
                    />
                  </Form.Field>
                )}       
                <Grid style={{paddingTop:"15px"}}>
                  <Grid.Row>
                    {this.accountStore.rootStore.profileStore.profiles
                      .filter(p => memorial.editModel.adminIds.indexOf(p._id) > -1)
                      .sort((left, right) => memorial.editModel.adminIds.indexOf(left._id) < memorial.editModel.adminIds.indexOf(right._id) ? -1 : 1)
                      .map(p => this.renderAdminProfile(p))
                    }
                  </Grid.Row>             
                </Grid>        
            </div>                
            }             
            </Modal.Content>

            <Modal.Actions>
              {memorial._id &&
                <div style={{float:"left"}}>
                    <Button onClick={() => this.deleteMemorialOpen = true} ><Icon name='warning sign' />Delete Memorial</Button>
                </div>
              }
                <Button secondary onClick={() => this.cancelEdit()}>
                  Cancel
                </Button>
                <Button primary type="submit" disabled={!form.form.meta.isValid} onClick={e => this.saveMemorial(e)}>
                  Save
                </Button>
            </Modal.Actions>
        </Modal>

        {this.deleteMemorialOpen && (
          <Modal
            open
            size="mini"

          >
            <Modal.Header>
              Warning
            </Modal.Header>
            <Modal.Content>
               <strong>
               This will PERMANENTLY delete this memorial.
               This action CANNOT be undone!
               Are you sure you want to continue?
               </strong>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.deleteMemorialOpen = false}>Cancel</Button>
              <Button secondary type="submit" onClick={() => this.deleteMemorial()}>Delete Memorial</Button>
            </Modal.Actions>
          </Modal>
        )}

        {this.avatarModalOpen && (
          <Modal open basic closeIcon size="large" onClose={() => this.closeAvatarModal()}>
            <Modal.Content>
              <ImageUploader
                photos={avatarUrls}
                aspectRatio={16/10}
                onUpload={img => this.uploadAvatarImage(img)}
              />
            </Modal.Content>
          </Modal>
        )}
      </div>
    );
  }

}

export default withRouter(MemorialEditModal);
//export default MemorialEditModal;


/*
                <Form.Field>
                  <Checkbox
                    checked={editModel.isPublic}
                    onClick={() => this.isPublic = !this.isPublic}
                  />
                  <label>
                    Public
                    <Popup
                      trigger={<Icon name="question" />}
                      content="Choose if this page will be publically visible or private"
                    />
                  </label>
                </Form.Field>*/
