import {
  observable,
  action,
  computed
} from 'mobx';
import { feathersClient } from 'state/apiClient'
import RootStore from './rootStore';
import { Profile } from 'state/objects';
import Logrocket from 'logrocket';

const client = feathersClient();
const authManagement = client.service('authManagement')

export default class UserStore {

  @observable rootStore: RootStore;

  @observable jwtToken: any;
  @observable userId: number;
  @observable email: string;
  @observable profileId: number;
  @observable emailFreq: string;
  @observable isFacebookConnected: boolean = false;

  @observable errors: any = {};
  @observable loading: boolean = false;

  @observable tryLoginDone: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.tryLogin();
  }

  @computed
  get myProfile(): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.profileId);
  }

  @action
  startAsync() {
    this.loading = true;
    this.errors = {};
  }

  @action
  tryLogin() {
    this.startAsync();
    return client.passport.getJWT()
      .then(jwt => {
        return client.authenticate({
          strategy: 'jwt',
          accessToken: jwt
        });
      })
      .then(response => {
        return client.passport.verifyJWT(response.accessToken);
      })
      .then(payload => {
        // @ts-ignore
        return client.service('users').get(payload.userId);
      })
      .then(user => {
        this.userId = user._id;
        this.profileId = user.profile;
        this.email = user.email;
        this.emailFreq = user.emailFreq;
        this.isFacebookConnected = !!user.facebookId;
        return this.rootStore.profileStore.fetchById(user.profile);
      })
      .then(profile => {
        this.tryLoginDone = true;

        Logrocket.identify(this.userId.toString(), {
          name: `${profile.firstName} ${profile.lastName}`,
          email: this.email
        });

        profile.fetchMemorials();
        this.rootStore.notificationStore.fetchAll();
        this.rootStore.chatStore.fetchAll();
      })
      .catch(err => {
        this.tryLoginDone = true;
      });
  }

  @action
  login(type: string, email?: string, password?: string) {
    this.startAsync();
    let body = {};

    switch (type) {
      case 'local':
        body = {
          strategy: 'local',
          email,
          password
        };
        break;
      case 'facebook':
        body = {
          strategy: 'facebook'
        }
        break;
      case 'twitter':

        break;
      case 'google':

        break;
    }

    // @ts-ignore
    return client.authenticate(body)
      .then(response => {
        return client.passport.verifyJWT(response.accessToken);
      })
      .then(payload => {
        return client.service('users').get(payload.userId);
      })
      .then(user => {
        this.userId = user._id;
        this.profileId = user.profile;
        this.email = user.email;
        this.emailFreq = user.emailFreq;
        return this.rootStore.profileStore.fetchById(user.profile);
      })
      .then(profile => {

        Logrocket.identify(this.userId.toString(), {
          name: `${profile.firstName} ${profile.lastName}`,
          email: this.email
        });

        profile.fetchMemorials();
        this.rootStore.notificationStore.fetchAll();
        this.rootStore.chatStore.fetchAll();
      })
      .catch(err => {
        throw err.message ? err.message : err;
      });
  }

  @action register(firstName: string, lastName: string, gender: string, email: string, password: any) {
    this.startAsync();
    let body = {
      firstName,
      lastName,
      email,
      password,
      gender
    };

    return client.service('users').create(body)
      .then(response => {
        //return this.login('local', email, password);
      })
      .catch(err => {
        throw err;
      });
  }

  @action
  verifyEmail(token: string) {
    this.startAsync();
    return client.service('authManagement').create({
      action: 'verifySignupLong',
      value: token
    })
      .then(response => {
      })
      .catch(err => {
        throw err;
      });
  }

  @action logout() {
    return client.logout()
      .then(response => {
        this.userId = 0;
        this.profileId = 0;
        this.email = '';
      })
      .catch(err => {
        throw err;
      });
  }

  @action
  async requestPasswordReset(email: string) {
    try {
      const response = await authManagement.create({
        action: 'sendResetPwd',
        value: { email }
      });
      return true;
    } catch (err) {
      throw err.message || 'Something went wrong.';
    }
  }

  @action
  async passwordReset(token: string, password: string) {
    try {
      const response = await authManagement.create({
        action: 'resetPwdLong',
        value: {
          token,
          password
        }
      });
      return true;
    } catch (err) {
      throw err.message || 'Something went wrong.';
    }
  }

  //**********************************
  // ACCOUNT SETTINGS STUFF
  //********************************** 

  @action
  getCookieSettings() {
    return true;
  }
  @action
  setCookieSettings(value: boolean) {
    console.log("cookieSettings: " + value)
  }
  @action
  getEmailUpdateFreq() {
    return "weekly";
  }

  @action
  async setEmailFreq(value: 'daily' | 'weekly' | 'monthly' | 'never'): Promise<any> {
    try {
      const response = await client.service('users').patch(this.userId, {
        emailFreq: value
      });
      this.emailFreq = response.emailFreq;
      return true;
    } catch (err) {
      throw new Error('AccountStore.setEmailFreq() - ' + err);
    }
  }

  @action
  async changeEmail(currentEmail: string, newEmail: string, password: string) {
    try {
      const response = await authManagement.create({
        action: 'identityChange',
        value: {
          user: { email: currentEmail },
          changes: { email: newEmail },
          password: password
        }
      });
      return true;
    } catch (err) {
      throw new Error(err.message ? err.message : 'Something went wrong');
    }
  }

  @action
  async confirmEmailChange(token: string) {
    try {
      const response = await authManagement.create({
        action: 'verifySignupLong',
        value: token
      });
      return true;
    } catch (err) {
      throw new Error(`AccountStore.confirmEmailChange() - ${err}`);
    }
  }

  @action
  async changePassword(currentPassword: string, newPassword: string): Promise<any> {
    try {
      const response = await authManagement.create({
        action: 'passwordChange',
        value: {
          user: { email: this.email },
          oldPassword: currentPassword,
          password: newPassword
        }
      });
      return true;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  @action
  deleteAccount(password?: string) {
    return client.service('users').remove(this.userId)
      .then(() => {
        this.userId = 0;
        this.profileId = 0;
        this.email = '';
        return this.logout();
      })
  }

  //**********************************
  // END OF ACCOUNT SETTINGS STUFF
  //**********************************
}