import * as React from 'react';
import { observer, inject } from "mobx-react";
import { observable } from 'mobx';
import { Link, withRouter } from 'react-router-dom';

import {
  Menu,
  Popup,
  Dropdown,
  Flag,
  Input,
  Sidebar,
  Icon
} from 'semantic-ui-react';

@inject('uiStore', 'accountStore') @observer
class MobileSidebar extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore;
    this.uiStore = this.props.uiStore;
  }

  render() {

    return (
      <div>
        <Menu.Item>
          <Link to="/account" onClick={() => this.close()}><Icon name="user" />Account</Link>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" href='https://goo.gl/forms/yWJ2foJq6zxMRcjs1'><Icon name="talk" />Send feedback</a>
        </Menu.Item>
        <Menu.Item>
          <Link to={this.props.match} onClick={() => this.signOut()}>
            <Icon name="sign out" />Sign Out
          </Link>
        </Menu.Item>
      </div>
    );
  }

  signOut() {
    this.accountStore.logout(() => this.props.history.push('/'));
  }

  close () {
    this.uiStore.toggleSidebar();
  }
}

export default withRouter(MobileSidebar);
