import * as React from "react";

import { Route, Switch } from "react-router-dom";

import { observer, inject } from "mobx-react";

import { Modal, Message } from "semantic-ui-react";

import Profile from "components/Profile";
import Memorial from "components/Memorial";
import TreeJoin from "components/FamilyTree/TreeJoin/TreeJoinPage";

import FrontPage from "components/FrontPage";
import StorePage from "components/StorePage";

import LoginModal from "components/Authentication/LoginModal/loginModal";
import SignupPage from "components/Authentication/Signup/signupFormGeneric";
import VerifyEmailPage from "components/Authentication/Signup/verifyEmail";
import ForgotPasswordPage from "components/Authentication/ForgotPassword/forgotPassword";
import NewPasswordPage from "components/Authentication/ForgotPassword/enterNewPassword";

import SearchPage from "components/Search";

import NotFound from "components/NotFound";
import PrivacyPolicy from "components/PrivacyPolicy";
import TermsOfService from "components/TermsOfService";
import CookiesInfo from "components/CookiesInfoPage";
import ContactDetails from "components/ContactDetails";

import LandingPage from "components/LandingPage";

import AsyncComponent from "components/AsyncComponent";
import sizeMe from "react-sizeme";

import ChatModal from "components/Chat/ChatModal";
import AccountSettings from "components/AccountSettings";

import ReportModal from 'components/ReportModal';

import { DndProvider } from "react-dnd";
import MouseBackEnd from "react-dnd-mouse-backend";

import AccountStore from 'state/account';
import UIStore from "state/ui";

/*
const Profile = (props) => (
  <AsyncComponent 
    showLoadingBar 
    moduleProvider={() => import(/* webpackChunkName "ProfilePage"  'components/Profile')} 
    {...props} 
  />
);

const Memorial = (props) => (
  <AsyncComponent 
    showLoadingBar 
    moduleProvider={() => import(/* webpackChunkName "MemorialPage"  'components/Memorial')} 
    {...props} 
  />
);
*/

interface Props {
  size?: {
    width: number;
    height: number;
  };
  accountStore?: AccountStore;
  uiStore?: UIStore;
}

let mobileModalOpen = false;

const Main = (props: Props) => {

  const accountStore = props.accountStore!;
  const uiStore = props.uiStore!;

  const { width } = props.size!;

  const mobileBreakPoint = mobileModalOpen ? 767 : 752;

  const isComputer = width >= 975;
  const isTablet = width > mobileBreakPoint && !isComputer;
  const isMobile = !isComputer && !isTablet;

  let loginModalSize = "tiny";

  mobileModalOpen = false;

  if (isTablet) {
    mobileModalOpen = false;
    loginModalSize = "tiny";
  }

  if (isMobile) {
    mobileModalOpen = true;
  }

  return (
    <DndProvider backend={MouseBackEnd}>
      <main>
        {accountStore.tryLoginDone && (
          <div style={{ paddingTop: 71 }}>
            <Modal open={mobileModalOpen}>
              <Message
                icon="warning"
                header="Mobile version not working"
                content="The site is not yet optimized for mobile and does not work on small screens. Please use a tablet or desktop in the meantime."
              />
            </Modal>

            <ChatModal
              open={uiStore.showChatModal}
              onClose={() => {
                uiStore.showChatModal = false;
              }}
            />

            <LoginModal
              open={uiStore.showLoginModal}
              onClose={() => (uiStore.showLoginModal = false)}
              size={loginModalSize}
            />

            <ReportModal />

            <Switch>

              

              <Route exact path="/" component={FrontPage} />
              <Route path="/landingpage" component={LandingPage} />

              <Route path="/signup/tjXTRw0AaWScCY7rJLFX" component={SignupPage} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/verify/:token" component={VerifyEmailPage} />
              <Route path="/iforgot" component={ForgotPasswordPage} />
              <Route path="/resetpassword/:token" component={NewPasswordPage} />

              <Route path="/profile/:profileID" component={Profile} />
              <Route path="/memorial/:profileID" component={Memorial} />
              <Route path="/settings" component={AccountSettings} />
              <Route
                path="/jointrees/:reqType/:requestingProfileId/:tarType/:targetProfileId"
                render={props => <TreeJoin mergeMode="merge" {...props} />}
              />
              <Route
                path="/jointrees/review/:newTreeId"
                render={props => <TreeJoin mergeMode="review" {...props} />}
              />

              <Route path="/store" component={StorePage} />
              <Route path="/search" component={SearchPage} />

              <Route path="/tos" component={TermsOfService} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/cookies" component={CookiesInfo} />
              <Route path="/contact" component={ContactDetails} />

              <Route path="*" component={NotFound} />
            </Switch>
          </div>
        )}
      </main>
    </DndProvider>
  );
};
// DragDropContext(MouseBackEnd)(
export default 
  sizeMe()(inject("uiStore", "accountStore")(observer(Main))
);
