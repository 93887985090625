import * as React from 'react';

import {
  Button,
  Form,
  Divider
} from 'semantic-ui-react';

function FormSubmit(props) {
  const {
    text,
    social,
    disabled
  } = props;

  if(!social)
    return (
      <Button disabled={disabled} fluid type="submit" primary>{text}</Button>
    );

  return (
    <div>
      <Button fluid type="submit" primary>{text}</Button>
      <Divider horizontal>or sign in with</Divider>
      <Button.Group fluid>
        <Button href="/auth/facebook" color="facebook" icon="facebook f" />
        <Button color="google plus" icon="google plus" />
        <Button color="twitter" icon="twitter" />
      </Button.Group>
    </div>
  );
}

export default FormSubmit;
