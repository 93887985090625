import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import {
  Label,
  Icon,
  Select,
  Form,
  Input,
} from 'semantic-ui-react';
import moment, { Moment } from 'moment';
import { isSet } from 'lodash-es';

const dayOptions: any[] = [];

for (var i = 1; i <= 31; i++) {
  dayOptions.push(
    { text: i, value: i }
  );
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const monthOptions: any[] = [];
for (var i = 0; i <= months.length - 1; i++) {

  monthOptions.push(
    { text: months[i], value: i }
  );
}

export interface Props {
  value: Moment | null;
  onChange(value: Moment | null): any;
}

@observer
class DateSelect extends React.Component<Props, {}> {

  @observable isSet: boolean = false;
  @observable day: number | null = null;
  @observable month: number | null = null;
  @observable yearString: string | null = '';
  year: number | null;


  constructor(props: Props, context) {
    super(props, context);

    if (this.props.value) {
      this.day = this.props.value.date();
      this.month = this.props.value.month();
      this.year = this.props.value.year();
      this.yearString = this.year.toString();
      this.isSet = true;
    }
  }

  updateDate() {
    if (!this.isSet) this.isSet = true;
    const d: Moment = moment();
    if (this.yearString) {
      if (!isNaN(Number(this.yearString))) {
        this.year = parseInt(this.yearString);
      }
    }
    if (this.day !== null) d.date(this.day);
    if (this.month !== null) d.month(this.month);
    if (this.year !== null) d.year(this.year);

    this.props.onChange(d);
  }

  resetDateToNull() {
    this.isSet = false;
    this.day = null;
    this.month = null;
    this.year = null;
    this.yearString = "";
    this.props.onChange(null);
  }

  render() {

    return (

      <div style={{ display: "block", width: "100%", height: "37.6px", }}>
        <div style={{ width: "7em", float: "left" }}>
          <Select
            fluid
            name="day"
            placeholder="Day"
            search
            selection
            value={this.day as any}
            onChange={(e, data) => {
              this.day = data.value as number;
              this.updateDate();
            }}
            options={dayOptions}
          />
        </div>
        <div style={{ width: "10em", float: "left" }}>
          <Select
            fluid
            name="month"
            placeholder="Month"
            search
            selection
            value={this.month as any}
            onChange={(e, data) => {
              this.month = data.value as number;
              this.updateDate();
            }}
            options={monthOptions}
          />
        </div>
        <div style={{ width: "7em", float: "left" }}>
          <Input
            type="text"
            required
            placeholder="Year"
            value={this.yearString}
            onChange={e => {
              if (e.currentTarget.value.length <= 4 && !isNaN(Number(e.currentTarget.value))) {
                this.yearString = e.currentTarget.value;
                this.updateDate();
              }
            }}
          />
        </div>
        {this.isSet &&
          <div style={{ float: "left", padding: "9.5px 7px" }}>
            <Icon
              onClick={() => this.resetDateToNull()}
              name="remove"
              size="large"
              color="grey"
              style={{ cursor: "pointer" }}
            />
          </div>
        }
      </div>
    );
  }
};

export default DateSelect;
