import * as React from 'react';
import mobx, { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Memorial, GuestbookEntry } from 'state/objects';
import {
  Button,
  Comment,
  Header,
  Divider,
  Grid,
  Input,
  TextArea,
  Icon,
  Modal,
  Form,
  Item,
  Image,
  List
} from 'semantic-ui-react';

import * as moment from 'moment';

import { Link } from 'react-router-dom';

import CommentBranchJS from './CommentBranch';
const CommentBranch: any = CommentBranchJS; //fix after ts mig

import MemoryItem from './MemoryItem';
import MemoryEditModal from './MemoryEditModal';
import { GuestbookEntryStore } from 'state/stores';

export interface MemoriesPaneProps {
  memorial: Memorial;
  scrollTo?: number;

  guestbookEntryStore?: GuestbookEntryStore;
}

@inject('accountStore', 'guestbookEntryStore') @observer
class MemoriesPane extends React.Component<MemoriesPaneProps, {}> {

  guestbookEntryStore: GuestbookEntryStore;

  @observable expanded = false;
  @observable addMemoryModalOpen: boolean = false;
  @observable newMemory: GuestbookEntry | null;

  constructor (props: MemoriesPaneProps, context) {
    super(props, context);
    this.guestbookEntryStore = props.guestbookEntryStore!;
    this.props.memorial.fetchMemories()
  }

  componentWillReceiveProps (nextProps: MemoriesPaneProps) {
    if (this.props.memorial !== nextProps.memorial) {
      nextProps.memorial.fetchMemories()
    }
  }

  startCreatingMemory () {
    this.newMemory = this.guestbookEntryStore.getNewMemory(this.props.memorial._id);
  }

  stopCreatingMemory () {
    this.newMemory = null;
  }

  /*
  postMemory () {
    if (this.memoryTitle || this.memoryInput) {
      this.props.memorial.memoryStore.add(this.memoryTitle, this.memoryInput);
      this.memoryTitle = '';
      this.memoryInput = '';
      this.addMemoryModalOpen = false;
    } else {
      //add form validation fucnitoon!
    }
  }

  /*
  leaveComment () {
    this.props.profile.commentStore.addPost(this.commentInput);
    this.commentInput = '';
  }
  */

  renderComment (comment) {

    const { memorial } = this.props;
    return null;
    /*
    return (
      <CommentBranch
        key={comment.id}
        comment={comment}
        store={memorial.commentStore}
      />
    );
    */
  }

  renderMemoryItem (memory: GuestbookEntry) {

    return (
      <MemoryItem
        key={memory._id}
        memory={memory}
      />
    );

  }

  render () {

    //sort with most recent first
    const memories = this.props.memorial.memories
      .sort((left, right) => left.createdAt.diff(right.createdAt))
      .reverse();

      const { memorial } = this.props;

      let {
        biography,
        displayName,
        avatar,
        placeOfBirth,
        placeOfPassing,
        placeOfResting,
        dateOfBirth,
        dateOfPassing,
        ageString,
        yearRange,
        relationshipToUser
      } = memorial;
  
      const bioIsTrimmed = biography.length > 200;

    return (

      <Grid style={{paddingBottom: 50}}>

        <Grid.Row>
          <Grid.Column computer={12} tablet={12} mobile={12} style={{paddingTop:"0px"}}>
            <List className="text-red" style={{textAlign:"center", margin:"15px 0px 0px 0px"}}>
              <List.Item as="h1" style={{fontWeight:"bold", margin:"7 0 7 0"}}>
                {displayName}
              </List.Item>
              {relationshipToUser && 
                <List.Item as="h3" style={{margin:"7 0 7 0"}}>
                  {relationshipToUser}
                </List.Item>}
              {ageString && 
                <List.Item as="h3" style={{margin:"7 0 7 0"}}>
                  {ageString}
                </List.Item>}         
              <List.Item as="h3" style={{margin:"7 0 7 0"}}>
                {dateOfBirth ? dateOfBirth.format('MMM D, YYYY') : 'Unknown date of birth'}
                {' - '}
                {dateOfPassing ? dateOfPassing.format('MMM D, YYYY') : 'Unknown date of passing'}
              </List.Item>
              {placeOfBirth &&
                <List.Item as="h3" style={{margin:"7 0 7 0"}}>
                  Born in {placeOfBirth}
                </List.Item>}
              {placeOfPassing &&
                <List.Item as="h3" style={{margin:"7 0 7 0"}}>
                  Passed away in {placeOfPassing}
                </List.Item>}
              {placeOfResting &&
                <List.Item as="h3" style={{margin:"7 0 7 0"}}>
                  Final resting place in {placeOfResting}
                </List.Item>}
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={12} tablet={12} mobile={12} style={{paddingBottom:"15px", textAlign:"left"}}>

            <div>
              <div style={!this.expanded 
                ? {maxHeight:"40px", overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "pre-line"} 
                : {whiteSpace: "pre-line"}}
              >
                {biography}
              </div>     
              {bioIsTrimmed && (
                <div style={{textAlign: 'left', color: '#4183c4', display:"inline-block"}}>
                  {!this.expanded ? (
                    <span style={{cursor: 'pointer'}} onClick={() => this.expanded = true}>
                      Show more <Icon name="plus" />
                    </span>
                  ) : (
                    <span style={{cursor: 'pointer'}} onClick={() => this.expanded = false}>
                      Show less <Icon name="minus" />
                    </span> 
                  )}
                </div>
              )}        
            </div>  

          </Grid.Column>
        </Grid.Row> 

        <Grid.Row>

          <Grid.Column computer={9} tablet={9} mobile={6}>
            <Header as="h3" color="red">Guestbook</Header>
            <Divider />
          </Grid.Column>

          <Grid.Column floated="right" computer={3} tablet={3} mobile={6}>
            <Button
              fluid
              compact
              primary
              onClick={() => this.startCreatingMemory()}
            >
              <Icon name="pencil" />Sign Guestbook
            </Button>
          </Grid.Column>

        </Grid.Row>

        {memories.length > 0 ? (
          <Grid.Column width={12}>  
            <Grid>
              {memories.map(memory => this.renderMemoryItem(memory))}
            </Grid>
          </Grid.Column>
        ) : (
          <Grid.Column width={12} textAlign="center">
            <Icon name="pencil" size="huge" style={{color: 'rgba(34,36,38,.15)'}} />
          </Grid.Column>
        )}

        {this.newMemory && (
          <MemoryEditModal
            memory={this.newMemory}
            onClose={() => this.stopCreatingMemory()}
          />
        )}

      </Grid>

    );

  }

/*
  render () {

    const profile = this.props.profile;

    const comments = profile.commentStore.comments;

    return (
      <div>
        <Comment.Group style={{maxWidth: 'none'}}>
          <Header color="red" as="h3">Memories</Header>
          <Divider />
          <div style={{paddingLeft: 49}}>
            <TextArea
              id="comment_input"
              placeholder="Leave a comment"
              value={this.commentInput}
              onChange={(e) => this.commentInput = e.target.value}
              help={profile.uiStore.errors.comment ? profile.uiStore.errors.comment[0] : ""}
              style={{maxWidth: '100%', width: 350}}
            />
            <br />
            <Button
              onClick={() => this.leaveComment()}
              primary
              size="small"
              style={{marginTop: 5}}
            > Send Comment </Button>
          </div>
          {comments.map(c => this.renderComment(c))}
        </Comment.Group>
      </div>
    );
  }
*/
};

export default MemoriesPane;
