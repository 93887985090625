import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';

import {
  Image,
  Icon,
  Header
} from 'semantic-ui-react';

import { Memorial, Profile } from 'state/objects';

export interface Props {
  profile: Profile | Memorial | {
    avatar?: string;
    displayName?: string;
    gender?: string;
    yearRange?: string;
    url?: string;
  };
  style?: "default" | "avatar";
  ownerProfile?: Profile;
  size?: number;
  nameColor?: string;
  dateColor?: string;
  borderColor?: string;
  noShadow?: boolean;
  noText?: boolean;
  noUserIndicator?: boolean;
  noLink?: boolean;
  onClick?(e?: any): any;
}

@observer
class RenderAvatar extends React.Component<Props, {}> {

  avatar: string;
  displayName: string;
  gender: string | undefined;
  yearRange: string | undefined;
  avatarBlockStyle: string;

  relationshipToProfile: string;

  constructor(props: Props, context) {
    super(props, context);
    this.componentDidUpdate();
  }

  componentDidUpdate() {

  }

  RenderPortrait(size: number) {

    const borderSize = Math.round(0.09 * size * 0.96 ** (size / 30));

    return (

      <div style={{ position: "relative", display: this.avatarBlockStyle, }}>
        <Image style={{ display: this.avatarBlockStyle, overflow: "visible" }}>
          <img src={this.avatar}
            style={{
              cursor: this.props.onClick ? "pointer" : "",
              position: "relative",
              width: size,
              height: size,
              objectFit: "cover",
              margin: '0px auto 0px auto',
              boxShadow: this.props.noShadow ? "" : `0px 0px ${0.04 * size}px 0px rgba(0,0,0,0.75)`,
              border: `${borderSize}px solid`,
              borderRadius: "50%",
              borderColor: this.props.borderColor ? this.props.borderColor
                : this.gender === 'male' ? "rgb(87, 114, 144)"
                  : this.gender === 'female' ? "rgb(239, 81, 136)"
                    : "#CACBCD",
            }}
          />
        </Image>
        {this.props.profile instanceof Profile && !this.props.noUserIndicator &&
          <div style={{ position: "absolute", bottom: "2px", left: size - 15 }}>
            <Icon name="user" color={this.gender === 'male' ? "blue" : "red"} />
          </div>
        }
      </div>
    );
  }

  RenderNameDefault(size: number, avatar: string) {

    return (

      <div style={{
        display: "block",
        padding: "14px 0 14px 0",
        marginTop: "0px",
        marginBottom: "0px"
      }}>
        {avatar}
        <div>
          <h3 className={this.props.nameColor ? this.props.nameColor : "text-blue"} style={{
            fontSize: `${0.16 * size}px`,
            marginTop: "10px",
            marginBottom: "0px",
            textAlign: "center",
            fontWeight: "bold",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}>
            {this.displayName}
          </h3>
          {this.relationshipToProfile && (
            <div className={this.props.nameColor ? this.props.nameColor : "text-blue"}
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: `${0.12 * size}px`,
                padding: "0 0 4px 0"
            }}>
              {this.relationshipToProfile}
            </div>
          )}
          {this.props.profile instanceof Memorial && this.yearRange &&
            <div className={this.props.dateColor ? this.props.dateColor : "text-red"} style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: `${0.14 * size}px`,
              padding: "2px 0 0 0"
            }}>
              {this.yearRange}
            </div>}
        </div>
      </div>

    );
  }

  RenderNameAvatar(avatar: string) {

    return (

      <Header as="h5" color="red">
        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", position: "relative", }}>
          {avatar}&nbsp;
          {this.displayName}
        </div>
      </Header>

    );
  }

  render() {

    if (this.props.profile.avatar) this.avatar = this.props.profile.avatar;
    if (this.props.profile.displayName) this.displayName = this.props.profile.displayName;
    this.gender = this.props.profile.gender;
    if (!(this.props.profile instanceof Profile)) {
      this.yearRange = this.props.profile.yearRange;
    }

    let size;
    if (this.props.size) size = this.props.size;
    let contents;

    if (this.props.profile instanceof Memorial && this.props.ownerProfile) {
      this.relationshipToProfile = this.props.profile.relationshipToProfile(this.props.ownerProfile);
    } else {
      this.relationshipToProfile = '';
    }

    switch (this.props.style) {
      case "avatar":
        this.avatarBlockStyle = "inline-block"
        if (!size) size = 30;
        if (!this.props.noText) {
          contents = this.RenderPortrait(size);
          contents = this.RenderNameAvatar(contents);
        } else {
          contents = this.RenderPortrait(size);
        }
        break;
      default:
        if (!size) size = 120;
        if (!this.props.noText) {
          this.avatarBlockStyle = "block";
          contents = this.RenderPortrait(size);
          contents = this.RenderNameDefault(size, contents);
        } else {
          this.avatarBlockStyle = "inline-block";
          contents = this.RenderPortrait(size);
        }
    }

    if (!this.props.noLink && (this.props.profile instanceof Profile || this.props.profile instanceof Memorial)) {
      return (
        <Link to={this.props.profile.url}>
          <div onClick={() => this.props.onClick && this.props.onClick()}>
            {contents}
          </div>
        </Link>
      );
    } else {
      return (
        <div onClick={() => this.props.onClick && this.props.onClick()}>
          {contents}
        </div>
      );
    }

  }

}

export default RenderAvatar;
