import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';

import {
  Grid,
  Container,
  Message,
  Menu,
  Icon,
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import CookieStore from 'state/cookieStore';

import GeneralTab from './GeneralTab';
import EmailTab from './EmailTab';
import PasswordTab from './PasswordTab';
import AdvancedTab from './AdvancedTab';

export interface Props {
  history?: any;
  accountStore?: AccountStore;
  cookieStore?: CookieStore;
}


@inject("cookieStore", "accountStore") @observer
class AccountSettingsPanel extends React.Component<Props, {}> {

  @observable activeItem: string = "";
  @observable panelContent: any;

  @observable showDeleteModal: boolean = false;

  constructor(props: Props, context) {
    super(props, context);

    this.activeItem = 'general';
    this.panelContent = <GeneralTab />;

  }

  handleTabClick(name) {

    this.activeItem = name;

    switch (name) {
      case "general":
        this.panelContent = <GeneralTab />;
        break;
      case "e-mail":
        this.panelContent = <EmailTab />;
        break;
      case "password":
        this.panelContent = <PasswordTab />;
        break;
      case "advanced":
        this.panelContent = <AdvancedTab />;
        break;

    }
  }

  goBack() {
    if (this.props.history.length > 0) {
      this.props.history.goBack();
      return;
    }
    this.props.history.push("/feed");
  }

  render() {

    return (

      <div>
        <Grid className="dark-blue no-mobile-padding fill-window-height">
          <Container>
            <Message floating style={{ marginTop: '40px' }}>
              <Message.Content>
                <Grid>
                  <Grid.Column width={12}>
                    <div style={{ margin: "0.411em 0.960em 0.411em 0em", float: "left", borderRight: "1px solid #d4d4d5" }}>
                      <Link to="#" onClick={() => { this.goBack() }}>
                        <Icon name='angle left' size='big' />
                      </Link>
                    </div>
                    <span><h1>Account Settings</h1></span>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Menu fluid vertical tabular>
                      <Menu.Item name='general' active={this.activeItem === 'general'} onClick={(e, { name }) => this.handleTabClick(name)} />
                      <Menu.Item name='e-mail' active={this.activeItem === 'e-mail'} onClick={(e, { name }) => this.handleTabClick(name)}>
                        E-mail
                        </Menu.Item>
                      <Menu.Item name='password' active={this.activeItem === 'password'} onClick={(e, { name }) => this.handleTabClick(name)} />
                      <Menu.Item name='advanced' active={this.activeItem === 'advanced'} onClick={(e, { name }) => this.handleTabClick(name)} />
                    </Menu>
                  </Grid.Column>
                  <Grid.Column stretched width={9} style={{ minHeight: "400px" }}>
                    {this.panelContent}
                  </Grid.Column>

                </Grid>
              </Message.Content>
            </Message>
          </Container>
        </Grid>
      </div>
    );
  }

}

export default AccountSettingsPanel;