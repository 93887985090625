import * as React from 'react';

import {
  Grid, 
  Container,
  Message
} from 'semantic-ui-react';

function ContactDetails (): JSX.Element {
  return (
    <Grid className="dark-blue no-mobile-padding fill-window-height">
    <Container>
      <Message floating style={{marginTop: '40px'}}>
        <Message.Content>
          <h1>Contact Details</h1>
          <a href="mailto:support@memoriatree.com" target="_blank">support@memoriatree.com</a>
          <p>MemoriaTree AB<br />Postbox 20<br />191 21, Sollentuna<br />SWEDEN</p>
        </Message.Content>
      </Message>
    </Container>
  </Grid>
  );
}

export default ContactDetails;