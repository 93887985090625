import * as React from 'react';
import { observable, autorun } from "mobx";
import { observer, inject, } from "mobx-react";
import { Link, withRouter } from 'react-router-dom';

import {
  Modal,
  Form,
  Input,
  Button,
  Grid,
  Header,
  List,
  Image,
  Icon,
  Popup
} from 'semantic-ui-react';

import {
  Memorial,
  Profile
} from 'state/objects';

import { MemorialStore, FamilyTreeStore } from 'state/stores'
import AccountStore from 'state/account';

import { FamilyTree } from 'state/familyTree';

import TreeGraphic from 'images/noun_Tree_2584135.svg';
import { ReactComponent as TreeGraphic2 } from 'images/noun_Tree_2584135.svg';
import UserAvatar from 'components/Generic/Avatar';

export interface Props {
  accountStore?: AccountStore;
  memorialStore?: MemorialStore;
  familyTreeStore?: FamilyTreeStore;
  familyTree: FamilyTree;
  treeIsLocked: boolean;
  targetName?: string;
  onSelect(memorial: Profile | Memorial): any;
  open: boolean;
  onClose(): any;
}


@inject('accountStore', 'memorialStore', 'familyTreeStore') @observer
class ChooseTreeModal extends React.Component<Props, {}> {

  accountStore: AccountStore;
  memorialStore: MemorialStore;
  familyTreeStore: FamilyTreeStore;

  @observable showCancelModal: boolean = false;
  @observable selectedMemorial: any;

  constructor(props: Props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.memorialStore = this.props.memorialStore!;
    this.familyTreeStore = this.props.familyTreeStore!;
  }

  componentDidMount() {
    if (this.accountStore.myProfile) {
      this.accountStore.myProfile.fetchMemorials();
    }
  }

  //done to ensure the first modal is still scrollable
  setBodyScrolling() {
    setTimeout(() => {
      document.body.classList.toggle('scrolling', true);
    }, 0);
  }

  selectTree(tree: FamilyTree) {
    if (tree) {
      let profile = tree.treeUsers.find(u => u instanceof Profile && u.userIsOwner);
      if (!profile) {
        profile = tree.treeUsers.find(u => u instanceof Memorial && u.userIsCreator);
      }
      if (!profile) {
        profile = tree.treeUsers.find(u => u.userIsOwner);
      }
      if (!profile) {
        throw 'No Profile/Memorial under user\'s control in family tree';
      }
      this.props.onSelect(profile);
    }
  }

  renderTreeItem(tree: FamilyTree) {

    return (

      <Button key={tree._id} basic fluid style={{ marginBottom: "7px", padding: "15px" }} onClick={() => this.selectTree(tree)}>
        <Image style={{ float: "left" }}>
          <div style={{
            backgroundColor: "rgb(220, 42, 84)",
            height: "100px",
            minHeight: "100px",
            maxHeight: "100px",
            minWidth: "100px",
            maxWidth: "100px",
            width: "100px",
            borderRadius: "50%",
            position: "relative",
            float: "left"
          }}>
            <object data={TreeGraphic} type="image/svg+xml" style={{ position: "absolute", bottom: "-5px", left: "15px", width: "75px" }} />
          </div>
        </Image>
        <div style={{ marginLeft: "115px" }}>
          <div style={{ width: "100%" }}>
            <Grid>
              {tree.treeUsers.slice(0, 5).map(user => this.renderTreeMember(user))}
              {tree.treeUsers.length > 5 &&
                <Grid.Column computer={4} tablet={6} mobile={6}>
                  <Popup
                    content={
                      <div>
                        {tree.treeUsers.slice(5, tree.treeUsers.length).map(user => this.renderTreeMember(user))}
                      </div>
                    }
                    trigger={
                      <Header as="h5" color="red">
                        And {tree.treeUsers.length - 4} more...
                      </Header>
                    }
                  />
                </Grid.Column>
              }
            </Grid>
          </div>
        </div>

      </Button>

    );

  };

  renderTreeMember(member?: Profile | Memorial) {

    if (!member) return null;

    return (
      <Grid.Column key={member._id} computer={4} tablet={6} mobile={6} >
          <UserAvatar
            profile={member}
            size={30}
            style={"avatar"}
            noShadow={true}
          />
      </Grid.Column>
    );
  }

  render() {

    let { open, onClose } = this.props;

    return !this.props.treeIsLocked ? (
      <Modal open={true} onClose={onClose} size="tiny">
        <Modal.Header>
          Select FamilyTree
        </Modal.Header>
        <Modal.Content scrolling>
          <p>
            Please select which one of your FamilyTrees you want to connect to {this.props.targetName}'s FamilyTree:
          </p>
          {this.familyTreeStore.userFamilyTrees
            .filter(tree => tree._id !== this.props.familyTree._id)
            .map(tree => this.renderTreeItem(tree))
          }
        </Modal.Content>
        <Modal.Actions>
          <div>
            <Button fluid onClick={() => { this.props.onClose() }}>
              Cancel
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    ) : (
        <Modal open={true} onClose={onClose} size="tiny">
          <Modal.Header>
            FamilyTree Is Locked
        </Modal.Header>
          <Modal.Content scrolling>
            <p>
              This FamilyTree already has a pending merge request, and needs to either
              accept or reject that request before you can merge your any of FamilyTrees with it. Please try again later.
          </p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => { this.props.onClose() }}>
              Ok
          </Button>
          </Modal.Actions>
        </Modal>
      );
  }
}



export default ChooseTreeModal;
