import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import remove from 'lodash/remove';
import find from 'lodash/find';
import moment from 'moment';
import { service, restClient } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import { IPhoto } from 'state/apiClient/ServiceTypes';
import StoreBaseClass from 'state/stores/storeBaseClass';
import {
  Photo,
  Memorial
} from 'state/objects';
import RootStore from 'state/rootStore';

/** Store containing photos uploaded to a memorial */
class PhotoStore extends StoreBaseClass {

  service: Service<IPhoto>;

  @observable photos: Photo[] = [];

  /**
   * Creates a new store
   * @param memorial - Memorial the photos are uploaded too
   */
  constructor (rootStore: RootStore) {
    super(rootStore);
    this.service = service('photos');
  }

  /** Fetches all photos belonging to memorial from server */
  @action
  async fetchAll () {
    throw new Error('PhotoStore.fetchAll() not implemented');
  }

  @action
  async fetchById (id: number): Promise<Photo> {
    try {
      const response = await this.service.get(id);
      await this.rootStore.profileStore.fetchById(response.ownerId);
      return this.addToArray(response);
    } catch (err) {
      throw new Error('PhotoStore.fetchById() - ' + err);
    }
  }

  @action
  async fetchByMemorialId (id: number): Promise<Photo[]> {
    try {
      const params = { query: {
        kind: 'memorials',
        parentId: id
      }};
      const response = await this.service.find(params) as IPhoto[];
      
      const photoIds = response.map(p => p._id);
      this.clearRemoved(id, photoIds);

      const profileIds = response.map(p => p.ownerId)
      await this.rootStore.profileStore.fetchByIds(profileIds);
      
      const photos = response.map(p => this.addToArray(p));
      return photos;
    } catch (err) {
      throw new Error('PhotoStore.fetchByMemoryId() - ' + err);
    }
  }

  /**
   * Uploads a new photo to the memorial
   * @param file - The image object
   */
  @action
  async add (profileId: number, file: File): Promise<Photo> {

    try {
      var data = new FormData();
      data.append('image', file);
      data.append('kind', 'memorials');
      data.append('parentId', profileId.toString());
      
      const response = await restClient('photos', {
        body: data,
        method: 'POST'
      });

      const photo = this.addToArray(parsePhotoData(response));
      return photo;
    } catch (err) {
      throw new Error('PhotoStore.add() ' + err.message)
    } 
  }

  @action
  addToArray (data: IPhoto): Photo {
    let photo = find(this.photos, c => c._id === data._id);

    if (photo) {
      Object.assign(photo, data);
    } else {
      photo = new Photo(this.rootStore, this, data);
      this.photos.push(photo);
    }
    return photo;
  }

  @action
  clearRemoved (memorialId: number, photoIds: number []) {
    remove(this.photos, p => p.parentId === memorialId && photoIds.indexOf(p._id) < 0);
  }

  /**
   * Deletes a photo
   * @param id - id number of the photo
   */
  @action
  async remove (id: number) {

    try {
      await this.service.remove(id);

      runInAction('photo deleted', () => {
        remove(this.photos, v => v._id === id);
      });
    } catch (err) {
      throw new Error('PhotoStore.remove() ' + err);
    }
  }
}

function parsePhotoData (data): IPhoto {
  data.createdAt = moment(data.createdAt);
  return data;
}

export default PhotoStore;