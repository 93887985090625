import {
  observable,
  action,
  computed,
  runInAction,
  toJS
} from 'mobx';

import remove from 'lodash/remove';
import find from 'lodash/find';
import uniq from 'lodash/uniq';

import { Service } from '@feathersjs/feathers';
import { IMemorial, IProfile } from 'state/apiClient/ServiceTypes';
import { service } from 'state/apiClient';
import StoreBaseClass from 'state/stores/storeBaseClass';
import {
  Profile,
  Memorial
} from 'state/objects'
import RootStore from 'state/rootStore';
import * as moment from 'moment';

/** Store containing profile objects */
class ProfileStore extends StoreBaseClass {

  @observable profiles: Profile[] = [];
  service: Service<IProfile>;

  /**
   * Creates a new store
   * @param rootStore - Root store
   */
  constructor(rootStore: RootStore) {
    super (rootStore);
    this.service = service('profiles');

    this.initEvents();
  }

  initEvents () {
    this.service.on('patched', p => this.onPatched(p));
    this.service.on('updated', p => this.onPatched(p));
  }

  /** Fetches all profiles from the server - NOT IMPLEMENTED */
  @action
  async fetchAll (): Promise<Profile[]> {
    try {
      const response = await this.service.find() as IProfile[];
      const profiles = response.map(p => this.addToArray(p));
      return profiles;
    } catch (err) {
      throw new Error('ProfileStore.fetchAll() - ' + err);
    }
  }

  @action
  async fetchById (id: string | number): Promise<Profile> {
    try {
      const response = await this.service.get(id);
      const profile = this.addToArray(response);
      return profile;
    } catch (err) {
      throw new Error('ProfileStore.fetchById - ' + err);
    }
  }

  @action
  async fetchByIds (ids: number[]): Promise<Profile[]> {
    ids = uniq(ids);
    return Promise.all(
      ids.map(id => {
        return this.service.get(id)
          .then(response => {
            const profile = this.addToArray(response);
            return profile;
          })
      })
    )
  }

  @action
  addToArray (data: IProfile): Profile {
    let profile = find(this.profiles, p => p._id === data._id);

    if (profile) {
      Object.assign(profile, data);
    } else {
      profile = new Profile(this.rootStore, this, data);
      this.profiles.push(profile);
    }
    return profile;
  }

  @action
  onPatched (profile: IProfile) {
    if (profile.dateOfBirth) {
      profile.dateOfBirth = moment(profile.dateOfBirth);
    }
    this.addToArray(profile);
  }

  @action
  async add () {
    throw new Error('ProfileStore.add() not implemented');
  }

  /**
   * NOT IMPLEMENTED
   * @param id - id number of the profile
   */
  @action
  async remove (id: number) {
    throw new Error('ProfileStore.remove() not implemented');
  }
}

export default ProfileStore;
