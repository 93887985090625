import * as React from 'react';
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import { Switch, Route, Link, withRouter } from 'react-router-dom';

import {
  Form,
  Grid,
  Menu,
  Container,
  Button,
  Icon,
  Modal,
  Message,
  Item,
  Breadcrumb
} from 'semantic-ui-react';

import AccountStore from 'state/account';
import uiStore from 'state/ui';

import StoreItem from 'components/StorePage/StoreItem';


interface Props {
  history?: any;
  accountStore: AccountStore;
  uiStore: uiStore;
}


@inject('accountStore', 'uiStore', 'memorialStore') @observer
class StorePage extends React.Component<Props, {}> {

  accountStore: AccountStore;
  uiStore: uiStore;

  constructor(props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore!;
    this.uiStore = this.props.uiStore!;
  }

  componentDidMount() {
    //this.uiStore.storeActive = true;
    //if (this.accountStore.myProfile && this.accountStore.myProfile._id)
    // return this.props.history.push('/profile/' + this.accountStore.myProfile._id);
  }

  componentWillUnmount() {
    //this.uiStore.storeActive = false;
  }

  render() {

    return (
      <div className="dark-blue" style={{ paddingTop: "40px", }}>
        <div className="no-mobile-padding">
          <Container style={{ margin: "15px 0 0 0", paddingBottom: "50px" }}>
            <Message>
              <Message.Content style={{ paddingBottom: "45px" }}>
                <Grid style={{ margin: "0 0rem 0 0rem" }}>
                  <Grid.Row>
                    <Grid.Column style={{ paddingTop: "0px" }}>
                      <h1 className="memtree-cursive">
                        The MemoriaTree Shop
                      </h1 >
                      <Menu>
                        <Menu.Item>
                          <Breadcrumb>
                            <Breadcrumb.Section link>Store Home</Breadcrumb.Section>
                            {/*}<Breadcrumb.Divider />-->
                            <Breadcrumb.Section active>MemoriaTree Gold</Breadcrumb.Section>*/}
                          </Breadcrumb>
                        </Menu.Item>
                        <Menu.Menu position='right'>
                          <Menu.Item>
                            <a target="_blank"><Icon name="shopping cart" />Cart: 0</a>
                          </Menu.Item>
                        </Menu.Menu>
                      </Menu>
                    </Grid.Column>
                  </Grid.Row>
                  <Item.Group divided link>
                    <StoreItem
                      itemName="MemoriaTree Premium"
                      descr="With a MemoriaTree Premium Membership you get two free Featured Memorials per month, and you help support the development and maintenance of the MemoriaTree website."
                      price="$5 per month"
                    />
                    <StoreItem
                      itemName="Feature Memorial"
                      descr="Feature one of your memorials in the Featured Memorials section of the front page."
                      price="$5"
                    />
                    <StoreItem
                      itemName="Ancestral Research Services"
                      descr="Team up with a professional researcher to explore your past."
                      price="$50"
                    />
                    <StoreItem
                      itemName="DNA Testing Kit"
                      descr="Discover your past with this simple-to-use DNA testing kit from DNA Testing Company Inc."
                      price="$49"
                    />
                  </Item.Group>
                </Grid>
                <div style={{ height: "50px" }}></div>
              </Message.Content>
            </Message>
          </Container>
        </div>
        <div style={{ height: "150px" }}></div>
      </div>

    );
  }

}

export default withRouter(StorePage);
