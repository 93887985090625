import * as React from 'react';
//import DevTools from 'mobx-react-devtools';
import Footer from 'components/Footer';
import Main from 'components/Main';
import MobileHeader from 'components/TopBar/contentMobile';

const isDevelopment = process.env.NODE_ENV !== 'production';

class App extends React.Component {
  render () {
    return (
      <div>
        <MobileHeader />
        <Main />
        <Footer />
        {/*isDevelopment && <DevTools position={{bottom: 0, left: 20}} />*/}
      </div>
    );
  }
};

export default App;
