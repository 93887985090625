import * as React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'mobx-react';

import {
  initializeStores,
  accountStore,
  searchStore,
  uiStore,
  memorialStore,
  profileStore,
  guestbookEntryStore,
  commentStore,
  connectionStore,
  photoStore,
  videoStore,
  chatStore,
  notificationStore,
  messageStore,
  familyTreeStore,
  cookieStore,
  reportStore
} from 'state';

import "video-react/dist/video-react.css";
import "semantic-ui-less/semantic.less";
import './styles/main.scss';
import "react-progress-2/main.css";

import {
  Router
} from 'react-router-dom';
import history from './history';
import moment from 'moment';
import App from 'components/App';
import Sidebar from 'components/TopBar/mobileSidebar';
import TopBar from 'components/TopBar';

//polyfill for canvas.toBlob()
import 'blueimp-canvas-to-blob';


window.addEventListener('error', function (e) {
  var stack = e.stack;
  var message = e.toString();

  if (stack) {
    message += '\n' + stack;
  }

  console.log(message);
  console.log(e);
});


//set date locale
var locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

// DEV SITE
// Sentry.init({ dsn: "https://4e61c09f1add47f68ac126180861c3cc@sentry.io/1551918" });
// LogRocket.init('bkcuzk/memoriatree-dev-server');

// BETA SITE
// Sentry.init({dsn: "https://31075fb575dd4d9f8eb72a1fa9fd8996@sentry.io/1551943"});
// LogRocket.init('bkcuzk/memoriatree-beta-server');

const renderApp = (stores) => {
  render(
    <AppContainer>
      <Provider {...stores}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </AppContainer>,
    document.getElementById('app')
  );
};

const renderTopbarAndSidebar = (stores) => {
  render(
    <AppContainer>
      <Provider {...stores}>
        <Router history={history}>
          <TopBar />
        </Router>
      </Provider>
    </AppContainer>,
    document.getElementById('topbar')
  );

  render(
    <AppContainer>
      <Provider {...stores}>
        <Router history={history}>
          <Sidebar />
        </Router>
      </Provider>
    </AppContainer>,
    document.getElementById('sidebar')
  );

};

initializeStores();
const stores = {
  cookieStore,
  accountStore,
  uiStore,
  searchStore,
  profileStore,
  memorialStore,
  guestbookEntryStore,
  commentStore,
  connectionStore,
  videoStore,
  photoStore,
  chatStore,
  notificationStore,
  messageStore,
  familyTreeStore,
  reportStore
};

renderTopbarAndSidebar(stores);
renderApp(stores);

if (module.hot) {
  module.hot.accept();
  /*
  module.hot.accept('./routes', () => {
    const newRoutes = require('./routes');
    renderApp(newRoutes);
  }); */
}
