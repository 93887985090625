import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import { Profile } from 'state/objects';
import { MessageStore } from 'state/stores';
import { service } from 'state/apiClient';
import { Moment } from 'moment';
import RootStore from 'state/rootStore';
import { IMessage } from 'state/apiClient/ServiceTypes';

class Message {

  rootStore: RootStore;

  @observable _id: number;
  @observable authorId: number;
  @observable chatId: number;
  @observable text: string = '';
  @observable createdAt: Moment;
  @observable editedAt: Moment;

  constructor (rootStore: RootStore, store: MessageStore, message: IMessage) {
    this.rootStore = rootStore;
    Object.assign(this, message);
  }

  @computed
  get author (): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.authorId);
  }

  @computed
  get authorIsUser (): boolean {
    const loggedInProfile = this.rootStore.accountStore.myProfile;
    if (!loggedInProfile) {
      return false;
    }
    return this.authorId === loggedInProfile._id;
  }

}

export default Message;