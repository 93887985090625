import {
  observable,
  action,
  computed,
  runInAction
} from 'mobx';

import pick from 'lodash/pick';
import extend from 'lodash/extend';

import { Service } from '@feathersjs/feathers';
import { IGuestbookEntry } from 'state/apiClient/ServiceTypes';
import {
  GuestbookEntryStore,
  CommentStore
} from 'state/stores';
import { Moment } from 'moment';
import { service } from 'state/apiClient';
import ObjectBaseClass from './objectBaseClass';
import {
  Profile,
  Memorial,
  Comment
} from 'state/objects';
import { MemoryForm } from 'state/stores/forms';
import { accountStore } from 'state';
import { createViewModel, IViewModel } from 'mobx-utils';
import RootStore from 'state/rootStore';

/** @todo Change to guestbook entry / write new class */
class GuestbookEntry extends ObjectBaseClass {

  service: Service<IGuestbookEntry> = service('guestbook-entries');

  rootStore: RootStore;
  store: GuestbookEntryStore;

  @observable _id: number;
  @observable text: string = '';
  @observable title: string = '';
  @observable createdAt: Moment;
  
  @observable authorId: number;
  @observable memorialId: number;

  @observable loading: boolean = false;

  @observable editModel: GuestbookEntry & IViewModel<GuestbookEntry>;
  isEditModel: boolean = false;

  /**
   * 
   * @param store - Store holding memory
   * @param entry - Memory data - in case of already existing memory. Not needed when creating new memory
   */
  constructor (rootStore: RootStore, store: GuestbookEntryStore, entry?: IGuestbookEntry) {
    super(rootStore, store);

    if (entry) {
      const data = pick(entry, [
        'text',
        'createdAt',
        'title',
        '_id',
        'memorialId',
        'authorId'
      ]);
  
      Object.assign(this, data);
    }
    this.editModel = createViewModel(this);
  }

  @computed 
  get author (): Profile | undefined {
    return this.rootStore.profileStore.profiles.find(p => p._id === this.authorId);
  }

  @computed 
  get memorial (): Memorial | undefined {
    return this.rootStore.memorialStore.memorials.find(m => m._id === this.memorialId);
  }
  
  @computed 
  get comments (): Comment[] {
    return this.rootStore.commentStore.comments.filter(c => c.parentId === this._id);
  }

  @action
  async fetchComments (): Promise<Comment[]> {
    return this.rootStore.commentStore.fetchByGuestbookEntryId(this._id);
  }

  /** Whether or not the user is allowed to delete memory */
  @computed
  get canDelete (): boolean {
    if (!this._id) {
      return false;
    }

    const profile = accountStore.myProfile;

    if (!profile) {
      return false;
    }

    if (profile._id === this.authorId) return true;

    return this.memorial !== undefined && profile._id === this.memorial.ownerId
  
  }

  /** Whether or not the user is allowed to edit memory */
  @computed
  get canEdit (): boolean {
    if (!this._id) {
      return false;
    }

    const profile = accountStore.myProfile;
    
    if (!profile) {
      return false;
    }
  
    return profile._id === this.authorId;
  }

  /** NOT IN USE */
  @action
  setTitle (value: string) {
    this.editModel.title = value; 
  }

  /** NOT IN USE */
  @action
  setText (value: string) {
    this.editModel.text = value; 
  }

  /** Returns the form object used to edit memory */
  getForm (): Promise<any> {
    const form = new MemoryForm(this);
    return form.loadRules();
  }

  /** Save the changes or uploads new memory to server. Don't use directly. Save through the form object */
  @action
  async saveEdit () {

    if (!this._id) {
      return this.store.add(this.memorialId, this.editModel.text);
    }

    this.loading = true;
    const body = pick(this.editModel, [
      'text',
      'title'
    ]);

    try {
      const response = await this.service.patch(this._id, body, {});

      runInAction(`memory ${this._id} updated`, () => {
        const data = pick(response, [
          'text',
          'title',
          'createdAt'
        ]);
        extend(this, data);
        this.editModel.reset();
      });
    } catch (err) {
      throw new Error('Memory.saveEdit(), ' + err);
    } finally {
      this.loading = true;
    }
  }

  /** Only used internally */
  @action
  resetEdit () {
    this.editModel.reset();
  }

  /** Deletes memory from server */
  @action
  remove () {
    this.loading = true;
    return this.store.remove(this._id);
  }
}

export default GuestbookEntry;