//*********************************************
// Holds all constants for rendering the tree
//********************************************* 

const constants = {

    nodeWidth: 80,
    nodeHeight: 120,
    nodePadding: 60,

    pictureWidth: 80,
    labelHeight: 40,
    
    frameBorderWidth: 4,
    frameBorderWidthConnected: 12,
    frameBorderShadow: "drop-shadow( 0px 3px 5px rgba(0,0,0,0.75))",
    frameBorderShadowDropped: "drop-shadow( 0px 0px 6px rgba( 255, 197, 43, 1))",
    opacity: 0.7,

    colorFemale: "rgb(239, 81, 136)",
    colorMale: "rgb(87, 114, 144)",

    rootScale: 1,
    rootParentScale: 1,
    rootChildScale: 1,
    rootSiblingScale: 1,

}




export default constants;