import { Application, HooksObject } from '@feathersjs/feathers';
import serviceTypes from '../ServiceTypes';
process.hrtime = require('browser-process-hrtime');
import populate from 'state/apiClient/hooks/populate';
import { renameField } from 'state/apiClient/hooks';
import {
  debug,
  iff,
  isProvider
} from 'feathers-hooks-common';

import {
  rename,
  remove,
  negate
} from './hooks';

const renameBefore = [
  //renameField('id', '_id')
];

const renameAfter = [
  //renameField('_id', 'id')
];

const hooks = {
  before: {
    all: [
      ...renameBefore
    ],
    find: [],
    get: [],
    create: [ 
      
    ],
    update: [ ...renameBefore  ],
    patch: [ ...renameBefore ],
    remove: [ ]
  },

  after: {
    all: [
      ...renameAfter,
    ],
    find: [
    ],
    get: [
    ],
    create: [
    ],
    update: [
    ],
    patch: [
    ],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default function (app: Application<serviceTypes>) {
  const connectionService = app.service('connections');
  connectionService.hooks(hooks);
};
