import {
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import find from 'lodash/find';
import remove from 'lodash/remove';
import StoreBaseClass from 'state/stores/storeBaseClass';
import { service } from 'state/apiClient';
import { Service } from '@feathersjs/feathers';
import { IComment } from 'state/apiClient/ServiceTypes';
import {
  GuestbookEntry,
  Comment
} from 'state/objects';
import RootStore from 'state/rootStore';

/** Store holding comments. Currently only used for guestbook entry replies */
class CommentStore extends StoreBaseClass {

  service: Service<IComment>;

  @observable comments: Comment[] = [];

  /**
   * Creates a new Store
   * @param memory - The memory/guestbook entry the comments in this store are replies to 
   */
  constructor(rootStore: RootStore) {
    super(rootStore);
    this.service = service('comments');
  }

  /** Fetches all comments on the memory from the server */
  @action
  async fetchAll(): Promise<Comment[]> {
    throw new Error('CommentStore.fetchAll() not implemented');
  }

  @action
  async fetchById(id: number): Promise<Comment> {
    try {
      const response = await this.service.get(id);
      await this.rootStore.profileStore.fetchById(response.authorId);
      return this.addToArray(response);
    } catch (err) {
      throw new Error('CommentStore.fetchById() - ' + err);
    }
  }

  @action
  async fetchByGuestbookEntryId(postId: number): Promise<Comment[]> {
    const params = {
      query: {
        kind: 'guestbookEntries',
        parentId: postId
      }
    };
    try {
      const response = await this.service.find(params) as IComment[];

      const commentIds = response.map(c => c._id);
      this.clearRemoved(postId, commentIds);

      const profileIds = response.map(c => c.authorId);
      await this.rootStore.profileStore.fetchByIds(profileIds);

      const comments = response.map(m => this.addToArray(m));
      return comments;
    } catch (err) {
      throw new Error('CommentStore.fetchByGuestbookEntryId() - ' + err);
    }
  }

  /**
   * Post a new comment.
   * @param text - The comment text.
   */
  @action
  async add(parentId: number, text: string): Promise<Comment> {
    if (!text) {
      throw new Error('CommentStore.add() needs non-empty text');
    }

    const body: Partial<IComment> = {
      parentId: parentId,
      kind: 'guestbookEntries',
      text
    };

    try {
      const response = await this.service.create(body);
      const comment = this.addToArray(response);
      return comment;
    } catch (err) {
      throw new Error('CommentStore.add() ' + err);
    }
  }

  @action
  addToArray(data: IComment): Comment {
    let comment = find(this.comments, c => c._id === data._id);

    if (comment) {
      Object.assign(comment, data);
    } else {
      comment = new Comment(this.rootStore, this, data);
      this.comments.push(comment);
    }
    return comment;
  }

  @action
  clearRemoved(parentId: number, commentIds: number[]) {
    remove(this.comments, c => c.parentId === parentId && commentIds.indexOf(c._id) < 0);
  }

  /**
   * Deletes a comment from the server
   * @param id - id number of the comment
   */
  @action
  async remove(id: number) {
    try {
      await this.service.remove(id);

      runInAction(`comment ${id} removed`, () => {
        remove(this.comments, c => c._id === id);
      });
    } catch (err) {
      throw new Error('CommentStore.remove() ' + err);
    }
  }

  getNewComment(parentId: number, kind: string): Comment {
    return new Comment(this.rootStore, this, { parentId, kind } as any);
  }
}

export default CommentStore;