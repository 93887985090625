import feathers, { Application, Service } from '@feathersjs/feathers';
import services from './services';

import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';

import serviceTypes from './ServiceTypes';

let instance: Application<serviceTypes>;
let restInstance: Application<serviceTypes>;

const appHooks = {
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  after: {
    all: [],
    find: [
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export function feathersClient(): Application<serviceTypes> {
  if (instance) return instance;

  const socket = io(process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:3030', {
    transports: ['websocket'],
    forceNew: true
  });

  instance = feathers()
    .configure(socketio(socket))
    .configure(services)
    .configure(auth({
      storage: window.localStorage
    }));

  instance.hooks(appHooks);

  return instance;
}

export function restClient(url: string, options: RequestInit = {}, onProgress?: any): Promise<any> {

  url = url[0] === '/' ? url.substring(1) : url;

  return feathersClient().passport.getJWT()
    .then(jwt => {

      const authHeader = process.env.NODE_ENV === 'production' ? {
        'Authorization-jwt': jwt
      } : { 'Authorization': jwt };

      const headers = Object.assign(
        {},
        options ? options.headers || {} : {},
        authHeader
      );
      options = Object.assign({}, options, { headers });

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(options.method || 'get', `/${url}`, true);

        if (process.env.NODE_ENV === 'production') {
          xhr.withCredentials = true;
        }

        for (var k in options.headers || {}) {
          xhr.setRequestHeader(k, options.headers![k]);
        }

        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200 || xhr.status === 201 || xhr.status === 202) {
              resolve(JSON.parse(xhr.response));
            } else {
              reject(JSON.parse(xhr.response));
            }
          }
        }

        xhr.onerror = () => {
          reject();
        };
        if (xhr.upload && onProgress) {
          xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        }

        xhr.send(options.body);
      })
    })


  /*
  return feathersClient().passport.getJWT()
  .then(jwt => {

    const headers = Object.assign(
      {}, 
      options ? options.headers || {}  : {}, 
      { 'Authorization': jwt });
    const data = Object.assign({}, options, { headers });
    return fetch(url, data);
  })*/
}

export function service<L extends keyof serviceTypes>(name: L): Service<serviceTypes[L]> {
  return feathersClient().service(name);
}
