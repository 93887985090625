import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import sizeMe from 'react-sizeme';

import ComputerBar from './contentComputer';
import TabletBar from './contentTablet';
import MobileBar from './contentMobile';
import StoreBar from './StoreBar';

import CookieNotice from '../CookieNotice';

import Progress from "react-progress-2";

import NotFound from 'components/NotFound';
import {
  Modal,
  Message
} from 'semantic-ui-react';

import accountStore from 'state/account';
import cookieStore from 'state/cookieStore';
import uiStore from 'state/ui';

interface ITopBarProps {
  uiStore: uiStore;
  accountStore: accountStore,
  cookieStore: cookieStore,
  size: {
    width: number,
    height: number
  },
  history: any
}

@inject('uiStore', 'accountStore', 'cookieStore') @observer
class TopBar extends React.Component<ITopBarProps> {

  accountStore: accountStore;
  cookieStore: cookieStore;
  uiStore: uiStore;
  mode: "computer" | "computer-small" | "tablet" | "mobile";

  constructor(props, context) {
    super(props, context);
    this.accountStore = this.props.accountStore;
    this.cookieStore = this.props.cookieStore;
    this.uiStore = this.props.uiStore;
  }

  render() {
    //this.accountStore = this.props.accountStore;

    const { width } = this.props.size;

    if (width >= 1075) this.mode = "computer";
    else if (width > 875) this.mode = "computer-small"
    else if (width > 750) this.mode = "tablet"
    else if (width <= 750) this.mode = "mobile"
    
    console.log(this.mode);
 
    //const isMobile = !isComputer && !isTablet;

    return (
      <header id="app-header" className="fixed">
        <Progress.Component />
        <ComputerBar mode={this.mode}/>
        {/*}
        {isComputer && <ComputerBar mode="computer"/>}
        {isTablet && <ComputerBar mode="tablet"/>}
        {isMobile && <MobileBar />}*/}
        {this.uiStore.storeActive && <StoreBar />}
        {/*!this.cookieStore.acceptsCookies &&
            <CookieNotice />
        */}
      </header>
    );
  }

  signOut() {
    this.accountStore.logout()
    .then(() => this.props.history.push('/'));
  }
};

// @ts-ignore
const TopBarExport: TopBar = sizeMe()(withRouter(TopBar));

export default TopBarExport;
