import {
  observable,
  action,
  computed
} from 'mobx';
import * as uuid from 'uuid/v4';

import Person from './person';
import constants from 'components/FamilyTree/FamilyTreeWindow/RenderingConstants';
import { scale } from 'transformation-matrix';

class FamilyTreeNode {

  person: Person;

  @observable x: number;
  @observable y: number;

  hasCalculated: boolean = false;

  constructor(person: Person) {
    this.person = person;
  }

  @computed
  get nodeScale(): number {
    if (this.person.isRoot) {
      return constants.rootScale;
    }
    else {
      return 1;
    }
  }

  @computed
  get height(): number {
    return constants.nodeHeight;
  }

  @computed
  get width(): number {
    return constants.nodeWidth;
  }

  @computed
  get padding(): number {
    return constants.nodePadding;
  }

  @computed
  get boxHeight() {
    return this.height + this.padding;
  }

  @computed
  get boxWidth() {
    return this.width + this.padding;
  }

  //x and y is in 1, 2, 3 etc
  //I still dont get it? shouldnt the box width put the X to the right?
  @computed
  get renderX() {
    if (!this.x && this.x !== 0) return 0;

    return this.x * this.boxWidth;
  }

  @computed
  get renderY() {
    if (!this.y && this.y !== 0) return 0;

    return this.y * this.boxHeight;
  }

  @computed
  get leftX() {
    return this.renderX;
  }

  @computed
  get rightX() {
    if (!this.x && this.x !== 0) return 0;

    return this.x * this.boxWidth + this.width;
  }

  @computed
  get topY() {
    return this.renderY;
  }

  @computed
  get bottomY() {
    if (!this.y && this.y !== 0) return 0;

    return this.y * this.boxHeight + this.height;
  }

  @computed
  get middleX() {
    if (!this.x && this.x !== 0) return 0;

    return this.x * this.boxWidth + this.width / 2;
  }

  @computed
  get middleY() {
    if (!this.y && this.y !== 0) return 0;

    return this.y * this.boxHeight + this.height / 2;
  }

  @computed
  get childWidth(): number {
    if (this.person.childrenToRender.length === 0) {
      return this.person.partnersToRender.length + 1;
    }

    return this.person.childrenToRender.reduce(
      (sum, child) => sum + Math.max(child.node.childWidth, child.partnersToRender.length + 1)
      , 0);
  }

  @computed
  get leftWidth(): number {
    if (!this.person.hasPartner) {
      return this.childWidth / 2;
    }
    const partner = this.person.partners[0];
    if (partner.node.isLeftAligned) {
      return this.childWidth / 2 + 0.5;
    }
    return this.childWidth / 2 - 0.5;
  }

  @computed
  get rightWidth(): number {
    return this.childWidth - this.leftWidth;
  }

  @computed
  get ancestorWidth(): number {
    const { parentsToRender } = this.person;
    if (parentsToRender.length === 0) {
      return 1;
    }
    return parentsToRender
      .map((parent) => parent.node.ancestorWidth)
      .reduce((acc, curr) => acc + curr, 0);
  }

  @computed
  get shouldRender(): boolean {
    const { person } = this;
    if (person.isRoot) return true;
    if (person.rank === null) return false;

    const { partners, children, parents } = person;

    //partners
    if (!person.isRelatedToRoot && partners.length === 0) return false;
    if (!person.isRelatedToRoot && !partners[0].isRelatedToRoot) return false;

    //older generation
    if (person.rank >= 2 && !person.isAnscestorOfRoot) return false;

    if (!person.isRelatedToRoot && partners[0].node.shouldRender) return true;

    if (person.parents.length > 0 && !parents[0].node.shouldRender) return false;

    return true;
  }

  @computed
  get hasHiddenParents(): boolean {
    return this.person.parents.filter(p => !p.node.shouldRender).length > 0;
  }

  @computed
  get isLeftAligned(): boolean {
    const { partnerships } = this.person;
    if (partnerships.length > 0) {
      const partnership = partnerships[0];
      return partnership.mother === this.person;
    }
    return false;
  }

}

export default FamilyTreeNode;
